import { EstoqueService } from './../../estoque.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SYSCONF_SIGLA } from 'src/app.api';
import { LoginService } from 'src/app/login/login.service';
@Component({
	selector: 'app-movimentacao-edit',
  	templateUrl: './movimentacaoEdit.component.html',
  	styleUrls: ['./movimentacaoEdit.component.scss']
})
export class MovimentacaoEditComponent implements OnInit{
	public message:string = '';
	movimentacao_id:number;
	sigla = SYSCONF_SIGLA;
	anexos = new Array();
	constructor(private estoqueService: EstoqueService, private activatedRoute: ActivatedRoute, private loginService: LoginService){
		this.estoqueService.getById('movimento',this.activatedRoute.snapshot.params['id']).subscribe(
			(success) =>{
				this.movimentacao_id = success.dados.id;
				this.anexos = success.dados.anexo;
			},
			(error) => {
				this.message = error.error.message;
			})
	}
	ngOnInit(){
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.estoque_movimentacao.includes("EDITAR"))
			this.message = 'Você não possui permissão para executar esta ação'
	}

	getPermissao(modulo,secao){
		return this.loginService.getPermissao(modulo,secao);
	}
	
	
}