import { DataService } from 'src/app/_services/data.service';
import { Federacao } from './../../../pessoa/federacao/federacao';
import { Component, ViewChild, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { PessoaService } from '../../../pessoa/pessoa.service';
import { Clube } from 'src/app/pessoa/clube/clube';
import { SYSCONF_SIGLA } from 'src/app.api';
import { Pessoa } from '../../../pessoa/pessoa';
import { Atleta } from 'src/app/pessoa/atleta/atleta';

declare var $: any;
@Component({
	selector: 'app-form-pessoa',
  	templateUrl: './form-pessoa.component.html',
  	styleUrls: ['./form-pessoa.component.scss']
})
export class FormPessoaComponent implements OnChanges{
	@ViewChild('Swal') public swal:SwalComponent; 
	@ViewChild('SwalDelete') public swalDelete:SwalComponent; 
	@Input() pessoa_id: number;
	@Input() form: FormGroup;
	@Input() federacoesSelect: Federacao[];
	@Input() clubesSelect: Clube[];
	@Input() graduacoesSelect: [{}];
	@Input() modalidadesCheckbox: [{}];
	@Input() estadoCivilSelect: [{}];
	@Input() funcionariosSelect: [{}];
	@Input() grauParentescoSelect: [{}];
	@Input() tipoForm:string;
	@Input() arbitro:boolean = false;
	@Input() atleta:boolean = false;
	@Input() clube:boolean = false;
	@Input() federacao:boolean = false;
	@Input() fornecedor:boolean = false;
	@Input() funcionario:boolean = false;
	@Input() estudantil:boolean = false;
	@Input() governamental:boolean = false;
	@Input() patrocinador:boolean = false;
	@Input() prestador:boolean = false;
	@Input() responsavel:boolean = false;
	@Input() tecnico:boolean = false;
	@Input() dependente:boolean = false;
    @Input() rota:string;
	@Input() genero:number;
	@Input() acao:string;
	@Input() thumb:string;
	@Input() detalhe:boolean;
	submitted: boolean = false;
	loading: boolean = false;
	public foto: File;
    id:number;
	tamanho_calcado = new Array();
	grau_instrucao = new Array();
	model;
	cadastroDuplicado: any;
	atletas: Atleta[];
	constructor(private pessoaService: PessoaService, private router: Router, private dt: DataService){
		for(let i = 20;i<=60;i++){
			this.tamanho_calcado.push(i+'.0');
			this.tamanho_calcado.push(i+'.5');
		}

		this.grau_instrucao = [ { id: 1, nome:  'Analfabeto' },
								{ id: 2, nome: 'Ensino fundamental incompleto' },
								{ id: 3, nome: 'Ensino fundamental completo' },
								{ id: 4, nome: 'Ensino médio incompleto' },
								{ id: 5, nome: 'Ensino médio completo' },
								{ id: 6, nome: 'Superior incompleto' },
								{ id: 7, nome: 'Superior completo' },
								{ id: 8, nome: 'Pós-Graduação' },
								{ id: 9, nome: 'Mestrado' },
								{ id: 10, nome: 'Doutorado' },
								{ id: 11, nome: 'Pós-Doutorado' }
							];
		
		
    }
	ngOnChanges(){
		if(this.acao == 'buscar' || !this.form.value.id)
			return;
		if(this.clube || this.estudantil || this.atleta || this.tecnico || this.arbitro) {
			if(this.form.value.federado) {
				//console.log(this.form.value.federado);
				this.setValidatorsFederado(this.form.value.federado);
			}
			
		}
			
		
		this.setValidators(this.form.value.estrangeiro,this.form.value.nacionalidade)
		if(this.prestador){
			this.setValidatorsPessoaFisicaJuridica(this.form.value.tipo);
		}
		
	}

	changeRadioValue(form,name,value){
		if(name == 'federado' && this.acao !== 'buscar')
			this.setValidatorsFederado(value)
		
		if(this.funcionario && name == 'status' && this.acao !== 'buscar')
			this.setValidatorsStatus(value)

		if(this.prestador && name == 'tipo' && this.acao !== 'buscar'){
			this.setValidators(this.form.value.estrangeiro)
			this.setValidatorsPessoaFisicaJuridica(value);
		}
		this[form].patchValue({[name]: value});
	}
	ngOnInit(){
		if(this.responsavel){
			this.pessoaService.getOptions('pessoa/atleta')
				.subscribe( (success) => {
					this.atletas = success.dados;
				},
				(error) => {
					this.swal.text = error.error.message;
					this.swal.type = "error";
					this.swal.title = "Erro!";
					this.swal.show();
					this.submitted = false;
				});
				
		}
	}
	ngAfterViewInit(){
		setTimeout(() => {
			$('.dropify').dropify({
				defaultFile: this.thumb,
			});
			if(this.thumb)
				$('.dropify-clear').hide();
		}, 3000);
	}
	askRemoverFoto(){
		this.swalDelete.show();
	}
	cancelRemoverFoto(){
		$('.dropify').dropify({
			defaultFile: this.thumb,
		});
	}
	confirmaRemoverFoto(){
		this.pessoaService.removerFoto(this.pessoa_id).subscribe( 
		(success) => {
			$('.dropify-clear').click();
			this.thumb = '';
			this.swal.text = "Foto removida com sucesso!";
			this.swal.type = "success";
			this.swal.title = "Pronto!";
			this.swal.show();
			this.submitted = false;
		},
		(error) => {
			this.swal.text = 'Não foi possível remover a foto, tente novamente!';
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}

	setValidatorsStatus(value){
		this.form.get('dt_desligamento').setValidators([]);
		if(value == 'I')
			this.form.get('dt_desligamento').setValidators([Validators.required]);
		this.form.get('dt_desligamento').updateValueAndValidity();
	}
	setValidators(estrangeiro,nacionalidade:string = ''):void{
		
		if(this.acao == 'edit') {
			nacionalidade = this.form.get('nacionalidade').value;
		}

		if(this.acao == 'buscar'){
			return;
		}

		if(!this.prestador)
			this.form.get('cpf').setValidators([]);
		this.form.get('passaporte_dt_validade').setValidators([]);
		this.form.get('passaporte').setValidators([]);
		
		this.form.patchValue({
			nacionalidade: nacionalidade
		})		
		
		if(estrangeiro === 'S'){
			
			this.form.get('passaporte').setValidators([Validators.required]);
			this.form.get('passaporte_dt_validade').setValidators([Validators.required]);

			if(this.prestador){
				if(this.form.value.tipo == 'J'){
					this.form.get('passaporte_dt_validade').setValidators([]);
					this.form.get('passaporte').setValidators([]);
					this.form.get('cnpj').setValidators([Validators.required]);
					this.form.get('cnpj').updateValueAndValidity();
				}
				if(this.form.value.tipo == 'F'){
					this.form.get('cpf').setValidators([]);
					this.form.get('cpf').updateValueAndValidity();
				}
			}
		}else{
			if(!this.prestador)
				this.form.get('cpf').setValidators([Validators.required]);
			this.form.patchValue({
				nacionalidade: 'Brasileiro'
			})
			if(this.prestador){
				if(this.form.value.tipo == 'J'){
					this.form.get('cnpj').setValidators([Validators.required]);
					this.form.get('cnpj').updateValueAndValidity();
				}
				if(this.form.value.tipo == 'F'){
					this.form.get('cpf').setValidators([Validators.required]);
					this.form.get('cpf').updateValueAndValidity();
				}
			}
			
		}
		if(!this.prestador)
		this.form.get('cpf').updateValueAndValidity();
		this.form.get('passaporte').updateValueAndValidity();
		this.form.get('passaporte_dt_validade').updateValueAndValidity();
	}

	setValidatorsPessoaFisicaJuridica(tipo){
		this.form.get('cpf').setValidators([]);
		this.form.get('cnpj').setValidators([]);
		this.form.get('dt_nascimento').setValidators([]);
		this.form.get('genero_id').setValidators([]);
		this.form.get('estado_civil_id').setValidators([]);
		this.form.get('grau_instrucao_id').setValidators([]);
		this.form.get('razao_social').setValidators([]);
		this.form.get('ultimo_nome').setValidators([]);
		this.form.get('primeiro_nome').setValidators([]);

		


		if(tipo == 'F'){
			if (this.form.get('estrangeiro').value != 'S') {
				this.form.get('cpf').setValidators([Validators.required]);
			}
			this.form.get('dt_nascimento').setValidators([Validators.required]);
			this.form.get('genero_id').setValidators([Validators.required]);
			this.form.get('estado_civil_id').setValidators([Validators.required]);
			//this.form.get('grau_instrucao_id').setValidators([Validators.required]);
			this.form.get('ultimo_nome').setValidators([Validators.required]);
			this.form.get('primeiro_nome').setValidators([Validators.required]);
		}
		else{
			this.form.get('razao_social').setValidators([Validators.required]);
			this.form.get('cnpj').setValidators([Validators.required]);
		}
		this.form.get('ultimo_nome').updateValueAndValidity();
		this.form.get('primeiro_nome').updateValueAndValidity();
		this.form.get('razao_social').updateValueAndValidity();
		this.form.get('cpf').updateValueAndValidity();
		this.form.get('cnpj').updateValueAndValidity();
		this.form.get('dt_nascimento').updateValueAndValidity();
		this.form.get('genero_id').updateValueAndValidity();
		this.form.get('estado_civil_id').updateValueAndValidity();
		this.form.get('grau_instrucao_id').updateValueAndValidity();
	}

	setValidatorsFederado(federado:string = null):void{
		if(this.form.get('federacao_id')) {
			this.form.get('federacao_id').setValidators([]);
		}
		
		if(this.arbitro){
			this.form.get('ano_inicio_carreira').setValidators([]);
			this.form.get('dt_registro').setValidators([]);
		}
		if(federado == 'S'){
			if(this.form.get('federacao_id')) {
				this.form.get('federacao_id').setValidators([Validators.required]);
			}

			if(this.arbitro){
				this.form.get('ano_inicio_carreira').setValidators([Validators.required]);
				this.form.get('dt_registro').setValidators([Validators.required]);
			}
		}
		if(this.form.get('federacao_id')) {
			this.form.get('federacao_id').updateValueAndValidity();
		}
		if(this.arbitro){
			this.form.get('ano_inicio_carreira').updateValueAndValidity();
			this.form.get('dt_registro').updateValueAndValidity();
		}
	}

	onFileChange(event){
		this.foto = event.target.files[0];		
	}

	/* Método utilizado ao clicar no checbox de modalidade */
	onCheckChange(event) {
		const formArray: FormArray = this.form.get('modalidade') as FormArray;
		
		/* Marcado */
		if(event.target.checked){
			// Adiciona um novo control para o Form Array
			formArray.push(new FormControl(event.target.value));
		}
		/* Desmarcar */
		else{
			// Encontra o elemento para remover
			let i: number = 0;
		
			formArray.controls.forEach((ctrl: FormControl) => {
			if(ctrl.value == event.target.value) {
				// Remove os elementos desmarcados
				formArray.removeAt(i);
				return;
			}
			i++;
			});
		}
	}

	/* Método utilizado ao clicar no checbox de modalidade */
	verifyChecked(modalidade) {
		const formArray: FormArray = this.form.get('modalidade') as FormArray;
		if(formArray.value.includes(modalidade))
			return true;

		return false;
		
	}

	/* Verificando se já existe uma pessoa com o mesmo cpf no sistema */
	consultaCpf(){ 
		let cpf = this.form.value.cpf;
		if(!cpf || this.acao == 'buscar'){
			return;
		}

		this.cadastroDuplicado = false;
		this.pessoaService.getPessoaByCpf(cpf).subscribe((success) => {
			let pessoa: Pessoa = success.dados;
			if(pessoa.id == this.pessoa_id){
				return;
			}
			this.pessoaService.getPessoaByTipo(this.tipoForm.replace('pessoa/',''),pessoa.id).subscribe((success2) => {
				this.cadastroDuplicado = success2.dados.result;
				if(this.cadastroDuplicado){
					this.form.patchValue({
						cpf: ''
					})
					this.swal.type = "error";
					this.swal.title = "Este CPF já está sendo utilizado: "+(cpf);

					if(this.dependente || this.funcionario)
						this.swal.title = "Este CPF já está vinculado a um dependente ou funcionário: "+(cpf);
					this.swal.text = "";
					this.swal.show();
					return;
				}
				
				this.form.patchValue({
					genero_id: pessoa.genero_id,
					pessoa_id: pessoa.id,
					primeiro_nome: pessoa.primeiro_nome ,
					ultimo_nome: pessoa.ultimo_nome ,
					nome_completo: pessoa.nome_completo ,
					apelido: pessoa.apelido ,
					estrangeiro: pessoa.estrangeiro,
					passaporte: pessoa.passaporte ,
					passaporte_dt_validade: pessoa.passaporte_dt_validade_br ,
					email: pessoa.email ,
					rg: pessoa.rg ,
					rg_uf: pessoa.rg_uf ,
					rg_dt_emissao: pessoa.rg_dt_emissao_br ,
					rg_emissor: pessoa.rg_emissor ,
					nacionalidade: pessoa.nacionalidade ,
					naturalidade: pessoa.naturalidade ,
					observacao: pessoa.observacao ,
					peso: pessoa.peso ,
					altura: pessoa.altura ,
					tipo_sanguineo: pessoa.tipo_sanguineo,
					tamanho_camisa: pessoa.tamanho_camisa,
					tamanho_agasalho: pessoa.tamanho_agasalho,
					tamanho_calca: pessoa.tamanho_calca,
					tamanho_calcado: pessoa.tamanho_calcado,
					tamanho_chinelo: pessoa.tamanho_chinelo,
					dt_nascimento: pessoa.dt_nascimento_br ,
					website: pessoa.website ,
					estado_civil_id: pessoa.estado_civil_id,
					pis: pessoa.pis,
					grau_instrucao_id: pessoa.grau_instrucao_id
				});
				this.swal.type = null;
				this.swal.title = "Dados carregados com sucesso!";
				this.swal.text = "Preencha os campos obrigatórios (em vermelho) para finalizar o cadastro.";
				this.swal.show();
				
				let dropify = $('.dropify').dropify();
				dropify = dropify.data('dropify');

				let thumb = null;
				try {
					thumb = pessoa.foto.thumb;
				}catch(message){};				

				dropify.settings['defaultFile'] = thumb;
				dropify.destroy();
				dropify.init();
			})
		},(erro) => {
			// this.swal.type = "warning";
			// this.swal.title = "Atenção!";
			// this.swal.text = "Não foi possível verificar se este CPF já está cadastrado no sistema. Tente novamente ou contate o suporte!";
			// this.swal.show();
		})
	}

	/* Verificando se já existe uma pessoa com o mesmo cnpj no sistema */
	consultaCNPJ(){ 
		let cnpj = this.form.value.cnpj;
		if(!cnpj || this.acao == 'buscar')
			return;
		this.cadastroDuplicado = false;
		this.pessoaService.getPessoaByCnpj(cnpj).subscribe((success) => {
			let pessoa: Pessoa = success.dados;
			if(pessoa.id == this.pessoa_id){
				return;
			}
			this.pessoaService.getPessoaByTipo(this.tipoForm.replace('pessoa/',''),pessoa.id).subscribe((success2) => {
				this.cadastroDuplicado = success2.dados.result;
				if(this.cadastroDuplicado){
					this.form.patchValue({
						cnpj: ''
					})
					this.swal.type = "error";
					this.swal.title = "Este CNPJ já está sendo utilizado: "+(cnpj);
					this.swal.text = "";
					this.swal.show();
					return;
				}
				this.form.patchValue({
					pessoa_id: pessoa.id,
					razao_social: pessoa.razao_social,
					nome_completo: pessoa.nome_completo,
					email: pessoa.email,
					observacao: pessoa.observacao,
				});
				this.swal.type = null;
				this.swal.title = "Dados carregados com sucesso!";
				this.swal.text = "Preencha os campos obrigatórios (em vermelho) para finalizar o cadastro.";
				this.swal.show();
				let dropify = $('.dropify').dropify();
				dropify = dropify.data('dropify');
				dropify.settings['defaultFile'] = pessoa.foto.thumb;
				dropify.destroy();
				dropify.init();
			})
		},(erro) => {
			// this.swal.type = "warning";
			// this.swal.title = "Atenção!";
			// this.swal.text = "Não foi possível verificar se este CNPJ já está cadastrado no sistema. Tente novamente ou contate o suporte!";
			// this.swal.show();
		})
	}

	/* Verificando se já existe uma pessoa com o mesmo passaporte no sistema */
	consultaPassaporte(){
		let estrangeiro = this.form.value.estrangeiro;
		let passaporte = this.form.value.passaporte;
		if(!passaporte || estrangeiro != 'S' || this.acao == 'buscar')
			return;
		this.cadastroDuplicado = false;
		this.pessoaService.getPessoaByPassaporte(passaporte).subscribe((success) => {
			let pessoa: Pessoa = success.dados;
			this.pessoaService.getPessoaByTipo(this.tipoForm.replace('pessoa/',''),pessoa.id).subscribe((success2) => {
				this.cadastroDuplicado = success2.dados.result;
				if(this.cadastroDuplicado){
					this.form.patchValue({
						passaporte: ''
					})
					this.swal.type = "error";
					this.swal.title = "Este passaporte já está sendo utilizado: "+(passaporte);
					this.swal.text = "";
					this.swal.show();
					return;
				}
				this.form.patchValue({
					genero_id: pessoa.genero_id,
					pessoa_id: pessoa.id,
					primeiro_nome: pessoa.primeiro_nome ,
					ultimo_nome: pessoa.ultimo_nome ,
					nome_completo: pessoa.nome_completo ,
					apelido: pessoa.apelido ,
					estrangeiro: pessoa.estrangeiro,
					cpf: pessoa.cpf ,
					passaporte_dt_validade: pessoa.passaporte_dt_validade_br ,
					email: pessoa.email ,
					rg: pessoa.rg ,
					rg_uf: pessoa.rg_uf ,
					rg_dt_emissao: pessoa.rg_dt_emissao_br ,
					rg_emissor: pessoa.rg_emissor ,
					nacionalidade: pessoa.nacionalidade ,
					naturalidade: pessoa.naturalidade ,
					observacao: pessoa.observacao ,
					peso: pessoa.peso ,
					altura: pessoa.altura ,
					tipo_sanguineo: pessoa.tipo_sanguineo,
					tamanho_camisa: pessoa.tamanho_camisa,
					tamanho_agasalho: pessoa.tamanho_agasalho,
					tamanho_calca: pessoa.tamanho_calca,
					tamanho_calcado: pessoa.tamanho_calcado,
					tamanho_chinelo: pessoa.tamanho_chinelo,
					dt_nascimento: pessoa.dt_nascimento_br ,
					website: pessoa.website ,
					estado_civil_id: pessoa.estado_civil_id,
					pis: pessoa.pis,
					grau_instrucao_id: pessoa.grau_instrucao_id
				});
				let dropify = $('.dropify').dropify();
				dropify = dropify.data('dropify');
				dropify.settings['defaultFile'] = pessoa.foto.thumb;
				dropify.destroy();
				dropify.init();
			})
		},(erro) => {
			// this.swal.type = "warning";
			// this.swal.title = "Atenção!";
			// this.swal.text = "Não foi possível verificar se este número de passaporte já está cadastrado no sistema. Tente novamente ou contate o suporte!";
			// this.swal.show();
		})
	}

	verificaDtAdmissao(){
		//console.log(this.form.value.dt_admissao)
		//console.log(typeof this.form.value.dt_desligamento)
		if(this.form.value.dt_admissao == null)
			return;
		let dt_admissao = new Date(this.form.value.dt_admissao.year, this.form.value.dt_admissao.month-1, this.form.value.dt_admissao.day)
		let dt_hoje = new Date();
		let dt_desligamento = new Date();
		if(typeof this.form.value.dt_desligamento == 'object' && this.form.value.dt_desligamento != null){
			dt_desligamento = new Date(this.form.value.dt_desligamento.year, this.form.value.dt_desligamento.month-1, this.form.value.dt_desligamento.day)
		}
		//console.log(dt_admissao)
		//console.log(dt_hoje)
		if(dt_admissao > dt_hoje){
			this.form.patchValue({
				dt_admissao: null
			})
			this.swal.text = 'A data de admissão não pode ser maior que a data atual.'; 
			this.swal.type = "warning";
			this.swal.title = "Atenção!";
			this.swal.show();
			return;
		}
		if(dt_admissao > dt_desligamento){
			this.form.patchValue({
				dt_desligamento: null
			})
			this.swal.text = 'A data de desligamento não pode ser maior que a data de admissão.'; 
			this.swal.type = "warning";
			this.swal.title = "Atenção!";
			this.swal.show();
			return;
		}
	}

	


    onSubmit(){
		this.submitted = true;
        if(!this.form.valid)
		return;

		
		if(this.acao == "buscar"){
			if(this.funcionario){
				let dt_admissao = this.dt.convertDataObject(this.form.value,'dt_admissao');
				let dt_desligamento = this.dt.convertDataObject(this.form.value,'dt_desligamento');
				this.form.patchValue({
					dt_admissao: dt_admissao,				
					dt_desligamento: dt_desligamento,
				})
			}
			this.router.navigate(['/'+SYSCONF_SIGLA+'/'+this.tipoForm.replace('pessoa/','')+'/page/1',this.form.value]);
			return;
		}
        this.pessoaService[this.acao](this.tipoForm,this.form,this.foto)
        .subscribe( (success) => {
			this.id = parseInt(success.dados.id);
			if(this.acao == 'create' || this.acao == 'add'){
				this.swal.text = `Registro cadastrado com sucesso!`;
			}
			else {
				this.swal.text = `Registro atualizado com sucesso!`;
			}
			
			/* Criando vínculo funcionário x dependente apenas no cadastro do dependente. A edição é feita apenas pela aba 'dependetes' em Funcionários. */
			if(this.dependente && this.acao == 'add'){
				this.form.patchValue({
					dependente_id: this.id
				})
				this.pessoaService.addFuncionarioDependente(this.form).subscribe((success) => {
					this.swal.type = "success";
					this.swal.title = "Pronto!";
					this.swal.show();
					this.submitted = false;
				},
				(error) =>{
					this.swal.text = `Registro cadastrado com sucesso, porém ocorreu um erro ao vincular o dependente ao funcionário. Vá ao funcionário desejado e crie o vínculo novamente na aba 'Dependentes'.`;
					this.swal.type = "success";
					this.swal.title = "Pronto!";
					this.swal.show();
					this.submitted = false;
				});
			}else{
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
			}	
			

			
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}
	onCloseModal(){
		if(this.swal.type == "success" && this.id && this.rota){
			this.router.navigate([this.rota, this.id]);
		}
	}
}