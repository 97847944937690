import { RHService } from '../../../rh/rh.service';
import { Component, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { PreCadastroService } from 'src/app/pre-cadastro/pre-cadastro.service';
import { FolhaDePagamento } from 'src/app/rh/folha-de-pagamento/folhadepagamento';
declare var $:any;
@Component({
	selector: 'app-form-folha-de-pagamento',
  	templateUrl: './form-folha-de-pagamento.component.html',
  	styleUrls: ['./form-folha-de-pagamento.component.scss']
})
export class FormFolhaDePagamentoComponent{
    @ViewChild('Swal') public swal:SwalComponent; 
	@Input() form: FormGroup;
	@Input() rota:string;
	@Input() acao:string;
	@Input() detalhe:boolean;
	folhadepagamento:FolhaDePagamento;
	submitted: boolean = false;
	loading: boolean = false;
	id:number;    
	constructor(private preCadastroService: PreCadastroService, private rhService: RHService, private router: Router, private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute){
		this.preCadastroService.getOptions('verba').subscribe((success) => {
		},(error) => {
		})

		if(this.activatedRoute.snapshot.params['id']){
			this.id = this.activatedRoute.snapshot.params['id'];
		}
			
	}

	initForm(){
		this.form = this.formBuilder.group({
			id: this.formBuilder.control(''),
			competencia: this.formBuilder.control('',[Validators.required]),
			competencia_mes: this.formBuilder.control(''),
			competencia_ano: this.formBuilder.control(''),
			dt_previsao: this.formBuilder.control('',[Validators.required]),
			dt_pagamento: this.formBuilder.control(''),
			valor: this.formBuilder.control(''),
			pago: this.formBuilder.control(''),
			descricao: this.formBuilder.control('',[Validators.required]),
		});	
	}

	
	ngOnInit(){
		this.initForm();
		if(this.id){ 
			this.rhService.getById('folha-pagamento',this.id).subscribe((folhadepagamento) =>{
				this.folhadepagamento = folhadepagamento.dados;
				this.form.patchValue({
					id: this.folhadepagamento.id,
					competencia: this.folhadepagamento.competencia_mes+'/'+this.folhadepagamento.competencia_ano,
					competencia_mes: this.folhadepagamento.competencia_mes,
					competencia_ano: this.folhadepagamento.competencia_ano,
					dt_previsao: this.folhadepagamento.dt_previsao_br,
					dt_pagamento: this.folhadepagamento.dt_pagamento_br,
					valor: this.folhadepagamento.valor,
					pago: this.folhadepagamento.pago,
					descricao: this.folhadepagamento.descricao,
				});	
			},(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
			})
		}
	}
	onSubmit(){
		this.submitted = true;
        if(!this.form.valid)
		return;
		this.rhService[this.acao](this.form)
        .subscribe( (success) => {
			this.id = parseInt(success.dados.id);

			if(this.acao == 'addFolhaDePagamento'){
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.text = `Folha de pagamento cadastrada com sucesso!`;
				this.swal.show();
				this.submitted = false;
			}
			else{
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.text = `Folha de pagamento atualizada com sucesso!`;
				this.swal.show();
				this.submitted = false;
			}
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}
	onCloseModal(){
		if(this.swal.type == "success"){
			this.router.navigate([this.rota, this.id]);
		}
	}
}