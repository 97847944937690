import { PreCadastroService } from './../../../pre-cadastro/pre-cadastro.service';
import { Funcionario } from './../funcionario';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { FormPessoaComponent } from '../../../_includes/forms/form-pessoa/form-pessoa.component';
import { SYSCONF_SIGLA } from 'src/app.api';
declare var $: any;
@Component({
	selector: 'app-funcionario-create',
  	templateUrl: './funcionarioCreate.component.html',
  	styleUrls: ['./funcionarioCreate.component.scss']
})

export class FuncionarioCreateComponent implements OnInit{
	
	@ViewChild('Swal') public swal:SwalComponent; 
	public message:string = '';
	cadastroForm: FormGroup;
	funcionario: Funcionario;
	genero;
	estadoCivil = [{}];
	sigla = SYSCONF_SIGLA;
	constructor(private preCadastroService: PreCadastroService, private formBuilder: FormBuilder, private router: Router, private form: FormPessoaComponent){
		this.cadastroForm = this.formBuilder.group({
			pessoa_id: this.formBuilder.control(''),
			estrangeiro: this.formBuilder.control('N',[Validators.required]),
			passaporte: this.formBuilder.control(''),
			passaporte_dt_validade: this.formBuilder.control(''),
			cpf: this.formBuilder.control('',[Validators.required]),
			dt_nascimento: this.formBuilder.control('',[Validators.required]),
			primeiro_nome: this.formBuilder.control('',[Validators.required]),
			ultimo_nome: this.formBuilder.control('',[Validators.required]),
			nome_completo: this.formBuilder.control(''),
			apelido: this.formBuilder.control(''),
			email: this.formBuilder.control('',[Validators.required, Validators.email]),
			nacionalidade: this.formBuilder.control('Brasileiro',[Validators.required]),
			naturalidade: this.formBuilder.control('',[Validators.required]),
			status: this.formBuilder.control('A',[Validators.required]),
			genero_id: this.formBuilder.control('',[Validators.required]),
			rg: this.formBuilder.control(''),
			rg_dt_emissao: this.formBuilder.control(''),
			rg_emissor: this.formBuilder.control(''),
			rg_uf: this.formBuilder.control(''),
			dt_admissao: this.formBuilder.control(null,[Validators.required]),
			dt_desligamento: this.formBuilder.control(null),
			observacao: this.formBuilder.control(''),
			estado_civil_id: this.formBuilder.control('',[Validators.required]),
			tipo_sanguineo: this.formBuilder.control(''),
			tamanho_camisa: this.formBuilder.control(''),
			tamanho_agasalho: this.formBuilder.control(''),
			tamanho_calca: this.formBuilder.control(''),
			tamanho_calcado: this.formBuilder.control(''),
			tamanho_chinelo: this.formBuilder.control(''),

			pis: this.formBuilder.control(''),
			grau_instrucao_id: this.formBuilder.control('')
		});	

		this.preCadastroService.get('pessoa/genero')
        .subscribe( (success) => {
			this.genero = success.dados;
			
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});

		this.preCadastroService.get('pessoa/estado-civil')
        .subscribe( (success) => {
			this.estadoCivil = success.dados;
			
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});

	}

	ngOnInit(){
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.rh_funcionario.includes("CRIAR"))
			this.message = 'Você não possui permissão para executar esta ação'
	}
	
	
}