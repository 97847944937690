import { PessoaService } from '../../pessoa.service';
import { Atleta } from './../atleta';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { SYSCONF_SIGLA } from 'src/app.api';
@Component({
	selector: 'app-atleta-detail',
  	templateUrl: './atletaDetail.component.html',
  	styleUrls: ['./atletaDetail.component.scss']
})

export class AtletaDetailComponent implements OnInit{
	
	@ViewChild('Swal') public swal:SwalComponent; 
	public message:string = '';
	detailForm: FormGroup;
	atleta: Atleta;
	atleta_id:number;
	thumb:string = '';
	pessoa_id:number;
	enderecos:object = new Array();
	telefones:object = new Array();
	idiomas:object = new Array();
	contasBancarias:object = new Array();
	sigla = SYSCONF_SIGLA;
	anexos: any;
	responsaveis:object = new Array(); 
	acesso = new Array();

	constructor(private pessoaService: PessoaService, private activatedRoute: ActivatedRoute,private formBuilder: FormBuilder){
		this.detailForm = this.formBuilder.group({
			id: this.formBuilder.control(0),
			estrangeiro: this.formBuilder.control(''),
			passaporte: this.formBuilder.control(''),
			passaporte_dt_validade: this.formBuilder.control(''),
			cpf: this.formBuilder.control(''),
			dt_cadastro: this.formBuilder.control(''),
			dt_atualizacao: this.formBuilder.control(''),
			dt_nascimento: this.formBuilder.control(''),
			primeiro_nome: this.formBuilder.control(''),
			ultimo_nome: this.formBuilder.control(''),
			nome_completo: this.formBuilder.control(''),
			apelido: this.formBuilder.control(''),
			email: this.formBuilder.control(''),
			nacionalidade: this.formBuilder.control(''),
			naturalidade: this.formBuilder.control(''),
			status: this.formBuilder.control(''),
			genero: this.formBuilder.control(''),
			foto: this.formBuilder.control(''),
			genero_id: this.formBuilder.control(''),
			rg: this.formBuilder.control(''),
			rg_dt_emissao: this.formBuilder.control(''),
			rg_emissor: this.formBuilder.control(''),
			rg_uf: this.formBuilder.control(''),
			altura: this.formBuilder.control(''),
			peso: this.formBuilder.control(''),
			tipo_sanguineo: this.formBuilder.control(''),
			tamanho_camisa: this.formBuilder.control(''),
			tamanho_agasalho: this.formBuilder.control(''),
			tamanho_calca: this.formBuilder.control(''),
			tamanho_calcado: this.formBuilder.control(''),
			tamanho_chinelo: this.formBuilder.control(''),
			federado: this.formBuilder.control(''),
			federacao: this.formBuilder.control(''),
			clube: this.formBuilder.control(''),
			situacao_federacao: this.formBuilder.control(''),
			dt_registro_federacao: this.formBuilder.control(''),
			situacao_confederacao: this.formBuilder.control(''),
			graduacao: this.formBuilder.control(''),
			dt_graduacao: this.formBuilder.control(''),
			website: this.formBuilder.control(''),
			observacao: this.formBuilder.control(''),
			estado_civil: this.formBuilder.control(''),
			estado_civil_id: this.formBuilder.control(''),
			pis: this.formBuilder.control(''),
			grau_instrucao_id: this.formBuilder.control(''),
			grau_instrucao: this.formBuilder.control(''),
			modalidade: this.formBuilder.control(''),
			selecao: this.formBuilder.control(''),
		});	
		this.atleta_id = this.activatedRoute.snapshot.params['id'];
		this.pessoaService.getPessoaById('pessoa/atleta',this.atleta_id).subscribe(
		(atleta) => {
			this.atleta = atleta.dados;
			this.pessoa_id = this.atleta.pessoa_id;
			this.responsaveis = this.atleta.responsavel;
			this.acesso = this.atleta.acesso;
			if(this.atleta.foto){
				this.thumb = this.atleta.foto.thumb;
			}

			this.pessoaService.getPessoaById('pessoa',this.pessoa_id).subscribe(
				(success) =>{
					this.enderecos = success.dados.endereco;
					this.telefones = success.dados.telefone;
					this.idiomas = success.dados.idioma;
					this.contasBancarias = success.dados.conta_bancaria;
					this.anexos = success.dados.anexo;
				},
				(error) => {
					this.message = error.error.message;
				})


				this.detailForm.patchValue({
					id: this.atleta.id,
					estrangeiro: this.atleta.estrangeiro,
					passaporte: this.atleta.passaporte,
					passaporte_dt_validade: this.atleta.passaporte_dt_validade_br,
					cpf: this.atleta.cpf,
					dt_cadastro: this.atleta.dt_cadastro_br,
					dt_atualizacao: this.atleta.dt_atualizacao_br,
					genero_id: this.atleta.genero_id,
					genero: this.atleta.genero,
					primeiro_nome: this.atleta.primeiro_nome,
					ultimo_nome: this.atleta.ultimo_nome,
					nome_completo: this.atleta.nome_completo,
					apelido: this.atleta.apelido,
					email: this.atleta.email,
					rg: this.atleta.rg,
					rg_uf: this.atleta.rg_uf,
					rg_dt_emissao: this.atleta.rg_dt_emissao_br,
					rg_emissor: this.atleta.rg_emissor,
					nacionalidade: this.atleta.nacionalidade,
					naturalidade: this.atleta.naturalidade,
					foto: this.thumb,
					observacao: this.atleta.observacao,
					peso: this.atleta.peso,
					altura: this.atleta.altura,
					tipo_sanguineo: this.atleta.tipo_sanguineo,
					tamanho_camisa: this.atleta.tamanho_camisa,
					tamanho_agasalho: this.atleta.tamanho_agasalho,
					tamanho_calca: this.atleta.tamanho_calca,
					tamanho_calcado: this.atleta.tamanho_calcado,
					tamanho_chinelo: this.atleta.tamanho_chinelo,
					federado: this.atleta.federado == 'S' ? 'S' : 'N',
					federacao: this.atleta.federacao,
					clube: this.atleta.clube,
					dt_nascimento: this.atleta.dt_nascimento_br,
					dt_registro_federacao: this.atleta.dt_registro_federacao_br,
					dt_graduacao:this.atleta.dt_graduacao_br,
					graduacao: this.atleta.graduacao,
					situacao_confederacao: this.atleta.situacao_confederacao,
					situacao_federacao: this.atleta.situacao_federacao,
					status: this.atleta.status,
					website: this.atleta.website,
					estado_civil: this.atleta.estado_civil,
					estado_civil_id: this.atleta.estado_civil_id,
					pis: this.atleta.pis,
					grau_instrucao_id: this.atleta.grau_instrucao_id,
					grau_instrucao: this.atleta.grau_instrucao,
					selecao: this.atleta.selecao == 'S' ? 'S' : 'N',
				});

				let modalidade = '';
				this.atleta.modalidade.forEach( (v:any) => {
					modalidade += v.nome+';';
				});
				this.detailForm.patchValue({
					modalidade: modalidade
				})
			})
		}

	ngOnInit(){
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.pessoa_atleta.includes("CONSULTAR"))
			this.message = 'Você não possui permissão para executar esta ação'
	}
	
	
}