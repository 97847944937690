import { PreCadastroService } from 'src/app/pre-cadastro/pre-cadastro.service';
import { Component, ViewChild, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';
import { ContratoService } from 'src/app/contrato/contrato.service';
import { formatNumber } from '@angular/common';
import { DataService } from '../../../_services/data.service';

declare var $: any;
@Component({
	selector: 'app-form-contrato-aditivo',
  	templateUrl: './form-contrato-aditivo.component.html',
  	styleUrls: ['./form-contrato-aditivo.component.scss']
})
export class FormContratoAditivoComponent implements OnInit{
	@ViewChild('Swal') public swal:SwalComponent;
	@ViewChild('SwalDelete') public swalDelete:SwalComponent; 
	@ViewChild('SwalDeleteParcela') public swalDeleteParcela:SwalComponent; 
	@Input() aditivos;
	@Input() contrato_id: number;
	@Input() detalhe:boolean;
	detalheModal:boolean = false;
	form: FormGroup;
	aditivo_id:number = null;
	modalTitle:string;
	submitted: boolean = false;
	loading: boolean = false;
	modalReference: NgbModalRef;   
	arquivo: File;
	statusSelect = new Array();
	parcelas: FormArray;
	confereValor:boolean = false;
	valorParcelas = 0;
	edit:boolean = false;
	detail:boolean = false;
	delete:boolean = false;
	create:boolean = false;
	disabled: boolean;
	parcelaIndex:number = null;
	constructor(private preCadastroService: PreCadastroService, private contratoService: ContratoService, private formBuilder: FormBuilder,private modalService: NgbModal, private dt: DataService){
		this.initForm();
		let t = JSON.parse(localStorage.getItem('CONFLogin'));

		if(t.permissao.contrato.includes("EDITAR"))
			this.edit = true;
		if(t.permissao.contrato.includes("CONSULTAR"))
			this.detail = true;
		if(t.permissao.contrato.includes("EXCLUIR"))
			this.delete = true;
		if(t.permissao.contrato.includes("CRIAR"))
			this.create = true;
		

        this.preCadastroService.getOptions('contrato/status').subscribe((success) => {
			this.statusSelect = success.dados;
		},(error) => {
			this.swal.text = 'Não existem status de contratos pré-cadastrados no sistema.';
			this.swal.type = "warning";
			this.swal.title = "Atenção!";
			this.swal.show();
		})
		

	}

	ngOnInit(){
		this.initForm();
	}

	initForm(){
		this.form = this.formBuilder.group({
			id: this.formBuilder.control(''),
			contrato_status_id: this.formBuilder.control('',[Validators.required]),
			contrato_status: this.formBuilder.control(''),
			dt_cadastro: this.formBuilder.control(''),
			dt_cadastro_br: this.formBuilder.control(''),
			dt_atualizacao: this.formBuilder.control(''),
			dt_atualizacao_br: this.formBuilder.control(''),
			dt_inicio: this.formBuilder.control(null,[Validators.required]),
			dt_inicio_br: this.formBuilder.control(''),
			dt_termino: this.formBuilder.control(null,[Validators.required]),
			dt_termino_br: this.formBuilder.control(''),
			numero: this.formBuilder.control('',[Validators.required]),
			valor: this.formBuilder.control('',[Validators.min(1)]),
			observacao: this.formBuilder.control(''),
			qtdParcelas: this.formBuilder.control('0',[Validators.min(0),Validators.max(60)]),
			parcelas: this.formBuilder.array([])
		});	
	}

	initParcelas(): FormGroup {
		return this.formBuilder.group({
		  id: 0,
		  valor: this.formBuilder.control('',[Validators.required]),
		  dt_pagamento: '',
		  status_pagamento: this.formBuilder.control('0',[Validators.required]),
		  dt_previsao: this.formBuilder.control(null,[Validators.required]),
		});
	}
	gerarParcelas(qtd){
		this.parcelas = this.form.get('parcelas') as FormArray;
		
		//Removendo todas as parcelas
		while (this.parcelas.length) {
			this.parcelas.removeAt(this.parcelas.length - 1);
		}

		//Gerando a quantidade de parcelas
		for(let j=1;j<=(qtd);j++){
			this.parcelas.push(this.initParcelas());
		}
	}
	
	addParcela(){
		this.parcelas.push(this.initParcelas());
	}

	removeParcela(index:number){
		this.parcelaIndex = index;
		this.parcelas = this.form.get('parcelas') as FormArray;
		if(this.parcelas.at(this.parcelaIndex).value.id == 0){
			this.parcelas.removeAt(this.parcelaIndex);
			this.confereValorTotal();
		}
		else{
			this.swalDeleteParcela.show();
		}
	}

	confirmDeleteParcela(){
		this.parcelas = this.form.get('parcelas') as FormArray;
		this.contratoService.delParcela(this.parcelas.at(this.parcelaIndex).value.id).subscribe( 
		(success) => {
			this.swal.text = "Parcela excluída com sucesso!";
			this.swal.type = "success";
			this.swal.title = "Pronto!";
			this.swal.show();
			this.parcelas.removeAt(this.parcelaIndex);
			this.parcelaIndex = null;
			this.confereValorTotal();
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		});
	}

	//Função para verificar se a soma das parcelas bate com o valor total informado.
	confereValorTotal(){
		this.valorParcelas = 0;
		let valorTotal = this.form.value.valor ? this.form.value.valor : 0;
		this.parcelas = this.form.get('parcelas') as FormArray;
		let stringParcela;
		for(let i=0;i<=(this.parcelas.length-1);i++){
			stringParcela = this.parcelas.at(i).value.valor ? this.parcelas.at(i).value.valor : 0
			stringParcela = parseFloat(stringParcela);
			this.valorParcelas += stringParcela;
		}
		if(Math.round(this.valorParcelas) != Math.round(valorTotal) )
			this.confereValor = false;
		else
			this.confereValor = true;
	}

	get formParcelas(){ 
		return <FormArray>this.form.get('parcelas'); 
	}

	openModal(modal,id:number = null, detalhe = false) {
		this.detalheModal = detalhe;
		//Se id == null então é cadastro.
		if(!id){
			this.disabled = false;
			this.initForm();
			this.aditivo_id = null;
			this.modalTitle = "Cadastrar Aditivo";
			this.form.patchValue({
				id: '',
				contrato_status_id: '',
				contrato_status: '',
				dt_cadastro: '',
				dt_cadastro_br: '',
				dt_atualizacao: '',
				dt_atualizacao_br: '',
				dt_inicio: null,
				dt_termino: null,
				numero: '',
				valor: '',
				observacao: '',
			});
			this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
			return;
		}
		this.disabled = true;
		this.initForm();
		
		//Senão é edição.
		this.aditivo_id = id;
		this.modalTitle = "Editar Aditivo";
		this.contratoService.getAditivoById(id).subscribe((success) => {
			this.form.patchValue({
				id: success.dados.id,
				contrato_status_id: success.dados.contrato_status_id,
				contrato_status: success.dados.contrato_status,
				dt_cadastro: success.dados.dt_cadastro,
				dt_cadastro_br: success.dados.dt_cadastro_br,
				dt_atualizacao: success.dados.dt_atualizacao,
				dt_atualizacao_br: success.dados.dt_atualizacao_br,
				dt_inicio:  this.dt.formataDataBr(success.dados.dt_inicio_br, this.detalheModal),
				dt_termino: this.dt.formataDataBr(success.dados.dt_termino_br, this.detalheModal),
				numero: success.dados.numero,
				valor: success.dados.valor,
				observacao: success.dados.observacao,
				qtdParcelas: success.dados.parcelas.length
			});
			if(success.dados.parcelas.length > 0){
				this.parcelas = this.form.get('parcelas') as FormArray;
				this.parcelas.removeAt(0);
				for(let i=0;i <= (success.dados.parcelas.length-1);i++){
					this.parcelas.push(this.initParcelas());
					this.parcelas.at(i).patchValue({
						id: success.dados.parcelas[i].id,
						valor: success.dados.parcelas[i].valor,
						dt_previsao: this.dt.formataDataBr(success.dados.parcelas[i].dt_previsao_br, this.detalheModal),
						status_pagamento: success.dados.parcelas[i].status_pagamento,
						dt_pagamento: this.dt.formataDataBr(success.dados.parcelas[i].dt_pagamento_br, this.detalheModal),
					})
					this.valorParcelas = parseFloat(this.valorParcelas.toString()) + parseFloat(success.dados.parcelas[i].valor);
				}
				this.confereValor = true;
				if(Math.round(this.valorParcelas) != Math.round(success.dados.valor))
					this.confereValor = false;
			}
			this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
			this.confereValorTotal();
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		})
	}

	setValidatorsParcelas(pago:string, index:number){
		this.parcelas = this.form.get('parcelas') as FormArray;

		this.parcelas.at(index).get('dt_pagamento').setValidators([]);
		this.parcelas.at(index).patchValue({
			dt_pagamento: null
		})
		if(pago == '1'){
			this.parcelas.at(index).get('dt_pagamento').setValidators([Validators.required]);
		}
		this.parcelas.at(index).get('dt_pagamento').updateValueAndValidity();
	}

	onSubmit(){
		this.submitted = true;
        if(!this.form.valid)
		return;
		
		if(!this.aditivo_id){
			this.contratoService.addAditivo(this.contrato_id, this.form)
			.subscribe( (success) => {
				
				this.aditivo_id = success.dados.id;
				this.parcelas = this.form.get('parcelas') as FormArray;

				if(this.parcelas.length <= 0){
					this.modalReference.close();
					this.swal.type = "success";
					this.swal.title = "Pronto!";
					this.swal.text = `Aditivo cadastrado com sucesso!`;
					this.swal.show();
					this.submitted = false;
					return;
				}

				this.contratoService.addParcela(this.parcelas,this.contrato_id,this.aditivo_id).subscribe((success) => {
					this.swal.type = "success";
					this.swal.title = "Pronto!";
					this.swal.text = `Aditivo cadastrado com sucesso!`;
					this.swal.show();
					this.submitted = false;
					
				},(error) =>{
					this.swal.text = 'O aditivo foi cadastrado com sucesso porém houve um erro ao inserir as parcelas. Vá no link de "Editar" do aditivo ou entre em contato com o suporte.';
					this.swal.type = "error";
					this.swal.title = "Erro!";
					this.swal.show();
					this.submitted = false;
				})
				this.modalReference.close();
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
			return;
		}

		this.contratoService.editAditivo(this.aditivo_id, this.form)
			.subscribe( (success) => {
				this.swal.text = "Aditivo atualizado com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
				this.contratoService.addParcela(this.parcelas,this.contrato_id,this.aditivo_id).subscribe((success) => {
					this.swal.type = "success";
					this.swal.title = "Pronto!";
					this.swal.text = `Aditivo atualizado com sucesso!`;
					this.swal.show();
					this.submitted = false;
					
				},(error) =>{
					let msg = 'O aditivo foi atualizado com sucesso porém houve um erro ao inserir as parcelas. Vá no link de "Editar" do aditivo ou entre em contato com o suporte.';
					if(error.error.message)
						msg = error.error.message;
					this.swal.text = msg;
					this.swal.type = "error";
					this.swal.title = "Erro!";
					this.swal.show();
					this.submitted = false;
				})
				this.modalReference.close();
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});

        
	}

	askDelete(id:number){
		this.aditivo_id = id;
		this.swalDelete.show();
	}

	confirmDelete(){
		this.contratoService.delAditivo(this.aditivo_id).subscribe( 
		(success) => {
			this.swal.text = "Aditivo deletado com sucesso!";
			this.swal.type = "success";
			this.swal.title = "Pronto!";
			this.swal.show();
			this.submitted = false;
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}

	onCloseModal(){
		if(this.swal.type == "success"){
			this.contratoService.getContratoById(this.contrato_id).subscribe((success)=>{
				this.aditivos = success.dados.aditivos;
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
		}
	}
}