import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { SYSCONF_LIMIT, SYSCONF_SIGLA } from '../../../app.api';
import { ContratoService } from '../../contrato/contrato.service';

@Component({
  selector: 'app-home-contrato-expirando',
  templateUrl: './home-contrato-expirando.component.html',
  styleUrls: ['../home.component.scss']
})
export class HomeContratoExpirandoComponent implements OnInit {

  contrato: any[];
  message: string;
  sigla = SYSCONF_SIGLA;

  constructor(private cd: ChangeDetectorRef, private contratoService: ContratoService) { }

  ngOnInit() {
    let params = {'contrato_status_id': 2, 'page': 1};
    this.contratoService.list(params, 5)
		.subscribe(
			(contrato) => {
        this.contrato = contrato.dados;
        
        if(!this.contrato || this.contrato.length == 0) {
          this.message = "Nenhum contrato expirando encontrado";
        }

        this.cd.markForCheck();
			},
			(error) => {

        this.cd.markForCheck();
				this.message = error.error.message;
      })
      
  }

}

