import { PessoaService } from '../../pessoa.service';
import { Dependente } from './../dependente';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { SYSCONF_SIGLA } from 'src/app.api';
@Component({
	selector: 'app-dependente-detail',
  	templateUrl: './dependenteDetail.component.html',
  	styleUrls: ['./dependenteDetail.component.scss']
})

export class DependenteDetailComponent implements OnInit{
	
	@ViewChild('Swal') public swal:SwalComponent; 
	public message:string = '';
	detailForm: FormGroup;
	dependente: Dependente;
	dependente_id: number;
	thumb:string = '';
	pessoa_id:number;
	enderecos:object = new Array();
	telefones:object = new Array();
	idiomas:object = new Array();
	contasBancarias:object = new Array();
	acesso:object = new Array();
	sigla = SYSCONF_SIGLA;
	anexos: any;
	constructor(private pessoaService: PessoaService, private activatedRoute: ActivatedRoute,private formBuilder: FormBuilder){
		this.dependente_id  = this.activatedRoute.snapshot.params['id'];
		this.detailForm = this.formBuilder.group({
			estrangeiro: this.formBuilder.control('N'),
			passaporte: this.formBuilder.control(''),
			passaporte_dt_validade: this.formBuilder.control(''),
			cpf: this.formBuilder.control('',[Validators.required]),
			dt_nascimento: this.formBuilder.control(''),
			primeiro_nome: this.formBuilder.control(''),
			ultimo_nome: this.formBuilder.control(''),
			nome_completo: this.formBuilder.control(''),
			apelido: this.formBuilder.control(''),
			email: this.formBuilder.control(''),
			nacionalidade: this.formBuilder.control(''),
			naturalidade: this.formBuilder.control(''),
			status: this.formBuilder.control('A'),
			genero_id: this.formBuilder.control(''),
			grau_parentesco_id: this.formBuilder.control(''),
			grau_parentesco: this.formBuilder.control(''),
			funcionario_id: this.formBuilder.control(''),
			funcionario: this.formBuilder.control(''),
			estado_civil_id: this.formBuilder.control(''),
			irrf: this.formBuilder.control(''),
			dnv: this.formBuilder.control(''),
			cartorio_registro: this.formBuilder.control(''),
			plano_saude: this.formBuilder.control(''),
			cartorio_nome: this.formBuilder.control(''),
			cartorio_livro: this.formBuilder.control(''),
			cartorio_folha: this.formBuilder.control(''),
			observacao: this.formBuilder.control(''),

			pis: this.formBuilder.control(''),
			grau_instrucao_id: this.formBuilder.control(''),
			grau_instrucao: this.formBuilder.control(''),
		});
		
		this.pessoaService.getPessoaById('pessoa/dependente',this.dependente_id).subscribe(
		(dependente) => {
			this.dependente = dependente.dados;
			this.acesso = dependente.dados.acesso;
			this.pessoa_id = this.dependente.pessoa_id;
			if(this.dependente.foto){
				this.thumb = this.dependente.foto.thumb;
			}

			this.pessoaService.getPessoaById('pessoa',this.pessoa_id).subscribe(
				(success) =>{
					this.enderecos = success.dados.endereco;
					this.telefones = success.dados.telefone;
					this.anexos = success.dados.anexo;
				},
				(error) => {
					this.message = error.error.message;
				})


				this.detailForm.patchValue({
					id: this.dependente.id,
					estrangeiro: this.dependente.estrangeiro,
					passaporte: this.dependente.passaporte,
					passaporte_dt_validade: this.dependente.passaporte_dt_validade_br,
					cpf: this.dependente.cpf,
					dt_cadastro: this.dependente.dt_cadastro_br,
					dt_atualizacao: this.dependente.dt_atualizacao_br,
					genero_id: this.dependente.genero_id,
					genero: this.dependente.genero,
					primeiro_nome: this.dependente.primeiro_nome,
					ultimo_nome: this.dependente.ultimo_nome,
					nome_completo: this.dependente.nome_completo,
					apelido: this.dependente.apelido,
					email: this.dependente.email,
					nacionalidade: this.dependente.nacionalidade,
					naturalidade: this.dependente.naturalidade,
					foto: this.thumb,
					observacao: this.dependente.observacao,
					dt_nascimento: this.dependente.dt_nascimento_br,
					status: this.dependente.status,
					estado_civil: this.dependente.estado_civil,
					estado_civil_id: this.dependente.estado_civil_id,
					grau_parentesco_id: this.dependente.grau_parentesco_id,
					grau_parentesco:  this.dependente.responsavel[0].grau_parentesco,
					funcionario_id: this.dependente.funcionario_id,
					funcionario: this.dependente.responsavel[0].nome_completo,
					irrf: this.dependente.irrf,
					dnv: this.dependente.dnv,
					cartorio_registro: this.dependente.cartorio_registro,
					plano_saude: this.dependente.plano_saude,
					cartorio_nome: this.dependente.cartorio_nome,
					cartorio_livro: this.dependente.cartorio_livro,
					cartorio_folha: this.dependente.cartorio_folha,

					pis: this.dependente.pis,
					grau_instrucao_id: this.dependente.grau_instrucao_id,
					grau_instrucao: this.dependente.grau_instrucao
				});
			})
		}

	ngOnInit(){
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.rh_dependente.includes("CONSULTAR"))
			this.message = 'Você não possui permissão para executar esta ação'
	}
	
	
}