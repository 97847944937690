import { FinanceiroService } from 'src/app/financeiro/financeiro.service';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { LoginService } from 'src/app/login/login.export';
import { SYSCONF_LIMIT, SYSCONF_SIGLA } from 'src/app.api';
declare var $: any;
@Component({
	selector: 'app-conciliacao-list',
	templateUrl: './conciliacaoList.component.html',
	styleUrls: ['./conciliacaoList.component.scss']
})

export class ConciliacaoListComponent implements OnInit{
	@ViewChild('Swal') public swal: SwalComponent;
	@ViewChild('SwalConciliar') public swalConciliar: SwalComponent;
	public message:string = '';
	conciliacao_id:number = null;
	sigla = SYSCONF_SIGLA;
	arquivo:File;
	submitted: boolean = false;
	movimento_id: number;
	dt_conciliacao: string;
	conciliacao_fitid: string;
	arquivos: any;
	form: FormGroup;
	ofx: FormArray;
	constructor(private formBuilder: FormBuilder, private financeiroService: FinanceiroService, private loginService: LoginService){
		this.form = this.formBuilder.group({
			ofx: this.formBuilder.array([]),
		});	
	}
	onFileChange(event){
		this.arquivo = event.target.files[0];	
	}

	initFormOfx(linha_ofx): FormGroup {
		let i = this.formBuilder.group({
			tipo: this.formBuilder.control(linha_ofx.tipo),
			valor_br: this.formBuilder.control(linha_ofx.valor_br),
			data_br: this.formBuilder.control(linha_ofx.data_br),
			descricao: this.formBuilder.control(linha_ofx.descricao),
			movimento: this.formBuilder.array([]),
			movimento_id: this.formBuilder.control(0),
			conciliado: this.formBuilder.control(linha_ofx.conciliado),
			fitid: this.formBuilder.control(linha_ofx.fitid),
		});
		let m = i.get('movimento') as FormArray;
		if(linha_ofx.movimento){
			linha_ofx.movimento.forEach((val) => {
				m.push(this.initFormOfxMovimento(val))
			})
		}
		return i;
	}

	initFormOfxMovimento(movimento){
		return this.formBuilder.group({
			id: this.formBuilder.control(movimento.id),
			tipo_descricao: this.formBuilder.control(movimento.tipo_descricao),
			dt_previsao_br: this.formBuilder.control(movimento.dt_previsao_br),
			descricao: this.formBuilder.control(movimento.descricao),
			pessoa: this.formBuilder.control(movimento.pessoa),
		});
	}


	addOfx(linha_ofx){
		this.ofx = this.form.get('ofx') as FormArray;
		this.ofx.push(this.initFormOfx(linha_ofx));
	}
	
	get formOfx(){ 
		return <FormArray>this.form.get('ofx'); 
	}
	
	ngOnInit(){ 
		$('.preloader').hide();
	}

	askConciliar(id:number, dt_conciliacao:string, fit_id:string){
		//console.log(id,dt_conciliacao,fit_id)
		this.movimento_id = id;
		this.dt_conciliacao = dt_conciliacao;
		this.conciliacao_fitid = fit_id;
		this.swalConciliar.show();
	}

	confirmConciliacao(){
		this.financeiroService.conciliar(this.dt_conciliacao, this.conciliacao_fitid, this.movimento_id).subscribe( 
		(success) => {
			let tmp = this.form.get('ofx') as FormArray;
			let i = tmp.value.find(x => x.fitid == this.conciliacao_fitid);
			i.conciliado = 'S';
			this.movimento_id = 0;
			this.dt_conciliacao = '';
			this.conciliacao_fitid = '';
			this.swal.text = "Movimento conciliado com sucesso!";
			this.swal.type = "success";
			this.swal.title = "Pronto!";
			this.swal.show();
			
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		});
	}

	resetForm() {
		this.form = this.formBuilder.group({
			ofx: this.formBuilder.array([]),
		});
	}
	
	onSubmit(){
		this.arquivos = this.ofx = null;
		this.submitted = true;
		this.financeiroService.add('conciliacao',null,this.arquivo)
			.subscribe( (success) => {
				this.swal.text = "Será necessário confirmar a conciliação de cada movimento da lista.";
				this.swal.type = "success";
				this.swal.title = "Arquivo enviado com sucesso!";
				this.swal.show();
				this.submitted = false;
				this.arquivos = success.dados.registros;

				this.resetForm();
				this.arquivos.forEach((val) => {
					this.addOfx(val);
				})
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
			return;
	}
	
	getPermissao(modulo,secao){
		return this.loginService.getPermissao(modulo,secao);
	}
}
