import { ProjetoService } from 'src/app/projeto/projeto.service';
import { Component, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/_services/data.service';
@Component({
	selector: 'app-form-projeto-documento',
  	templateUrl: './form-projeto-documento.component.html',
  	styleUrls: ['./form-projeto-documento.component.scss']
})
export class FormProjetoDocumentoComponent{
	@ViewChild('Swal') public swal:SwalComponent;
	@ViewChild('SwalDelete') public swalDelete:SwalComponent; 
	@Input() projetosDocumentos = new Array();
	@Input() detalhe: boolean;
	@Input() acao:string;
	create:boolean;
	edit:boolean;
	detail:boolean;
	delete:boolean;
	modalDetalhe:boolean = false;
	form: FormGroup;
	projeto_documento_id:number = null;
	modalTitle:string;
	submitted: boolean = false;
	loading: boolean = false;
	modalReference: NgbModalRef; 
	arquivo: File;
	constructor(private projetoService: ProjetoService, private formBuilder: FormBuilder, private modalService: NgbModal, private activatedRoute: ActivatedRoute, private dt: DataService){
        this.form = this.formBuilder.group({
			descricao: this.formBuilder.control('',[Validators.required]),
			dt_previsao_recebimento: this.formBuilder.control(''),
			dt_recebimento: this.formBuilder.control(null, [Validators.required]),
			observacao: this.formBuilder.control(''),
			link_arquivo: this.formBuilder.control('')
		})
	}
	onFileChange(event){
		this.arquivo = event.target.files[0];	
	}
	ngOnInit(){ 
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(t.permissao.projeto_documento.includes("CONSULTAR")){
			this.detail = true;
		}

		if(t.permissao.projeto_documento.includes("CRIAR")){
			this.create = true;
		}
		if(t.permissao.projeto_documento.includes("EDITAR")){
			this.edit = true;
		}
		if(t.permissao.projeto_documento.includes("EXCLUIR")) {
			this.delete = true;
		}

		this.form = this.formBuilder.group({
			descricao: this.formBuilder.control('',[Validators.required]),
			dt_previsao_recebimento: this.formBuilder.control(''),
			dt_recebimento: this.formBuilder.control('',[Validators.required]),
			observacao: this.formBuilder.control(''),
			link_arquivo: this.formBuilder.control('')
		});
	}
	
	onSubmit(){
		this.submitted = true;
        if(!this.form.valid)
		return;
		this.modalReference.close();
		if(!this.projeto_documento_id){
			this.projetoService.addProjetoDocumento(this.activatedRoute.snapshot.params['id'], this.form, this.arquivo)
			.subscribe( (success) => {
				this.swal.text = "Documento cadastrado com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
			return;
		}

		this.projetoService.editProjetoDocumento(this.projeto_documento_id,this.form, this.arquivo)
			.subscribe( (success) => {
				this.swal.text = "Documento atualizado com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});

        
	}

	openModal(modal,id:number = null,detalhe:boolean = false) {
		this.modalDetalhe = detalhe;
		//Se id == null então é cadastro.
		if(!id){
			this.projeto_documento_id = null;
			this.modalTitle = "Cadastrar Documento";
			this.form.patchValue({
				descricao: '',
				dt_previsao_recebimento: null,
				dt_recebimento: null,
				observacao: '',
				link_arquivo: ''
			});
			this.arquivo = null;

			this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
			return;
		}
		
		//Senão é edição.
		this.projeto_documento_id = id;
		this.modalTitle = "Editar Documento";
		this.projetoService.getProjetoDocumentoById(id).subscribe((success) => {
			this.form.patchValue({
				descricao: success.dados.descricao,
				dt_previsao_recebimento: this.dt.formataDataBr(success.dados.dt_previsao_recebimento_br, this.detalhe),
				dt_recebimento: this.dt.formataDataBr(success.dados.dt_recebimento_br, this.detalhe),
				observacao: success.dados.observacao,
				link_arquivo: success.dados.arquivo
			});
			this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		})
	}

	askDelete(id:number){
		this.projeto_documento_id = id;
		this.swalDelete.show();
	}

	confirmDelete(){
		this.projetoService.delProjetoDocumento(this.projeto_documento_id).subscribe( 
		(success) => {
			this.swal.text = "Documento deletado com sucesso!";
			this.swal.type = "success";
			this.swal.title = "Pronto!";
			this.swal.show();
			this.submitted = false;
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}

	onCloseModal(){
		if(this.swal.type == "success"){
			this.projetoService.getProjetoById(this.activatedRoute.snapshot.params['id']).subscribe((success)=>{
				this.projetosDocumentos = success.dados.documento;
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
		}
	}
}