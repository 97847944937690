import { LoginService } from 'src/app/login/login.service';
import { PreCadastroService } from './../../../pre-cadastro/pre-cadastro.service';
import { PessoaService } from '../../pessoa.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { Responsavel } from '../responsavel';
import { SYSCONF_SIGLA } from 'src/app.api';
@Component({
	selector: 'app-responsavel-edit',
  	templateUrl: './responsavelEdit.component.html',
  	styleUrls: ['./responsavelEdit.component.scss']
})

export class ResponsavelEditComponent implements OnInit{
	
	@ViewChild('Swal') public swal:SwalComponent; 
	public message:string = '';
	editForm: FormGroup;
	responsavel: Responsavel;
	responsavel_id:number;
	genero;
	thumb:string = '';
	pessoa_id:number;
	enderecos:object = new Array();
	telefones:object = new Array();
	contasBancarias:object = new Array();
	dependentes:object = new Array();
	estadoCivil: [{}];
	sigla = SYSCONF_SIGLA;
	anexos: any;
	acesso = new Array();
	constructor(public loginService:LoginService, private pessoaService: PessoaService, private preCadastroService: PreCadastroService , private activatedRoute: ActivatedRoute ,private formBuilder: FormBuilder){
		this.preCadastroService.get('pessoa/genero')
        .subscribe( (success) => {
			this.genero = success.dados;
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});

		this.preCadastroService.get('pessoa/estado-civil')
        .subscribe( (success) => {
			this.estadoCivil = success.dados;
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});

		this.editForm = this.formBuilder.group({
			id: this.formBuilder.control(0,[Validators.required]),
			estrangeiro: this.formBuilder.control('N',[Validators.required]),
			passaporte: this.formBuilder.control(''),
			passaporte_dt_validade: this.formBuilder.control(''),
			cpf: this.formBuilder.control('',[Validators.required]),
			dt_cadastro: this.formBuilder.control(''),
			dt_atualizacao: this.formBuilder.control(''),
			dt_nascimento: this.formBuilder.control('',[Validators.required]),
			primeiro_nome: this.formBuilder.control('',[Validators.required]),
			ultimo_nome: this.formBuilder.control('',[Validators.required]),
			nome_completo: this.formBuilder.control(''),
			apelido: this.formBuilder.control(''),
			email: this.formBuilder.control('',[Validators.required, Validators.email]),
			status: this.formBuilder.control('',[Validators.required]),
			genero_id: this.formBuilder.control('',[Validators.required]),
			rg: this.formBuilder.control(''),
			rg_dt_emissao: this.formBuilder.control(''),
			rg_emissor: this.formBuilder.control(''),
			rg_uf: this.formBuilder.control(''),
			observacao: this.formBuilder.control(''),
			estado_civil_id: this.formBuilder.control('',[Validators.required]),

			pis: this.formBuilder.control(''),
			grau_instrucao_id: this.formBuilder.control('')
			
		});	
		this.responsavel_id = this.activatedRoute.snapshot.params['id'];
		this.pessoaService.getPessoaById('pessoa/responsavel',this.responsavel_id).subscribe(
			(responsavel) => {
			this.responsavel = responsavel.dados;
			this.pessoa_id = this.responsavel.pessoa_id;
			this.acesso = this.responsavel.acesso;
			this.dependentes = this.responsavel.dependente;
			if(this.responsavel.foto){
				this.thumb = this.responsavel.foto.thumb;
			}
			this.pessoaService.getPessoaById('pessoa',this.pessoa_id).subscribe(
				(success) =>{
					this.enderecos = success.dados.endereco;
					this.telefones = success.dados.telefone;
					this.contasBancarias = success.dados.conta_bancaria;
					
					this.anexos = success.dados.anexo;
				},
				(error) => {
					this.message = error.error.message;
				})
			this.editForm.patchValue({
				id: this.responsavel.id,
				estrangeiro: this.responsavel.estrangeiro,
				passaporte: this.responsavel.passaporte,
				passaporte_dt_validade: this.responsavel.passaporte_dt_validade_br,
				cpf: this.responsavel.cpf,
				genero_id: this.responsavel.genero_id,
				dt_cadastro: this.responsavel.dt_cadastro_br,
				dt_atualizacao: this.responsavel.dt_atualizacao_br,
				primeiro_nome: this.responsavel.primeiro_nome,
				ultimo_nome: this.responsavel.ultimo_nome,
				nome_completo: this.responsavel.nome_completo,
				apelido: this.responsavel.apelido,
				email: this.responsavel.email,
				rg: this.responsavel.rg,
				rg_uf: this.responsavel.rg_uf,
				rg_dt_emissao: this.responsavel.rg_dt_emissao_br,
				rg_emissor: this.responsavel.rg_emissor,
				nacionalidade: this.responsavel.nacionalidade,
				naturalidade: this.responsavel.naturalidade,
				foto: this.responsavel.foto,
				observacao: this.responsavel.observacao,
				dt_nascimento: this.responsavel.dt_nascimento_br,
				status: this.responsavel.status,
				estado_civil_id: this.responsavel.estado_civil_id,

				pis: this.responsavel.pis,
				grau_instrucao_id: this.responsavel.grau_instrucao_id
			});
		},
		(error) => {
			this.message = error.error.message;
		})
	}

	ngOnInit(){
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.pessoa_responsavel.includes("EDITAR"))
			this.message = 'Você não possui permissão para executar esta ação'
	}
	
	
}