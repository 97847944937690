import { DataService } from './../../_services/data.service';
import { PessoaService } from './../../pessoa/pessoa.service';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { LoginService } from './../../login/login.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SYSCONF_LIMIT, SYSCONF_SIGLA } from './../../../app.api';
import { Contrato } from '../contrato';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ContratoService } from '../contrato.service';
import { PreCadastroService } from 'src/app/pre-cadastro/pre-cadastro.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-contrato-list',
	templateUrl: './contratoList.component.html',
	styleUrls: ['./contratoList.component.scss']
})

export class ContratoListComponent implements OnInit{
	@ViewChild('Swal') public swal: SwalComponent;
	@ViewChild('SwalDelete') public swalDelete: SwalComponent;
	contratos: Contrato[];
	public message:string = '';
	edit:boolean = false;
	detail:boolean = false;
	delete:boolean = false;
	params = null;
	page = 1;
	totalPages:number = 1;
	recordsPerPage:number = SYSCONF_LIMIT;
	buscaForm: FormGroup;
	formExportar: FormGroup;
	contrato_id:number = null;
	statusSelect = new Array();
	sigla = SYSCONF_SIGLA;
	constructor(private preCadastroService: PreCadastroService, private modalService: NgbModal, private dt:DataService, private activatedRoute: ActivatedRoute,  private router: Router, private contratoService: ContratoService, private loginService: LoginService, private formBuilder: FormBuilder){
		this.buscaForm = this.formBuilder.group({
			numero: this.formBuilder.control(''),
			dt_inicio: this.formBuilder.control(null),
			dt_termino: this.formBuilder.control(null),
			contrato_status_id: this.formBuilder.control(''),
		});

		this.preCadastroService.getOptions('contrato/status').subscribe((success) => {
			this.statusSelect = success.dados;
		},(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});

	}
	changePage(event){
		let page = parseInt(event);
		let newParams:Object = new Object();
		this.activatedRoute.params.subscribe(
			param => {
				for(let i in param) {
					if(i != 'page') 
						newParams[i] = param[i];
				}
			})
		if(!page)
		return;
		this.router.navigate(['/'+SYSCONF_SIGLA+`/contrato/page/${page}`,newParams]);
	}
	ngOnInit(){ 
		let t = JSON.parse(localStorage.getItem('CONFLogin'));

		if(t.permissao.contrato.includes("EDITAR")){
			this.edit = true;
		}

		if(t.permissao.contrato.includes("CONSULTAR")){		
			this.detail = true;
		}

		if(t.permissao.contrato.includes("EXCLUIR")){
			this.delete = true;
		}

		if(!t.permissao.contrato.includes("CONSULTAR")){
			this.router.navigate(['/'+SYSCONF_SIGLA]);
			return;
		}


		this.activatedRoute.params.subscribe(params => {
			this.paramsChange(params);			
		});
	}

	initFormExportar(){
		this.formExportar = this.formBuilder.group({
			campos: new FormArray([]),
		});
	}

	openExportModal(modal) {
		this.initFormExportar();
		this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
	}

	paramsChange(params) {
		this.message = "";
		this.params = params;
		this.contratoService.list(params)
		.subscribe(
			(contrato) => {
				
				this.contratos = contrato.dados;
				this.totalPages = contrato.paging.rows;
				this.page = params['page'];
				if(typeof params['numero']){
					this.buscaForm.patchValue({
						numero: params['numero'] != undefined ? params['numero'] : '' ,
						dt_inicio: params['dt_inicio'] != undefined ? this.dt.formataDataBr(params['dt_inicio']) : null ,
						dt_termino: params['dt_termino'] != undefined ? this.dt.formataDataBr(params['dt_termino']) : null,				
						contrato_status_id: params['contrato_status_id'] != undefined ? params['contrato_status_id'] : '' ,
					})
				}
			},
			(error) => {
				this.message = error.error.message;
			})
	}

	onSubmit(){
		let dt_inicio = this.dt.convertDataObject(this.buscaForm.value,'dt_inicio');
		let dt_termino = this.dt.convertDataObject(this.buscaForm.value,'dt_termino');
		this.buscaForm.patchValue({
			dt_inicio: dt_inicio,				
			dt_termino: dt_termino,
		})
		if(dt_inicio.indexOf('undefined') !== -1){
			this.buscaForm.patchValue({
				dt_inicio: ''
			})
		}
		if(dt_termino.indexOf('undefined') !== -1){
			this.buscaForm.patchValue({
				dt_termino: ''
			})
		}
		this.router.navigate(['/'+SYSCONF_SIGLA+'/contrato/page/1',this.buscaForm.value]);
	}
	
	getPermissao(modulo,secao){
		return this.loginService.getPermissao(modulo,secao);
	}

	askDelete(id:number){
		this.contrato_id = id;
		this.swalDelete.show();
	}

	confirmDelete(){
		this.contratoService.delete(this.contrato_id).subscribe( 
		(success) => {
			this.swal.text = "Contrato deletado com sucesso!";
			this.swal.type = "success";
			this.swal.title = "Pronto!";
			this.swal.show();
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		});
	}

}
