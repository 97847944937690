import { DataService } from './../../../_services/data.service';
import { PreCadastroService } from 'src/app/pre-cadastro/pre-cadastro.service';
import { PessoaService } from '../../pessoa.service';
import { Funcionario } from './../funcionario';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { Dependente } from '../../dependente/dependente';
import { SYSCONF_SIGLA } from 'src/app.api';
import { LoginService } from './../../../login/login.service';
declare var $: any;
@Component({
	selector: 'app-funcionario-edit',
  	templateUrl: './funcionarioEdit.component.html',
  	styleUrls: ['./funcionarioEdit.component.scss']
})

export class FuncionarioEditComponent implements OnInit{
	
	@ViewChild('Swal') public swal:SwalComponent; 
	public message:string = '';
	editForm: FormGroup;
	funcionario: Funcionario;
	funcionario_id: number;
	genero;
	thumb:string = '';
	pessoa_id:number;
	enderecos:object = new Array();
	telefones:object = new Array();
	idiomas:object = new Array();
	contasBancarias:object = new Array();
	acesso:object = new Array();
	estadoCivil:object = new Array();
	dependente: Dependente[];
	cargo:object = new Array();
	afastamento = new Array();
	ferias:object = new Array();
	sigla = SYSCONF_SIGLA;
	anexos: any;
	constructor(public loginService: LoginService, private pessoaService: PessoaService, private activatedRoute: ActivatedRoute ,private formBuilder: FormBuilder, private preCadastroService: PreCadastroService, private dt: DataService){
		this.funcionario_id  = this.activatedRoute.snapshot.params['id'];
		
		this.preCadastroService.get('pessoa/genero')
        .subscribe( (success) => {
			this.genero = success.dados;
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});

		this.preCadastroService.get('pessoa/estado-civil')
        .subscribe( (success) => {
			this.estadoCivil = success.dados;
			
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});

		this.editForm = this.formBuilder.group({
			id: this.formBuilder.control(0,[Validators.required]),
			estrangeiro: this.formBuilder.control('N',[Validators.required]),
			passaporte: this.formBuilder.control(''),
			passaporte_dt_validade: this.formBuilder.control(''),
			cpf: this.formBuilder.control('',[Validators.required]),
			dt_nascimento: this.formBuilder.control('',[Validators.required]),
			primeiro_nome: this.formBuilder.control('',[Validators.required]),
			ultimo_nome: this.formBuilder.control('',[Validators.required]),
			nome_completo: this.formBuilder.control(''),
			apelido: this.formBuilder.control(''),
			email: this.formBuilder.control('',[Validators.required, Validators.email]),
			nacionalidade: this.formBuilder.control('',[Validators.required]),
			naturalidade: this.formBuilder.control('',[Validators.required]),
			status: this.formBuilder.control('A',[Validators.required]),
			genero_id: this.formBuilder.control('',[Validators.required]),
			rg: this.formBuilder.control(''),
			rg_dt_emissao: this.formBuilder.control(''),
			rg_emissor: this.formBuilder.control(''),
			rg_uf: this.formBuilder.control(''),
			dt_admissao: this.formBuilder.control(null,[Validators.required]),
			dt_desligamento: this.formBuilder.control(null),
			observacao: this.formBuilder.control(''),
			estado_civil_id: this.formBuilder.control('',[Validators.required]),
			tipo_sanguineo: this.formBuilder.control(''),
			tamanho_camisa: this.formBuilder.control(''),
			tamanho_agasalho: this.formBuilder.control(''),
			tamanho_calca: this.formBuilder.control(''),
			tamanho_calcado: this.formBuilder.control(''),
			tamanho_chinelo: this.formBuilder.control(''),
			
			pis: this.formBuilder.control(''),
			grau_instrucao_id: this.formBuilder.control('')
		});	

		this.pessoaService.getPessoaById('pessoa/funcionario',this.activatedRoute.snapshot.params['id']).subscribe(
			(funcionario) => {
			this.funcionario = funcionario.dados;
			this.pessoa_id = this.funcionario.pessoa_id;
			this.acesso = funcionario.dados.acesso;
			this.dependente = funcionario.dados.dependente;
			this.cargo = funcionario.dados.cargo;
			this.afastamento = funcionario.dados.afastamento;
			this.ferias = funcionario.dados.ferias;
			if(this.funcionario.foto){
				this.thumb = this.funcionario.foto.thumb;
			}
			this.pessoaService.getPessoaById('pessoa',this.pessoa_id).subscribe(
				(success) =>{
					this.enderecos = success.dados.endereco;
					this.telefones = success.dados.telefone;
					this.idiomas = success.dados.idioma;
					this.contasBancarias = success.dados.conta_bancaria;
					this.anexos = success.dados.anexo;
				},
				(error) => {
					this.message = error.error.message;
				})
			
			this.editForm.patchValue({
				id: this.funcionario.id,
				estrangeiro: this.funcionario.estrangeiro,
				passaporte: this.funcionario.passaporte,
				passaporte_dt_validade: this.funcionario.passaporte_dt_validade_br,
				cpf: this.funcionario.cpf,
				dt_cadastro: this.funcionario.dt_cadastro_br,
				dt_atualizacao: this.funcionario.dt_atualizacao_br,
				genero_id: this.funcionario.genero_id,
				genero: this.funcionario.genero,
				primeiro_nome: this.funcionario.primeiro_nome,
				ultimo_nome: this.funcionario.ultimo_nome,
				nome_completo: this.funcionario.nome_completo,
				apelido: this.funcionario.apelido,
				email: this.funcionario.email,
				rg: this.funcionario.rg,
				rg_uf: this.funcionario.rg_uf,
				rg_dt_emissao: this.funcionario.rg_dt_emissao_br,
				rg_emissor: this.funcionario.rg_emissor,
				nacionalidade: this.funcionario.nacionalidade,
				naturalidade: this.funcionario.naturalidade,
				foto: this.thumb,
				observacao: this.funcionario.observacao,
				dt_nascimento: this.funcionario.dt_nascimento_br,
				status: this.funcionario.status,
				dt_admissao: this.dt.formataDataBr(this.funcionario.dt_admissao_br),
				dt_desligamento: this.dt.formataDataBr(this.funcionario.dt_desligamento_br),
				estado_civil_id: this.funcionario.estado_civil_id,
				tipo_sanguineo: this.funcionario.tipo_sanguineo,
				tamanho_camisa: this.funcionario.tamanho_camisa,
				tamanho_agasalho: this.funcionario.tamanho_agasalho,
				tamanho_calca: this.funcionario.tamanho_calca,
				tamanho_calcado: this.funcionario.tamanho_calcado,
				tamanho_chinelo: this.funcionario.tamanho_chinelo,

				pis: this.funcionario.pis,
				grau_instrucao_id: this.funcionario.grau_instrucao_id
			})
		},
		(error) => {
			this.message = error.error.message;
		})
	}
	ngOnInit(){
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.rh_funcionario.includes("EDITAR")){
			this.message = 'Você não possui permissão para executar esta ação';
		}
	}
}