import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { SYSCONF_LIMIT, SYSCONF_SIGLA } from '../../../app.api';
import { ProjetoService } from '../../projeto/projeto.service';

@Component({
  selector: 'app-home-ultimo-projeto',
  templateUrl: './home-ultimo-projeto.component.html',
  styleUrls: ['../home.component.scss']
})
export class HomeUltimoProjetoComponent implements OnInit {
  projetos: any[];
  message: string;
  sigla = SYSCONF_SIGLA;
  constructor(private cd: ChangeDetectorRef, private projetoService: ProjetoService) { }

  ngOnInit() {
    let params = { 'page': 1};
    this.projetoService.list(params, 5)
		.subscribe(
			(projeto) => {
        this.projetos = projeto.dados;
        
        if(!this.projetos || this.projetos.length == 0) {
          this.message = "Nenhum projeto encontrado";
        }
        this.cd.markForCheck();
			},
			(error) => {
        this.cd.markForCheck();
				this.message = error.error.message;
      })
      
  }

}
