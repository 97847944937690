import { Component, ViewChild, Input, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { FinanceiroService } from 'src/app/financeiro/financeiro.service';
import { PessoaService } from '../../../pessoa/pessoa.service';
import { PreCadastroService } from 'src/app/pre-cadastro/pre-cadastro.service';
import { SYSCONF_SIGLA } from 'src/app.api';
import { TreeviewItem } from 'ngx-treeview';
import { Movimento } from '../../../financeiro/movimento/movimento';
import { DataService } from 'src/app/_services/data.service';

import { ProjetoService } from '../../../projeto/projeto.service';
import { ContratoService } from '../../../contrato/contrato.service';

declare var $:any;
@Component({
	selector: 'app-form-financeiro-movimento',
  	templateUrl: './form-financeiro-movimento.component.html',
	  styleUrls: ['./form-financeiro-movimento.component.scss', '../../../../../node_modules/@ng-select/ng-select/themes/default.theme.css'],
	  encapsulation: ViewEncapsulation.None
})
export class FormFinanceiroMovimentoComponent{
	@ViewChild('Swal') public swal:SwalComponent; 
	@ViewChild('SwalConcluir') public swalConcluir:SwalComponent; 
	@Input() form: FormGroup;
	@Input() rota:string;
	@Input() acao:string;
	@Input() detalhe:boolean;
	movimento: Movimento;
	submitted: boolean = false;
	loading: boolean = false;
	id:number;    
	tiposPessoasSelect = new Array();
	pessoasSelect = new Array();
	projetosSelect = new Array();
	contratosSelect = new Array();
	estoqueMovimentosSelect = new Array();
	slugs = new Array();
	disabled: boolean;
	contaBancariaSelect: any = new Array();
	planoDeContas = new Array();
	planoDeContasFiltrado = new Array();
	planoDeContasFiltradoOrigem = new Array();
	planoDeContasFiltradoDestino = new Array();
	items: TreeviewItem[] = new Array();
	pessoaContasBancarias = new Array();
	formasDePagamentoSelect = new Array();
	centrosDeCustoSelect = new Array();
	value: number;
	public valor_liquido: string;
	fontesDeRecursosSelect = new Array();
	value_origem_plano_contas: number;
	value_destino_plano_contas: number;
	constructor(private pessoaService: PessoaService, private preCadastroService:PreCadastroService, private financeiroService: FinanceiroService, private router: Router, private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, private dt: DataService, private projetoService: ProjetoService, private contratoService: ContratoService,){
		this.preCadastroService.getOptions('financeiro/conta-bancaria').subscribe((success) => {
			this.contaBancariaSelect = success.dados;
			this.contaBancariaSelect.map((row) => { row.labelSelect = `${row.banco} - Ag.: ${row.num_agencia}-${row.dv_agencia} - ${row.tipo}: ${row.num_conta}-${row.dv_conta}`; return row; });
		},(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		})

		this.preCadastroService.getOptions('financeiro/recurso').subscribe((success) => {
			this.fontesDeRecursosSelect = success.dados;
		},(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		})

		

		this.preCadastroService.getOptions('financeiro/forma-pagamento').subscribe((success) => {
			this.formasDePagamentoSelect = success.dados;
		},(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		})

		this.preCadastroService.getOptions('financeiro/centro-custo').subscribe((success) => {
			this.centrosDeCustoSelect = success.dados;
		},(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		})

		this.contratoService.getOptions('contrato').subscribe((success) => {
			this.contratosSelect = success.dados;	
		})

		this.projetoService.getOptions('projeto').subscribe((success) => {
			this.projetosSelect = success.dados;	
		})

		let paramsEstoque = new Array();
		paramsEstoque['tipo'] = 'A';
		paramsEstoque['status'] = new Array();
		paramsEstoque['status'].push('1');
		paramsEstoque['status'].push('3');
		this.preCadastroService.getOptions('estoque/movimento',paramsEstoque).subscribe((success) => {
			this.estoqueMovimentosSelect = success.dados;
			this.estoqueMovimentosSelect.map((row) => { row.labelSelect = `Cod: ${row.id} - ${row.descricao_tipo} - ${row.destino_local} - ${row.dt_movimento_br} - ${row.descricao_status}`; return row; });
		},(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		})

		if(this.activatedRoute.snapshot.params['id'])
			this.id = this.activatedRoute.snapshot.params['id'];
	}

	initForm(){
		if(this.acao == 'buscar'){
			this.form = this.formBuilder.group({
				id:  this.formBuilder.control(''),
				financeiro_recurso_id:  this.formBuilder.control(''),
				tipo_pessoa_id:  this.formBuilder.control(null),
				pessoa_id: this.formBuilder.control(null),
				dt_cadastro:  this.formBuilder.control(''),
				dt_atualizacao:  this.formBuilder.control(''),
				centro_custo_id:  this.formBuilder.control(''),
				plano_contas_id:  this.formBuilder.control(''),
				forma_pagamento_id:  this.formBuilder.control(''),
				conta_bancaria_id:  this.formBuilder.control(null),
				descricao:  this.formBuilder.control(''),
				de_dt_emissao:  this.formBuilder.control(null),
				ate_dt_emissao:  this.formBuilder.control(null),
				de_dt_vencimento:  this.formBuilder.control(null),
				ate_dt_vencimento:  this.formBuilder.control(null),
				de_dt_baixa:  this.formBuilder.control(null),
				ate_dt_baixa:  this.formBuilder.control(null),
				de_dt_previsao:  this.formBuilder.control(null),
				ate_dt_previsao:  this.formBuilder.control(null),
				codigo_barras:  this.formBuilder.control(''),
				numero_doc:  this.formBuilder.control(''),
				nosso_numero:  this.formBuilder.control(''),
				de_dt_competencia:  this.formBuilder.control(''),
				ate_dt_competencia:  this.formBuilder.control(''),
				de_dt_conciliacao:  this.formBuilder.control(null),
				ate_dt_conciliacao:  this.formBuilder.control(null),
				de_valor_bruto:  this.formBuilder.control(''),
				ate_valor_bruto:  this.formBuilder.control(''),
				de_valor_liquido:  this.formBuilder.control(''),
				ate_valor_liquido:  this.formBuilder.control(''),
				de_juros:  this.formBuilder.control(''),
				ate_juros:  this.formBuilder.control(''),
				de_desconto:  this.formBuilder.control(''),
				ate_desconto:  this.formBuilder.control(''),
				tipo:  this.formBuilder.control(''),
				status:  this.formBuilder.control(''),
				conta_transferencia_id:  this.formBuilder.control(null),
				nota_fiscal: this.formBuilder.control(''),
				origem_plano_contas_id: this.formBuilder.control(''),
				origem_conta_bancaria_id:  this.formBuilder.control(null),
				origem_centro_custo_id: this.formBuilder.control(''),
				destino_plano_contas_id: this.formBuilder.control(''),
				destino_conta_bancaria_id:  this.formBuilder.control(null),
				destino_centro_custo_id: this.formBuilder.control(''),
				contrato_id: this.formBuilder.control(null),
				projeto_id: this.formBuilder.control(null),
				estoque_movimento_id: this.formBuilder.control(null),
			});	
			return;
		}
		this.form = this.formBuilder.group({
			id: this.formBuilder.control(''),
			financeiro_recurso_id:  this.formBuilder.control(''),
			financeiro_recurso:  this.formBuilder.control(''),
			tipo_pessoa_id: this.formBuilder.control(null,[Validators.required]),
			tipo_pessoa: this.formBuilder.control(''),
			pessoa_id: this.formBuilder.control(null,[Validators.required]),
			pessoa: this.formBuilder.control(''),
			dt_cadastro: this.formBuilder.control(''),
			dt_atualizacao: this.formBuilder.control(''),
			centro_custo_id: this.formBuilder.control('',[Validators.required]),
			centro_custo: this.formBuilder.control(''),
			plano_contas_id: this.formBuilder.control('',[Validators.required]),
			plano_contas: this.formBuilder.control(''),
			forma_pagamento_id: this.formBuilder.control('',[Validators.required]),
			forma_pagamento: this.formBuilder.control(''),
			conta_bancaria_id: this.formBuilder.control(null,[Validators.required]),
			conta_bancaria: this.formBuilder.control(''),
			dt_emissao: this.formBuilder.control(null),
			dt_vencimento:  this.formBuilder.control(null,[Validators.required]),
			descricao: this.formBuilder.control('',[Validators.required]),
			dt_baixa: this.formBuilder.control(null),
			dt_previsao: this.formBuilder.control(null,[Validators.required]),
			codigo_barras: this.formBuilder.control(''),
			numero_doc: this.formBuilder.control(''),
			nosso_numero: this.formBuilder.control(''),
			dt_competencia: this.formBuilder.control('',[Validators.required]),
			dt_conciliacao: this.formBuilder.control(null),
			valor_bruto: this.formBuilder.control('',[Validators.required]),
			valor_liquido: this.formBuilder.control({value:'', disabled: true}),
			juros: this.formBuilder.control(''),
			desconto: this.formBuilder.control(''),
			tipo: this.formBuilder.control('',[Validators.required]),
			status: this.formBuilder.control(''),
			conta_transferencia_id: this.formBuilder.control(null),
			conta_transferencia: this.formBuilder.control(''),
			numero: this.formBuilder.control(''),
			cheque_dt_emissao: this.formBuilder.control(null),
			dt_compensacao: this.formBuilder.control(null),
			cheque_numero: this.formBuilder.control(''),
			cheque_dt_compensacao: this.formBuilder.control(''),
			nota_fiscal: this.formBuilder.control(''),
			origem_plano_contas_id: this.formBuilder.control(''),
			origem_conta_bancaria_id:  this.formBuilder.control(null),
			origem_centro_custo_id: this.formBuilder.control(''),
			destino_plano_contas_id: this.formBuilder.control(''),
			destino_conta_bancaria_id:  this.formBuilder.control(null),
			destino_centro_custo_id: this.formBuilder.control(''),
			origem_plano_contas: this.formBuilder.control(''),
			origem_conta_bancaria:  this.formBuilder.control(null),
			origem_centro_custo: this.formBuilder.control(''),
			destino_plano_contas: this.formBuilder.control(''),
			destino_conta_bancaria:  this.formBuilder.control(null),
			destino_centro_custo: this.formBuilder.control(''),
			contrato_id: this.formBuilder.control(null),
			contrato_numero: this.formBuilder.control(null),
			projeto_id: this.formBuilder.control(null),
			projeto: this.formBuilder.control(null),
			estoque_movimento_id: this.formBuilder.control(null),
			estoque: this.formBuilder.control(''),
		});	
		
	}

	setValidatorsTipoMovimento(tipo:string):void{
		this.planoDeContasFiltrado = this.items;
		if(tipo == 'D')
			this.planoDeContasFiltrado = this.items.filter(p => p.value ==  '2');
		if(tipo == 'R')
			this.planoDeContasFiltrado = this.items.filter(p => p.value ==  '1');
		if(tipo == 'T'){
			this.planoDeContasFiltradoOrigem = this.items.filter(p => p.value ==  '2');
			this.planoDeContasFiltradoDestino = this.items.filter(p => p.value ==  '1');
		}
		


		this.form.get('tipo_pessoa_id').setValidators([]);
		this.form.get('pessoa_id').setValidators([]);
		this.form.get('centro_custo_id').setValidators([]);
		this.form.get('plano_contas_id').setValidators([]);
		this.form.get('conta_bancaria_id').setValidators([]);
		this.form.get('dt_vencimento').setValidators([]);
		this.form.get('forma_pagamento_id').setValidators([]);
		this.form.get('origem_centro_custo_id').setValidators([]);
		this.form.get('origem_plano_contas_id').setValidators([]);
		this.form.get('origem_conta_bancaria_id').setValidators([]);
		this.form.get('destino_centro_custo_id').setValidators([]);
		this.form.get('destino_plano_contas_id').setValidators([]);
		this.form.get('destino_conta_bancaria_id').setValidators([]);
		if(tipo == 'T'){
			this.form.get('origem_centro_custo_id').setValidators([Validators.required]);
			this.form.get('origem_plano_contas_id').setValidators([Validators.required]);
			this.form.get('origem_conta_bancaria_id').setValidators([Validators.required]);
			this.form.get('destino_centro_custo_id').setValidators([Validators.required]);
			this.form.get('destino_plano_contas_id').setValidators([Validators.required]);
			this.form.get('destino_conta_bancaria_id').setValidators([Validators.required]);
			this.setValidatorsFormaDePagamento('0');
		} else {
			this.form.get('tipo_pessoa_id').setValidators([Validators.required]);
			this.form.get('pessoa_id').setValidators([Validators.required]);
			this.form.get('centro_custo_id').setValidators([Validators.required]);
			this.form.get('plano_contas_id').setValidators([Validators.required]);
			this.form.get('conta_bancaria_id').setValidators([Validators.required]);
			this.form.get('dt_vencimento').setValidators([Validators.required]);
			this.form.get('forma_pagamento_id').setValidators([Validators.required]);
		}
		this.form.get('tipo_pessoa_id').updateValueAndValidity();
		this.form.get('pessoa_id').updateValueAndValidity();
		this.form.get('centro_custo_id').updateValueAndValidity();
		this.form.get('plano_contas_id').updateValueAndValidity();
		this.form.get('conta_bancaria_id').updateValueAndValidity();
		this.form.get('dt_vencimento').updateValueAndValidity();
		this.form.get('origem_centro_custo_id').updateValueAndValidity();
		this.form.get('origem_plano_contas_id').updateValueAndValidity();
		this.form.get('origem_conta_bancaria_id').updateValueAndValidity();
		this.form.get('destino_centro_custo_id').updateValueAndValidity();
		this.form.get('destino_plano_contas_id').updateValueAndValidity();
		this.form.get('destino_conta_bancaria_id').updateValueAndValidity();
		this.form.get('conta_transferencia_id').updateValueAndValidity();
		this.form.get('forma_pagamento_id').updateValueAndValidity();
	}

	setValidatorsFormaDePagamento(id, limpa_campos = true):void{
		if(limpa_campos)
		this.form.patchValue({
			numero: '',
			cheque_dt_emissao: null,
			dt_compensacao: null,
			conta_transferencia_id: ''
		});

		this.form.get('numero').setValidators([]);
		this.form.get('cheque_dt_emissao').setValidators([]);
		this.form.get('dt_compensacao').setValidators([]);
		this.form.get('conta_transferencia_id').setValidators([]);
		if(id === '2' && this.form.value.tipo != 'T'){
			this.form.get('numero').setValidators([Validators.required]);
			this.form.get('cheque_dt_emissao').setValidators([Validators.required]);
			this.form.get('dt_compensacao').setValidators([Validators.required]);
		}
		else if(id === '4' && this.form.value.tipo == 'D'){
			this.form.get('conta_transferencia_id').setValidators([Validators.required]);
		}
		this.form.get('numero').updateValueAndValidity();
		this.form.get('cheque_dt_emissao').updateValueAndValidity();
		this.form.get('dt_compensacao').updateValueAndValidity();
		this.form.get('conta_transferencia_id').updateValueAndValidity();
	}

	selectDtBaixa() {
		this.form.patchValue({
			status: '2'
		})
	}

	changeDtBaixa() {
		this.changeStatus();
	}

	changeStatus() {
		
		if(this.acao == "buscar"){
			return;
		}

		this.form.get('dt_baixa').setValidators([]);

		if (this.form.get('status').value == '2') {			
			this.form.get('dt_baixa').setValidators([Validators.required]);			
		}
		this.form.get('dt_baixa').updateValueAndValidity();
	}

	//Quando trocar o plano de contas
	onValueChange(value: number,tipo:string = null) {
		if(tipo == 'origem'){
			this.form.patchValue({
				origem_plano_contas_id: value
			})
			return;
		}
		else if(tipo == 'destino'){
			this.form.patchValue({
				destino_plano_contas_id: value
			})
			return;
		}
		this.form.patchValue({
			plano_contas_id: value
	   })
	}

	alteraValorLiquido(){
		let valBruto = 0;
		let valJuros = 0;
		let valDesconto = 0;
		if(this.form.value.valor_bruto)
			valBruto = parseFloat(this.form.value.valor_bruto);
		if(this.form.value.juros)
			valJuros = parseFloat(this.form.value.juros);
		if(this.form.value.desconto)
			valDesconto = parseFloat(this.form.value.desconto);


		this.form.patchValue({
			valor_liquido: (valBruto+valJuros)-valDesconto
		})
	}

	//Carrega o campo "Nome do Envolvido" de acordo com o "Tipo de Envolvido"
	loadTiposPessoas(id,pessoa_id:any = ''){
		this.loadContasBancarias(null)
		this.form.patchValue({pessoa_id: pessoa_id})
		this.pessoasSelect = new Array();
		let endpoint = this.tiposPessoasSelect.find(c => c.id == id);
		if(!endpoint)
			return;
		this.pessoaService.getOptions('pessoa/'+endpoint.slug).subscribe((success) => {
			this.pessoasSelect = success.dados;	
		},(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		})
	}



	//Carrega a conta bancária do Envolvido selecionado
	loadContasBancarias($event){
		if(!$event){
			this.pessoaContasBancarias = new Array();
			return;
		}
		let pessoa_id = $event.pessoa_id;	
		this.pessoaService.getPessoaById('pessoa',pessoa_id).subscribe(
			(success) =>{
				this.pessoaContasBancarias = success.dados.conta_bancaria;
		});
	}
	
	//Cria a árvore de plano de contas
	createTreeView(planoDeContas,children = 0):any{
		if(planoDeContas.length == 0)
			return;
		let children_items:TreeviewItem[] = new Array();
		if(children){
			planoDeContas.forEach( k => { 
				if(k.sub_plano.length > 0)
				children_items.push(new TreeviewItem({text: k.nome, value: k.id, children: this.createTreeView(k.sub_plano,1)}))
				else
				children_items.push(new TreeviewItem({text: k.nome, value: k.id}))
			});
			return children_items;
		}
		this.planoDeContas.forEach( k => { 
			this.items.push(new TreeviewItem({text: k.nome, value: k.id, children: this.createTreeView(k.sub_plano,1)}))
		});
		this.planoDeContasFiltrado = this.items;
	}
	
	ngOnInit(){
		this.disabled = this.acao == 'edit' ? true : false;
		this.initForm();
		if(this.id){
			this.pessoaService.getOptions('pessoa/tipo').subscribe((success) => {
				this.tiposPessoasSelect = success.dados;
				this.financeiroService.getById('movimento',this.id).subscribe((movimento) =>{
					this.movimento = movimento.dados;
					this.form.patchValue({
						id: this.movimento.id,
						financeiro_recurso_id:  this.movimento.financeiro_recurso_id,
						financeiro_recurso:  this.movimento.financeiro_recurso,
						tipo_pessoa_id: this.movimento.tipo_pessoa_id,
						tipo_pessoa: this.movimento.tipo_pessoa,
						pessoa_id: this.movimento.pessoa_id,
						pessoa: this.movimento.pessoa,
						dt_cadastro: this.movimento.dt_cadastro_br,
						dt_atualizacao: this.movimento.dt_atualizacao_br,
						centro_custo_id: this.movimento.centro_custo_id,
						centro_custo: this.movimento.centro_custo,
						plano_contas_id: this.movimento.plano_contas_id,
						plano_contas: this.movimento.plano_contas,
						forma_pagamento_id: this.movimento.forma_pagamento_id,
						forma_pagamento: this.movimento.forma_pagamento,
						conta_bancaria_id: this.movimento.financeiro_conta_bancaria_id,
						conta_bancaria: this.movimento.banco+' - Agência: '+ this.movimento.num_agencia+'-'+ this.movimento.dv_agencia+' - Conta: '+ this.movimento.num_conta+'-'+ this.movimento.dv_conta,
						descricao: this.movimento.descricao,
						dt_emissao: this.dt.formataDataBr(this.movimento.dt_emissao_br, this.detalhe),
						dt_vencimento: this.dt.formataDataBr(this.movimento.dt_vencimento_br, this.detalhe),
						dt_baixa: this.dt.formataDataBr(this.movimento.dt_baixa_br, this.detalhe),
						dt_previsao: this.dt.formataDataBr(this.movimento.dt_previsao_br, this.detalhe),
						codigo_barras: this.movimento.codigo_barras,
						numero_doc: this.movimento.numero_doc,
						nosso_numero: this.movimento.nosso_numero,
						dt_competencia: this.movimento.dt_competencia,
						dt_conciliacao: this.dt.formataDataBr(this.movimento.dt_conciliacao_br, this.detalhe),
						valor_bruto: this.movimento.valor_bruto,
						valor_liquido: this.movimento.valor_liquido,
						juros: this.movimento.juros,
						desconto: this.movimento.desconto,
						tipo: this.movimento.tipo,
						status: this.movimento.status,
						conta_transferencia_id: this.movimento.conta_transferencia_id,
						conta_transferencia: this.movimento.conta_transferencia,
						numero: this.movimento.cheque_numero,
						cheque_dt_emissao: this.dt.formataDataBr(this.movimento.cheque_dt_emissao_br, this.detalhe),
						dt_compensacao: this.dt.formataDataBr(this.movimento.cheque_dt_compensacao_br, this.detalhe),
						cheque_numero: this.movimento.cheque_numero,
						cheque_dt_compensacao: this.movimento.cheque_dt_compensacao_br,
						nota_fiscal: this.movimento.nota_fiscal,
						contrato_id: this.movimento.contrato_id,
						contrato_numero: this.movimento.contrato_numero,
						projeto_id: this.movimento.projeto_id,
						projeto: this.movimento.projeto,
						estoque_movimento_id: this.movimento.estoque_movimento_id,
						estoque: this.movimento.estoque.id ? `Cod: ${this.movimento.estoque.id} - ${this.movimento.estoque.descricao_tipo} - ${this.movimento.estoque.destino_local} - ${this.movimento.estoque.dt_movimento_br} - ${this.movimento.estoque.descricao_status}` : ``,
					});	

					if(this.movimento.vinculos && this.movimento.vinculos.length > 0){
						this.form.patchValue({
							origem_plano_contas_id: this.movimento.origem_plano_contas_id,
							origem_conta_bancaria_id:  this.movimento.origem_conta_bancaria_id,
							origem_centro_custo_id: this.movimento.origem_centro_custo_id,
							origem_plano_contas: this.movimento.vinculos[0].plano_contas,
							origem_conta_bancaria:  `${this.movimento.vinculos[0].banco} - Ag.: ${this.movimento.vinculos[0].num_agencia}-${this.movimento.vinculos[0].dv_agencia} - Conta: ${this.movimento.vinculos[0].num_conta}-${this.movimento.vinculos[0].dv_conta}`,
							origem_centro_custo: this.movimento.vinculos[0].centro_custo,
							destino_plano_contas_id: this.movimento.destino_plano_contas_id,
							destino_conta_bancaria_id:  this.movimento.destino_conta_bancaria_id,
							destino_centro_custo_id: this.movimento.destino_centro_custo_id,
							destino_plano_contas: this.movimento.vinculos[1].plano_contas,
							destino_conta_bancaria:   `${this.movimento.vinculos[1].banco} - Ag.: ${this.movimento.vinculos[1].num_agencia}-${this.movimento.vinculos[1].dv_agencia} - Conta: ${this.movimento.vinculos[1].num_conta}-${this.movimento.vinculos[1].dv_conta}`,
							destino_centro_custo: this.movimento.vinculos[1].centro_custo,
						});
					}
					
					this.valor_liquido = this.movimento.valor_liquido;
					if(this.movimento.tipo !== 'T'){
						this.loadTiposPessoas(this.movimento.tipo_pessoa_id,this.movimento.pessoa_id);
						this.loadContasBancarias({pessoa_id: this.movimento.pessoa_id});
					}
					this.financeiroService.get('plano-contas')
					.subscribe( (success) => {
						this.planoDeContas = success.dados;
						this.createTreeView(this.planoDeContas);
						this.value = this.movimento.plano_contas_id;
						this.value_origem_plano_contas = this.movimento.origem_plano_contas_id;
						this.value_destino_plano_contas = this.movimento.destino_plano_contas_id;
						if(this.movimento.tipo == 'T'){
							this.onValueChange(this.value_origem_plano_contas, 'origem')
							this.onValueChange(this.value_destino_plano_contas, 'destino')
						}
						this.setValidatorsTipoMovimento(this.movimento.tipo)
						this.setValidatorsFormaDePagamento(this.movimento.forma_pagamento_id,false)
					});
					

				},(error) => {
					this.swal.text = error.error.message;
					this.swal.type = "error";
					this.swal.title = "Erro!";
					this.swal.show();
				})

				
			},(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
			})
		}else{
			this.pessoaService.getOptions('pessoa/tipo').subscribe((success) => {
				this.tiposPessoasSelect = success.dados;
				this.financeiroService.get('plano-contas').subscribe( (success) => {
					this.planoDeContas = success.dados;
					this.createTreeView(this.planoDeContas);
				});
			})
		}
	}
	verifySubmit(){
		console.log(this.movimento.status);
		console.log(this.form.value.status);
		if(this.movimento.status !== this.form.value.status && this.form.value.status === '2'){
			this.askConcluir();
		}else{
			this.onSubmit();
		}
	}
	askConcluir(){
		this.swalConcluir.show();
	}
	onSubmit(){
		this.submitted = true;
        if(!this.form.valid)
		return;
		if(this.acao == "buscar"){
			this.convertData();
			this.router.navigate(['/'+SYSCONF_SIGLA+'/movimento/page/1',this.form.value]);
			return;
		}
        this.financeiroService[this.acao]('movimento',this.form)
        .subscribe( (success) => {
			this.id = parseInt(success.dados.id);
			if(this.acao == 'add'){
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.text = `Movimento cadastrado com sucesso!`;
				this.swal.show();
				this.submitted = false;
			}
			else{
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.text = `Movimento atualizado com sucesso!`;
				this.swal.show();
				this.submitted = false;
			}
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}
	convertData(){
		let de_dt_emissao = this.dt.convertDataObject(this.form.value,'de_dt_emissao');
		let ate_dt_emissao = this.dt.convertDataObject(this.form.value,'ate_dt_emissao');
		let de_dt_vencimento = this.dt.convertDataObject(this.form.value,'de_dt_vencimento');
		let ate_dt_vencimento = this.dt.convertDataObject(this.form.value,'ate_dt_vencimento');
		let de_dt_baixa = this.dt.convertDataObject(this.form.value,'de_dt_baixa');
		let ate_dt_baixa = this.dt.convertDataObject(this.form.value,'ate_dt_baixa');
		let de_dt_previsao = this.dt.convertDataObject(this.form.value,'de_dt_previsao');
		let ate_dt_previsao = this.dt.convertDataObject(this.form.value,'ate_dt_previsao');
		let de_dt_conciliacao = this.dt.convertDataObject(this.form.value,'de_dt_conciliacao');
		let ate_dt_conciliacao = this.dt.convertDataObject(this.form.value,'ate_dt_conciliacao');

		let de_cheque_dt_emissao = this.dt.convertDataObject(this.form.value,'de_cheque_dt_emissao');
		let ate_cheque_dt_emissao = this.dt.convertDataObject(this.form.value,'ate_cheque_dt_emissao');
			
		this.form.patchValue({
			de_dt_emissao: de_dt_emissao,				
			ate_dt_emissao: ate_dt_emissao,
			de_dt_vencimento: de_dt_vencimento,				
			ate_dt_vencimento: ate_dt_vencimento,
			de_dt_baixa: de_dt_baixa,
			ate_dt_baixa: ate_dt_baixa,
			de_dt_previsao: de_dt_previsao,
			ate_dt_previsao: ate_dt_previsao,
			de_dt_conciliacao: de_dt_conciliacao,
			ate_dt_conciliacao: ate_dt_conciliacao,
			de_cheque_dt_emissao: de_cheque_dt_emissao,				
			ate_cheque_dt_emissao: ate_cheque_dt_emissao,
		})
			
	}
	onCloseModal(){
		if(this.swal.type == "success"){
			this.router.navigate([this.rota, this.id]);
		}
	}
}