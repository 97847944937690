import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { LoginService } from './login.service';
import { SwalComponent, SwalDirective } from '@toverux/ngx-sweetalert2';
import { SYSCONF_SIGLA } from 'src/app.api';


@Component({
    templateUrl: './login.component.html',
    styleUrls: [
        './login.component.scss'
    ]
})

export class LoginComponent implements OnInit, AfterViewInit{
    @ViewChild('errorSwal') private errorSwal:SwalComponent; 
    loginForm: FormGroup;
    esqueciSenhaForm: FormGroup;
    navigateTo: string;
    data:any = {'login':'','senha':''}
    submitted = false;
    loading = false;
    constructor(private modalService:NgbModal,
                private router: Router, 
                private _location: Location, 
                private loginService: LoginService,
                private formBuilder: FormBuilder,
                private ActivatedRoute: ActivatedRoute){}

    ngOnInit(){
        if(this.loginService.isLoggedIn()){
            this._location.go(SYSCONF_SIGLA+'/home');
            location.reload();
        }
        this.loginForm = this.formBuilder.group({
            login: this.formBuilder.control('',[Validators.required, Validators.email]),
            senha: this.formBuilder.control('',[Validators.required]),
        });
        this.esqueciSenhaForm = this.formBuilder.group({
            login: this.formBuilder.control('',[Validators.required, Validators.email]),
        });
        this.navigateTo = this.ActivatedRoute.snapshot.params['to'] || '/';
    }

    ngAfterViewInit(){
		$(function(){
			$(".preloader").fadeOut();
		})
	}

    //Apenas para facilitar de chamar os controles
    get f() { return this.loginForm.controls; }
    
    onSubmit() {
        this.submitted = true;
        if(!this.loginForm.valid)
        return;

        this.loading = true;
        this.loginService.auth(this.loginForm.value.login,this.loginForm.value.senha)
        .subscribe( (login) => {
                    login.dados.sigla = SYSCONF_SIGLA;
                    localStorage.setItem('CONFLogin',JSON.stringify(login.dados));
                    this._location.go(SYSCONF_SIGLA+'/home');
                    location.reload();
                },
                (error) => {
                    let msg = 'O servidor não está respondendo no momento. Tente novamente ou entre em contato com o COB.';
                    if(error.status !== 0)
                    msg = error.error.message;
                    this.errorSwal.text = msg;
                    this.errorSwal.type = "error";
                    this.errorSwal.title = "Oops...";
                    this.errorSwal.show();
                    this.loading = false;
                },
                () => {
                    
                    //this.router.navigate([this.navigateTo])
                })
       }

        openModal(modal) {
            this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
            return;
        }
      onSubmitEmail() {
        if(!this.esqueciSenhaForm.valid)
        return;

        this.loading = true;
        this.loginService.esqueciSenha(this.esqueciSenhaForm.value.login)
        .subscribe( (login) => {
                        this.modalService.dismissAll();
                        this.errorSwal.text = 'Um link para recuperar senha foi enviado para o seu e-mail. Expira em 4h.';
                        this.errorSwal.type = "success";
                        this.errorSwal.title = "Pronto!";
                        this.errorSwal.show();
                        this.loading = false;
                    },
                    (error) => {
                        this.modalService.dismissAll();
                        //console.log(error)
                        let msg = 'O servidor não está respondendo no momento. Tente novamente ou entre em contato com o COB.';
                        if(error.status !== 0)
                        msg = error.error.message;
                        this.errorSwal.text = msg;
                        this.errorSwal.type = "error";
                        this.errorSwal.title = "Oops...";
                        this.errorSwal.show();
                        this.loading = false;
                    },
                    () => {
                        this.loading = false;
                        //this.router.navigate([this.navigateTo])
                    })
        
        
        
      }
}