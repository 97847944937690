import { NivelService } from './../nivel.service';
import { ViewEncapsulation, Component, OnInit, ViewChild } from '@angular/core';
import { Nivel } from '../nivel';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { SYSCONF_SIGLA } from 'src/app.api';
declare var $: any;

@Component({
  	templateUrl: './nivelEdit.component.html',
    encapsulation: ViewEncapsulation.None,
  	styleUrls: [
  		'./nivelEdit.component.scss'
	]
})

export class NivelEditComponent implements OnInit{
	@ViewChild('Swal') public swal:SwalComponent;
	public message:string = '';
	editForm: FormGroup;
	editFormData = new FormData();
	isChecked:boolean = false;
	public nivel: Nivel;
	niveis:any;
	submitted: boolean = false;
	modulosAgrupados = new Array()
	acoesAgrupadas = new Array();
	moduloId: string;
	acaoId:string;
	private id: number = 0;
	private checkboxes_id = new Array();
	sigla = SYSCONF_SIGLA;
	newModulo: any;
	constructor(private route: ActivatedRoute, private router: Router, private nivelService: NivelService, private formBuilder: FormBuilder){

	}

	isActive(modulo_id,acao_id){
		let t = `minimal-checkbox-${modulo_id}-${acao_id}`;
		
		if(this.checkboxes_id.includes(t))
		return true;
		
		return;
	}

	doCheck(event){
		let name = event.target.name;
		let value = event.target.value;
		if(event.target.checked && !this.editFormData.getAll(name).includes(value)){
			this.editFormData.append(name,value);
			
		}else if(!event.target.checked && this.editFormData.getAll(name).includes(value)){
			
			let tmp = this.editFormData.getAll(name); 
			const index: number = tmp.indexOf(value);
			if (index !== -1) {
				tmp.splice(index, 1);
			} 
			this.editFormData.delete(name);
			tmp.forEach((key : any) => {
				
				this.editFormData.append(name,key);
			})
		}		
	}

	ngOnInit(){

		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.nivel.includes("EDITAR"))
			this.message = 'Você não possui permissão para executar esta ação';

		
		this.editForm = this.formBuilder.group({
			nome: this.formBuilder.control('',[Validators.required]),
		});

		
			
				this.nivelService.nivelById(this.route.snapshot.params['id']).
				subscribe(
					(nivel) => {
						this.nivel = nivel.dados;
					//	console.log(this.nivel)
						this.editForm.patchValue({
							nome: this.nivel.nome
						});



						this.editFormData.append('id',nivel.dados.id);
						this.nivelService.listModulo().subscribe((modulos) => {
							this.newModulo = modulos.dados;
							this.nivel.modulo.forEach(n => {
								this.newModulo.forEach(m => {
									m.modulos.forEach(v => {
										if(v.slug == n.slug){
											//CONSULTAR
											if(n.permissao.includes('CONSULTAR') && !this.checkboxes_id.includes(`minimal-checkbox-${v.id}-3`)){
												this.checkboxes_id.push(`minimal-checkbox-${v.id}-3`)
												this.editFormData.append(`permissao[${v.id}][]`,'3')
											}

											//CRIAR
											if(n.permissao.includes('CRIAR') && !this.checkboxes_id.includes(`minimal-checkbox-${v.id}-1`)){
												this.checkboxes_id.push(`minimal-checkbox-${v.id}-1`)
												this.editFormData.append(`permissao[${v.id}][]`,'1')
											}

											//EDITAR
											if(n.permissao.includes('EDITAR') && !this.checkboxes_id.includes(`minimal-checkbox-${v.id}-2`)){
												this.checkboxes_id.push(`minimal-checkbox-${v.id}-2`)
												this.editFormData.append(`permissao[${v.id}][]`,'2')
											}

											//Excluir
											if(n.permissao.includes('EXCLUIR') && !this.checkboxes_id.includes(`minimal-checkbox-${v.id}-4`)){
												this.checkboxes_id.push(`minimal-checkbox-${v.id}-4`)
												this.editFormData.append(`permissao[${v.id}][]`,'4')
											}

											//Exportar
											if(n.permissao.includes('EXPORTAR') && !this.checkboxes_id.includes(`minimal-checkbox-${v.id}-5`)){
												this.checkboxes_id.push(`minimal-checkbox-${v.id}-5`)
												this.editFormData.append(`permissao[${v.id}][]`,'5')
											}
										}
									});
									
								});
							});

						});
		
		}, (error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		})
	}

	onSubmit(){
		
		this.submitted = true;
        if(!this.editForm.valid)
		return;
		
		if(!this.editFormData.has('nome')) {
			this.editFormData.append('nome',this.editForm.value.nome);
		}

        this.nivelService.edit(this.editFormData)
        .subscribe( (success) => {
				this.id = success.dados.id;
				this.swal.text = "Nível de acesso atualizado com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
				//this.ngOnInit();
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}
	
	onCloseModal(){
		if(this.swal.type == "success"){
			this.router.navigate(['/'+SYSCONF_SIGLA+'/nivel/editar', this.id]);
		}
	}
}
