import { Component, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { FinanceiroService } from 'src/app/financeiro/financeiro.service';
import { PessoaService } from '../../../pessoa/pessoa.service';
import { PreCadastroService } from 'src/app/pre-cadastro/pre-cadastro.service';
import { SYSCONF_SIGLA } from 'src/app.api';
import { Cheque } from '../../../financeiro/cheque/cheque';
import { DataService } from '../../../_services/data.service';
declare var $:any;
@Component({
	selector: 'app-form-financeiro-cheque',
  	templateUrl: './form-financeiro-cheque.component.html',
  	styleUrls: ['./form-financeiro-cheque.component.scss']
})
export class FormFinanceiroChequeComponent{
    @ViewChild('Swal') public swal:SwalComponent; 
	@Input() form: FormGroup;
	@Input() rota:string;
	@Input() acao:string;
	@Input() detalhe:boolean;
	cheque: Cheque;
	submitted: boolean = false;
	loading: boolean = false;
	id:number;    
	tiposPessoasSelect = new Array();
	pessoasSelect = new Array();
	statusSelect = new Array();
	slugs = new Array();
	contaBancariaSelect: any;
	constructor(private pessoaService: PessoaService, private preCadastroService:PreCadastroService, private financeiroService: FinanceiroService, private router: Router, private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, private dt: DataService){
		this.preCadastroService.getOptions('financeiro/conta-bancaria').subscribe((success) => {
			this.contaBancariaSelect = success.dados;
			this.contaBancariaSelect.map((row) => { row.labelSelect = `${row.banco} - Ag.: ${row.num_agencia}-${row.dv_agencia} - ${row.tipo}: ${row.num_conta}-${row.dv_conta}`; return row; });
		},(error) => {
			// this.swal.text = 'Nenhuma conta bancária encontrada.';
			// this.swal.type = "warning";
			// this.swal.title = "Erro!";
			// this.swal.show();
		})
		if(this.activatedRoute.snapshot.params['id'])
			this.id = this.activatedRoute.snapshot.params['id'];
	}

	initForm(){
		if(this.acao == 'buscar'){
			this.form = this.formBuilder.group({
				id: this.formBuilder.control(''),
				tipo_pessoa_id: this.formBuilder.control(''),
				tipo_favorecido: this.formBuilder.control(''),
				pessoa_id: this.formBuilder.control(''),
				favorecido: this.formBuilder.control(''),
				dt_cadastro: this.formBuilder.control(''),
				dt_atualizacao: this.formBuilder.control(''),
				dt_emissao: this.formBuilder.control(null),
				de_dt_emissao: this.formBuilder.control(null),
				ate_dt_emissao: this.formBuilder.control(null),
				dt_compensacao: this.formBuilder.control(null),
				de_dt_compensacao: this.formBuilder.control(null),
				ate_dt_compensacao: this.formBuilder.control(null),
				numero: this.formBuilder.control(''),
				valor: this.formBuilder.control(''),
				de_valor: this.formBuilder.control(''),
				ate_valor: this.formBuilder.control(''),
				observacao: this.formBuilder.control(''),
				finalidade: this.formBuilder.control(''),
				conta_bancaria_id: this.formBuilder.control(''),
				conta_bancaria: this.formBuilder.control(''),
			});	
			return;
		}
		this.form = this.formBuilder.group({
			id: this.formBuilder.control(''),
			tipo_pessoa_id: this.formBuilder.control('',[Validators.required]),
			tipo_favorecido: this.formBuilder.control(''),
			pessoa_id: this.formBuilder.control('',[Validators.required]),
			favorecido: this.formBuilder.control(''),
			dt_cadastro: this.formBuilder.control(''),
			dt_atualizacao: this.formBuilder.control(''),
			dt_emissao: this.formBuilder.control(null,[Validators.required]),
			dt_compensacao: this.formBuilder.control(null,[Validators.required]),
			numero: this.formBuilder.control('',[Validators.required]),
			valor: this.formBuilder.control('',[Validators.required]),
			de_valor: this.formBuilder.control(''),
			ate_valor: this.formBuilder.control(''),
			observacao: this.formBuilder.control(''),
			finalidade: this.formBuilder.control('',[Validators.required]),
			conta_bancaria_id: this.formBuilder.control('',[Validators.required]),
			conta_bancaria: this.formBuilder.control(''),
			financeiro_movimento_id: this.formBuilder.control(0),
		});	
	}

	//Carrega o campo "Nome da Pessoa" de acordo com o "Tipo de Pessoa"
	loadTiposPessoas(id,pessoa_id:any = ''){
		this.form.patchValue({pessoa_id: pessoa_id})
		this.pessoasSelect = new Array();
		let endpoint = this.tiposPessoasSelect.find(c => c.id == id);
		if(!endpoint)
			return;
		this.pessoaService.getOptions('pessoa/'+endpoint.slug).subscribe((success) => {
			this.pessoasSelect = success.dados;	
		},(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		})
	}

	ngOnInit(){
		
		this.initForm();
		if(this.id){
			this.pessoaService.getOptions('pessoa/tipo').subscribe((success) => {
				this.tiposPessoasSelect = success.dados;
				this.financeiroService.getById('cheque',this.id).subscribe((cheque) =>{
					this.cheque = cheque.dados;
					this.form.patchValue({
						id: this.cheque.id,
						tipo_pessoa_id: this.cheque.tipo_pessoa_id,
						tipo_favorecido: this.cheque.tipo_favorecido,
						pessoa_id: this.cheque.pessoa_id,
						favorecido: this.cheque.favorecido,
						dt_cadastro: this.cheque.dt_cadastro_br,
						dt_atualizacao: this.cheque.dt_atualizacao_br,
						dt_emissao: this.dt.formataDataBr(this.cheque.dt_emissao_br, this.detalhe),
						dt_compensacao: this.dt.formataDataBr(this.cheque.dt_compensacao_br, this.detalhe),
						numero: this.cheque.numero,
						valor: this.cheque.valor,
						observacao: this.cheque.observacao,
						finalidade: this.cheque.finalidade,
						conta_bancaria_id:  this.cheque.conta_bancaria_id,
						conta_bancaria: this.cheque.banco+' - Agência: '+ this.cheque.num_agencia+'-'+ this.cheque.dv_agencia+' - Conta: '+ this.cheque.num_conta+'-'+ this.cheque.dv_conta,
						financeiro_movimento_id: this.cheque.financeiro_movimento_id,
					});	
					this.loadTiposPessoas(this.cheque.tipo_pessoa_id,this.cheque.pessoa_id);	
				},(error) => {
					// this.swal.text = error.error.message;
					// this.swal.type = "error";
					// this.swal.title = "Erro!";
					// this.swal.show();
				})
			},(error) => {
				// this.swal.text = error.error.message;
				// this.swal.type = "error";
				// this.swal.title = "Erro!";
				// this.swal.show();
			})
		}else{
			this.pessoaService.getOptions('pessoa/tipo').subscribe((success) => {
				this.tiposPessoasSelect = success.dados;
			},(error) => {
				// this.swal.text = error.error.message;
				// this.swal.type = "error";
				// this.swal.title = "Erro!";
				// this.swal.show();
			})
		}
	}
	onSubmit(){
		this.submitted = true;
        if(!this.form.valid)
		return;

		let de_dt_emissao = '';
		if(this.form.value.de_dt_emissao != null)
		de_dt_emissao = `${this.form.value.de_dt_emissao.day}/${this.form.value.de_dt_emissao.month}/${this.form.value.de_dt_emissao.year}`

		let ate_dt_emissao = '';
		if(this.form.value.ate_dt_emissao != null)
		ate_dt_emissao = `${this.form.value.ate_dt_emissao.day}/${this.form.value.ate_dt_emissao.month}/${this.form.value.ate_dt_emissao.year}`
		
		let de_dt_compensacao = '';
		if(this.form.value.de_dt_compensacao != null)
		de_dt_compensacao = `${this.form.value.de_dt_compensacao.day}/${this.form.value.de_dt_compensacao.month}/${this.form.value.de_dt_compensacao.year}`

		let ate_dt_compensacao = '';
		if(this.form.value.ate_dt_compensacao != null)
		ate_dt_compensacao = `${this.form.value.ate_dt_compensacao.day}/${this.form.value.ate_dt_compensacao.month}/${this.form.value.ate_dt_compensacao.year}`
			

		this.form.patchValue({
			de_dt_emissao: de_dt_emissao,				
			ate_dt_emissao: ate_dt_emissao,
			de_dt_compensacao: de_dt_compensacao,
			ate_dt_compensacao: ate_dt_compensacao,
		})


		if(this.acao == "buscar"){
			this.router.navigate(['/'+SYSCONF_SIGLA+'/cheque/page/1',this.form.value]);
			return;
		}
        this.financeiroService[this.acao]('cheque',this.form)
        .subscribe( (success) => {
			this.id = parseInt(success.dados.id);
			if(this.acao == 'add'){
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.text = `Cheque cadastrado com sucesso!`;
				this.swal.show();
				this.submitted = false;
			}
			else{
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.text = `Cheque atualizado com sucesso!`;
				this.swal.show();
				this.submitted = false;
			}
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}
	onCloseModal(){
		if(this.swal.type == "success"){
			this.router.navigate([this.rota, this.id]);
		}
	}
}