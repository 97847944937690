import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'extension' })

export class getExtensionPipe implements PipeTransform {
  transform(item) {
    
    return item.split('.').pop()
  }

}