import { NgbDateStruct, NgbDatepickerConfig, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';

@Injectable()
export class NgbDatepickerCustomConfig extends NgbDatepickerConfig {
  config: NgbDatepickerConfig;

  constructor() {
    super();
    // customize default values of datepickers used by this component tree
    this.minDate = {year: 1940, month: 1, day: 1};
    this.maxDate = {year: 2099, month: 12, day: 31};
  }
}