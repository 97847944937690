import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SYSCONF_API, SYSCONF_SIGLA, SYSCONF_APP_ID, SYSCONF_CREDENTIALS, SYSCONF_LIMIT } from '../../app.api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { tap } from 'rxjs/operators';
import { Projeto } from './projeto';
import { FormGroup, FormArray } from '@angular/forms';
import { FormHelper } from '../_includes/forms/form-helper';

@Injectable()
export class ProjetoService{
    projeto: Projeto[];
    private headers;
    cob = null;
    constructor(private http: HttpClient, private formHelper: FormHelper){
    this.cob = JSON.parse(localStorage.getItem('CONFLogin'));
        this.headers = {'App-Id': SYSCONF_APP_ID,'Content-Type':'application/json','App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
    }


    export(tipo: 'excel' | 'pdf', form: FormGroup, params) {
		let criterioUrl:string = '';
        let campos:string;
        campos = form.get('campos').value.join('&campos[]=');
        criterioUrl = this.formHelper.getConditionURL(params);
        return this.http.get(`${SYSCONF_API}/projeto/exportar/${tipo}/?campos[]=${campos}&${criterioUrl}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response,
                error => error
            )
        );
    }
    
    getOptions(tipo,params = []): Observable<any>{
        let criterioUrl:string = '';
        criterioUrl = this.formHelper.getConditionURL(params);
        return this.http.get(`${SYSCONF_API}/${tipo}?limit=10000&page=1&${criterioUrl}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response,
                error => error
            )
        );
    }
    
    getProjetoById(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.get(`${SYSCONF_API}/projeto/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }
    
    list(params, limit = SYSCONF_LIMIT): Observable<any>{
        let criterioUrl:string = '';
        criterioUrl = this.formHelper.getConditionURL(params);
        return this.http.get(`${SYSCONF_API}/projeto?limit=${limit}&page=${params.page}&${criterioUrl}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response,
                error => error
            )
        );
    }

    add(form:FormGroup): Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}

        let formulario = this.formHelper.createFormDataProjeto(form);
        return this.http.post(`${SYSCONF_API}/projeto`,formulario,
        {headers: new HttpHeaders(this.headers)})
        .pipe(
            tap(
                response => this.projeto = response.dados,
                error => error
            )
        )
    }

    edit(form: FormGroup): Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        
        let formulario = this.formHelper.createFormDataProjeto(form);
        return this.http.post(`${SYSCONF_API}/projeto/${form.value.id}`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => this.projeto = response.dados,
                error => error
            )
        )
    }

    delete(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.delete(`${SYSCONF_API}/projeto/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    getProjetoAtividadeById(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.get(`${SYSCONF_API}/projeto/atividade/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    addProjetoAtividade(projeto_id, form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataAtividade(form, projeto_id);
        return this.http.post(`${SYSCONF_API}/projeto/atividade`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        )
    }

    editProjetoAtividade(id:number, form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataAtividade(form);
        return this.http.post(`${SYSCONF_API}/projeto/atividade/${id}`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        )
    }

    delProjetoAtividade(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.delete(`${SYSCONF_API}/projeto/atividade/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    getProjetoDocumentoById(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.get(`${SYSCONF_API}/projeto/documento/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    addProjetoDocumento(projeto_id, form: FormGroup, arquivo: File):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataProjetoDocumento(form, arquivo, projeto_id);
        return this.http.post(`${SYSCONF_API}/projeto/documento`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        )
    }

    editProjetoDocumento(id:number, form: FormGroup, arquivo: File):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataProjetoDocumento(form, arquivo);
        return this.http.post(`${SYSCONF_API}/projeto/documento/${id}`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        )
    }

    delProjetoDocumento(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.delete(`${SYSCONF_API}/projeto/documento/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    getProjetoStakeholderById(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.get(`${SYSCONF_API}/projeto/stakeholder/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    addProjetoStakeholder(projeto_id, form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataStakeholder(form, projeto_id);
        return this.http.post(`${SYSCONF_API}/projeto/stakeholder`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        )
    }

    editProjetoStakeholder(id:number, form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataStakeholder(form);
        return this.http.post(`${SYSCONF_API}/projeto/stakeholder/${id}`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        )
    }

    delProjetoStakeholder(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.delete(`${SYSCONF_API}/projeto/stakeholder/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    getProjetoInstituicaoParceiraById(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.get(`${SYSCONF_API}/projeto/instituicao-parceira/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    addProjetoInstituicaoParceira(projeto_id, form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataInstituicaoParceira(form, projeto_id);
        return this.http.post(`${SYSCONF_API}/projeto/instituicao-parceira`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        )
    }

    editProjetoInstituicaoParceira(id:number, form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataInstituicaoParceira(form);
        return this.http.post(`${SYSCONF_API}/projeto/instituicao-parceira/${id}`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        )
    }

    delProjetoInstituicaoParceira(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.delete(`${SYSCONF_API}/projeto/instituicao-parceira/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    getProjetoEtapaById(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.get(`${SYSCONF_API}/projeto/etapa/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    addProjetoEtapa(projeto_id, form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataEtapa(form, projeto_id);
        return this.http.post(`${SYSCONF_API}/projeto/etapa`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        )
    }

    editProjetoEtapa(id:number, form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataEtapa(form);
        return this.http.post(`${SYSCONF_API}/projeto/etapa/${id}`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        )
    }

    delProjetoEtapa(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.delete(`${SYSCONF_API}/projeto/etapa/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    getProjetoMetaById(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.get(`${SYSCONF_API}/projeto/meta/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    addProjetoMeta(projeto_id, form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataMeta(form, projeto_id);
        return this.http.post(`${SYSCONF_API}/projeto/meta`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        )
    }

    editProjetoMeta(id:number, form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataMeta(form);
        return this.http.post(`${SYSCONF_API}/projeto/meta/${id}`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        )
    }

    delProjetoMeta(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.delete(`${SYSCONF_API}/projeto/meta/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    getProjetoOrcamentoById(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.get(`${SYSCONF_API}/projeto/orcamento/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    addProjetoOrcamento(projeto_id, form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataOrcamento(form, projeto_id);
        return this.http.post(`${SYSCONF_API}/projeto/orcamento`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        )
    }

    editProjetoOrcamento(id:number, form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataOrcamento(form);
        return this.http.post(`${SYSCONF_API}/projeto/orcamento/${id}`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        )
    }

    delProjetoOrcamento(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.delete(`${SYSCONF_API}/projeto/orcamento/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    getProjetoRiscoById(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.get(`${SYSCONF_API}/projeto/risco/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    addProjetoRisco(projeto_id, form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataRisco(form, projeto_id);
        return this.http.post(`${SYSCONF_API}/projeto/risco`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        )
    }

    editProjetoRisco(id:number, form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataRisco(form);
        return this.http.post(`${SYSCONF_API}/projeto/risco/${id}`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        )
    }

    delProjetoRisco(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.delete(`${SYSCONF_API}/projeto/risco/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    getProjetoSituacaoById(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.get(`${SYSCONF_API}/projeto/situacao/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    addProjetoSituacao(projeto_id, form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataSituacao(form, projeto_id);
        return this.http.post(`${SYSCONF_API}/projeto/situacao`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        )
    }

    editProjetoSituacao(id:number, form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataSituacao(form);
        return this.http.post(`${SYSCONF_API}/projeto/situacao/${id}`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        )
    }

    delProjetoSituacao(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.delete(`${SYSCONF_API}/projeto/situacao/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }
}