import { PessoaService } from '../../pessoa.service';
import { Governamental } from './../governamental';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { SYSCONF_SIGLA } from 'src/app.api';
@Component({
	selector: 'app-governamental-detail',
  	templateUrl: './governamentalDetail.component.html',
  	styleUrls: ['./governamentalDetail.component.scss']
})

export class GovernamentalDetailComponent implements OnInit{
	@ViewChild('Swal') public swal:SwalComponent; 
	public message:string = '';
	detailForm: FormGroup;
	governamental: Governamental;
	governamental_id:number;
	thumb:string = '';
	pessoa_id:number;
	enderecos:object = new Array();
	telefones:object = new Array();
	contasBancarias:object = new Array();
	sigla = SYSCONF_SIGLA;
	anexos: any;
	acesso = new Array();
	constructor(private pessoaService: PessoaService, private activatedRoute: ActivatedRoute,private formBuilder: FormBuilder){
		this.detailForm = this.formBuilder.group({
			id: this.formBuilder.control(0),
			dt_cadastro: this.formBuilder.control(''),
			dt_atualizacao: this.formBuilder.control(''),
			cnpj: this.formBuilder.control(''),
			razao_social: this.formBuilder.control(''),
			nome_completo: this.formBuilder.control(''),
			email: this.formBuilder.control(''),
			status: this.formBuilder.control(''),
			sigla: this.formBuilder.control(''),
			tipo: this.formBuilder.control(''),
			observacao: this.formBuilder.control(''),
			website: this.formBuilder.control(''),
			foto: this.formBuilder.control('')
		});	
		this.governamental_id = this.activatedRoute.snapshot.params['id'];
		this.pessoaService.getPessoaById('pessoa/governamental',this.governamental_id).subscribe(
		(governamental) => {
			this.governamental = governamental.dados;
			this.pessoa_id = this.governamental.pessoa_id;
			this.acesso = this.governamental.acesso;
			if(this.governamental.foto){
				this.thumb = this.governamental.foto.thumb;
			}
			this.pessoaService.getPessoaById('pessoa',this.pessoa_id).subscribe(
				(success) =>{
					this.enderecos = success.dados.endereco;
					this.telefones = success.dados.telefone;
					this.contasBancarias = success.dados.conta_bancaria;
					this.anexos = success.dados.anexo;
				},
				(error) => {
					this.message = error.error.message;
				})
				this.detailForm.patchValue({
					id: this.governamental.id,
					dt_cadastro: this.governamental.dt_cadastro_br,
					dt_atualizacao: this.governamental.dt_atualizacao_br,
					cnpj: this.governamental.cnpj,
					razao_social: this.governamental.razao_social,
					nome_completo: this.governamental.nome_completo,
					email: this.governamental.email,
					status: this.governamental.status,
					sigla: this.governamental.sigla,
					tipo: this.governamental.tipo,
					observacao: this.governamental.observacao,
					website: this.governamental.website,
					foto: this.thumb
				});
			})
		}

	ngOnInit(){
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.pessoa_governamental.includes("CONSULTAR"))
			this.message = 'Você não possui permissão para executar esta ação'
	}
	
	
}