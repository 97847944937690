import { isArray } from 'util';
import { Component, ViewChild, Input, OnInit} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from '../../../login/login.service';
import { FinanceiroService } from '../../financeiro.service';
import { RelatorioFinanceiroService } from '../relatorioFinanceiro.service';
import { TreeviewItem, TreeviewConfig, TreeItem, DownlineTreeviewItem, TreeviewComponent, TreeviewHelper } from 'ngx-treeview';
import { SYSCONF_SIGLA } from '../../../../app.api';
import { PreCadastroService } from 'src/app/pre-cadastro/pre-cadastro.service';
import { ProjetoService } from '../../../projeto/projeto.service';
import { DataService } from '../../../_services/data.service';

declare var $: any;
@Component({
	selector: 'app-relatorio-balancete',
	templateUrl: './relatorioBalancete.component.html',
	styleUrls: ['./relatorioBalancete.component.scss']
})

export class RelatorioBalanceteComponent implements OnInit{
	@ViewChild(TreeviewComponent) treeviewComponent: TreeviewComponent;
	form: FormGroup;
	plano_contas_id:number = null;
	contasBancariasSelect = new Array();
	centrosDeCustoSelect = new Array();
	projetosSelect = new Array();
	modalTitle:string;
	submitted: boolean = false;
	loading: boolean = false;
	modalReference: NgbModalRef;   
	planoDeContas = new Array();
	items: TreeviewItem[] = new Array();
	value;
	sigla = SYSCONF_SIGLA;
	planoDeContasSigef = new Array();
    config = TreeviewConfig.create({
		hasAllCheckBox: false,
		hasFilter: true,
        hasCollapseExpand: true,
		decoupleChildFromParent: false,
		maxHeight: 99999
	});

	totalDespesaPeriodo:number = 0;
	totalReceitaPeriodo:number = 0;

	saldoPeriodo:number = 0;
	saldoAnterior:number = 0;
	saldoAtual:number = 0;

	constructor(private router: Router, private activatedRoute: ActivatedRoute, private preCadastroService: PreCadastroService, private projetoService: ProjetoService, private loginService:LoginService, private relatorioFinanceiroService: RelatorioFinanceiroService, private financeiroService: FinanceiroService, private formBuilder: FormBuilder,  private dt: DataService){
		this.preCadastroService.getOptions('financeiro/conta-bancaria').subscribe((success) => {
			this.contasBancariasSelect = success.dados;
			this.contasBancariasSelect.map((row) => { row.labelSelect = `${row.banco} - Ag.: ${row.num_agencia}-${row.dv_agencia} - ${row.tipo}: ${row.num_conta}-${row.dv_conta}`; return row; });
			
		},(error) => {
			
		})

		this.preCadastroService.getOptions('financeiro/centro-custo').subscribe((success) => {
			this.centrosDeCustoSelect = success.dados;
		},(error) => {

		})

		this.projetoService.getOptions('projeto').subscribe((success) => {
			this.projetosSelect = success.dados;	
		},(error) => {

		})
		
		this.form = this.formBuilder.group({
			de_periodo: this.formBuilder.control(''),
			ate_periodo: this.formBuilder.control(''),
			conta_bancaria_id: this.formBuilder.control(''),
			centro_custo_id: this.formBuilder.control(''),
			projeto_id: this.formBuilder.control(''),
			status: this.formBuilder.control(''),
			data_base: this.formBuilder.control('dt_previsao')
		})
	}

	ngOnInit(){
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.financeiro_relatorio_balancete.includes("CONSULTAR")){
			this.router.navigate(['/'+SYSCONF_SIGLA]);
			return;
		}

		this.activatedRoute.params.subscribe(params => {
			this.totalDespesaPeriodo = 0;
			this.totalReceitaPeriodo = 0;
		
			this.saldoPeriodo = 0;
			this.saldoAnterior = 0;
			this.saldoAtual = 0;

			if(params.data_base) {
				switch(params.data_base) {
					case 'dt_conciliacao':
						params = Object.assign({ de_dt_conciliacao: params.de_periodo, ate_dt_conciliacao: params.ate_periodo }, params);
						break;
					case 'dt_baixa':
						params = Object.assign({ de_dt_baixa: params.de_periodo, ate_dt_baixa: params.ate_periodo }, params);
						break;

					case 'dt_previsao':
						params = Object.assign({ de_dt_previsao: params.de_periodo, ate_dt_previsao: params.ate_periodo }, params);
						break;
					default:
						params = Object.assign({ de_dt_conciliacao: params.de_periodo, ate_dt_conciliacao: params.ate_periodo }, params);
				}
			}else{
				params = Object.assign({ de_dt_conciliacao: params.de_periodo, ate_dt_conciliacao: params.ate_periodo }, params);
			}

			this.relatorioFinanceiroService.get('balancete', params)
			.subscribe( (success) => {
				this.items = [];
				this.planoDeContas = success.dados.dados_gerais;

				this.saldoAnterior = success.dados.saldo_anterior;
				this.saldoAtual = success.dados.saldo_total;
				this.createTreeView(this.planoDeContas);
				
				//console.log(this.dt.formataDataBr(params.de_periodo));
				
				this.form.patchValue({

					de_periodo: params.de_periodo && params.de_periodo.length > 0 ? this.dt.formataDataBr(params.de_periodo) : '',				
					ate_periodo: params.ate_periodo && params.ate_periodo.length > 0 ? this.dt.formataDataBr(params.ate_periodo) : '',
					conta_bancaria_id: params.conta_bancaria_id && params.conta_bancaria_id.length > 0 ?  params.conta_bancaria_id : '',
					centro_custo_id: params.centro_custo_id && params.centro_custo_id.length > 0 ? params.centro_custo_id : '',
					projeto_id: params.projeto_id && params.projeto_id.length > 0 ? params.projeto_id : '',
					status: params.status && params.status.length > 0  ? params.status : '',
					data_base: params.data_base && params.data_base.length > 0  ? params.data_base : 'dt_previsao'
				})
			},
			(error) => {});
		});
	}


	
	createTreeView(planoDeContas,children = 0):any{
		if(planoDeContas.length == 0)
			return;

		let children_items:TreeviewItem[] = new Array();
		let dados:any;

		if(children){
			planoDeContas.forEach( k => { 
				if(k.sub_plano.length > 0) {
					k.total = +k.total + this.somaSubPlanos(k.sub_plano);
				}
				dados = { id: k.id, total: k.total };

				this.planoDeContasSigef[k.id] = k.codigo_cob; 

				if(k.sub_plano.length > 0){
					children_items.push(new TreeviewItem({text: k.nome, value: dados, children: this.createTreeView(k.sub_plano,1)}))
				} else {
					children_items.push(new TreeviewItem({text: k.nome, value: dados }))
				}
			});
			return children_items;
		}

		this.planoDeContas.forEach( (v, i) => { 

			if(v.sub_plano.length > 0 && i>1) {
				v.total = +v.total + this.somaSubPlanos(v.sub_plano);
			}

			if(i == 1) {
				this.totalDespesaPeriodo = parseFloat(v.total);
				this.saldoPeriodo = this.saldoPeriodo - this.totalDespesaPeriodo;
			}

			if(i == 0) {
				this.totalReceitaPeriodo = parseFloat(v.total);
				this.saldoPeriodo = this.saldoPeriodo + this.totalReceitaPeriodo;
			}


			dados = {
				id: v.id.toString(),
				total: v.total.toString(),
				tipo: v.nome == 'Despesa' ? 'despesa' : 'receita'
			}
		
			this.planoDeContasSigef[v.id] = v.codigo_cob; 
			this.items.push(new TreeviewItem({text: v.nome, value: dados, children: this.createTreeView(v.sub_plano,1)}))
		});
	}

	somaSubPlanos(sp) :number {
		let total:number = 0;
		sp.forEach( k => { 
			if(k.sub_plano && k.sub_plano.length > 0) {
				let total_sub = this.somaSubPlanos(k.sub_plano);
				total = total + total_sub;
			}

			total = total + +k.total;

		});
		return total;
		

	}
	
	getPermissao(modulo,secao){
		return this.loginService.getPermissao(modulo,secao);
	}

	onSubmit(){
		let de_periodo = this.dt.convertDataObject(this.form.value,'de_periodo');
		let ate_periodo = this.dt.convertDataObject(this.form.value,'ate_periodo');
		let form = this.formBuilder.group({
			de_periodo: this.formBuilder.control(de_periodo),
			ate_periodo: this.formBuilder.control(ate_periodo),
			conta_bancaria_id: this.formBuilder.control(this.form.value.conta_bancaria_id),
			centro_custo_id: this.formBuilder.control(this.form.value.centro_custo_id),
			projeto_id: this.formBuilder.control(this.form.value.projeto_id),
			status: this.formBuilder.control(this.form.value.status),
			data_base: this.formBuilder.control(this.form.value.data_base),
		});
		if(de_periodo.indexOf('undefined') !== -1){
			form.patchValue({
				de_periodo: ''
			})
		}
		if(ate_periodo.indexOf('undefined') !== -1){
			form.patchValue({
				ate_periodo: ''
			})
		}
		this.router.navigate(['/'+SYSCONF_SIGLA+'/relatorio-financeiro/balancete/',form.value]);
	}


}
