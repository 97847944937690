import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { Movimento } from '../../financeiro/movimento/movimento';
import { FinanceiroService } from '../../financeiro/financeiro.service';


@Component({
  selector: 'app-home-movimento',
  templateUrl: './home-movimento.component.html',
  styleUrls: ['../home.component.scss']
})
export class HomeMovimentoComponent implements OnInit {
  movimentoTodos: Movimento[];
  movimentoReceita: Movimento[];
  movimentoDespesa: Movimento[];

  mostrarTodos: boolean;
  mostrarReceita: boolean;
  mostrarDespesa: boolean;

  messageTodos: string;
  messageReceita: string;
  messageDespesa: string;

  limit: number = 10;

  viewDate: Date = new Date();
  dayMonth: string;
  today: string;

  edit:boolean = false;
	detail:boolean = false;
	delete:boolean = false;
	create:boolean = false;
  

  constructor(private cd: ChangeDetectorRef, private financeiroService: FinanceiroService) { }

  ngOnInit() {

    let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(t.permissao.financeiro_movimento.includes("CONSULTAR"))
			this.detail = true;
		if(t.permissao.financeiro_movimento.includes("CRIAR"))
			this.create = true;
		if(t.permissao.financeiro_movimento.includes("EDITAR"))
			this.edit = true;
		if(t.permissao.financeiro_movimento.includes("EXCLUIR"))
      this.delete = true;
      
    let day = ("0" + this.viewDate.getDate()).slice(-2);		
		let month = ("0" + (this.viewDate.getMonth() + 1)).slice(-2);
		this.dayMonth = day + "/" + month;
    
    this.today = day + "/" + month + "/" + this.viewDate.getFullYear();

    this.mostrar('todos');

  }

  mostrar(tipo){
    
    switch(tipo){
      
      case 'receita': {
        if(!this.movimentoReceita){
          this.getReceita();
        }

        this.mostrarTodos = false;
        this.mostrarReceita = true;
        this.mostrarDespesa = false;

        break; 
      }

      case 'despesa': {
        if(!this.movimentoDespesa){
          this.getDespesa();
        }

        this.mostrarTodos = false;
        this.mostrarReceita = false;
        this.mostrarDespesa = true;

        break; 
      }

      default: {

        if(!this.movimentoTodos){
          this.getTodos();
        }

        this.mostrarTodos = true;
        this.mostrarReceita = false;
        this.mostrarDespesa = false;


        break; 

      }

    }
      
      
    
  }

  getTodos(){
    let param = { 'de_dt_previsao': this.today, 'ate_dt_previsao': this.today, 'page': 1};
    this.financeiroService.listCustom('movimento',param, 10)
    .subscribe(
      (movimento) => {

        this.movimentoTodos = movimento.dados.registros;

        if(this.movimentoTodos.length == 0) {
          this.messageTodos = "Nenhum movimento encontrado";
        }

 
        this.cd.markForCheck();
      },
      (error) => {
        this.cd.markForCheck();
        this.messageTodos = error.error.message;
      });

  }

  getReceita(){
    let param = { 'tipo': 'R', 'de_dt_previsao': this.today, 'ate_dt_previsao': this.today, 'page': 1};
    this.financeiroService.listCustom('movimento',param, 10)
    .subscribe(
      (movimento) => {
      
        this.movimentoReceita = movimento.dados.registros;

        if(this.movimentoReceita.length == 0) {
          this.messageReceita = "Nenhuma receita encontrada";
        }

       
        this.cd.markForCheck();
      },
      (error) => {
        this.cd.markForCheck();
        this.messageReceita = error.error.message;
      });

  }

  getDespesa(){
    let param = { 'tipo': 'D', 'de_dt_previsao': this.today, 'ate_dt_previsao': this.today, 'page': 1};
    this.financeiroService.listCustom('movimento',param, 10)
      .subscribe(
        (movimento) => {

          this.movimentoDespesa = movimento.dados.registros;

          if(this.movimentoDespesa.length == 0) {
            this.messageDespesa = "Nenhuma despesa encontrada";
          }


          this.cd.markForCheck();
        },
        (error) => {
          this.cd.markForCheck();
          this.messageDespesa = error.error.message;
        });
  }

  
}
