import { Component, ViewChild, Input, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { PessoaService } from '../../../pessoa/pessoa.service';
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';
import { Dependente } from 'src/app/pessoa/dependente/dependente';
import { PreCadastroService } from 'src/app/pre-cadastro/pre-cadastro.service';

declare var $: any;
@Component({
	selector: 'app-form-funcionario-dependente',
  	templateUrl: './form-funcionario-dependente.component.html',
  	styleUrls: ['./form-funcionario-dependente.component.scss']
})
export class FormFuncionarioDependenteComponent implements OnChanges{
	@ViewChild('Swal') public swal:SwalComponent;
	@ViewChild('SwalDelete') public swalDelete:SwalComponent; 
	@Input() dependentes: Dependente[] = null;
	@Input() pessoa_id: number;
	@Input() acao:string;
	@Input() detalhe:boolean;
	@Input() pessoa_status: string = 'A';
	form: FormGroup;
	dependente_id:number = null;
	modalTitle:string;
	submitted: boolean = false;
	loading: boolean = false;
	modalReference: NgbModalRef; 
	grauParentescoSelect = new Array();
	dependentesSelect: Dependente[];
	disabled = false;
	constructor(private pessoaService: PessoaService, private preCadastroService: PreCadastroService, private formBuilder: FormBuilder, private modalService: NgbModal, private activatedRoute: ActivatedRoute){
        

		this.preCadastroService.get('pessoa/grau-parentesco')
        .subscribe( (success) => {
			this.grauParentescoSelect = success.dados;
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
		this.form = this.formBuilder.group({
			grau_parentesco_id: this.formBuilder.control('',[Validators.required]),
			dependente_id: this.formBuilder.control({value: '', disabled: this.disabled},[Validators.required]),
			funcionario_id: this.formBuilder.control(this.activatedRoute.snapshot.params['id']),
			nome_completo: this.formBuilder.control(''),
			cpf: this.formBuilder.control(''),
		})
	}
	ngOnChanges(){
		this.initDependentes();
	}
	initDependentes(){
		this.pessoaService.getOptions('pessoa/dependente')
        .subscribe( (success) => {
			this.dependentesSelect = success.dados;
			//Removendo os dependentes que já são vinculados a esse funcionário do select do modal.
			this.dependentesSelect = this.dependentesSelect.filter((obj) => {
				let existe = this.dependentes.find(item => item.dependente_id == obj.id)
				if(existe)
					return false;
				return true;
			  })
		})
	}

	onSubmit(){
		this.submitted = true;
        if(!this.form.valid)
		return;
		this.modalReference.close();
		if(!this.dependente_id){
			this.pessoaService.addFuncionarioDependente(this.form)
			.subscribe( (success) => {
				this.swal.text = "Dependente cadastrado com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
			return;
		}

		this.pessoaService.editFuncionarioDependente(this.dependente_id,this.form)
			.subscribe( (success) => {
				this.swal.text = "Dependente atualizado com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});

        
	}

	openModal(modal,id:number = null) {
		this.disabled = false;
		//Se id == null então é cadastro.
		if(!id){
			
			this.dependente_id = null;
			this.modalTitle = "Cadastrar Dependente";
			this.form.patchValue({
				grau_parentesco_id: '',
				dependente_id:  '',
				nome_completo:  '',
				cpf:  ''
			});
			this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
			return;
		}
		
		//Senão é edição.
		this.dependente_id = id;
		this.disabled = true;
		this.modalTitle = "Editar Dependente";
		this.pessoaService.getFuncionarioDependenteById(id).subscribe((success) => {
			this.form.patchValue({
				grau_parentesco_id: success.dados.grau_parentesco_id,
				dependente_id: this.dependente_id,
				nome_completo:  success.dados.nome_completo,
				cpf:  success.dados.cpf
			});
			this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		})
	}

	askDelete(id:number){
		this.dependente_id = id;
		this.swalDelete.show();
	}

	confirmDelete(){
		this.pessoaService.delFuncionarioDependente(this.dependente_id).subscribe( 
		(success) => {
			this.swal.text = "Dependente removido com sucesso!";
			this.swal.type = "success";
			this.swal.title = "Pronto!";
			this.swal.show();
			this.submitted = false;
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}

	onCloseModal(){
		if(this.swal.type == "success"){
			this.pessoaService.getPessoaById('pessoa/funcionario',this.activatedRoute.snapshot.params['id']).subscribe((success)=>{
				this.dependentes = success.dados.dependente;
				this.submitted = false;
				this.initDependentes();
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
		}
	}
}