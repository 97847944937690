import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { Movimento } from '../../financeiro/movimento/movimento';
import { FinanceiroService } from '../../financeiro/financeiro.service';
import { t } from '@angular/core/src/render3';

declare var Chartist: any;

@Component({
  selector: 'app-home-receita-despesa',
  templateUrl: './home-receita-despesa.component.html',
  styleUrls: ['../home.component.scss']
})
export class HomeReceitaDespesaComponent implements OnInit {
  movimento: any[];
  totalDespesa: number = 0;
  totalReceita: number = 0;
  saldo: number = 0;
  saldoNegativo: boolean = false;
  limit: number = 10;
  message: string;
  viewDate: Date = new Date();
  dayMonth: string;
  monthYear:string;
  today: string;
  mesSelecionado: number;
  mesesOptions: any[];
  anoAtual: number;
  constructor(private cd: ChangeDetectorRef, private financeiroService: FinanceiroService) { }

  ngOnInit() {
    this.mesesOptions = new Array();
    this.mesesOptions.push({'num': 1, 'nome': 'Janeiro'});
    this.mesesOptions.push({'num': 2, 'nome': 'Fevereiro'});
    this.mesesOptions.push({'num': 3, 'nome': 'Março'});
    this.mesesOptions.push({'num': 4, 'nome': 'Abril'});
    this.mesesOptions.push({'num': 5, 'nome': 'Maio'});
    this.mesesOptions.push({'num': 6, 'nome': 'Junho'});
    this.mesesOptions.push({'num': 7, 'nome': 'Julho'});
    this.mesesOptions.push({'num': 8, 'nome': 'Agosto'});
    this.mesesOptions.push({'num': 9, 'nome': 'Setembro'});
    this.mesesOptions.push({'num': 10, 'nome': 'Outubro'});
    this.mesesOptions.push({'num': 11, 'nome': 'Novembro'});
    this.mesesOptions.push({'num': 12, 'nome': 'Dezembro'});

    this.anoAtual = this.viewDate.getFullYear();
    let day = this.viewDate.getDate();
    
    this.mesSelecionado = this.viewDate.getMonth() + 1;

    let month = ("0" + (this.viewDate.getMonth() + 1)).slice(-2);
    this.monthYear = this.mesesOptions[this.viewDate.getMonth()].nome + " " + this.viewDate.getFullYear();
    
    this.getMovimentoByMes(this.mesSelecionado);
    

    }


    getMovimentoByMes(mes:number) {
      this.movimento = [];
      this.totalDespesa = 0;
      this.totalReceita = 0;
      this.saldo = 0;
      this.saldoNegativo = false;
      var chart;
      this.financeiroService.getStats('movimento-financeiro/'+mes+'/'+this.viewDate.getFullYear())
    .subscribe(
      (movimento) => {

        this.cd.markForCheck();

        this.movimento = movimento.dados.registros;

        this.totalDespesa = movimento.dados.total_despesa > 0 ? movimento.dados.total_despesa : 0;
        this.totalReceita = movimento.dados.total_receita > 0 ? movimento.dados.total_receita : 0;
        this.saldo = movimento.dados.saldo ;

        if(this.saldo < 0) {
          this.saldoNegativo = true;
          this.saldo = Math.abs(this.saldo);
        }
          
        this.generateChart(mes);

      },
      (error) => {
        this.cd.markForCheck();

        this.generateChart(mes);
      });
    }


    generateChart(mes) {
        let initialDate = 1;
        let lastDayDate = new Date(this.viewDate.getFullYear(), mes, 0, 23, 59, 59);
        let labels = new Array();
        let despesa = new Array();
        let receita = new Array();
        let indexDespesa:number, valDespesa:number;
        let indexReceita:number, valReceita:number;
        let maxValue: number;
        let i = 0;

        while(i < lastDayDate.getDate()){
          
          labels.push(initialDate);

          indexDespesa = this.movimento.findIndex(item => item.dia === ("0" + (initialDate)).slice(-2) && item.tipo === "D");
          indexReceita = this.movimento.findIndex(item => item.dia === ("0" + (initialDate)).slice(-2) && item.tipo === "R");

          
          
          valDespesa = 0;
          if(indexDespesa > -1){
            valDespesa = this.movimento[indexDespesa].total; 

            if(valDespesa > maxValue) {
              maxValue = valDespesa;
            }
          }

          valReceita = 0;
          if(indexReceita > -1){
            valReceita = this.movimento[indexReceita].total;

            if(valReceita > maxValue) {
              maxValue = valReceita;
            }
          }

          despesa.push(valDespesa);
          receita.push(valReceita);

          

          i++;
          initialDate = initialDate + 1;
        }


      let chart = new Chartist.Line('.website-visitor', {
          labels: labels,
          series: [
            despesa,
            receita
          ]
      },{
          low: 0,
          high: maxValue,
          showArea: true,
          fullWidth: true,
          plugins: [
            Chartist.plugins.tooltip()
          ],axisY: {
              onlyInteger: true,
              scaleMinSpace: 40,
              offset: 20,
              labelInterpolationFnc: function (value) {
                  return (value / 1) + 'k';
              }
          },lineSmooth: Chartist.Interpolation.simple({
            divisor: 2
          })
      });
    
      chart.on('draw', function(ctx){  
          if(ctx.type === 'area'){
              ctx.element.attr({
                  x1: ctx.x1 + 0.001
              });
          }
      });

    chart.on('created', function(ctx) {
        var defs = ctx.svg.elem('defs');
        defs.elem('linearGradient', {
            id: 'gradient',
            x1: 0,
            y1: 1,
            x2: 0,
            y2: 0
        }).elem('stop',{
            offset: 0,
            'stop-color': 'rgba(255, 255, 255, 1)'
        }).parent().elem('stop',{
            offset: 1,
            'stop-color': 'rgba(38, 198, 218, 1)'
        });
    });
    }
    verificaDespesa(item) {
      let date: Date = new Date;
      if(item.dia == ("0" + (date.getDate())).slice(-2) && item.tipo == "D"){
        return true;
      }else{
        return false;
      }
    }

    verificaReceita(item) {
      let date: Date = new Date;
      if(item.dia == ("0" + (date.getDate())).slice(-2) && item.tipo == "R"){
        return true;
      }else{
        return false;
      }
    }

}
