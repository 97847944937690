import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { PessoaService } from '../../pessoa/pessoa.service';

@Component({
  selector: 'app-home-aniversariante',
  templateUrl: './home-aniversariante.component.html',
  styleUrls: ['../home.component.scss']
})
export class HomeAniversarianteComponent implements OnInit {
  aniversariante: any[];
  mesSelecionado: number;
  message: string;
  
  viewDate: Date = new Date();

  mesesOptions: any[];
  mesAno: string;

  constructor(private cd: ChangeDetectorRef, private pessoaService: PessoaService) { }

  ngOnInit() {

    this.mesesOptions = new Array();
    this.mesesOptions.push({'num': 1, 'nome': 'Janeiro'});
    this.mesesOptions.push({'num': 2, 'nome': 'Fevereiro'});
    this.mesesOptions.push({'num': 3, 'nome': 'Março'});
    this.mesesOptions.push({'num': 4, 'nome': 'Abril'});
    this.mesesOptions.push({'num': 5, 'nome': 'Maio'});
    this.mesesOptions.push({'num': 6, 'nome': 'Junho'});
    this.mesesOptions.push({'num': 7, 'nome': 'Julho'});
    this.mesesOptions.push({'num': 8, 'nome': 'Agosto'});
    this.mesesOptions.push({'num': 9, 'nome': 'Setembro'});
    this.mesesOptions.push({'num': 10, 'nome': 'Outubro'});
    this.mesesOptions.push({'num': 11, 'nome': 'Novembro'});
    this.mesesOptions.push({'num': 12, 'nome': 'Dezembro'});
    
    this.mesSelecionado = this.viewDate.getMonth() + 1;
    this.mesAno = this.mesesOptions[this.viewDate.getMonth()].nome + " " + this.viewDate.getFullYear();

    this.getAniversarianteByMes(this.mesSelecionado);
  }

  getAniversarianteByMes(mes){

    this.aniversariante = [];
    this.pessoaService.getFuncionarioAniversarianteByMes(mes)
    .subscribe(
      (aniversariante) => {
      
        this.aniversariante = aniversariante.dados;

        if(!this.aniversariante || this.aniversariante.length == 0) {
          this.message = "Nenhum aniversariante para este mês";
        }

        
        this.cd.markForCheck();
      },
      (error) => {
        this.cd.markForCheck();
        this.message = error.error.message;
      });

  }

}
