import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SYSCONF_API, SYSCONF_SIGLA, SYSCONF_APP_ID, SYSCONF_CREDENTIALS, SYSCONF_LIMIT } from '../../app.api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { tap } from 'rxjs/operators';
import { FormHelper } from '../_includes/forms/form-helper';
import { FormGroup, FormArray } from '@angular/forms';
import { FolhaDePagamento } from './folha-de-pagamento/folhadepagamento';
@Injectable()
export class RHService{
    folhadepagamento: FolhaDePagamento[];
    private headers;
    cob = null;
    constructor(private http: HttpClient, private formHelper: FormHelper){
    this.cob = JSON.parse(localStorage.getItem('CONFLogin'));
        this.headers = {'App-Id': SYSCONF_APP_ID,'Content-Type':'application/json','App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
    }

    
    get(endpoint, params, limit = SYSCONF_LIMIT): Observable<any>{
        let criterioUrl:string = '';
        criterioUrl = this.formHelper.getConditionURL(params);
        return this.http.get(`${SYSCONF_API}/${endpoint}?limit=${limit}&page=${params.page}&${criterioUrl}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response,
                error => error
            )
        );
    }   
    
    getById(endpoint, id): Observable<any>{
        return this.http.get(`${SYSCONF_API}/${endpoint}/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response,
                error => error
            )
        );
    }

    addFolhaDePagamento(form:FormGroup): Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = this.formHelper.createFormDataFolhaDePagamento(form);
        return this.http.post(`${SYSCONF_API}/folha-pagamento`,formulario,
        {headers: new HttpHeaders(this.headers)})
        .pipe(
            tap(
                response => this.folhadepagamento = response.dados,
                error => error
            )
        )
    }

    editFolhaDePagamento(form: FormGroup): Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = this.formHelper.createFormDataFolhaDePagamento(form);
        return this.http.post(`${SYSCONF_API}/folha-pagamento/${form.value.id}`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => this.folhadepagamento = response.dados,
                error => error
            )
        )
    }

    getFolhaDePagamentoFuncionarioById(id): Observable<any>{
        return this.http.get(`${SYSCONF_API}/folha-pagamento/funcionario/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response,
                error => error
            )
        );
    }

    addFolhaDePagamentoFuncionario(form: FormGroup): Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = this.formHelper.createFormDataFolhaDePagamentoFuncionario(form);
        return this.http.post(`${SYSCONF_API}/folha-pagamento/funcionario`,formulario,
        {headers: new HttpHeaders(this.headers)})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        )
    }

    editFolhaDePagamentoFuncionario(form: FormGroup): Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = this.formHelper.createFormDataFolhaDePagamentoFuncionario(form);
        return this.http.post(`${SYSCONF_API}/folha-pagamento/funcionario`,formulario,
        {headers: new HttpHeaders(this.headers)})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        )
    }

    addFolhaDePagamentoFuncionarioVerba(folhapagamento_funcionario_id, verbas: FormArray): Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = this.formHelper.createFormDataFolhaDePagamentoFuncionarioVerba(folhapagamento_funcionario_id, verbas);
        return this.http.post(`${SYSCONF_API}/folha-pagamento/verba`,formulario,
        {headers: new HttpHeaders(this.headers)})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        )
    }

    delete(endpoint, id): Observable<any>{
        return this.http.delete(`${SYSCONF_API}/${endpoint}/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response,
                error => error
            )
        );
    } 
    
    export(tipo: 'excel' | 'pdf',endpoint: string, form: FormGroup, params: any) {
		let criterioUrl:string = '';
        let campos:string;
        campos = form.get('campos').value.join('&campos[]=');
        criterioUrl = this.formHelper.getConditionURL(params);
        return this.http.get(`${SYSCONF_API}/${endpoint}/exportar/${tipo}/?campos[]=${campos}&${criterioUrl}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response,
                error => error
            )
        );
	}
}