import { Component, ViewChild, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { PessoaService } from '../../../pessoa/pessoa.service';
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';
import { Atleta } from 'src/app/pessoa/atleta/atleta';
import { PreCadastroService } from 'src/app/pre-cadastro/pre-cadastro.service';

declare var $: any;
@Component({
	selector: 'app-form-dependente',
  	templateUrl: './form-dependente.component.html',
  	styleUrls: ['./form-dependente.component.scss']
})
export class FormDependenteComponent{
	@ViewChild('Swal') public swal:SwalComponent;
	@ViewChild('SwalDelete') public swalDelete:SwalComponent; 
	@Input() dependentes;
	@Input() pessoa_id: number;
	@Input() acao:string;
	@Input() detalhe:boolean;
	form: FormGroup;
	dependente_id:number = null;
	modalTitle:string;
	submitted: boolean = false;
	loading: boolean = false;
	modalReference: NgbModalRef; 
	atletas: Atleta[];  
	grausParentesco:[{}];
    
	constructor(private pessoaService: PessoaService, private preCadastroService: PreCadastroService, private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute,private modalService: NgbModal){
        this.pessoaService.getOptions('pessoa/atleta')
        .subscribe( (success) => {
			this.atletas = success.dados;
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});

		this.preCadastroService.get('pessoa/grau-parentesco')
        .subscribe( (success) => {
			this.grausParentesco = success.dados;
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
		this.form = this.formBuilder.group({
			grau_parentesco_id: this.formBuilder.control('',[Validators.required]),
			atleta_id: this.formBuilder.control('',[Validators.required]),
			responsavel_id: this.formBuilder.control(this.activatedRoute.snapshot.params['id']),
		})
    }

	onSubmit(){
		this.submitted = true;
        if(!this.form.valid)
		return;
		this.modalReference.close();
		if(!this.dependente_id){
			this.pessoaService.addDependente(this.form)
			.subscribe( (success) => {
				this.swal.text = "Dependente cadastrado com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
			return;
		}

		this.pessoaService.editDependente(this.dependente_id,this.form)
			.subscribe( (success) => {
				this.swal.text = "Dependente atualizado com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});

        
	}

	openModal(modal,id:number = null) {

		//Se id == null então é cadastro.
		if(!id){
			this.dependente_id = null;
			this.modalTitle = "Cadastrar Dependente";
			this.form.patchValue({
				grau_parentesco_id: '',
				atleta_id:  ''
			});
			this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
			return;
		}
		
		//Senão é edição.
		this.dependente_id = id;
		this.modalTitle = "Editar Dependente";
		this.pessoaService.getDependenteById(id).subscribe((success) => {
			this.form.patchValue({
				grau_parentesco_id: success.dados.grau_parentesco_id,
				atleta_id: success.dados.atleta_id
			});
			this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		})
	}

	askDelete(id:number){
		this.dependente_id = id;
		this.swalDelete.show();
	}

	confirmDelete(){
		this.pessoaService.delDependente(this.dependente_id).subscribe( 
		(success) => {
			this.swal.text = "Dependente deletado com sucesso!";
			this.swal.type = "success";
			this.swal.title = "Pronto!";
			this.swal.show();
			this.submitted = false;
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}

	onCloseModal(){
		if(this.swal.type == "success"){
			this.pessoaService.getPessoaById('pessoa/responsavel',this.activatedRoute.snapshot.params['id']).subscribe((success)=>{
				this.dependentes = success.dados.dependente;
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
		}
	}
}