import { Component, OnInit} from '@angular/core';
import { SYSCONF_SIGLA } from 'src/app.api';
@Component({
	selector: 'app-suprimento-create',
  	templateUrl: './suprimentoCreate.component.html',
  	styleUrls: ['./suprimentoCreate.component.scss']
})

export class SuprimentoCreateComponent implements OnInit{
	public message:string = '';
	sigla = SYSCONF_SIGLA;
	ngOnInit(){
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.estoque_suprimento.includes("CRIAR"))
			this.message = 'Você não possui permissão para executar esta ação'
	}
}