import { Component, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { PessoaService } from '../../../pessoa/pessoa.service';
import { NgxViacepService } from '@brunoc/ngx-viacep';
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;
@Component({
	selector: 'app-form-idioma',
  	templateUrl: './form-idioma.component.html',
  	styleUrls: ['./form-idioma.component.scss']
})
export class FormIdiomaComponent{
	@ViewChild('Swal') public swal:SwalComponent;
	@ViewChild('SwalDelete') public swalDelete:SwalComponent; 
	@Input() idiomas;
	@Input() pessoa_id: number;
	@Input() acao:string;
	@Input() detalhe:boolean;
	@Input() pessoa_status: string = 'A';
	form: FormGroup;
	idioma_id:number = null;
	modalTitle:string;
	submitted: boolean = false;
	loading: boolean = false;
	modalReference: NgbModalRef; 
	linguas;  
    
	constructor(private pessoaService: PessoaService, private formBuilder: FormBuilder, private router: Router, private viaCep: NgxViacepService,private modalService: NgbModal){
        this.pessoaService.listLinguas()
        .subscribe( (success) => {
			this.linguas = success.dados;
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
		this.form = this.formBuilder.group({
			idioma_id: this.formBuilder.control('',[Validators.required]),
			nivel: this.formBuilder.control('',[Validators.required]),
		})
    }

	onSubmit(){
		this.submitted = true;
        if(!this.form.valid)
		return;
		this.modalReference.close();
		if(!this.idioma_id){
			this.pessoaService.addIdioma(this.pessoa_id,this.form)
			.subscribe( (success) => {
				this.swal.text = "Idioma cadastrado com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
			return;
		}

		this.pessoaService.editIdioma(this.idioma_id,this.form)
			.subscribe( (success) => {
				this.swal.text = "Idioma atualizado com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});

        
	}

	openModal(modal,id:number = null) {

		//Se id == null então é cadastro.
		if(!id){
			this.idioma_id = null;
			this.modalTitle = "Cadastrar Idioma";
			this.form.patchValue({
				idioma_id: '',
				nivel:  ''
			});
			this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
			return;
		}
		
		//Senão é edição.
		this.idioma_id = id;
		this.modalTitle = "Editar Idioma";
		this.pessoaService.getIdiomaById(id).subscribe((success) => {
			this.form.patchValue({
				idioma_id: success.dados.idioma_id,
				nivel: success.dados.nivel
			});
			this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		})
	}

	askDelete(id:number){
		this.idioma_id = id;
		this.swalDelete.show();
	}

	confirmDelete(){
		this.pessoaService.delIdioma(this.idioma_id).subscribe( 
		(success) => {
			this.swal.text = "Idioma deletado com sucesso!";
			this.swal.type = "success";
			this.swal.title = "Pronto!";
			this.swal.show();
			this.submitted = false;
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}

	onCloseModal(){
		if(this.swal.type == "success"){
			this.pessoaService.getPessoaById('pessoa',this.pessoa_id).subscribe((success)=>{
				this.idiomas = success.dados.idioma;
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
		}
	}
}