import { PessoaService } from '../../pessoa.service';
import { Atleta } from './../atleta';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { Federacao } from '../../federacao/federacao';
import { Clube } from '../../clube/clube';
import { PreCadastroService } from 'src/app/pre-cadastro/pre-cadastro.service';
import { SYSCONF_SIGLA } from 'src/app.api';
import { LoginService } from 'src/app/login/login.export';
declare var $: any;
@Component({
	selector: 'app-atleta-edit',
  	templateUrl: './atletaEdit.component.html',
  	styleUrls: ['./atletaEdit.component.scss']
})

export class AtletaEditComponent implements OnInit{
	
	@ViewChild('Swal') public swal:SwalComponent; 
	public message:string = '';
	editForm: FormGroup;
	atleta: Atleta;
	genero;
	thumb:string = '';
	pessoa_id:number;
	atleta_id:number;
	enderecos:object = new Array();
	telefones:object = new Array();
	idiomas:object = new Array();
	contasBancarias:object = new Array();
	federacoes: Federacao[];
	clubes: Clube[];
	graduacoes: [{}];
	modalidades: [{}];
	estadoCivil:[{}];
	sigla = SYSCONF_SIGLA;
	responsaveis:object = new Array(); 
	anexos: any;
	acesso:object = new Array();
	constructor(public loginService: LoginService,private pessoaService: PessoaService, private preCadastroService: PreCadastroService, private activatedRoute: ActivatedRoute ,private formBuilder: FormBuilder){
		this.preCadastroService.get('pessoa/genero')
        .subscribe( (success) => {
			this.genero = success.dados;
		},
		(error) => { });

		this.pessoaService.getOptions('pessoa/federacao')
        .subscribe( (success) => {
			this.federacoes = success.dados;
		},
		(error) => {
			
		});
		
		this.pessoaService.getOptions('pessoa/clube')
        .subscribe( (success) => {
			this.clubes = success.dados;
		},
		(error) => {
			
		});

		this.preCadastroService.get('graduacao')
        .subscribe( (success) => {
			this.graduacoes = success.dados;
		},
		(error) => {
			
		});

		this.preCadastroService.get('modalidade')
        .subscribe( (success) => {
			this.modalidades = success.dados;
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});

		this.preCadastroService.get('pessoa/estado-civil')
        .subscribe( (success) => {
			this.estadoCivil = success.dados;
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});

		this.editForm = this.formBuilder.group({
			id: this.formBuilder.control(0,[Validators.required]),
			estrangeiro: this.formBuilder.control('N',[Validators.required]),
			passaporte: this.formBuilder.control(''),
			passaporte_dt_validade: this.formBuilder.control(''),
			cpf: this.formBuilder.control('',[Validators.required]),
			dt_cadastro: this.formBuilder.control(''),
			dt_atualizacao: this.formBuilder.control(''),
			dt_nascimento: this.formBuilder.control('',[Validators.required]),
			primeiro_nome: this.formBuilder.control('',[Validators.required]),
			ultimo_nome: this.formBuilder.control('',[Validators.required]),
			nome_completo: this.formBuilder.control(''),
			apelido: this.formBuilder.control(''),
			email: this.formBuilder.control('',[Validators.required, Validators.email]),
			nacionalidade: this.formBuilder.control('',[Validators.required]),
			naturalidade: this.formBuilder.control('',[Validators.required]),
			status: this.formBuilder.control('',[Validators.required]),
			genero_id: this.formBuilder.control(1,[Validators.required]),
			rg: this.formBuilder.control(''),
			rg_dt_emissao: this.formBuilder.control(''),
			rg_emissor: this.formBuilder.control(''),
			rg_uf: this.formBuilder.control(''),
			altura: this.formBuilder.control(''),
			peso: this.formBuilder.control(''),
			tipo_sanguineo: this.formBuilder.control(''),
			tamanho_camisa: this.formBuilder.control(''),
			tamanho_agasalho: this.formBuilder.control(''),
			tamanho_calca: this.formBuilder.control(''),
			tamanho_calcado: this.formBuilder.control(''),
			tamanho_chinelo: this.formBuilder.control(''),
			federado: this.formBuilder.control('',[Validators.required]),
			federacao_id: this.formBuilder.control(''),
			clube_id: this.formBuilder.control(''),
			situacao_federacao: this.formBuilder.control(''),
			dt_registro_federacao: this.formBuilder.control(''),
			situacao_confederacao: this.formBuilder.control(''),
			graduacao_id: this.formBuilder.control(''),
			modalidade: new FormArray([]),
			dt_graduacao: this.formBuilder.control(''),
			website: this.formBuilder.control(''),
			observacao: this.formBuilder.control(''),
			estado_civil_id: this.formBuilder.control('',[Validators.required]),
			pis: this.formBuilder.control(''),
			grau_instrucao_id: this.formBuilder.control('', [Validators.required]),
			selecao: this.formBuilder.control('N',[Validators.required]),
		});	
		this.atleta_id = this.activatedRoute.snapshot.params['id'];
		this.pessoaService.getPessoaById('pessoa/atleta',this.atleta_id).subscribe(
			(atleta) => {
			this.atleta = atleta.dados;
			this.pessoa_id = this.atleta.pessoa_id;
			this.responsaveis = this.atleta.responsavel;
			this.acesso = this.atleta.acesso;
			if(this.atleta.foto){
				this.thumb = this.atleta.foto.thumb;
			}
			this.pessoaService.getPessoaById('pessoa',this.pessoa_id).subscribe(
				(success) =>{
					this.enderecos = success.dados.endereco;
					this.telefones = success.dados.telefone;
					this.idiomas = success.dados.idioma;
					this.contasBancarias = success.dados.conta_bancaria;
					this.anexos = success.dados.anexo;
				},
				(error) => {
					this.message = error.error.message;
				})
			
			this.editForm.patchValue({
				id: this.atleta.id,
				estrangeiro: this.atleta.estrangeiro,
				passaporte: this.atleta.passaporte,
				passaporte_dt_validade: this.atleta.passaporte_dt_validade_br,
				cpf: this.atleta.cpf,
				genero_id: this.atleta.genero_id,
				dt_cadastro: this.atleta.dt_cadastro_br,
				dt_atualizacao: this.atleta.dt_atualizacao_br,
				primeiro_nome: this.atleta.primeiro_nome,
				ultimo_nome: this.atleta.ultimo_nome,
				nome_completo: this.atleta.nome_completo,
				apelido: this.atleta.apelido,
				email: this.atleta.email,
				rg: this.atleta.rg,
				rg_uf: this.atleta.rg_uf,
				rg_dt_emissao: this.atleta.rg_dt_emissao_br,
				rg_emissor: this.atleta.rg_emissor,
				nacionalidade: this.atleta.nacionalidade,
				naturalidade: this.atleta.naturalidade,
				foto: this.atleta.foto,
				observacao: this.atleta.observacao,
				peso: this.atleta.peso,
				altura: this.atleta.altura,
				tipo_sanguineo: this.atleta.tipo_sanguineo,
				tamanho_camisa: this.atleta.tamanho_camisa,
				tamanho_agasalho: this.atleta.tamanho_agasalho,
				tamanho_calca: this.atleta.tamanho_calca,
				tamanho_calcado: this.atleta.tamanho_calcado,
				tamanho_chinelo: this.atleta.tamanho_chinelo,
				dt_nascimento: this.atleta.dt_nascimento_br,
				dt_registro_federacao: this.atleta.dt_registro_federacao_br,
				dt_graduacao:this.atleta.dt_graduacao_br,
				graduacao_id: this.atleta.graduacao_id,
				situacao_confederacao: this.atleta.situacao_confederacao,
				federado: this.atleta.federado == 'S' ? 'S' : 'N',
				federacao_id: this.atleta.federacao_id,
				clube_id: this.atleta.clube_id,
				situacao_federacao: this.atleta.situacao_federacao,
				status: this.atleta.status,
				website: this.atleta.website,
				estado_civil_id: this.atleta.estado_civil_id,
				pis: this.atleta.pis,
				grau_instrucao_id: this.atleta.grau_instrucao_id,
				selecao: this.atleta.selecao == 'S' ? 'S' : 'N',
			});
			this.checkModalidade();
		},
		(error) => {
			this.message = error.error.message;
		})
	}

	/* Incluir no editForm os ids das modalidades selecionadas */
	checkModalidade() {
		const formArray: FormArray = this.editForm.get('modalidade') as FormArray;
		if(this.atleta.modalidade != null){	
			this.atleta.modalidade.forEach((key : any) => {
				formArray.push(new FormControl(key.id));
			})
		}
	}

	ngOnInit(){
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.pessoa_atleta.includes("EDITAR"))
			this.message = 'Você não possui permissão para executar esta ação'
	}
	
	
}