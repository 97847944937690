import { Component, OnInit } from '@angular/core';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { Subject } from 'rxjs';


//Cores para o calendario
const colors: any = {
	red: {
	  primary: '#ad2121',
	  secondary: '#FAE3E3'
	},
	blue: {
	  primary: '#1e90ff',
	  secondary: '#D1E8FF'
	},
	yellow: {
	  primary: '#e3bc08',
	  secondary: '#FDF1BA'
	}
  };


@Component({
  selector: 'app-home-agendamento',
  templateUrl: './home-agendamento.component.html',
  styleUrls: ['../home.component.scss']
})
export class HomeAgendamentoComponent implements OnInit {
  viewDate: Date = new Date();
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  

	modalData: {
		action: string;
		event: CalendarEvent;
	};
	actions: CalendarEventAction[] = [
		{
		  label: '<!-- <i class="fa fa-fw fa-pencil"></i> -->',
		  onClick: ({ event }: { event: CalendarEvent }): void => {
			this.handleEvent('Edited', event);
		  }
		},
		{
		  label: '<!-- <i class="fa fa-fw fa-times"></i> -->',
		  onClick: ({ event }: { event: CalendarEvent }): void => {
			//this.events = this.events.filter(iEvent => iEvent !== event);
			this.handleEvent('Deleted', event);
		  }
		}
	  ];
	
	  refresh: Subject<any> = new Subject();
	
	  events: CalendarEvent[] = [
		{
		  start: subDays(startOfDay(new Date()), 1),
		  end: addDays(new Date(), 1),
		  title: 'Evento fictício com duração de 3 dias.',
		  color: colors.red,
		  actions: this.actions,
		  allDay: true,
		  resizable: {
			beforeStart: true,
			afterEnd: true
		  },
		  draggable: true
		},
		{
		  start: startOfDay(new Date()),
		  title: 'Evento fictício sem data de término.',
		  color: colors.yellow,
		  actions: this.actions
		},
		{
		  start: subDays(endOfMonth(new Date()), 3),
		  end: addDays(endOfMonth(new Date()), 3),
		  title: 'Um evento longo com duração de 2 meses.',
		  color: colors.blue,
		  allDay: true
		},
		{
		  start: addHours(startOfDay(new Date()), 2),
		  end: new Date(),
		  title: 'Um evento fictício que pode alterado apenas clicando e arrastando no calendário.',
		  color: colors.yellow,
		  actions: this.actions,
		  resizable: {
			beforeStart: true,
			afterEnd: true
		  },
		  draggable: true
		}
	  ];
	
	  activeDayIsOpen: boolean = true;
  constructor() { }

  ngOnInit() {
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
		if (isSameMonth(date, this.viewDate)) {
		  this.viewDate = date;
		  if (
			(isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
			events.length === 0
		  ) {
			this.activeDayIsOpen = false;
		  } else {
			this.activeDayIsOpen = true;
		  }
		}
	  }
	
	  eventTimesChanged({
		event,
		newStart,
		newEnd
	  }: CalendarEventTimesChangedEvent): void {
		event.start = newStart;
		event.end = newEnd;
		this.handleEvent('Dropped or resized', event);
		this.refresh.next();
	  }
	
	  handleEvent(action: string, event: CalendarEvent): void {
		//this.modalData = { event, action };
		//this.modal.open(this.modalContent, { size: 'lg' });
	  }
	
	  addEvent(): void {
		this.events.push({
		  title: 'New event',
		  start: startOfDay(new Date()),
		  end: endOfDay(new Date()),
		  color: colors.red,
		  draggable: true,
		  resizable: {
			beforeStart: true,
			afterEnd: true
		  }
		});
		this.refresh.next();
	  }

}
