import { Component, AfterViewInit } from '@angular/core';
import { SYSCONF_SIGLA } from 'src/app.api';
declare var $:any;
@Component({
	templateUrl: './contratoVideo.component.html',
})
export class contratoVideoComponent implements AfterViewInit{
	sigla = SYSCONF_SIGLA;
	constructor(){}

	ngAfterViewInit() {
		$(".preloader").fadeOut();
	 }
}
