import { SYSCONF_SIGLA } from 'src/app.api';
import { LoginService } from 'src/app/login/login.service';
import { Component, OnInit } from '@angular/core';
declare var $:any
@Component({
	selector: 'app-configuracao-boleto',
  	templateUrl: './configuracao-boleto.component.html',
  	styleUrls: ['./configuracao-boleto.component.scss']
})
export class ConfiguracaoBoletoComponent implements OnInit{
	public message:string = '';
	sigla = SYSCONF_SIGLA;
	constructor(private loginService: LoginService){
	}
	ngOnInit(){
		if(!this.getPermissao('financeiro_configuracao_boleto','criar'))
			this.message = 'Você não possui permissão para executar esta ação.';
		
		$('.preloader').hide();
	}

	getPermissao(modulo,secao){
		return this.loginService.getPermissao(modulo,secao);
	}
	
	
}