import { Component, ViewChild, Input, OnInit, EventEmitter, Output, SimpleChanges, OnChanges, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from '../../../login/login.service';
import { FinanceiroService } from '../../financeiro.service';
import { TreeviewItem, TreeviewConfig, TreeItem, DownlineTreeviewItem, TreeviewComponent, TreeviewHelper } from 'ngx-treeview';
import { SYSCONF_SIGLA } from '../../../../app.api';


declare var $: any;
@Component({
	selector: 'app-financeiro-plano-conta',
  	templateUrl: './planoDeContaList.component.html',
  	styleUrls: ['./planoDeContaList.component.scss']
})
export class FinanceiroPlanoDeContaComponent implements OnInit{
	
	@ViewChild('Swal') public swal:SwalComponent;
	@ViewChild('SwalDelete') public swalDelete:SwalComponent; 
	@ViewChild(TreeviewComponent) treeviewComponent: TreeviewComponent;
	@Input() acao:string;
	@Input() detalhe:boolean;
	form: FormGroup;
	formExportar: FormGroup;
	plano_contas_id:number = null;
	modalTitle:string;
	submitted: boolean = false;
	loading: boolean = false;
	modalReference: NgbModalRef;   
	planoDeContas = new Array();
	items: TreeviewItem[] = new Array();
	value;
	sigla = SYSCONF_SIGLA;
	planoDeContasSigef = new Array();
    config = TreeviewConfig.create({
		hasAllCheckBox: false,
		hasFilter: true,
        hasCollapseExpand: true,
        decoupleChildFromParent: false,
	});
	constructor(private loginService:LoginService, private financeiroService: FinanceiroService, private formBuilder: FormBuilder, private modalService: NgbModal){
		this.form = this.formBuilder.group({
			id: this.formBuilder.control(''),
			plano_contas_id: this.formBuilder.control('',[Validators.required]),
			nome: this.formBuilder.control('',[Validators.required]),
		})
	}

	onValueChange(value: number) {
	
		this.form.patchValue({
		plano_contas_id: value
	   })
	}
	ngOnInit(){
		this.financeiroService.get('plano-contas')
        .subscribe( (success) => {
			this.planoDeContas = success.dados;
			this.createTreeView(this.planoDeContas);
			
		},
		(error) => {});
	}

	planoDeContaExportado(planoDeContasId):boolean{
		//console.log(this.planoDeContasSigef);
		if(this.planoDeContasSigef[planoDeContasId] == null  )
		return true;
		
		return;
	}
	
	createTreeView(planoDeContas,children = 0):any{
		if(planoDeContas.length == 0)
			return;
		let children_items:TreeviewItem[] = new Array();
		if(children){
			planoDeContas.forEach( k => { 
				this.planoDeContasSigef[k.id] = k.codigo_cob; 
				if(k.sub_plano.length > 0)
				children_items.push(new TreeviewItem({text: k.nome, value: k.id, children: this.createTreeView(k.sub_plano,1)}))
				else
				children_items.push(new TreeviewItem({text: k.nome, value: k.id}))
			});
			return children_items;
		}
		this.planoDeContas.forEach( k => { 
			this.planoDeContasSigef[k.id] = k.codigo_cob; 
			this.items.push(new TreeviewItem({text: k.nome, value: k.id, children: this.createTreeView(k.sub_plano,1)}))
		});
	}

	getPermissao(modulo,secao){
		return this.loginService.getPermissao(modulo,secao);
	}

	onSubmit(){
		this.submitted = true;
        if(!this.form.valid)
		return;
		this.modalReference.close();
		if(!this.plano_contas_id){
			this.financeiroService.add('plano-contas',this.form)
			.subscribe( (success) => {
				this.swal.text = "Plano de conta cadastrado com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
			return;
		}

		this.financeiroService.edit('plano-contas',this.form)
			.subscribe( (success) => {
				this.swal.text = "Plano de conta atualizado com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});

        
	}
	 
	initFormExportar(){
		this.formExportar = this.formBuilder.group({
			campos: new FormArray([]),
		});
	}

	openExportModal(modal) {
		this.initFormExportar();
		this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
	}

	openModal(modal,id:number = null) {
		
		//Se id == null então é cadastro.
		if(!id){
			this.plano_contas_id = null;
			this.modalTitle = "Cadastrar Plano de Conta";
			this.form.patchValue({
				id: '',
				plano_contas_id: '',
				nome: '',
			});
			this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
			return;
		}
		
		//Senão é edição.
		this.plano_contas_id = id;
		this.modalTitle = "Editar Plano de Conta";
		this.financeiroService.getById('plano-contas',id).subscribe((success) => {
			this.value = success.dados.plano_contas_id,
			this.form.patchValue({
				id: success.dados.id,
				plano_contas_id: success.dados.plano_contas_id,
				nome: success.dados.nome,
			});
			this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		})
	}

	askDelete(id:number){
		this.plano_contas_id = id;
		this.swalDelete.show();
	}

	confirmDelete(){
		this.financeiroService.del('plano-contas',this.plano_contas_id).subscribe( 
		(success) => {
			this.swal.text = "Plano de conta deletado com sucesso!";
			this.swal.type = "success";
			this.swal.title = "Pronto!";
			this.swal.show();
			this.submitted = false;
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}

	onCloseModal(){
		if(this.swal.type == "success"){
			this.financeiroService.get('plano-contas').subscribe((success)=>{
				this.planoDeContas = success.dados;
				this.items = new Array();
				this.createTreeView(this.planoDeContas);
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
		}
	}
}