import { Component, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { ContratoService } from 'src/app/contrato/contrato.service';
import { Contrato } from 'src/app/contrato/contrato';
import { PessoaService } from '../../../pessoa/pessoa.service';
import { Funcionario } from '../../../pessoa/funcionario/funcionario';
import { PreCadastroService } from 'src/app/pre-cadastro/pre-cadastro.service';
import { SYSCONF_SIGLA } from 'src/app.api';
import { TreeviewItem } from 'ngx-treeview';
import { FinanceiroService } from '../../../financeiro/financeiro.service';
import { DataService } from '../../../_services/data.service';
declare var $:any;
@Component({
	selector: 'app-form-contrato',
  	templateUrl: './form-contrato.component.html',
  	styleUrls: ['./form-contrato.component.scss']
})
export class FormContratoComponent{
    @ViewChild('Swal') public swal:SwalComponent; 
    @ViewChild('SwalDelete') public swalDelete:SwalComponent; 
	@Input() form: FormGroup;
	@Input() rota:string;
	@Input() acao:string;
	@Input() detalhe:boolean;
	contrato: Contrato;
	submitted: boolean = false;
	loading: boolean = false;
	id:number;    
	tiposPessoasSelect = new Array();
	pessoasSelect = new Array();
	funcionariosSelect:Funcionario[];
	statusSelect = new Array();
	areasSelect = new Array();
	naturezasSelect = new Array();
	slugs = new Array();
	parcelas: FormArray;
	confereValor:boolean = false;
	valorParcelas = 0;
	disabled: boolean;
	formasPagamentoSelect = new Array();
	contaBancariaSelect = new Array();
	centroCustoSelect = new Array();
	fontesDeRecursosSelect = new Array();
	planoDeContas = new Array();
	items: TreeviewItem[] = new Array();
	value: number;
	parcelaIndex:number = null;
	constructor(private pessoaService: PessoaService, private contratoService: ContratoService, private preCadastroService:PreCadastroService, private financeiroService: FinanceiroService, private router: Router, private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, private dt: DataService){
		
		
		this.pessoaService.getOptions('pessoa/funcionario').subscribe((success) => {
			this.funcionariosSelect = success.dados;
		},(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		})

		this.preCadastroService.getOptions('contrato/status').subscribe((success) => {
			this.statusSelect = success.dados;
		},(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		})

		this.preCadastroService.getOptions('contrato/area').subscribe((success) => {
			this.areasSelect = success.dados;
		},(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		})

		this.preCadastroService.getOptions('contrato/natureza').subscribe((success) => {
			this.naturezasSelect = success.dados;
		},(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		})

		this.preCadastroService.getOptions('financeiro/centro-custo').subscribe((success) => {
			this.centroCustoSelect = success.dados;
		},(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		})

		this.preCadastroService.getOptions('financeiro/conta-bancaria').subscribe((success) => {
			this.contaBancariaSelect = success.dados;
			this.contaBancariaSelect.map((row) => { row.labelSelect = `${row.banco} - Ag.: ${row.num_agencia}-${row.dv_agencia} - ${row.tipo}: ${row.num_conta}-${row.dv_conta}`; return row; });

		},(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		})

		this.preCadastroService.getOptions('financeiro/forma-pagamento').subscribe((success) => {
			this.formasPagamentoSelect = success.dados;
		},(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		})
		
		this.preCadastroService.getOptions('financeiro/recurso').subscribe((success) => {
			this.fontesDeRecursosSelect = success.dados;
		},(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		})
		
		if(this.activatedRoute.snapshot.params['id'])
			this.id = this.activatedRoute.snapshot.params['id'];
	}

	initForm(){
		if(this.acao == 'buscar'){
			this.form = this.formBuilder.group({
				id: this.formBuilder.control(''),
				financeiro_recurso_id: this.formBuilder.control(''),
				contrato_status_id: this.formBuilder.control(''),
				contrato_status: this.formBuilder.control(''),
				gestor_id: this.formBuilder.control(''),
				gestor: this.formBuilder.control(''),
				tipo_pessoa_id: this.formBuilder.control(''),
				tipo_pessoa: this.formBuilder.control(''),
				pessoa_id: this.formBuilder.control(''),
				pessoa: this.formBuilder.control(''),
				dt_cadastro: this.formBuilder.control(''),
				dt_cadastro_br: this.formBuilder.control(''),
				dt_atualizacao: this.formBuilder.control(''),
				dt_atualizacao_br: this.formBuilder.control(''),
				dt_inicio: this.formBuilder.control(null),
				dt_inicio_br: this.formBuilder.control(''),
				dt_termino: this.formBuilder.control(null),
				dt_termino_br: this.formBuilder.control(''),
				objeto: this.formBuilder.control(''),
				numero: this.formBuilder.control(''),
				valor: this.formBuilder.control(''),
				de_valor: this.formBuilder.control(''),
				ate_valor: this.formBuilder.control(''),
				observacao: this.formBuilder.control(''),
				contrato_natureza_id: this.formBuilder.control(''),
				contrato_natureza: this.formBuilder.control(''),
				contrato_area_id: this.formBuilder.control(''),
				contrato_area: this.formBuilder.control(''),
				centro_custo_id: this.formBuilder.control(''),
				centro_custo: this.formBuilder.control(''),
				forma_pagamento_id: this.formBuilder.control(''),
				forma_pagamento: this.formBuilder.control(''),
				conta_bancaria_id: this.formBuilder.control(''),
				conta_bancaria: this.formBuilder.control(''),
				plano_contas_id: this.formBuilder.control(''),
				plano_contas: this.formBuilder.control(''),
				
			});	
			return;
		}
		this.form = this.formBuilder.group({
			id: this.formBuilder.control(''),
			financeiro_recurso_id: this.formBuilder.control(''),
			financeiro_recurso: this.formBuilder.control(''),
			contrato_status_id: this.formBuilder.control('',[Validators.required]),
			contrato_status: this.formBuilder.control(''),
			gestor_id: this.formBuilder.control('',[Validators.required]),
			gestor: this.formBuilder.control(''),
			tipo_pessoa_id: this.formBuilder.control('',[Validators.required]),
			tipo_pessoa: this.formBuilder.control(''),
			pessoa_id: this.formBuilder.control('',[Validators.required]),
			pessoa: this.formBuilder.control(''),
			dt_cadastro: this.formBuilder.control(''),
			dt_cadastro_br: this.formBuilder.control(''),
			dt_atualizacao: this.formBuilder.control(''),
			dt_atualizacao_br: this.formBuilder.control(''),
			dt_inicio: this.formBuilder.control(null,[Validators.required]),
			dt_inicio_br: this.formBuilder.control(''),
			dt_termino: this.formBuilder.control(null,[Validators.required]),
			dt_termino_br: this.formBuilder.control(''),
			objeto: this.formBuilder.control('',[Validators.required]),
			numero: this.formBuilder.control('',[Validators.required]),
			valor: this.formBuilder.control(''),
			de_valor: this.formBuilder.control(''),
			ate_valor: this.formBuilder.control(''),
			observacao: this.formBuilder.control(''),
			contrato_natureza_id: this.formBuilder.control('',[Validators.required]),
			contrato_natureza: this.formBuilder.control(''),
			contrato_area_id: this.formBuilder.control('',[Validators.required]),
			contrato_area: this.formBuilder.control(''),
			centro_custo_id: this.formBuilder.control('',),
			centro_custo: this.formBuilder.control(''),
			forma_pagamento_id: this.formBuilder.control('',),
			forma_pagamento: this.formBuilder.control(''),
			conta_bancaria_id: this.formBuilder.control('',),
			conta_bancaria: this.formBuilder.control(''),
			plano_contas_id: this.formBuilder.control(''),
			plano_contas: this.formBuilder.control(''),
			qtdParcelas: this.formBuilder.control(0,[Validators.min(0),Validators.max(60)]),
			parcelas: this.formBuilder.array([])
		});	
	}

	//Cria a árvore de plano de contas
	createTreeView(planoDeContas,children = 0):any{
		if(planoDeContas.length == 0)
			return;
		let children_items:TreeviewItem[] = new Array();
		if(children){
			planoDeContas.forEach( k => { 
				if(k.sub_plano.length > 0)
				children_items.push(new TreeviewItem({text: k.nome, value: k.id, children: this.createTreeView(k.sub_plano,1)}))
				else
				children_items.push(new TreeviewItem({text: k.nome, value: k.id}))
			});
			return children_items;
		}
		this.planoDeContas.forEach( k => { 
			this.items.push(new TreeviewItem({text: k.nome, value: k.id, children: this.createTreeView(k.sub_plano,1)}))
		});
	}

	//Quando trocar o plano de contas
	onValueChange(value: number) {
	
		this.form.patchValue({
		plano_contas_id: value
	   })
	}

	initParcelas(): FormGroup {
		return this.formBuilder.group({
		  id: 0,
		  valor: this.formBuilder.control('',[Validators.required]),
		  dt_pagamento: this.formBuilder.control(null),
		  status_pagamento: this.formBuilder.control('0',[Validators.required]),
		  dt_previsao: this.formBuilder.control(null,[Validators.required]),
		});
	}
	gerarParcelas(qtd){
		this.parcelas = this.form.get('parcelas') as FormArray;
		//Removendo todas as parcelas
		while (this.parcelas.length) {
			this.parcelas.removeAt(this.parcelas.length - 1);
		}

		//Gerando a quantidade de parcelas
		for(let j=1;j<=(qtd);j++){
			this.parcelas.push(this.initParcelas());
		}
	}

	addParcela(){
		this.parcelas.push(this.initParcelas());
	}
	removeParcela(index:number){
		this.parcelaIndex = index;
		this.parcelas = this.form.get('parcelas') as FormArray;
		if(this.parcelas.at(this.parcelaIndex).value.id == 0){
			this.parcelas.removeAt(this.parcelaIndex);
			this.confereValorTotal();
		}
		else{
			this.swalDelete.show();
		}
	}
	confirmDelete(){
		this.parcelas = this.form.get('parcelas') as FormArray;
		this.contratoService.delParcela(this.parcelas.at(this.parcelaIndex).value.id).subscribe( 
		(success) => {
			this.swal.text = "Parcela excluída com sucesso!";
			this.swal.type = "success";
			this.swal.title = "Pronto!";
			this.swal.show();
			this.parcelas.removeAt(this.parcelaIndex);
			this.parcelaIndex = null;
			this.confereValorTotal();
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		});
	}

	setValidatorsParcelas(pago:string, index:number){
		this.parcelas = this.form.get('parcelas') as FormArray;

		this.parcelas.at(index).get('dt_pagamento').setValidators([]);
		this.parcelas.at(index).patchValue({
			dt_pagamento: null
		})
		if(pago == '1'){
			this.parcelas.at(index).get('dt_pagamento').setValidators([Validators.required]);
		}
		this.parcelas.at(index).get('dt_pagamento').updateValueAndValidity();
	}

	//Função para verificar se a soma das parcelas bate com o valor total informado.
	confereValorTotal(){
		this.valorParcelas = 0;
		let valorTotal = this.form.value.valor;
		this.parcelas = this.form.get('parcelas') as FormArray;
		let stringParcela;
		for(let i=0;i<=(this.parcelas.length-1);i++){
			stringParcela = this.parcelas.at(i).value.valor
			stringParcela = parseFloat(stringParcela);
			this.valorParcelas += stringParcela;
		}
		if(Math.round(this.valorParcelas) != Math.round(valorTotal))
			this.confereValor = false;
		else
			this.confereValor = true;
	}
	

	//Carrega o campo "Contratado" de acordo com o "Tipo de Contratado"
	loadTiposPessoas(id,pessoa_id = null){
		this.form.patchValue({pessoa_id: pessoa_id})
		this.pessoasSelect = new Array(); 
		let endpoint = this.tiposPessoasSelect.find(c => c.id == id);
		if(!endpoint)
			return;
		this.pessoaService.getOptions('pessoa/'+endpoint.slug).subscribe((success) => {
			this.pessoasSelect = success.dados;	
		},(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		})
		
	}

	get formParcelas(){ 
		return <FormArray>this.form.get('parcelas'); 
	}
	ngOnInit(){
		
		this.disabled = this.acao == 'edit' ? true : false;
		this.initForm();
		if(this.id){
			this.pessoaService.getOptions('pessoa/tipo').subscribe((success) => {
				this.tiposPessoasSelect = success.dados;
				this.contratoService.getContratoById(this.id).subscribe((contrato) => {
					this.contrato = contrato.dados;
					this.form.patchValue({
						id: this.contrato.id,
						financeiro_recurso_id: this.contrato.financeiro_recurso_id,
						financeiro_recurso: this.contrato.financeiro_recurso,
						contrato_status_id: this.contrato.contrato_status_id,
						contrato_status: this.contrato.contrato_status,
						gestor_id: this.contrato.gestor_id,
						gestor: this.contrato.gestor,
						tipo_pessoa_id: this.contrato.tipo_pessoa_id,
						tipo_pessoa: this.contrato.tipo_pessoa,
						pessoa_id: this.contrato.pessoa_id,
						pessoa: this.contrato.pessoa,
						dt_cadastro: this.contrato.dt_cadastro,
						dt_cadastro_br: this.contrato.dt_cadastro_br,
						dt_atualizacao: this.contrato.dt_atualizacao,
						dt_atualizacao_br: this.contrato.dt_atualizacao_br,
						dt_inicio: this.dt.formataDataBr(this.contrato.dt_inicio_br, this.detalhe),
						dt_termino: this.dt.formataDataBr(this.contrato.dt_termino_br, this.detalhe),
						objeto: this.contrato.objeto,
						numero: this.contrato.numero,
						valor: this.contrato.valor,
						observacao: this.contrato.observacao,
						contrato_natureza_id: this.contrato.contrato_natureza_id,
						contrato_natureza: this.contrato.contrato_natureza,
						contrato_area_id:  this.contrato.contrato_area_id,
						contrato_area: this.contrato.contrato_area,
						centro_custo_id:  this.contrato.centro_custo_id,
						centro_custo: this.contrato.centro_custo,
						forma_pagamento_id:  this.contrato.forma_pagamento_id,
						forma_pagamento: this.contrato.forma_pagamento,
						conta_bancaria_id:  this.contrato.conta_bancaria_id,
						conta_bancaria: this.contrato.conta_bancaria,
						plano_contas_id:  this.contrato.plano_contas_id,
						plano_contas: this.contrato.plano_contas,
					});	
					this.loadTiposPessoas(this.contrato.tipo_pessoa_id,this.contrato.pessoa_id);
				
					
					if(this.contrato.parcelas.length > 0){
						this.parcelas = this.form.get('parcelas') as FormArray;
						this.parcelas.removeAt(0);
						for(let i=0;i <= (this.contrato.parcelas.length-1);i++){
							this.parcelas.push(this.initParcelas());
							this.parcelas.at(i).patchValue({
								id: this.contrato.parcelas[i].id,
								valor: this.contrato.parcelas[i].valor,
								dt_previsao: this.dt.formataDataBr(this.contrato.parcelas[i].dt_previsao_br, this.detalhe),
								status_pagamento: this.contrato.parcelas[i].status_pagamento,
								dt_pagamento: this.dt.formataDataBr(this.contrato.parcelas[i].dt_pagamento_br, this.detalhe)
							})

							// if(this.contrato.parcelas[i].movimento_status == 2) {
							// 	this.parcelas.at(i).get('valor').disable();
							// 	this.parcelas.at(i).get('dt_previsao').disable();
							// 	this.parcelas.at(i).get('status_pagamento').disable();
							// 	this.parcelas.at(i).get('dt_pagamento').disable();
							// }
							
						}
					}
					this.confereValorTotal();

					this.form.patchValue({
						qtdParcelas: this.contrato.parcelas.length
					})

					this.financeiroService.get('plano-contas')
					.subscribe( (success) => {
						this.planoDeContas = success.dados;
						this.createTreeView(this.planoDeContas);
						this.value = this.contrato.plano_contas_id;
					});
					
				},(error) => {
					this.swal.text = error.error.message;
					this.swal.type = "error";
					this.swal.title = "Erro!";
					this.swal.show();
				})
			},(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
			})
		}else{
			this.pessoaService.getOptions('pessoa/tipo').subscribe((success) => {
				this.tiposPessoasSelect = success.dados;
				this.financeiroService.get('plano-contas').subscribe( (success) => {
					this.planoDeContas = success.dados;
					this.createTreeView(this.planoDeContas);
				});
				
			},(error) => {
				// this.swal.text = error.error.message;
				// this.swal.type = "error";
				// this.swal.title = "Erro!";
				// this.swal.show();
			})
		}
		
	}
	

	onSubmit(){
		this.submitted = true;
        if(!this.form.valid)
		return;
		if(this.acao == "buscar"){
			let dt_inicio = this.dt.convertDataObject(this.form.value,'dt_inicio');
			let dt_termino = this.dt.convertDataObject(this.form.value,'dt_termino');
			this.form.patchValue({
				dt_inicio: dt_inicio,				
				dt_termino: dt_termino,
			})
			if(dt_inicio.indexOf('undefined') !== -1){
				this.form.patchValue({
					dt_inicio: ''
				})
			}
			if(dt_termino.indexOf('undefined') !== -1){
				this.form.patchValue({
					dt_termino: ''
				})
			}
			this.router.navigate(['/'+SYSCONF_SIGLA+'/contrato/page/1',this.form.value]);
			return;
		}
        this.contratoService[this.acao](this.form)
        .subscribe( (success) => {
			this.id = parseInt(success.dados.id);

			
			this.parcelas = this.form.get('parcelas') as FormArray;
			if(this.parcelas.length <= 0){
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.text = `Contrato cadastrado com sucesso!`;
				this.swal.show();
				this.submitted = false;
				return;
			}
			this.contratoService.addParcela(this.parcelas,this.id).subscribe((success) => {
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.text = `Contrato cadastrado com sucesso!`;
				this.swal.show();
				this.submitted = false;
		
				this.ngOnInit();
			},(error) =>{
				let msg = 'O contrato foi cadastrado/atualizado porém houve um erro ao inserir/atualizar as parcelas. Vá no link de "Editar" do contrato ou entre em contato com o suporte.';
				if(error.error.message)
						msg = error.error.message;
				this.swal.text = msg;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			})

			if(this.acao == 'add'){
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.text = `Contrato cadastrado com sucesso!`;
				this.swal.show();
				this.submitted = false;
			}
			else{
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.text = `Contrato atualizado com sucesso!`;
				this.swal.show();
				this.submitted = false;
			}

			
		},
		(error) => {
			// let message = 'Erro na comunicação com o servidor, tente novamente ou contate o suporte.'
			// if(error.status !== 500)
			// 	message = error.error.message;
			//this.swal.text = message;
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}
	onCloseModal(){
		if(this.swal.type == "success"){
			this.router.navigate([this.rota, this.id]);
		}
	}
}