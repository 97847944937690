import { FinanceiroService } from './../../financeiro.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SYSCONF_SIGLA } from 'src/app.api';
import { LoginService } from 'src/app/login/login.service';
@Component({
	selector: 'app-movimento-edit',
  	templateUrl: './movimentoEdit.component.html',
  	styleUrls: ['./movimentoEdit.component.scss']
})
export class MovimentoEditComponent implements OnInit{
	public message:string = '';
	movimento_id:number;
	anexos = new Array();
	sigla = SYSCONF_SIGLA;
	constructor(private financeiroService: FinanceiroService, private activatedRoute: ActivatedRoute, private loginService: LoginService){
		this.financeiroService.getById('movimento',this.activatedRoute.snapshot.params['id']).subscribe(
			(success) =>{
				this.movimento_id = success.dados.id;
				this.anexos = success.dados.anexos;
			},
			(error) => {
				this.message = error.error.message;
			})
	}
	ngOnInit(){
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.financeiro_movimento.includes("EDITAR"))
			this.message = 'Você não possui permissão para executar esta ação'
	}

	getPermissao(modulo,secao){
		return this.loginService.getPermissao(modulo,secao);
	}
	
	
}