import { PreCadastroService } from '../../../pre-cadastro/pre-cadastro.service';
import { Component, ViewChild, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';


@Component({
	selector: 'app-form-precadastro-estoque-atributo',
  	templateUrl: './form-precadastro-estoque-atributo.component.html',
  	styleUrls: ['./form-precadastro-estoque-atributo.component.scss']
})
export class FormPreCadastroEstoqueAtributoComponent{
	@ViewChild('Swal') public swal:SwalComponent;
	@ViewChild('SwalDelete') public swalDelete:SwalComponent; 
	@Input() acao:string;
	@Input() detalhe:boolean;
	form: FormGroup;
	atributo_id:number = null;
	modalTitle:string;
	submitted: boolean = false;
	loading: boolean = false;
	modalReference: NgbModalRef; 
	cob = null;
	atributos;
	itens: FormArray;
	constructor(private preCadastro: PreCadastroService, private formBuilder: FormBuilder,private modalService: NgbModal){
    	this.preCadastro.get('estoque/atributo')
        .subscribe( (success) => {
			this.atributos = success.dados;
		},
		(error) => {
			
		});
	}

	initItens(nome = ''): FormGroup {
		return this.formBuilder.group({
			id: this.formBuilder.control(''),
			nome: this.formBuilder.control(nome),
		});
	}

	addItem(nome){
		this.itens = this.form.get('itens') as FormArray;
		this.itens.push(this.initItens(nome));	
		this.form.patchValue({
			item: ''
		})
	}

	removeItem(linha,id){
		if(this.atributo_id){
			this.preCadastro.del('estoque/atributo/item',id)
			.subscribe( (success) => {
				this.itens = this.form.get('itens') as FormArray;
				this.itens.removeAt(linha);
				return;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
			});
		}else{
			this.itens = this.form.get('itens') as FormArray;
			this.itens.removeAt(linha);
		}
	}
	
	get formItens(){ 
		return <FormArray>this.form.get('itens'); 
	}
	
	onSubmit(){
		this.submitted = true;
        if(!this.form.valid)
		return;
		this.modalReference.close();
		let itens = this.form.get('itens') as FormArray;
		if(!this.atributo_id){
			
			this.preCadastro.add('estoque/atributo', this.form, itens)
			.subscribe( (success) => {
				this.swal.text = "Registro cadastrado com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
			return;
		}

		this.preCadastro.edit('estoque/atributo', this.atributo_id,this.form, itens)
			.subscribe( (success) => {
				this.swal.text = "Registro atualizado com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});

        
	}

	openModal(modal,id:number = null) {
		this.form = this.formBuilder.group({
			nome: this.formBuilder.control('',[Validators.required]),
			item: this.formBuilder.control(''),
			itens: this.formBuilder.array([]),
		})
		//Se id == null então é cadastro.
		if(!id){
			this.atributo_id = null;
			this.modalTitle = "Cadastrar Atributo";
			this.modalReference = this.modalService.open(modal, {backdrop: 'static'});
			return;
		}
		
		//Senão é edição.
		this.atributo_id = id;
		this.modalTitle = "Editar Atributo";
		this.preCadastro.getById('estoque/atributo', id).subscribe((success) => {
			this.form.patchValue({
				nome: success.dados.nome,
				itens: []
			});
			if(success.dados.values && success.dados.values.length > 0){
				this.itens = this.form.get('itens') as FormArray;
				this.itens.removeAt(0);
				for(let i=0;i <= (success.dados.values.length-1);i++){
					this.itens.push(this.initItens());
					this.itens.at(i).patchValue({
						id: success.dados.values[i].id,
						nome: success.dados.values[i].nome,
					})
				}
			}
			this.modalReference = this.modalService.open(modal, {backdrop: 'static'});
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		})
	}

	askDelete(id:number){
		this.atributo_id = id;
		this.swalDelete.show();
	}

	confirmDelete(){
		this.preCadastro.del('estoque/atributo', this.atributo_id).subscribe( 
		(success) => {
			this.swal.text = "Registro deletado com sucesso!";
			this.swal.type = "success";
			this.swal.title = "Pronto!";
			this.swal.show();
			this.submitted = false;
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}

	onCloseModal(){
		if(this.swal.type == "success"){
			this.preCadastro.get('estoque/atributo').subscribe((success)=>{
				this.atributos = success.dados;
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
		}
	}
}