import { Component, OnInit} from '@angular/core';
import { SYSCONF_SIGLA } from 'src/app.api';
@Component({
	selector: 'app-folha-de-pagamento-create',
  	templateUrl: './folhaDePagamentoCreate.component.html',
  	styleUrls: ['./folhaDePagamentoCreate.component.scss']
})

export class FolhaDePagamentoCreateComponent implements OnInit{
	public message:string = '';
	sigla = SYSCONF_SIGLA;
	ngOnInit(){
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.rh_folha_pagamento.includes("CRIAR"))
			this.message = 'Você não possui permissão para executar esta ação'
	}

}