import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContratoService } from '../contrato.service';
import { SYSCONF_SIGLA } from 'src/app.api';
@Component({
	selector: 'app-contrato-detail',
  	templateUrl: './contratoDetail.component.html',
  	styleUrls: ['./contratoDetail.component.scss']
})

export class ContratoDetailComponent implements OnInit{
	public message:string = '';
	anexos = new Array();
	aditivos = new Array();
	contrato_id:number = null;
	sigla = SYSCONF_SIGLA;
	constructor(private contratoService: ContratoService, private activatedRoute: ActivatedRoute){
		this.contratoService.getContratoById(this.activatedRoute.snapshot.params['id']).subscribe(
			(success) =>{
				this.contrato_id = success.dados.id;
				this.anexos = success.dados.anexos;
				this.aditivos = success.dados.aditivos;
			},
			(error) => {
				this.message = error.error.message;
			})
	}
	ngOnInit(){
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.contrato.includes("CONSULTAR"))
			this.message = 'Você não possui permissão para executar esta ação'
	}
}