import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NivelService } from './../nivel.service';
import { Nivel } from '../nivel';
import { SYSCONF_SIGLA } from 'src/app.api';

@Component({
  selector: 'app-nivel-detail',
  templateUrl: './nivelDetail.component.html',
  styleUrls: ['./nivelDetail.component.css']
})
export class NivelDetailComponent implements OnInit {

  public message:string = '';
	conf: Nivel;
	submitted: boolean = false;
	loading: boolean = false;
  uploadData = new FormData();
  public cores: any;
  sigla = SYSCONF_SIGLA;
 constructor(private route: ActivatedRoute, private nivelService: NivelService) {
  }

  ngOnInit() {

    let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.nivel.includes("CONSULTAR"))
			this.message = 'Você não possui permissão para executar esta ação';

    this.nivelService.nivelById(this.route.snapshot.params['id']).
    subscribe(
			(nivel) => {
				nivel.dados.modulo.forEach(function(v,k){
                    nivel.dados.modulo[k].permissoes = v.permissao.join(', ');
                });
                this.conf = nivel.dados;

			},
			(error) => {
				this.message = error.error.message;
			})
  }

}
