import { Router, NavigationStart} from '@angular/router';
import { Component } from '@angular/core';
import { LoginService } from '../login/login.service';
import { SYSCONF_SIGLA } from 'src/app.api';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
	templateUrl: './pre-cadastro.component.html',
})
export class PreCadastroComponent {
	title:string;
	precadastro:string;
	endpoint:string;
	formExportar: FormGroup;
	sigla = SYSCONF_SIGLA;
	constructor(private router: Router, private loginService: LoginService, private modalService: NgbModal, private formBuilder: FormBuilder){
		this.changeRoute(this.router.url);
		router.events.forEach((event) => {
			if(event instanceof NavigationStart) {
				this.changeRoute(event.url);
			}
		  });
	}

	changeRoute(url){
		if(url.indexOf('cargo') !== -1) {
			this.title = 'Pessoa - Cargos';
			this.precadastro = 'cargo';
		}
		
		if(url.indexOf('idioma') !== -1) {
			this.title = 'Pessoa - Idiomas';
			this.precadastro = 'idioma';
		}
			
		if(url.indexOf('estado-civil') !== -1) {
			this.title = 'Pessoa - Estados Civis';
			this.precadastro = 'estado-civil';
		}

		if(url.indexOf('genero') !== -1) {
			this.title = 'Pessoa - Gêneros';
			this.precadastro = 'genero';
		}
			

		if(url.indexOf('graduacao') !== -1) {
			this.title = 'Pessoa - Graduações';
			this.precadastro = 'graduacao';
		}

		if(url.indexOf('grau-parentesco') !== -1) {
			this.title = 'Pessoa - Graus de Parentesco';
		}

		if(url.indexOf('modalidade') !== -1) {
			this.title = 'Pessoa - Modalidades';
		}

		if(url.indexOf('natureza') !== -1) {
			this.title = 'Contrato - Naturezas';
		}

		if(url.indexOf('area') !== -1) {
			this.title = 'Contrato - Áreas';
		}

		if(url.indexOf('projeto-status') !== -1) {
			this.title = 'Projeto - Status';
		}

		if(url.indexOf('projeto-enquadramento') !== -1) {
			this.title = 'Projeto - Tipos';
		}
		
		if(url.indexOf('centro-de-custo') !== -1) {
			this.title = 'Financeiro - Centros de Custo';
			this.precadastro = 'centro-custo';
			this.endpoint = 'financeiro/centro-custo';
		}
		
			
		if(url.indexOf('conta-bancaria') !== -1) {
			this.title = 'Financeiro - Contas Bancárias';
			this.precadastro = 'conta-bancaria';
			this.endpoint = 'financeiro/conta-bancaria';
		}
			
		if(url.indexOf('orgao') !== -1) {
			this.title = 'Financeiro - Órgãos';
		}
			
		if(url.indexOf('forma-de-pagamento') !== -1) {
			this.title = 'Financeiro - Formas de Pagamento';
			this.precadastro = 'forma-pagamento';
			this.endpoint = 'financeiro/forma-pagamento';
		}
		
		if(url.indexOf('fonte-de-recurso') !== -1) {
			this.title = 'Projeto/Contrato/Financeiro - Fontes de Recurso';
		}
		
		if(url.indexOf('marca') !== -1) {
			this.title = 'Estoque - Marcas';
		}
		
		if(url.indexOf('motivo-retirada') !== -1) {
			this.title = 'Estoque - Motivos de Retirada';
		}
		
		if(url.indexOf('tipo') !== -1) {
			this.title = 'Estoque - Tipos';
		}
		
		if(url.indexOf('unidade') !== -1) {
			this.title = 'Estoque - Unidades de Medida';
		}
		
		if(url.indexOf('local') !== -1) {
			this.title = 'Estoque - Locais';
		}

		if(url.indexOf('atributo') !== -1) {
			this.title = 'Estoque - Atributos';
		}

		if(url.indexOf('categoria') !== -1) {
			this.title = 'Estoque - Categorias';
		}

		if(url.indexOf('verba') !== -1) {
			this.title = 'RH - Verbas';	
		}		
		
	}

	initFormExportar(){
		this.formExportar = this.formBuilder.group({
			campos: new FormArray([]),
		});
	}

	openExportModal(modal) {
		this.initFormExportar();
		this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
	}
	
	getPermissao(modulo,secao){
		return this.loginService.getPermissao(modulo,secao);
	}

}