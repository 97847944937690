import { LoginService } from 'src/app/login/login.service';
import { PessoaService } from './../../pessoa.service';
import { Estudantil } from '../estudantil';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { Federacao } from '../../federacao/federacao';
import { SYSCONF_SIGLA } from 'src/app.api';
@Component({
	selector: 'app-estudantil-edit',
  	templateUrl: './estudantilEdit.component.html',
  	styleUrls: ['./estudantilEdit.component.scss']
})
export class EstudantilEditComponent implements OnInit{
	@ViewChild('Swal') public swal:SwalComponent; 
	federacoes: Federacao[];
	public message:string = '';
	editForm: FormGroup;
	estudantil: Estudantil;
	estudantil_id:number;
	thumb:string = '';
	pessoa_id:number;
	enderecos:object = new Array();
	telefones:object = new Array();
	contasBancarias:object = new Array();
	sigla = SYSCONF_SIGLA;
	anexos: any;
	acesso = new Array();
	constructor(public loginService: LoginService,private pessoaService: PessoaService , private activatedRoute: ActivatedRoute ,private formBuilder: FormBuilder){
		this.editForm = this.formBuilder.group({
			id: this.formBuilder.control(0,[Validators.required]),
			dt_cadastro: this.formBuilder.control(''),
			dt_atualizacao: this.formBuilder.control(''),
			cnpj: this.formBuilder.control('',[Validators.required]),
			razao_social: this.formBuilder.control('',[Validators.required]),
			nome_completo: this.formBuilder.control('',[Validators.required]),
			email: this.formBuilder.control('',[Validators.required, Validators.email]),
			status: this.formBuilder.control('',[Validators.required]),
			federacao_id: this.formBuilder.control(''),
			sigla: this.formBuilder.control('',[Validators.required]),
			federado: this.formBuilder.control('',[Validators.required]),
			website: this.formBuilder.control(''),
			observacao: this.formBuilder.control('')
		});	
		this.pessoaService.getOptions('pessoa/federacao')
        .subscribe( (success) => {
			this.federacoes = success.dados;
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});
		this.estudantil_id = this.activatedRoute.snapshot.params['id'];
		this.pessoaService.getPessoaById('pessoa/estudantil',this.estudantil_id).subscribe(
			(estudantil) => {
			this.estudantil = estudantil.dados;
			this.pessoa_id = this.estudantil.pessoa_id;
			this.acesso = this.estudantil.acesso;
			if(this.estudantil.foto){
				this.thumb = this.estudantil.foto.thumb;
			}
			this.pessoaService.getPessoaById('pessoa',this.pessoa_id).subscribe(
				(success) =>{
					this.enderecos = success.dados.endereco;
					this.telefones = success.dados.telefone;
					this.contasBancarias = success.dados.conta_bancaria;
					this.anexos = success.dados.anexo;
				},
				(error) => {
					this.message = error.error.message;
				})
			
			this.editForm.patchValue({
				id: this.estudantil.id,
				dt_cadastro: this.estudantil.dt_cadastro_br,
				dt_atualizacao: this.estudantil.dt_atualizacao_br,
				cnpj: this.estudantil.cnpj,
				razao_social: this.estudantil.razao_social,
				nome_completo: this.estudantil.nome_completo,
				email: this.estudantil.email,
				status: this.estudantil.status,
				federacao_id: this.estudantil.federacao_id,
				sigla: this.estudantil.sigla,
				federado: this.estudantil.federado == 'S' ? 'S' : 'N',
				website: this.estudantil.website,
				observacao: this.estudantil.observacao
			});
			
		},
		(error) => {
			this.message = error.error.message;
		})
	}
	ngOnInit(){
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.pessoa_estudantil.includes("EDITAR"))
			this.message = 'Você não possui permissão para executar esta ação'
	}	
}