import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SYSCONF_LIMIT, SYSCONF_SIGLA } from './../../../app.api';
import { FormGroup } from '@angular/forms';
import { ImportacaoService } from '../importacao.service';
import { LoginService } from 'src/app/login/login.export';

@Component({
	selector: 'app-importacao-list',
	templateUrl: './importacaoList.component.html',
	styleUrls: ['./importacaoList.component.scss']
})

export class ImportacaoListComponent implements OnInit{
	@ViewChild('Swal') public swal: SwalComponent;
	arquivos = new Array();
	public message:string = '';
	edit:boolean = false;
	detail:boolean = false;
	delete:boolean = false;
	params = null;
	page = 1;
	totalPages:number = 1;
	recordsPerPage:number = SYSCONF_LIMIT;
	buscaForm: FormGroup;
	importacao_id:number = null;
	sigla = SYSCONF_SIGLA;
	arquivo:File;
	submitted: boolean = false;
	constructor(private activatedRoute: ActivatedRoute,  private router: Router, private importacaoService: ImportacaoService, private loginService: LoginService){

	}
	onFileChange(event){
		this.arquivo = event.target.files[0];	
	}
	changePage(event){
		let page = parseInt(event);
		let newParams:Object = new Object();
		this.activatedRoute.params.subscribe(
			param => {
				for(let i in param) {
					if(i != 'page') 
						newParams[i] = param[i];
				}
			})
		if(!page)
		return;
		this.router.navigate(['/'+SYSCONF_SIGLA+`/importacao/page/${page}`,newParams]);
	}
	ngOnInit(){ 
		let t = JSON.parse(localStorage.getItem('CONFLogin'));

		//if(t.permissao.importacao.includes("CONSULTAR"))
		this.detail = true;
		
		this.activatedRoute.params.subscribe(params => {
			this.paramsChange(params);			
		});
	}
	paramsChange(params) {
		this.message = "";
		this.importacaoService.list(params)
		.subscribe(
			(importacao) => {
				this.arquivos = importacao.dados;
				this.totalPages = importacao.paging.rows;
				this.page = params['page'];
			},
			(error) => {
				this.message = error.error.message;
			})
	}

	onSubmit(){
		this.submitted = true;
		this.importacaoService.add(this.arquivo)
			.subscribe( (success) => {
				this.swal.text = "Acompanhe o status da importação na lista de importações. Esse processo pode demorar severos minutos.";
				this.swal.type = "success";
				this.swal.title = "Arquivo enviado com sucesso!";
				this.swal.show();
				this.submitted = false;
				this.activatedRoute.params.subscribe(params => {
					this.paramsChange(params);			
				});
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
			return;
	}
	
	getPermissao(modulo,secao){
		return this.loginService.getPermissao(modulo,secao);
	}
}
