import { Funcionario } from './../../../pessoa/funcionario/funcionario';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { LoginService } from './../../../login/login.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SYSCONF_LIMIT, SYSCONF_SIGLA } from '../../../../app.api';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { RHService } from '../../rh.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-cargo-e-salario-list',
	templateUrl: './cargoESalarioList.component.html',
	styleUrls: ['./cargoESalarioList.component.scss']
})

export class CargosESalariosListComponent implements OnInit{
	@ViewChild('Swal') public swal: SwalComponent;
	cargos = new Array();
	public message:string = '';
	edit:boolean = false;
	detail:boolean = false;
	delete:boolean = false;
	params = null;
	page = 1;
	totalPages:number = 1;
	recordsPerPage:number = SYSCONF_LIMIT;
	buscaForm: FormGroup;
	formExportar: FormGroup;
	sigla = SYSCONF_SIGLA;
	constructor(private activatedRoute: ActivatedRoute, private modalService: NgbModal, private router: Router, private rhService: RHService, private loginService: LoginService, private formBuilder: FormBuilder){
		this.buscaForm = this.formBuilder.group({
			id: this.formBuilder.control(''),
			nome_completo: this.formBuilder.control(''),
			cpf: this.formBuilder.control(''),
			apelido: this.formBuilder.control(''),
		});

	}
	changePage(event){
		let page = parseInt(event);
		let newParams:Object = new Object();
		this.activatedRoute.params.subscribe(
			param => {
				for(let i in param) {
					if(i != 'page') 
						newParams[i] = param[i];
				}
			})
		if(!page)
		return;
		this.router.navigate(['/'+SYSCONF_SIGLA+`/cargo-e-salario/page/${page}`,newParams]);
	}
	ngOnInit(){ 
		
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		
		if(t.permissao.rh_cargo_salario.includes("CONSULTAR"))
			this.detail = true;
		
		this.activatedRoute.params.subscribe(params => {
			this.paramsChange(params);
		});
	}

	initFormExportar(){
		this.formExportar = this.formBuilder.group({
			campos: new FormArray([]),
		});
	}
	openExportModal(modal) {
		this.initFormExportar();
		this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
	}

	paramsChange(params) {
		this.message = "";
		this.params = params;
		this.rhService.get('cargo-salario',params)
		.subscribe(
			(cargos) => {
				this.cargos = cargos.dados;
				this.totalPages = cargos.paging.rows;
				this.page = params['page'];
			},
			(error) => {
				this.message = error.error.message;
			})
	}

	onSubmit(){
		this.router.navigate(['/'+SYSCONF_SIGLA+'/cargo-e-salario/page/1',this.buscaForm.value]);
	}
	
	getPermissao(modulo,secao){
		return this.loginService.getPermissao(modulo,secao);
	}
}
