import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { LoginService } from './../../../login/login.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SYSCONF_LIMIT, SYSCONF_SIGLA } from '../../../../app.api';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { FinanceiroService } from '../../financeiro.service';
import { PreCadastroService } from 'src/app/pre-cadastro/pre-cadastro.service';
import { PessoaService } from '../../../pessoa/pessoa.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-cheque-list',
	templateUrl: './chequeList.component.html',
	styleUrls: ['./chequeList.component.scss']
})

export class ChequeListComponent implements OnInit{
	@ViewChild('Swal') public swal: SwalComponent;
	@ViewChild('SwalDelete') public swalDelete:SwalComponent; 
	cheque = new Array();
	cheque_id:number = null;
	public message:string = '';
	edit:boolean = false;
	detail:boolean = false;
	delete:boolean = false;
	create:boolean = false;
	params = null;
	page = 1;
	totalPages:number = 1;
	recordsPerPage:number = SYSCONF_LIMIT;
	formExportar: FormGroup;
	buscaForm: FormGroup;
	sigla = SYSCONF_SIGLA;
	pessoasSelect = new Array();
	contasBancariasSelect = new Array();
	tiposPessoasSelect = new Array();
	constructor(private activatedRoute: ActivatedRoute, private loginService: LoginService, private router: Router, private modalService: NgbModal, private financeiroService: FinanceiroService, private formBuilder: FormBuilder, private preCadastroService: PreCadastroService, private pessoaService: PessoaService){
		this.preCadastroService.getOptions('financeiro/conta-bancaria').subscribe((success) => {
			this.contasBancariasSelect = success.dados;
			this.contasBancariasSelect.map((row) => { row.labelSelect = `${row.banco} - Ag.: ${row.num_agencia}-${row.dv_agencia} - ${row.tipo}: ${row.num_conta}-${row.dv_conta}`; return row; });			
		},(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		})
		
		this.pessoaService.getOptions('pessoa/tipo').subscribe((success) => {
			this.tiposPessoasSelect = success.dados;
		},(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		})

		this.buscaForm = this.formBuilder.group({
			numero: this.formBuilder.control(''),
			tipo_pessoa_id: this.formBuilder.control(''),
			pessoa_id: this.formBuilder.control(null),
			conta_bancaria_id: this.formBuilder.control(null),
		});

	}
	changePage(event){
		let page = parseInt(event);
		let newParams:Object = new Object();
		this.activatedRoute.params.subscribe(
			param => {
				for(let i in param) {
					if(i != 'page') 
						newParams[i] = param[i];
				}
			})
		if(!page)
		return;
		this.router.navigate(['/'+SYSCONF_SIGLA+`/cheque/page/${page}`,newParams]);
	}
	ngOnInit(){ 
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(t.permissao.financeiro_cheque.includes("CONSULTAR"))
			this.detail = true;
		if(t.permissao.financeiro_cheque.includes("CRIAR"))
			this.create = true;
		if(t.permissao.financeiro_cheque.includes("EDITAR"))
			this.edit = true;
		if(t.permissao.financeiro_cheque.includes("EXCLUIR"))
			this.delete = true;
		this.activatedRoute.params.subscribe(params => {
			this.paramsChange(params);
		});
	}

	getPermissao(modulo,secao){
		return this.loginService.getPermissao(modulo,secao)
	}

	initFormExportar(){
		this.formExportar = this.formBuilder.group({
			campos: new FormArray([]),
		});
	}

	openExportModal(modal) {
		this.initFormExportar();
		this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
	}

	//Carrega o campo "Nome da Pessoa" de acordo com o "Tipo de Pessoa"
	loadTiposPessoas(id){
		this.buscaForm.patchValue({pessoa_id: ''})
		this.pessoasSelect = new Array();
		let endpoint = this.tiposPessoasSelect.find(c => c.id == id);
		if(!endpoint)
			return;
		this.pessoaService.getOptions('pessoa/'+endpoint.slug).subscribe((success) => {
			this.pessoasSelect = success.dados;	
		},(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		})
	}

	askDelete(id:number){
		this.cheque_id = id;
		this.swalDelete.show();
	}

	confirmDelete(){
		this.financeiroService.del('cheque',this.cheque_id).subscribe( 
		(success) => {
			this.swal.text = "Cheque deletado com sucesso!";
			this.swal.type = "success";
			this.swal.title = "Pronto!";
			this.swal.show();
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		});
	}

	paramsChange(params) {
		this.params = params;
		this.message = "";
		this.cheque = [];
		this.totalPages = 1;
		this.financeiroService.list('cheque',params)
		.subscribe(
			(cheque) => {
				this.cheque = cheque.dados;
				this.totalPages = cheque.paging.rows;
				this.page = params['page'];
			},
			(error) => {
				this.message = error.error.message;
			})
	}

	onSubmit(){
		this.router.navigate(['/'+SYSCONF_SIGLA+'/cheque/page/1',this.buscaForm.value]);
	}
	
	onCloseModal(){
		let params = new Array();
		params['page'] = 1;
		if(this.swal.type == "success"){
			this.financeiroService.list('cheque',params)
			.subscribe(
				(cheque) => {
					this.cheque = cheque.dados;
					this.totalPages = cheque.paging.rows;
					this.page = params['page'];
				},
				(error) => {
					this.message = error.error.message;
				})
		}
	}
}
