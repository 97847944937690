import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { PreCadastroService } from 'src/app/pre-cadastro/pre-cadastro.service';
import { PessoaService } from '../../pessoa.service';
import { SYSCONF_SIGLA } from 'src/app.api';
@Component({
	selector: 'app-dependente-search',
  	templateUrl: './dependenteSearch.component.html',
  	styleUrls: ['./dependenteSearch.component.scss']
})

export class DependenteSearchComponent implements OnInit{
	
	@ViewChild('Swal') public swal:SwalComponent; 
	public message:string = '';
	searchForm: FormGroup;
	genero;
	estadoCivil = new Array();
	funcionarios = new Array();
	sigla = SYSCONF_SIGLA;
	grauParentesco = new Array();
	constructor(private preCadastroService: PreCadastroService, private pessoaService: PessoaService, private formBuilder: FormBuilder){
		this.searchForm = this.formBuilder.group({
			estrangeiro: this.formBuilder.control(''),
			passaporte: this.formBuilder.control(''),
			passaporte_dt_validade: this.formBuilder.control(''),
			cpf: this.formBuilder.control(''),
			dt_nascimento: this.formBuilder.control(''),
			primeiro_nome: this.formBuilder.control(''),
			ultimo_nome: this.formBuilder.control(''),
			nome_completo: this.formBuilder.control(''),
			apelido: this.formBuilder.control(''),
			email: this.formBuilder.control(''),
			nacionalidade: this.formBuilder.control(''),
			naturalidade: this.formBuilder.control(''),
			status: this.formBuilder.control(''),
			genero_id: this.formBuilder.control(''),
			grau_parentesco_id: this.formBuilder.control(''),
			funcionario_id: this.formBuilder.control(''),
			estado_civil_id: this.formBuilder.control(''),
			observacao: this.formBuilder.control(''),
			irrf: this.formBuilder.control(''),
			dnv: this.formBuilder.control(''),
			cartorio_registro: this.formBuilder.control(''),
			plano_saude: this.formBuilder.control(''),
			cartorio_nome: this.formBuilder.control(''),
			cartorio_livro: this.formBuilder.control(''),
			cartorio_folha: this.formBuilder.control(''),

			pis: this.formBuilder.control(''),
			grau_instrucao_id: this.formBuilder.control('')
		});	

		this.preCadastroService.get('pessoa/genero')
        .subscribe( (success) => {
			this.genero = success.dados;
			
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});

		this.preCadastroService.get('pessoa/estado-civil')
        .subscribe( (success) => {
			this.estadoCivil = success.dados;
			
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});

		this.preCadastroService.get('pessoa/grau-parentesco')
        .subscribe( (success) => {
			this.grauParentesco = success.dados;
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});

		this.pessoaService.getOptions('pessoa/funcionario')
        .subscribe( (success) => {
			this.funcionarios = success.dados;
			
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});
	}

	ngOnInit(){
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.rh_dependente.includes("CONSULTAR"))
			this.message = 'Você não possui permissão para executar esta ação'
	}
	
	
}