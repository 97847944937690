import { Injectable } from '@angular/core';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class DataService {

	constructor() {}

	formataDataBr(data_br:string = null, detalhe:any = false){
		if(detalhe === true)
		return data_br;
		
		if(data_br && data_br.indexOf('/') !== -1 && data_br.indexOf('undefined') === -1){
			return {
				year: parseInt(data_br.split('/')[2]),
				month: parseInt(data_br.split('/')[1]),
				day: parseInt(data_br.split('/')[0])
			}
		}

		return null;
	}

	compareTwoDates(date1, date2){
		if(new Date(date1) < new Date(date2))
			return false;
			
		return true;
	}

	convertDataObject(formValue,key){
		if(formValue[key] == null || formValue[key] == 'undefined')
			return '';
		return `${formValue[key].day}/${formValue[key].month}/${formValue[key].year}`
		
	}

	mesExtenso(mes:any) {

		switch(mes){
			case 1:
				return 'Janeiro';
			case 2:
				return 'Fevereiro';
			case 3:
				return 'Março';
			case 4:
				return 'Abril';
			case 5:
				return 'Maio';
			case 6:
				return 'Junho';
			case 7:
				return 'Julho';
			case 8:
				return 'Agosto';
			case 9:
				return 'Setembro';
			case 10:
				return 'Outubro';
			case 11:
				return 'Novembro';
			case 12:
				return 'Dezembro';
		}
	}

}
