import { Component, OnInit} from '@angular/core';
import { SYSCONF_SIGLA } from 'src/app.api';
@Component({
	selector: 'app-movimentacao-search',
  	templateUrl: './movimentacaoSearch.component.html',
  	styleUrls: ['./movimentacaoSearch.component.scss']
})

export class MovimentacaoSearchComponent implements OnInit{
	public message:string = '';
	sigla = SYSCONF_SIGLA;
	ngOnInit(){
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.estoque_movimentacao.includes("CONSULTAR"))
			this.message = 'Você não possui permissão para executar esta ação'
	}
	
	
}