import { environment } from './environments/environment';
export const LANGUAGE = 'pt';

let sigla_url:string = location.pathname.split('/')[1];
if(sigla_url === '' || sigla_url === '404'){
    let sigla_local = JSON.parse(localStorage.getItem('CONFLogin'));
    if(sigla_local)
    sigla_url = sigla_local.sigla;
}
export const SYSCONF_SIGLA =  sigla_url;
export const SYSCONF_API = environment.apiUrl+SYSCONF_SIGLA;
//export const SYSCONF_SIGLA = 'dev-app';

export const SYSCONF_LIMIT = 20;
export const SYSCONF_CREDENTIALS = true;
export const SYSCONF_APP_ID = '2151981';
export const SYSCONF_PUBKEY = "-----BEGIN PUBLIC KEY-----\
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA7s+swY4CwLxTfQXh/aEc\
Iesu55G++H0Ayhj1YO+B3+BX6qzKHFaMhTeu6wJgha1QDnlq1nsPAesu6QYUTl4Y\
ZQnjc6ruNqpQxXXoqLXEMaBAKrRdtFo5PXTEqri5n1gT70oSRe0aS0nLpkvjjweS\
ih0WU/jM6ouyEJCiQ5v/NXB4LVDFC5vv43wHEe6bR7zh0ug6DmGbxJTH8kSQDoQ9\
dFrO7B2hxH2mtWvycBLZ/c5bWFJnG0BQyvCZuBquubayC6ROsxjtjkeCatiU19UK\
xswjUiBKrQfdX3rFN0v9G3uV9Eg8UVeOxkxMebIfQaoE+tDM6L0hmlf+Dl6pszAV\
6QIDAQAB\
-----END PUBLIC KEY-----";