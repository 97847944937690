import { EstoqueService } from './../../estoque.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SYSCONF_SIGLA } from 'src/app.api';
import { LoginService } from '../../../login/login.service';
@Component({
	selector: 'app-suprimento-detail',
  	templateUrl: './suprimentoDetail.component.html',
  	styleUrls: ['./suprimentoDetail.component.scss']
})

export class SuprimentoDetailComponent implements OnInit{
	public message:string = '';
	suprimento_id:number;
	sigla = SYSCONF_SIGLA;
	constructor(private estoqueService: EstoqueService, private activatedRoute: ActivatedRoute, private loginService: LoginService){
		this.estoqueService.getById('suprimento',this.activatedRoute.snapshot.params['id']).subscribe(
			(success) =>{
				this.suprimento_id = success.dados.id;
			},
			(error) => {
				this.message = error.error.message;
			})
	}
	ngOnInit(){
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.estoque_suprimento.includes("CONSULTAR"))
			this.message = 'Você não possui permissão para executar esta ação'
	}

	getPermissao(modulo,secao){
		return this.loginService.getPermissao(modulo,secao);
	}
}