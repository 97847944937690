import { Component, OnInit } from '@angular/core';
import {SlimLoadingBarService} from 'ng2-slim-loading-bar';
import { NavigationCancel, Event, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { HeaderComponent } from './_includes/header/header.component';
import { NgcCookieConsentService, NgcInitializeEvent, NgcStatusChangeEvent, NgcNoCookieLawEvent, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { SYSCONF_SIGLA } from 'src/app.api';
import { environment } from 'src/environments/environment';
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent implements OnInit{
    title = 'app';
     initializeSubscription: Subscription;
    popupOpenSubscription: Subscription;
    popupCloseSubscription: Subscription;
    statusChangeSubscription: Subscription;
    revokeChoiceSubscription: Subscription;
    noCookieLawSubscription: Subscription;

    constructor(private headerComponent: HeaderComponent, private _loadingBar: SlimLoadingBarService, public _router: Router, private ccService: NgcCookieConsentService) {
        this._router.events.subscribe((event: Event) => {
            this.navigationInterceptor(event);
        });
    }
    private navigationInterceptor(event: Event): void {
        if (event instanceof NavigationStart) {
            this._loadingBar.start();
            this.headerComponent.loadJqueryFunctions();
        }
        if (event instanceof NavigationEnd) {
            this._loadingBar.complete();
            
            if($(window).innerWidth() < 992){
                let temp = document.querySelectorAll('.menu-page a').length;
                $('.menu-page a').css('width',(100 / temp)+'%');
            }
        }
        if (event instanceof NavigationCancel) {
        this._loadingBar.stop();
        }
        
        if (event instanceof NavigationError) {
        this._loadingBar.stop();
        }
    }

    ngOnInit(){  
      this.ccService.destroy();
      let cookieConfig:NgcCookieConsentConfig = {
        cookie: {
          domain: environment.cookieDomain
        },
        palette: {
          popup: {
            background: '#0167CB'
          },
          button: {
            background: '#FFFFFF'
          }
        },
        theme: 'classic',
        type: 'opt-out',
        "content": {
          "message": "Ao acessar o Sysconf você concorda com o uso de cookies para uma melhor experiência de navegação.",
          "dismiss": "Entendi!",
          "allow": "Entendi!",
          "deny": "",
          "link": "Leia Mais",
          "href": `/${SYSCONF_SIGLA}/politica-cookies`,
          "policy": "Política de Cookies"
        }
      };
    //  console.log('Vamos dar o INIT')
        // subscribe to cookieconsent observables to react to main events
    this.ccService.init(cookieConfig)
        
     
    }
}
