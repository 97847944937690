import { PessoaService } from './../../pessoa/pessoa.service';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { LoginService } from './../../login/login.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SYSCONF_LIMIT, SYSCONF_SIGLA } from './../../../app.api';
import { Projeto } from '../projeto';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ProjetoService } from '../projeto.service';
import { PreCadastroService } from 'src/app/pre-cadastro/pre-cadastro.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-projeto-list',
	templateUrl: './projetoList.component.html',
	styleUrls: ['./projetoList.component.scss']
})

export class ProjetoListComponent implements OnInit{
	@ViewChild('Swal') public swal: SwalComponent;
	@ViewChild('SwalDelete') public swalDelete: SwalComponent;
	projetos: Projeto[];
	public message:string = '';
	edit:boolean = false;
	detail:boolean = false;
	delete:boolean = false;
	params = null;
	page = 1;
	totalPages:number = 0;
	recordsPerPage:number = SYSCONF_LIMIT;
	buscaForm: FormGroup;
	formExportar: FormGroup;
	projeto_id:number = null;
	statusSelect = new Array();
	enquadramentosSelect = new Array();
	sigla = SYSCONF_SIGLA;
	constructor(private preCadastroService: PreCadastroService, private modalService: NgbModal, private pessoaService:PessoaService, private activatedRoute: ActivatedRoute,  private router: Router, private projetoService: ProjetoService, private loginService: LoginService, private formBuilder: FormBuilder){
		this.buscaForm = this.formBuilder.group({
			id: this.formBuilder.control(''),
			nome: this.formBuilder.control(''),
			projeto_enquadramento_id: this.formBuilder.control(''),
			projeto_status_id: this.formBuilder.control(''),
		});

		this.preCadastroService.getOptions('projeto/status').subscribe((success) => {
			this.statusSelect = success.dados;
		},(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		});

		this.preCadastroService.getOptions('projeto/enquadramento').subscribe((success) => {
			this.enquadramentosSelect = success.dados;
		},(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		});
	}

	changePage(event){
		let page = parseInt(event);
		
		let newParams:Object = new Object();
		this.activatedRoute.params.subscribe(
			param => {
				for(let i in param) {
					if(i != 'page') 
						newParams[i] = param[i];
				}
			})
		if(!page)
		return;
		this.router.navigate(['/'+SYSCONF_SIGLA+`/projeto/page/${page}`,newParams]);
	}
	ngOnInit(){ 
		let t = JSON.parse(localStorage.getItem('CONFLogin'));

		if(t.permissao.projeto.includes("EDITAR"))
			this.edit = true;
		if(t.permissao.projeto.includes("CONSULTAR"))
			this.detail = true;
		if(t.permissao.projeto.includes("EXCLUIR"))
			this.delete = true;
		this.activatedRoute.params.subscribe(params => {
			this.paramsChange(params);			
		});
	}
	paramsChange(params) {
		this.message = "";
		this.params = params;
		this.projetoService.list(params)
		.subscribe(
			(projeto) => {
				
				this.projetos = projeto.dados;
				this.totalPages = projeto.paging.rows;
				this.page = params['page'];
			},
			(error) => {
				this.message = error.error.message;
			})
	}

	initFormExportar(){
		this.formExportar = this.formBuilder.group({
			campos: new FormArray([]),
		});
	}

	openExportModal(modal) {
		this.initFormExportar();
		this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
	}

	onSubmit(){
		this.router.navigate(['/'+SYSCONF_SIGLA+'/projeto/page/1',this.buscaForm.value]);
	}
	
	getPermissao(modulo,secao){
		return this.loginService.getPermissao(modulo,secao);
	}

	askDelete(id:number){
		this.projeto_id = id;
		this.swalDelete.show();
	}

	confirmDelete(){
		this.projetoService.delete(this.projeto_id).subscribe( 
		(success) => {
			this.swal.text = "Projeto deletado com sucesso!";
			this.swal.type = "success";
			this.swal.title = "Pronto!";
			this.swal.show();
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		});
	}

	onCloseModal(){
		let params = new Array();
		params['page'] = this.page;
		this.projetoService.list(params)
		.subscribe(
			(projeto) => {
				
				this.projetos = projeto.dados;
				this.totalPages = projeto.paging.rows;
			},
			(error) => {
				this.message = error.error.message;
			})
	}

}
