import { Component, ViewChild, Input, OnChanges, Renderer2, ElementRef } from '@angular/core';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { EstoqueService } from 'src/app/estoque/estoque.export';

declare var $: any;
@Component({
	selector: 'app-form-estoque-exportar',
  	templateUrl: './form-estoque-exportar.component.html',
  	styleUrls: ['./form-estoque-exportar.component.scss']
})
export class FormEstoqueExportarComponent implements OnChanges{
	@ViewChild('Swal') public swal:SwalComponent; 
	@Input() form: FormGroup;
	@Input() movimento:boolean;
	@Input() suprimento:boolean;

	//MAIS AQUI
	@Input() endpoint:string;
	@Input() params;
	submitted: boolean = false;
	loading: boolean = false;
	model;
	constructor(private estoqueService: EstoqueService, private renderer: Renderer2, private elem: ElementRef){}
	ngOnChanges(){
		
	}
	changeRadioValue(form,name,value){
		this[form].patchValue({[name]: value});
	}
	
	selectAll(event) {
		let elements = this.elem.nativeElement.querySelectorAll('#form-exportar .check');

		const formArray: FormArray = this.form.get('campos') as FormArray;

		let i: number = 0;
		elements.forEach((ctrl) => {

			if(ctrl.id != 'checkbox-exportar-todos') {

				if(event.target.checked){
					ctrl.checked = true;
					formArray.push(new FormControl(ctrl.value));
				} else {
					ctrl.checked = false;
					formArray.removeAt(i);
				}
			}
			i++;
		});
	}
	onCheckChange(event) {
		const formArray: FormArray = this.form.get('campos') as FormArray;
		/* Marcado */
		if(event.target.checked){
			// Adiciona um novo control para o Form Array
			formArray.push(new FormControl(event.target.value));
		}
		/* Desmarcar */
		else{
			// Encontra o elemento para remover
			let i: number = 0;
		
			formArray.controls.forEach((ctrl: FormControl) => {
			if(ctrl.value == event.target.value) {
				// Remove os elementos desmarcados
				formArray.removeAt(i);
				return;
			}
			i++;
			});
		}

	}
	
	get campos(){
		return this.form.get('campos') as FormArray;
	}


    onSubmit(tipo: 'excel' | 'pdf'){
		this.submitted = true;
        if(!this.form.valid){
			this.submitted = false;
			return;
		}

        this.estoqueService.export(tipo, this.endpoint, this.form, this.params)
        .subscribe( (success:{dados:{link:string}}) => {
			this.submitted = false;
			window.open(success.dados.link, "_blank");
		},
		(error) => {
			this.submitted = false;
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}
}