import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { SYSCONF_LIMIT, SYSCONF_SIGLA } from '../../../app.api';
import { EstoqueService } from '../../estoque/estoque.service';
import { Suprimento } from 'src/app/estoque/suprimento/suprimento';

@Component({
  selector: 'app-home-alerta-estoque',
  templateUrl: './home-alerta-estoque.component.html',
  styleUrls: ['../home.component.scss']
})
export class HomeAlertaEstoqueComponent implements OnInit {
  
  suprimentoTodos: Suprimento[];
  suprimentoEmFalta: Suprimento[];
  suprimentoEsgotando: Suprimento[];

  verificadoTodos: boolean = false;
  verificadoEmFalta: boolean = false;
  verificadoEsgotando: boolean = false;
  
  mostrarTodos: boolean;
  mostrarEmFalta: boolean;
  mostrarEsgotando: boolean;

  messageTodos: string;
  messageEmFalta: string;
  messageEsgotando: string;

  limit: number = 10;

  sigla = SYSCONF_SIGLA;

  constructor(private cd: ChangeDetectorRef, private estoqueService: EstoqueService) { }

  ngOnInit() {
    this.mostrar('em-falta');
    this.mostrar('esgotando');    
  }  

  mostrar(tipo:string){
    
    switch(tipo){
      
      case 'em-falta': {
        if(this.verificadoEmFalta == false){
          this.getSuperimentoEmFalta();
          this.verificadoEmFalta = true;
        }

        this.mostrarTodos = false;
        this.mostrarEmFalta = true;
        this.mostrarEsgotando = false;

        break; 
      }

      case 'esgotando': {
        if(this.verificadoEsgotando == false){
          this.getSuprimentoEsgotando();
          this.verificadoEsgotando = true;
        }

        this.mostrarTodos = false;
        this.mostrarEmFalta = false;
        this.mostrarEsgotando = true;

        break; 
      }

      

      default: {

        if(!this.suprimentoTodos){
          this.getSuprimentoTodos();
        }

        this.mostrarTodos = true;
        this.mostrarEmFalta = false;
        this.mostrarEsgotando = false;


        break; 

      }

    }
      
      
    
  }


  getSuprimentoTodos(){
    let param = { 'page': 1};
    this.estoqueService.list('suprimento',param, this.limit)
    .subscribe(
      (estoque) => {

        this.suprimentoTodos = estoque.dados;

        if(this.suprimentoTodos.length == 0) {
          this.messageTodos = "Nenhum suprimento esgotando ou em falta";
        }

        this.cd.markForCheck();
      },
      (error) => {
        this.messageTodos = error.message;
      });

  }

  getSuperimentoEmFalta(){
    let param = {'page': 1};
    this.estoqueService.list('suprimento/em-falta',param, this.limit)
    .subscribe(
      (estoque) => {
        
        if(estoque.dados) {
          this.suprimentoEmFalta = estoque.dados;
        }

        if(this.suprimentoEmFalta.length == 0) {
          this.messageEmFalta = "Nenhum suprimento em falta";
        }
        this.cd.markForCheck();
      },
      (error) => {
        this.cd.markForCheck();
        this.messageEmFalta = error.error.message;
      });

  }

  getSuprimentoEsgotando(){
    let param = {'page': 1};
    this.estoqueService.list('suprimento/esgotando',param, this.limit)
      .subscribe(
        (estoque) => {

          if(estoque.dados) {
            this.suprimentoEsgotando = estoque.dados;
          }

          if(this.suprimentoEsgotando.length == 0) {
            this.messageEsgotando = "Nenhum suprimento esgotando";
          }

          this.cd.markForCheck();
        },
        (error) => {
          this.cd.markForCheck();
          this.messageEsgotando = error.error.message;
         

        });
  }

}
