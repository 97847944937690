import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { LoginService } from './../../../login/login.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PessoaService } from '../../pessoa.service';
import { SYSCONF_LIMIT, SYSCONF_SIGLA } from '../../../../app.api';
import { Responsavel } from '../responsavel';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';

@Component({
	selector: 'app-responsavel-list',
	templateUrl: './responsavelList.component.html',
	styleUrls: ['./responsavelList.component.scss']
})

export class ResponsavelListComponent implements OnInit{
	@ViewChild('Swal') public swal: SwalComponent;
	responsaveis: Responsavel[];
	public message:string = '';
	edit:boolean = false;
	detail:boolean = false;
	delete:boolean = false;
	params = null;
	page = 1;
	totalPages:number = 1;
	recordsPerPage:number = SYSCONF_LIMIT;
	buscaForm: FormGroup;
	ordemForm: FormGroup;
	formExportar: FormGroup;
	sigla = SYSCONF_SIGLA;
	constructor(private activatedRoute: ActivatedRoute,  private router: Router, private pessoaService:PessoaService, private loginService: LoginService, private formBuilder: FormBuilder, private modalService: NgbModal){
		this.buscaForm = this.formBuilder.group({
			id: this.formBuilder.control(''),
			nome_completo: this.formBuilder.control(''),
			apelido: this.formBuilder.control(''),
			cpf: this.formBuilder.control(''),
		});

		this.ordemForm = this.formBuilder.group({
			ordem: this.formBuilder.control('nome_completo'),
			ordem_direcao: this.formBuilder.control('asc')
		});

	}
	changePage(event){
		let page = parseInt(event);
		let newParams:Object = new Object();
		this.activatedRoute.params.subscribe(
			param => {

				for(let i in param) {
					if(i != 'page') 
						newParams[i] = param[i];
				}

				newParams = {...newParams, ...this.ordemForm.value};
			})
		if(!page)
		return;
		this.router.navigate(['/'+SYSCONF_SIGLA+`/responsavel/page/${page}`,newParams]);
	}
	ngOnInit(){ 
		
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		
		if(t.permissao.pessoa_prestador.includes("EDITAR"))
			this.edit = true;
		if(t.permissao.pessoa_prestador.includes("CONSULTAR"))
			this.detail = true;
		
		this.activatedRoute.params.subscribe(params => {
			this.paramsChange(params);
		});
	}
	paramsChange(params) {
		this.params = params;
		this.message = "";
		this.pessoaService.list('pessoa/responsavel',params)
		.subscribe(
			(responsavel) => {
				this.responsaveis = responsavel.dados;
				this.totalPages = responsavel.paging.rows;
				this.page = params['page'];
			},
			(error) => {
				this.message = error.error.message;
			})
	}

	onSubmit(){
		this.router.navigate(['/'+SYSCONF_SIGLA+'/responsavel/page/1',this.buscaForm.value]);
	}
	
	getPermissao(modulo,secao){
		return this.loginService.getPermissao(modulo,secao);
	}
	initFormExportar(){
		this.formExportar = this.formBuilder.group({
			campos: new FormArray([]),
		});
	}

	openModal(modal) {
		this.initFormExportar();
		this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
	}

	ordenar() {
		this.activatedRoute.params.subscribe(params => {
			let newParams: object; 
			newParams = {...params, ...this.ordemForm.value};
			this.paramsChange(newParams);
		});
	}
}
