import { PreCadastroService } from './../../../pre-cadastro/pre-cadastro.service';
import { Component, ViewChild, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';


@Component({
	selector: 'app-form-precadastro-funcionario-cargo',
  	templateUrl: './form-precadastro-funcionario-cargo.component.html',
  	styleUrls: ['./form-precadastro-funcionario-cargo.component.scss']
})
export class FormPreCadastroFuncionarioCargoComponent{
	@ViewChild('Swal') public swal:SwalComponent;
	@ViewChild('SwalDelete') public swalDelete:SwalComponent; 
	@Input() acao:string;
	@Input() detalhe:boolean;
	form: FormGroup;
	cargo_id:number = null;
	modalTitle:string;
	submitted: boolean = false;
	loading: boolean = false;
	modalReference: NgbModalRef; 
	cob = null;
	cargos;
	constructor(private preCadastro: PreCadastroService, private formBuilder: FormBuilder,private modalService: NgbModal){
    	this.form = this.formBuilder.group({
			nome: this.formBuilder.control('',[Validators.required]),
			cbo: this.formBuilder.control('',[Validators.required]),
			descricao: this.formBuilder.control('', [Validators.required]),
			piso_salarial: this.formBuilder.control('', [Validators.required]),
			teto_salarial: this.formBuilder.control('', [Validators.required])
		})
		this.preCadastro.get('cargo')
        .subscribe( (success) => {
			this.cargos = success.dados;
		},
		(error) => {
			
		});
	}
	
	confereValores():void{
		if(this.form.value.piso_salarial > this.form.value.teto_salarial && this.form.value.teto_salarial > 0){
			this.form.patchValue({
				piso_salarial: ''
			})
			this.swal.text = "O piso salarial deve ser menor que o teto salarial!";
			this.swal.type = "warning";
			this.swal.title = "Atenção!";
			this.swal.show();
		}
	}
	onSubmit(){
		this.submitted = true;
        if(!this.form.valid)
		return;
		this.modalReference.close();
		if(!this.cargo_id){
			this.preCadastro.add('cargo', this.form)
			.subscribe( (success) => {
				this.swal.text = "Registro cadastrado com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
			return;
		}

		this.preCadastro.edit('cargo', this.cargo_id,this.form)
			.subscribe( (success) => {
				this.swal.text = "Registro atualizado com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});

        
	}

	openModal(modal,id:number = null) {

		//Se id == null então é cadastro.
		if(!id){
			this.cargo_id = null;
			this.modalTitle = "Cadastrar Cargo";
			this.form.patchValue({
				nome: '',
				cbo: '',
				descricao: '',
				piso_salarial: '',
				teto_salarial: '',
			});
			this.modalReference = this.modalService.open(modal, {backdrop: 'static'});
			return;
		}
		
		//Senão é edição.
		this.cargo_id = id;
		this.modalTitle = "Editar Cargo";
		this.preCadastro.getById('cargo', id).subscribe((success) => {
			this.form.patchValue({
				nome: success.dados.nome,
				cbo: success.dados.cbo,
				descricao: success.dados.descricao,
				piso_salarial: success.dados.piso_salarial,
				teto_salarial: success.dados.teto_salarial,
			});
			this.modalReference = this.modalService.open(modal, {backdrop: 'static'});
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		})
	}

	askDelete(id:number){
		this.cargo_id = id;
		this.swalDelete.show();
	}

	confirmDelete(){
		this.preCadastro.del('cargo', this.cargo_id).subscribe( 
		(success) => {
			this.swal.text = "Registro deletado com sucesso!";
			this.swal.type = "success";
			this.swal.title = "Pronto!";
			this.swal.show();
			this.submitted = false;
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}

	onCloseModal(){
		if(this.swal.type == "success"){
			this.preCadastro.get('cargo').subscribe((success)=>{
				this.cargos = success.dados;
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
		}
	}
}