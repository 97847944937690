import { PessoaService } from '../../pessoa.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { Federacao } from '../../federacao/federacao';
import { Clube } from '../../clube/clube';
import { PreCadastroService } from 'src/app/pre-cadastro/pre-cadastro.service';
import { SYSCONF_SIGLA } from 'src/app.api';

declare var $: any;
@Component({
	selector: 'app-atleta-search',
  	templateUrl: './atletaSearch.component.html',
  	styleUrls: ['./atletaSearch.component.scss']
})

export class AtletaSearchComponent implements OnInit{
	
	@ViewChild('Swal') public swal:SwalComponent; 
	public message:string = '';
	searchForm: FormGroup;
	genero;
	federacoes: Federacao[];
	clubes: Clube[];
	estadoCivil: [{}];
	modalidades: [{}];
	graduacoes: [{}];
	sigla = SYSCONF_SIGLA;
	constructor(private pessoaService: PessoaService, private formBuilder: FormBuilder, private preCadastroService: PreCadastroService){
		this.searchForm = this.formBuilder.group({
			estrangeiro: this.formBuilder.control(''),
			passaporte: this.formBuilder.control(''),
			passaporte_dt_validade: this.formBuilder.control(''),
			cpf: this.formBuilder.control(''),
			dt_nascimento: this.formBuilder.control(''),
			primeiro_nome: this.formBuilder.control(''),
			ultimo_nome: this.formBuilder.control(''),
			nome_completo: this.formBuilder.control(''),
			apelido: this.formBuilder.control(''),
			email: this.formBuilder.control(''),
			nacionalidade: this.formBuilder.control(''),
			naturalidade: this.formBuilder.control(''),
			status: this.formBuilder.control(''),
			genero_id: this.formBuilder.control(''),
			rg: this.formBuilder.control(''),
			rg_dt_emissao: this.formBuilder.control(''),
			rg_emissor: this.formBuilder.control(''),
			rg_uf: this.formBuilder.control(''),
			altura: this.formBuilder.control(''),
			peso: this.formBuilder.control(''),
			tipo_sanguineo: this.formBuilder.control(''),
			tamanho_camisa: this.formBuilder.control(''),
			tamanho_agasalho: this.formBuilder.control(''),
			tamanho_calca: this.formBuilder.control(''),
			tamanho_calcado: this.formBuilder.control(''),
			tamanho_chinelo: this.formBuilder.control(''),
			situacao_federacao: this.formBuilder.control(''),
			dt_registro_federacao: this.formBuilder.control(''),
			situacao_confederacao: this.formBuilder.control(''),
			graduacao_id: this.formBuilder.control(''),
			dt_graduacao: this.formBuilder.control(''),
			observacao: this.formBuilder.control(''),
			website: this.formBuilder.control(''),
			estado_civil_id: this.formBuilder.control(''),
			federacao_id: this.formBuilder.control(''),
			clube_id: this.formBuilder.control(''),
			modalidade: new FormArray([]),
			federado: this.formBuilder.control(''),
			pis: this.formBuilder.control(''),
			grau_instrucao_id: this.formBuilder.control(''),
			selecao: this.formBuilder.control(''),
		});	
		this.preCadastroService.get('pessoa/genero')
        .subscribe( (success) => {
			this.genero = success.dados;
			
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});

		this.pessoaService.getOptions('pessoa/federacao')
        .subscribe( (success) => {
			this.federacoes = success.dados;
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});
		this.pessoaService.getOptions('pessoa/clube')
        .subscribe( (success) => {
			this.clubes = success.dados;
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});
		this.preCadastroService.get('pessoa/estado-civil')
        .subscribe( (success) => {
			this.estadoCivil = success.dados;
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});
		this.preCadastroService.get('graduacao')
        .subscribe( (success) => {
			this.graduacoes = success.dados;
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});
		this.preCadastroService.get('modalidade')
        .subscribe( (success) => {
			this.modalidades = success.dados;
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});
	}
	ngOnInit(){
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.pessoa_atleta.includes("CONSULTAR"))
			this.message = 'Você não possui permissão para executar esta ação'
	}
}