import { Component, ViewChild, Input, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';
import { EstoqueService } from 'src/app/estoque/estoque.service';

declare var $: any;
@Component({
	selector: 'app-form-estoque-movimentacao-anexo',
  	templateUrl: './form-estoque-movimentacao-anexo.component.html',
  	styleUrls: ['./form-estoque-movimentacao-anexo.component.scss']
})
export class FormEstoqueMovimentacaoAnexoComponent{
	@ViewChild('Swal') public swal:SwalComponent;
	@ViewChild('SwalDelete') public swalDelete:SwalComponent; 
	@Input() anexos;
	@Input() estoque_movimento_id: number;
	@Input() detalhe:boolean;
	form: FormGroup;
	anexo_id:number = null;
	modalTitle:string;
	submitted: boolean = false;
	loading: boolean = false;
	modalReference: NgbModalRef;   
	arquivo: File;
    
	constructor(private estoqueService: EstoqueService, private formBuilder: FormBuilder,private modalService: NgbModal){
        this.form = this.formBuilder.group({
			id: this.formBuilder.control(''),
			descricao: this.formBuilder.control('',[Validators.required]),
			estoque_movimento_id: this.formBuilder.control(this.estoque_movimento_id)
		})
	}
	onFileChange(event){
		this.arquivo = event.target.files[0];	
	}
	onSubmit(){
		this.submitted = true;
        if(!this.form.valid)
		return;
		this.modalReference.close();
		if(!this.anexo_id){
			this.estoqueService.add('movimento/anexo', this.form, null, this.arquivo)
			.subscribe( (success) => {
				this.swal.text = "Anexo cadastrado com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
			return;
		}

		this.estoqueService.edit('movimento/anexo',this.form, null, this.arquivo)
			.subscribe( (success) => {
				this.swal.text = "Anexo atualizado com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});

        
	}

	openModal(modal,id:number = null) {
		//Se id == null então é cadastro.
		if(!id){
			this.anexo_id = null;
			this.modalTitle = "Cadastrar Anexo";
			this.form.patchValue({
				id: '',
				descricao: '',
				estoque_movimento_id: this.estoque_movimento_id
			});
			this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
			return;
		}
		
		//Senão é edição.
		this.anexo_id = id;
		this.modalTitle = "Editar Anexo";
		this.estoqueService.getById('movimento/anexo',id).subscribe((success) => {
			this.form.patchValue({
				id: success.dados.id,
				descricao: success.dados.descricao,
				estoque_movimento_id: success.dados.estoque_movimento_id
			});
			this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		})
	}

	askDelete(id:number){
		this.anexo_id = id;
		this.swalDelete.show();
	}

	confirmDelete(){
		this.estoqueService.del('movimento/anexo',this.anexo_id).subscribe( 
		(success) => {
			this.swal.text = "Anexo deletado com sucesso!";
			this.swal.type = "success";
			this.swal.title = "Pronto!";
			this.swal.show();
			this.submitted = false;
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}

	onCloseModal(){
		if(this.swal.type == "success"){
			this.estoqueService.getById('movimento',this.estoque_movimento_id).subscribe((success)=>{
				this.anexos = success.dados.anexo;
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
		}
	}
}