import { Component, ViewChild, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { PessoaService } from '../../../pessoa/pessoa.service';
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/_services/data.service';
declare var $: any;
@Component({
	selector: 'app-form-funcionario-afastamento',
  	templateUrl: './form-funcionario-afastamento.component.html',
  	styleUrls: ['./form-funcionario-afastamento.component.scss']
})
export class FormFuncionarioAfastamentoComponent{
	@ViewChild('Swal') public swal:SwalComponent;
	@ViewChild('SwalDelete') public swalDelete:SwalComponent; 
	@Input() afastamentos:object = new Array();
	@Input() pessoa_id: number;
	@Input() acao:string;
	@Input() detalhe:boolean;
	@Input() pessoa_status: string = 'A';
	form: FormGroup;
	afastamento_id:number = null;
	modalTitle:string;
	submitted: boolean = false;
	loading: boolean = false;
	modalReference: NgbModalRef; 
	disabled = false;
	constructor(private pessoaService: PessoaService, private formBuilder: FormBuilder, private modalService: NgbModal, private activatedRoute: ActivatedRoute, private dt: DataService){
      	this.form = this.formBuilder.group({
			dt_inicio: this.formBuilder.control(null,[Validators.required]),
			dt_termino: this.formBuilder.control(null),
			motivo: this.formBuilder.control('',[Validators.required]),
			funcionario_id: this.formBuilder.control(this.activatedRoute.snapshot.params['id']),
		})
    }

	onSubmit(){
		this.submitted = true;
        if(!this.form.valid)
		return;
		this.modalReference.close();
		if(!this.afastamento_id){
			this.pessoaService.addFuncionarioAfastamento(this.form)
			.subscribe( (success) => {
				this.swal.text = "Afastamento cadastrado com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
				this.zeraForm();
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
			return;
		}

		this.pessoaService.editFuncionarioAfastamento(this.afastamento_id,this.form)
			.subscribe( (success) => {
				this.swal.text = "Afastamento atualizado com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
				this.zeraForm();
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
				this.zeraForm();
			});
	}

	verificaPeriodo(dt_inicio,dt_termino){
		if(!dt_inicio || !dt_termino)
		return;
		let convert_dt_inicio = new Date(dt_inicio.year, dt_inicio.month-1, dt_inicio.day)
		let convert_dt_termino = new Date(dt_termino.year, dt_termino.month-1, dt_termino.day)
		if(convert_dt_inicio > convert_dt_termino){
			this.swal.text = "A data de término deve ser maior que a data de início!";
			this.swal.type = "warning";
			this.swal.title = "Atenção!";
			this.swal.show();
			this.form.patchValue({
				dt_termino: null
			})
			return;
		}
	}
	
	zeraForm():void{
		this.form.patchValue({
			dt_inicio: null,
			dt_termino:  null,
			motivo:  '',
		});
	}

	openModal(modal,id:number = null) {
		this.disabled = false;
		//Se id == null então é cadastro.
		if(!id){
			this.afastamento_id = null;
			this.modalTitle = "Cadastrar Afastamento";
			this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
			return;
		}
		
		//Senão é edição.
		this.afastamento_id = id;
		this.disabled = true;
		this.modalTitle = "Editar Afastamento";
		this.pessoaService.getFuncionarioAfastamentoById(id).subscribe((success) => {
			this.form.patchValue({
				dt_inicio: this.dt.formataDataBr(success.dados.dt_inicio_br),
				dt_termino: this.dt.formataDataBr(success.dados.dt_termino_br),
				motivo:  success.dados.motivo
			});
			this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		})
	}

	askDelete(id:number){
		this.afastamento_id = id;
		this.swalDelete.show();
	}

	confirmDelete(){
		this.pessoaService.delFuncionarioAfastamento(this.afastamento_id).subscribe( 
		(success) => {
			this.swal.text = "Afastamento deletado com sucesso!";
			this.swal.type = "success";
			this.swal.title = "Pronto!";
			this.swal.show();
			this.submitted = false;
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}

	onCloseModal(){
		if(this.swal.type == "success"){
			this.pessoaService.getPessoaById('pessoa/funcionario',this.activatedRoute.snapshot.params['id']).subscribe((success)=>{
				this.afastamentos = success.dados.afastamento;
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
		}
	}
}