import { RHService } from './../../../rh/rh.service';
import { Component, ViewChild, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { PessoaService } from '../../../pessoa/pessoa.service';
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';
import { PreCadastroService } from 'src/app/pre-cadastro/pre-cadastro.service';
import { Funcionario } from 'src/app/pessoa/funcionario/funcionario';
declare var $: any;
@Component({
	selector: 'app-form-folha-de-pagamento-funcionario',
  	templateUrl: './form-folha-de-pagamento-funcionario.component.html',
  	styleUrls: ['./form-folha-de-pagamento-funcionario.component.scss']
})
export class FormFolhaDePagamentoFuncionarioComponent{
	@ViewChild('Swal') public swal:SwalComponent;
	@ViewChild('SwalDelete') public swalDelete:SwalComponent; 
	@Input() funcionarios;
	@Input() pessoa_id: number;
	@Input() acao:string;
	@Input() detalhe:boolean;
	modalDetalhe:boolean = false;
	form: FormGroup;
	folhapagamento_funcionario_id:number = null;
	modalTitle:string;
	submitted: boolean = false;
	loading: boolean = false;
	modalReference: NgbModalRef; 
	funcionariosSelect: Funcionario[];  
	verbasSelect = new Array();
	grausParentesco:[{}];
	disabled:boolean = false;
	verbas: FormArray;
	salario:string = null;
	detail:boolean = false;
	create:boolean = false;
	edit:boolean = false;
	delete:boolean = false;
	constructor(private pessoaService: PessoaService, private rhService: RHService, private preCadastroService:PreCadastroService,private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute,private modalService: NgbModal){
        this.pessoaService.getOptions('pessoa/funcionario')
        .subscribe( (success) => {
			this.funcionariosSelect = success.dados;
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});

		this.preCadastroService.getOptions('verba')
        .subscribe( (success) => {
			this.verbasSelect = success.dados;
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
		this.form = this.formBuilder.group({
			folhadepagamento_id: this.formBuilder.control(this.activatedRoute.snapshot.params['id']),
			funcionario_id: this.formBuilder.control('',[Validators.required]),
			funcionario_nome:  this.formBuilder.control(''),
			total_provento: this.formBuilder.control(''),
			total_desconto: this.formBuilder.control(''),
			valor_liquido: this.formBuilder.control(''),
			verba: this.formBuilder.control(''),
			verbas: this.formBuilder.array([]),
		})

		
	}

	ngOnInit(){ 
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(t.permissao.rh_folha_pagamento.includes("CONSULTAR"))
			this.detail = true;
		if(t.permissao.rh_folha_pagamento.includes("CRIAR"))
			this.create = true;
		if(t.permissao.rh_folha_pagamento.includes("EDITAR"))
			this.edit = true;
		if(t.permissao.rh_folha_pagamento.includes("EXCLUIR"))
			this.delete = true;
	}

	getFuncionarioSalario(event){
		this.salario = null;
		this.pessoaService.getPessoaById('pessoa/funcionario',event.id).subscribe((success) => {
			if(success.dados.cargo[0].salario)
			this.salario = success.dados.cargo[0].salario
		},(error) => {
			this.swal.text = 'Erro ao capturar salário do funcionário. Tente novamente.';
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		})
	}
	
	initVerbas(nome_verba = '',tipo = ''): FormGroup {
		let valor = '';
		if(this.form.value.verba == 2){
			valor = this.salario;
		}
		return this.formBuilder.group({
		  id: this.formBuilder.control(this.form.value.verba),
		  nome: this.formBuilder.control(nome_verba),
		  tipo: this.formBuilder.control(tipo),
		  referencia: this.formBuilder.control({value: '', disabled: this.disabled},[Validators.required]),
		  valor: this.formBuilder.control({value: valor, disabled: this.disabled},[Validators.required]),
		});
	}

	addVerba(){
		this.preCadastroService.getById('verba',this.form.value.verba).subscribe((success) => {
			this.verbas = this.form.get('verbas') as FormArray;
			this.verbas.push(this.initVerbas(success.dados.nome,success.dados.tipo));	
		}, (error) => {
			
		})
	}
	

	get formVerbas(){ 
		return <FormArray>this.form.get('verbas'); 
	}

	openModal(modal,id:number = null,detalhe:boolean = false) {
		this.modalDetalhe = detalhe;

		this.verbas = this.form.get('verbas') as FormArray;
		if(this.verbas.length > 0){
			for(let i=0;i <= (this.verbas.length-1);i++)
			this.verbas.removeAt(i);
		}

		//Se id == null então é cadastro.
		if(!id){
			this.folhapagamento_funcionario_id = null;
			this.modalTitle = "Inserir Funcionário";
			this.form.patchValue({
				funcionario_id: '',
				funcionario_nome: '',
				total_provento: '',
				total_desconto: '',
				valor_liquido: '',
				verba: '',
				verbas: [],
			});
			this.verbas.removeAt(0);
			this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
			return;
		}
		
		//Senão é edição.
		this.folhapagamento_funcionario_id = id;
		this.modalTitle = "Editar Funcionário";
		this.rhService.getFolhaDePagamentoFuncionarioById(id).subscribe((success) => {
			this.salario = success.dados.salario_base;
			this.form.patchValue({
				folhadepagamento_id: success.dados.folhapagamento_id,
				funcionario_id: success.dados.funcionario_id,
				funcionario_nome: success.dados.funcionario_nome,
				total_provento: success.dados.total_provento,
				total_desconto: success.dados.total_desconto,
				valor_liquido: success.dados.valor_liquido,
				verba: '',
				verbas: [],
			});	
			
			if(success.dados.verba.length > 0){
				this.verbas = this.form.get('verbas') as FormArray;
				this.verbas.removeAt(0);
				for(let i=0;i <= (success.dados.verba.length-1);i++){
					this.verbas.push(this.initVerbas());
					this.verbas.at(i).patchValue({
						id: success.dados.verba[i].folhapagamento_verba_id,
						nome: success.dados.verba[i].nome,
						tipo: success.dados.verba[i].tipo,
						referencia: success.dados.verba[i].referencia,
						valor: success.dados.verba[i].valor
					})
				}
			}
			this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		})
	}

	onSubmit(){
		this.submitted = true;
        if(!this.form.valid)
		return;
		this.modalReference.close();

		//Enviando as verbas separadamente para criar formulário
		let verbas = this.form.get('verbas') as FormArray;
		if(!this.folhapagamento_funcionario_id){
			this.rhService.addFolhaDePagamentoFuncionario(this.form)
			.subscribe( (success) => {

				this.rhService.addFolhaDePagamentoFuncionarioVerba(success.dados.id,verbas).subscribe( (success) => {
					this.swal.text = "Funcionário inserido com sucesso!";
					this.swal.type = "success";
					this.swal.title = "Pronto!";
					this.swal.show();
					this.submitted = false;
				},
				(error) => {
					this.swal.text = "Funcionário inserido com sucesso, porém houve um erro ao inserir as verbas. Edite a folha deste funcionário e preencha novamente!";
					this.swal.type = "error";
					this.swal.title = "Erro!";
					this.swal.show();
					this.submitted = false;
				})
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
			return;
		}
		this.rhService.addFolhaDePagamentoFuncionarioVerba(this.folhapagamento_funcionario_id,verbas).subscribe( (success) => {
			this.swal.text = "Folha de pagamento do funcionário atualizada com sucesso!";
			this.swal.type = "success";
			this.swal.title = "Pronto!";
			this.swal.show();
			this.submitted = false;
		},
		(error) => {
			this.swal.text = "Erro ao atualizar as verbas! Tente novamente ou entre em contato com o suporte.";
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		})
	}

	askDelete(id:number){
		this.folhapagamento_funcionario_id = id;
		this.swalDelete.show();
	}

	confirmDelete(){
		this.rhService.delete('folha-pagamento/funcionario',this.folhapagamento_funcionario_id).subscribe( 
		(success) => {
			this.swal.text = "Funcionário removido com sucesso!";
			this.swal.type = "success";
			this.swal.title = "Pronto!";
			this.swal.show();
			this.submitted = false;
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}

	onCloseModal(){
		if(this.swal.type == "success"){
			this.rhService.getById('folha-pagamento',this.activatedRoute.snapshot.params['id']).subscribe((success)=>{
				this.funcionarios = success.dados.funcionario;
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
		}
	}
}