import { DataService } from '../../../_services/data.service';
import { Movimento } from './../../movimento/movimento';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SYSCONF_LIMIT, SYSCONF_SIGLA } from '../../../../app.api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FinanceiroService } from '../../financeiro.service';
import { RelatorioFinanceiroService } from '../relatorioFinanceiro.service';
import { PreCadastroService } from 'src/app/pre-cadastro/pre-cadastro.service';
import { PessoaService } from '../../../pessoa/pessoa.service';
import { isArray } from 'util';
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-relatorio-conciliacao',
	templateUrl: './relatorioConciliacao.component.html',
	styleUrls: ['./relatorioConciliacao.component.scss']
})

export class RelatorioConciliacaoComponent implements OnInit{
	@ViewChild('Swal') public swal: SwalComponent;
	@ViewChild('SwalDelete') public swalDelete:SwalComponent; 
	movimento: Movimento[];
	movimento_id = null;
	public message:string = '';
	detail:boolean = false;
	params = null;
	page = 1;
	totalPages:number = 1;
	recordsPerPage:number = SYSCONF_LIMIT;
	buscaForm: FormGroup;
	formConcluir: FormGroup;
	sigla = SYSCONF_SIGLA;
	pessoasSelect = new Array();
	contasBancariasSelect = new Array();
	contaBancariaSelecionada = new Array();
	tiposPessoasSelect = new Array();
	checkMovimentos = new FormData();
	periodoTexto: string;
	total_despesa: number;
	total_receita: number;
	saldo_periodo: number;
	saldo_periodo_positivo:boolean = false;
	saldo_anterior: number;
	saldo_final: number;
	saldo_anterior_positivo:boolean = false;
	saldo_final_positivo:boolean = false;
	saldo: number;
	submitted: boolean = false;
	loading: boolean = false;
	modalReference: NgbModalRef; 

	
	constructor(private activatedRoute: ActivatedRoute,  private router: Router, private relatorioFinanceiroService: RelatorioFinanceiroService, private financeiroService: FinanceiroService, private modalService: NgbModal, private formBuilder: FormBuilder, private preCadastroService: PreCadastroService,  private dt: DataService){
		this.buscaForm = this.formBuilder.group({
			de_dt_conciliacao: this.formBuilder.control(null),
			ate_dt_conciliacao: this.formBuilder.control(null),
			conta_bancaria_id: this.formBuilder.control(''),
		});
		
		this.preCadastroService.getOptions('financeiro/conta-bancaria').subscribe((success) => {
			this.contasBancariasSelect = success.dados;
			this.contasBancariasSelect.map((row) => { row.labelSelect = `${row.banco} - Ag.: ${row.num_agencia}-${row.dv_agencia} - ${row.tipo}: ${row.num_conta}-${row.dv_conta}`; return row; });
			
			
			this.activatedRoute.params.subscribe(params => {
				
				this.buscaForm.patchValue({
					de_dt_conciliacao: this.dt.formataDataBr(params['de_dt_conciliacao']),				
					ate_dt_conciliacao: this.dt.formataDataBr(params['ate_dt_conciliacao']),
					conta_bancaria_id: params['conta_bancaria_id'] != undefined ? params['conta_bancaria_id'] : ''
				})
				if(params.conta_bancaria_id && params.conta_bancaria_id.length > 0) {
					let contaBancariaSelecionadaIndex = this.contasBancariasSelect.findIndex(item => item.id == params.conta_bancaria_id);
					let contaBancariaSelecionadaObj = this.contasBancariasSelect[contaBancariaSelecionadaIndex];
					this.contaBancariaSelecionada = contaBancariaSelecionadaObj.labelSelect;
				}
			});

		},(error) => {})
		
		

	}

	ngOnInit(){ 
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.financeiro_relatorio_conciliacao.includes("CONSULTAR")){
			this.router.navigate(['/'+SYSCONF_SIGLA]);
			return;
		}

		if(t.permissao.financeiro_movimento.includes("CONSULTAR"))
			this.detail = true;

		

		this.activatedRoute.params.subscribe(params => {

			params = Object.assign({ page: 1 }, params);

			let today = new Date();
			let dd = today.getDate();
			let mm = today.getMonth()+1;
			let yyyy = today.getFullYear();

			let inicio:any;
			let fim:any;

			if(params.de_dt_conciliacao != undefined && params.de_dt_conciliacao != 'null') {
				inicio = params.de_dt_conciliacao;
			}else{
				inicio =  '1/' + mm + '/' + yyyy;
			}

			if(params.ate_dt_conciliacao != undefined && params.ate_dt_conciliacao != 'null') {
				fim = params.ate_dt_conciliacao;
			}else{
				let dtFim = new Date(yyyy, mm, 0);
				fim = dtFim.getDate() + '/' + mm + '/' + yyyy;
			}

			params = Object.assign({de_dt_conciliacao: inicio, ate_dt_conciliacao: fim}, params);

			this.periodoTexto = this.getPeriodoTexto(params.de_dt_conciliacao, params.ate_dt_conciliacao);

			
			this.listarDados(params);
			this.totais(params);

		});

		
	}

	doCheck(event){
		let name = event.target.name;
		let value = event.target.value;
		if(event.target.checked){
			this.checkMovimentos.append(name,value);
		}else{
			let tmp = this.checkMovimentos.getAll(name); 
			
			const index: number = tmp.indexOf(value);
			if (index !== -1) {
				tmp.splice(index, 1);
			} 
			this.checkMovimentos.delete(name);
			tmp.forEach((key : any) => {
				this.checkMovimentos.append(name,key);
			})
		}
	}


	getPeriodoTexto(inicio, fim) {
		let dtInicio:any = this.dt.formataDataBr(inicio);
		let dtFim:any = this.dt.formataDataBr(fim);
		let mesInicio = this.dt.mesExtenso(dtInicio.month);
		let anoInicio = dtInicio.year;

		
		let periodo = mesInicio + '/' + anoInicio;
		if(dtInicio != null && dtFim != null && dtInicio.month != dtFim.month) {
			let mesFim = this.dt.mesExtenso(dtFim.month);
			let anoFim = dtFim.year;

			periodo += ' à ' + mesFim + '/' + anoFim;
		}

		return periodo;
	}

	listarDados(params) {
		this.message = "";
		this.financeiroService.list('movimento',params, 10000)
		.subscribe(
			(movimento) => {
				this.total_receita = parseFloat(movimento.dados.total_receita);
				this.total_despesa = parseFloat(movimento.dados.total_despesa);
				this.saldo_periodo = this.total_receita - this.total_despesa;
				this.saldo_final = this.saldo_anterior + this.saldo_periodo;

				// if(this.saldo_periodo > 0) {
				// 	this.saldo_periodo_positivo = true;
				// }

				// if(this.saldo_final > 0) {
				// 	this.saldo_final_positivo = true;
				// }

				// if(this.saldo_anterior > 0) {
				// 	this.saldo_anterior_positivo = true;
				// }
				
				// this.saldo_anterior = Math.abs(this.saldo_anterior);
				// this.saldo_final = Math.abs(this.saldo_final);
				// this.saldo_periodo = Math.abs(this.saldo_periodo);
				
				this.saldo = parseFloat(movimento.dados.saldo);
				this.movimento = movimento.dados.registros;

				if(typeof params['id']){
					
					this.buscaForm.patchValue({

						de_dt_conciliacao: params['de_dt_conciliacao'] != undefined ? this.dt.formataDataBr(params['de_dt_conciliacao']) : null,				
						ate_dt_conciliacao: params['ate_dt_conciliacao'] != undefined ? this.dt.formataDataBr(params['ate_dt_conciliacao']) : null,
						conta_bancaria_id: params['conta_bancaria_id'] != undefined ? params['conta_bancaria_id'] : ''
					})
				}
			},
			(error) => {
				this.message = error.error.message;
			});
	}

	totais(params) {
		this.relatorioFinanceiroService.get('conciliacao',params)
		.subscribe(
			(conciliacao) => {
				this.saldo_anterior = parseFloat(conciliacao.dados.saldo_anterior);
				this.saldo_final = parseFloat(conciliacao.dados.saldo_final);
				this.saldo_periodo = parseFloat(conciliacao.dados.saldo_periodo);
			},
			(error) => {});
	}

	onSubmit(){
		let de_dt_conciliacao = this.dt.convertDataObject(this.buscaForm.value,'de_dt_conciliacao');
		let ate_dt_conciliacao = this.dt.convertDataObject(this.buscaForm.value,'ate_dt_conciliacao');
		let form = this.formBuilder.group({
			de_dt_conciliacao: this.formBuilder.control(de_dt_conciliacao),
			ate_dt_conciliacao: this.formBuilder.control(ate_dt_conciliacao),
			conta_bancaria_id: this.formBuilder.control(this.buscaForm.value.conta_bancaria_id),
		});
		//console.log(form.value);
		this.router.navigate(['/'+SYSCONF_SIGLA+'/relatorio-financeiro/conciliacao/',form.value]);
	}
	
	onCloseModal(){
		let params = new Array();
		params['page'] = 1;
		if(this.swal.type == "success"){
			this.financeiroService.list('movimento',params)
			.subscribe(
				(movimento) => {
					this.movimento = movimento.dados.registros;
					this.totalPages = movimento.paging.rows;
					this.page = params['page'];
					this.movimento_id = null;
				},
				(error) => {
					this.message = error.error.message;
				})
		}
	}


}
