import { ViewEncapsulation, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Nivel } from '../nivel';
import { NivelService } from './../nivel.service';
import { URLSearchParams } from '@angular/http';
import { switchMap } from 'rxjs/operators';
import { PARAMETERS } from '@angular/core/src/util/decorators';
import { SYSCONF_SIGLA } from 'src/app.api';

declare var $: any;

@Component({
	templateUrl: './nivelList.component.html',
	encapsulation: ViewEncapsulation.None,
  	styleUrls: [
  		'./nivelList.component.scss'
  	]
})


export class NivelListComponent implements OnInit{
	loading:boolean = false;
	niveis: Nivel[]
	public message:string = '';
	edit:boolean = false;
	detail:boolean = false;
	create:boolean = false;
	params = null;
	sigla = SYSCONF_SIGLA;
	constructor(private route: ActivatedRoute, private nivelService: NivelService){

	}

	ngAfterViewInit(){
		 
	}

	ngOnInit(){

		let t = JSON.parse(localStorage.getItem('CONFLogin'));

		if(t.permissao.nivel.includes("EDITAR"))
			this.edit = true;
		if(t.permissao.nivel.includes("CONSULTAR"))
			this.detail = true;
		if(t.permissao.nivel.includes("CRIAR"))
			this.create = true;

		this.route.params.subscribe(params => {
			this.paramsChange(params);
		});


	}

	 paramsChange(params) {
		this.message = "";
		this.nivelService.list(params)
		.subscribe(
			(nivel) => {
				this.niveis = nivel.dados;
			},
			(error) => {
				this.message = error.error.message;
			})
    }
}
