import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SYSCONF_API, SYSCONF_APP_ID, SYSCONF_PUBKEY, SYSCONF_CREDENTIALS, SYSCONF_SIGLA } from './../../app.api';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Login } from './login';
import { Location } from '@angular/common';
declare var JSEncrypt: any;
@Injectable()
export class LoginService{

    login: Login;
    
    headers:any = {'App-Id': SYSCONF_APP_ID,'Content-Type':'application/json','App-Sigla': SYSCONF_SIGLA};
    date = new Date();
    zero:string = '';
    zeroSeconds:string = '';
    zeroHours:string = '';
    zeroDate:string = '';
    zeroMilliseconds:string = '';
    SYSCONF_DATE:any;
    Milliseconds;
    constructor(private http: HttpClient, private router: Router, private _location: Location){
        console.log(SYSCONF_SIGLA);
        if((this.date.getMonth()+1) < 10)
            this.zero = '0';
        if(this.date.getSeconds() < 10)
            this.zeroSeconds = '0';
        if(this.date.getHours() < 10)
            this.zeroHours = '0';
        if(this.date.getDate() < 10)
            this.zeroDate = '0';
        if(this.date.getMilliseconds() < 10)
            this.zeroMilliseconds = '0';
        
        this.Milliseconds = this.date.getMilliseconds();
        if(this.Milliseconds > 99){
            this.Milliseconds = 59;
        }
        
        this.SYSCONF_DATE = this.date.getFullYear()+'-'+this.zero+(this.date.getMonth()+1)+'-'+this.zeroDate+this.date.getDate()+' '+this.zeroHours+this.date.getHours()+':'+this.zeroSeconds+this.date.getSeconds()+':'+this.zeroMilliseconds+this.Milliseconds;

    }

    recuperarSenha(form: any) {
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        let crypt = new JSEncrypt;
        crypt.setPublicKey(SYSCONF_PUBKEY);
        let cryptedPassword = crypt.encrypt(form.senha);
        let cryptedConfirmPassword = crypt.encrypt(form.confirmaSenha);
        formulario.append('hash',form.hash)
        formulario.append('senha',cryptedPassword)
        formulario.append('senha2',cryptedConfirmPassword)
        return this.http.post(`${SYSCONF_API}/auth/reset-password`,formulario,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(tap(login => login ))
    }

    esqueciSenha(email: string) {
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario.append('email',email)
        return this.http.post(`${SYSCONF_API}/auth/forgot-password`,formulario,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(tap(login => login ))
    }

    auth(login: string, senha:string):Observable<any>{
        let crypt = new JSEncrypt;
        crypt.setPublicKey(SYSCONF_PUBKEY);
        let cryptedPassword = crypt.encrypt(senha);
        return this.http.post(`${SYSCONF_API}/auth`,{'email': login,'senha': cryptedPassword},{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(tap(login => this.login = login.dados ))
    }

    setToken(token){
        let CONFLogin = JSON.parse(localStorage.getItem('CONFLogin'));
        CONFLogin.token.key = token.key;
        CONFLogin.token.dt_expiracao = token.dt_expiracao;
        localStorage.setItem('CONFLogin',JSON.stringify(CONFLogin));
    }

    getToken(){
        let CONFLogin = JSON.parse(localStorage.getItem('CONFLogin'));
        let key = null
        if(CONFLogin)
            key = CONFLogin.token.key;
        return key;
    }

    updateToken():Observable<any>{
        let cob = JSON.parse(localStorage.getItem('CONFLogin'));
        this.headers = {'App-Id': SYSCONF_APP_ID,'Content-Type':'application/json','App-Token': cob.token.key,'App-Sigla': SYSCONF_SIGLA}
    
        return this.http.get(`${SYSCONF_API}/auth/update-token`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(tap(login => this.login = login.dados ))
    }

    isLoggedIn():boolean{
        let CONFLogin = JSON.parse(localStorage.getItem('CONFLogin'));
        if(!CONFLogin || !CONFLogin.token.key || CONFLogin.sigla !== SYSCONF_SIGLA)
        return;
        
        return true;
    }

    handleLogin(){
        this.router.navigate(['/'+SYSCONF_SIGLA+'/login']);
    }

    logout(){
        localStorage.removeItem('CONFLogin');
        this.router.navigate(['/'+SYSCONF_SIGLA+'/login']);
    }

    getPermissao(modulo,secao):boolean{  
        if(localStorage.getItem('CONFLogin') == null)
			return false;

        let permissao = JSON.parse(localStorage.getItem('CONFLogin'));
        //let nivel = JSON.parse(localStorage.getItem('CONFLogin')).nivel;
        
        if(permissao.isAdmin === '1')
          return true;
        
        permissao = permissao.permissao;

        let tmp = false;
        if(!permissao[modulo])
            return tmp;

        for(let t of permissao[modulo]){
            if(t.toUpperCase() == secao.toUpperCase()){
                tmp = true;
            }
        }
        return tmp;
  }
    
    ngOnInit(){}
}