import { Component, OnInit, ViewChild } from '@angular/core';
import { SYSCONF_SIGLA } from 'src/app.api';
@Component({
	selector: 'app-perfil-edit',
  	templateUrl: './perfilEdit.component.html',
  	styleUrls: ['./perfilEdit.component.scss']
})

export class PerfilEditComponent implements OnInit{
	sigla = SYSCONF_SIGLA;
	constructor(){}
	ngOnInit(){}
	
}