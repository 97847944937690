import { Component, ViewChild, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { PessoaService } from '../../../pessoa/pessoa.service';
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';
import { PreCadastroService } from 'src/app/pre-cadastro/pre-cadastro.service';
import { Responsavel } from 'src/app/pessoa/responsavel/responsavel';

declare var $: any;
@Component({
	selector: 'app-form-responsavel',
  	templateUrl: './form-responsavel.component.html',
  	styleUrls: ['./form-responsavel.component.scss']
})
export class FormResponsavelComponent{
	@ViewChild('Swal') public swal:SwalComponent;
	@ViewChild('SwalDelete') public swalDelete:SwalComponent; 
	@Input() responsaveis: Responsavel[];
	@Input() pessoa_id: number;
	@Input() acao:string;
	@Input() detalhe:boolean;
	form: FormGroup;
	responsavel_id:number = null;
	modalTitle:string;
	submitted: boolean = false;
	loading: boolean = false;
	modalReference: NgbModalRef; 
	// responsaveis: Responsavel[];  
	grausParentesco:[{}];
    
	constructor(private pessoaService: PessoaService, private preCadastroService: PreCadastroService, private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute,private modalService: NgbModal){


    }

	// onSubmit(){
	// 	this.submitted = true;
    //     if(!this.form.valid)
	// 	return;
	// 	this.modalReference.close();
	// 	if(!this.responsavel_id){
	// 		this.pessoaService.addResponsavel(this.form)
	// 		.subscribe( (success) => {
	// 			this.swal.text = "Responsavel cadastrado com sucesso!";
	// 			this.swal.type = "success";
	// 			this.swal.title = "Pronto!";
	// 			this.swal.show();
	// 			this.submitted = false;
	// 		},
	// 		(error) => {
	// 			this.swal.text = error.error.message;
	// 			this.swal.type = "error";
	// 			this.swal.title = "Erro!";
	// 			this.swal.show();
	// 			this.submitted = false;
	// 		});
	// 		return;
	// 	}

	// 	this.pessoaService.editResponsavel(this.responsavel_id,this.form)
	// 		.subscribe( (success) => {
	// 			this.swal.text = "Responsavel atualizado com sucesso!";
	// 			this.swal.type = "success";
	// 			this.swal.title = "Pronto!";
	// 			this.swal.show();
	// 			this.submitted = false;
	// 		},
	// 		(error) => {
	// 			this.swal.text = error.error.message;
	// 			this.swal.type = "error";
	// 			this.swal.title = "Erro!";
	// 			this.swal.show();
	// 			this.submitted = false;
	// 		});

        
	// }

	// openModal(modal,id:number = null) {

	// 	//Se id == null então é cadastro.
	// 	if(!id){
	// 		this.responsavel_id = null;
	// 		this.modalTitle = "Cadastrar Responsavel";
	// 		this.form.patchValue({
	// 			grau_parentesco_id: '',
	// 			atleta_id:  ''
	// 		});
	// 		this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
	// 		return;
	// 	}
		
	// 	//Senão é edição.
	// 	this.responsavel_id = id;
	// 	this.modalTitle = "Editar Responsavel";
	// 	this.pessoaService.getResponsavelById(id).subscribe((success) => {
	// 		this.form.patchValue({
	// 			grau_parentesco_id: success.dados.grau_parentesco_id,
	// 			atleta_id: success.dados.atleta_id
	// 		});
	// 		this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
	// 	},
	// 	(error) => {
	// 		this.swal.text = error.error.message;
	// 		this.swal.type = "error";
	// 		this.swal.title = "Erro!";
	// 		this.swal.show();
	// 	})
	// }

	askDelete(id:number){
		this.responsavel_id = id;
		this.swalDelete.show();
	}

	// confirmDelete(){
	// 	this.pessoaService.delResponsavel(this.responsavel_id).subscribe( 
	// 	(success) => {
	// 		this.swal.text = "Responsavel deletado com sucesso!";
	// 		this.swal.type = "success";
	// 		this.swal.title = "Pronto!";
	// 		this.swal.show();
	// 		this.submitted = false;
	// 	},
	// 	(error) => {
	// 		this.swal.text = error.error.message;
	// 		this.swal.type = "error";
	// 		this.swal.title = "Erro!";
	// 		this.swal.show();
	// 		this.submitted = false;
	// 	});
	// }

	onCloseModal(){
		if(this.swal.type == "success"){
			this.pessoaService.getPessoaById('pessoa/responsavel',this.activatedRoute.snapshot.params['id']).subscribe((success)=>{
				this.responsaveis = success.dados.responsavel;
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
		}
	}
}