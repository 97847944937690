import { LoginService } from 'src/app/login/login.service';
import { PessoaService } from '../../pessoa.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { Federacao } from '../../federacao/federacao';
import { Arbitro } from '../arbitro';
import { PreCadastroService } from 'src/app/pre-cadastro/pre-cadastro.service';
import { SYSCONF_SIGLA } from 'src/app.api';
@Component({
	selector: 'app-arbitro-edit',
  	templateUrl: './arbitroEdit.component.html',
  	styleUrls: ['./arbitroEdit.component.scss']
})

export class ArbitroEditComponent implements OnInit{
	
	@ViewChild('Swal') public swal:SwalComponent; 
	public message:string = '';
	editForm: FormGroup;
	arbitro: Arbitro;
	arbitro_id:number;
	genero;
	thumb:string = '';
	pessoa_id:number;
	enderecos:object = new Array();
	telefones:object = new Array();
	idiomas:object = new Array();
	contasBancarias:object = new Array();
	federacoes: Federacao[];
	graduacoes: [{}];
	modalidades:[{}];
	estadoCivil:[{}];
	sigla = SYSCONF_SIGLA;
	anexos: any;
	acesso = new Array();
	constructor(public loginService:LoginService, private pessoaService: PessoaService, private activatedRoute: ActivatedRoute ,private formBuilder: FormBuilder, private preCadastroService: PreCadastroService){
		this.preCadastroService.get('pessoa/genero')
        .subscribe( (success) => {
			this.genero = success.dados;
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});

		this.pessoaService.getOptions('pessoa/federacao')
        .subscribe( (success) => {
			this.federacoes = success.dados;
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});

		this.preCadastroService.get('graduacao')
        .subscribe( (success) => {
			this.graduacoes = success.dados;
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});

		this.preCadastroService.get('modalidade')
        .subscribe( (success) => {
			this.modalidades = success.dados;
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});

		this.preCadastroService.get('pessoa/estado-civil')
        .subscribe( (success) => {
			this.estadoCivil = success.dados;
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});

		this.editForm = this.formBuilder.group({
			id: this.formBuilder.control(0,[Validators.required]),
			estrangeiro: this.formBuilder.control('N',[Validators.required]),
			passaporte: this.formBuilder.control(''),
			passaporte_dt_validade: this.formBuilder.control(''),
			cpf: this.formBuilder.control('',[Validators.required]),
			dt_cadastro: this.formBuilder.control(''),
			dt_atualizacao: this.formBuilder.control(''),
			dt_nascimento: this.formBuilder.control('',[Validators.required]),
			primeiro_nome: this.formBuilder.control('',[Validators.required]),
			ultimo_nome: this.formBuilder.control('',[Validators.required]),
			nome_completo: this.formBuilder.control(''),
			apelido: this.formBuilder.control(''),
			email: this.formBuilder.control('',[Validators.required, Validators.email]),
			nacionalidade: this.formBuilder.control('',[Validators.required]),
			naturalidade: this.formBuilder.control('',[Validators.required]),
			status: this.formBuilder.control('',[Validators.required]),
			genero_id: this.formBuilder.control(1,[Validators.required]),
			rg: this.formBuilder.control(''),
			rg_dt_emissao: this.formBuilder.control(''),
			rg_emissor: this.formBuilder.control(''),
			rg_uf: this.formBuilder.control(''),
			ano_inicio_carreira: this.formBuilder.control('',[Validators.required]),
			dt_exame: this.formBuilder.control(''),
			dt_registro: this.formBuilder.control('',[Validators.required]),
			dt_graduacao: this.formBuilder.control(''),
			graduacao_id: this.formBuilder.control(''),
			graduacao: this.formBuilder.control(''),
			federado: this.formBuilder.control('',[Validators.required]),
			federacao_id: this.formBuilder.control(''),
			federacao: this.formBuilder.control(''),
			observacao: this.formBuilder.control(''),
			website: this.formBuilder.control(''),
			modalidade: new FormArray([]),
			estado_civil_id: this.formBuilder.control('',[Validators.required]),
			tamanho_camisa: this.formBuilder.control(''),
			tamanho_agasalho: this.formBuilder.control(''),
			tamanho_calca: this.formBuilder.control(''),
			tamanho_calcado: this.formBuilder.control(''),
			tamanho_chinelo: this.formBuilder.control(''),

			pis: this.formBuilder.control(''),
			grau_instrucao_id: this.formBuilder.control('')
		});	
		this.arbitro_id = this.activatedRoute.snapshot.params['id'];
		this.pessoaService.getPessoaById('pessoa/arbitro',this.arbitro_id).subscribe(
			(arbitro) => {
			this.arbitro = arbitro.dados;
			this.pessoa_id = this.arbitro.pessoa_id;
			this.acesso = this.arbitro.acesso;
			if(this.arbitro.foto){
				this.thumb = this.arbitro.foto.thumb;
			}
			this.pessoaService.getPessoaById('pessoa',this.pessoa_id).subscribe(
				(success) =>{
					this.enderecos = success.dados.endereco;
					this.telefones = success.dados.telefone;
					this.idiomas = success.dados.idioma;
					this.contasBancarias = success.dados.conta_bancaria;
					this.anexos = success.dados.anexo;
				},
				(error) => {
					this.message = error.error.message;
				})
			
			this.editForm.patchValue({
				id: this.arbitro.id,
				estrangeiro: this.arbitro.estrangeiro,
				passaporte: this.arbitro.passaporte,
				passaporte_dt_validade: this.arbitro.passaporte_dt_validade_br,
				cpf: this.arbitro.cpf,
				genero_id: this.arbitro.genero_id,
				dt_cadastro: this.arbitro.dt_cadastro_br,
				dt_atualizacao: this.arbitro.dt_atualizacao_br,
				primeiro_nome: this.arbitro.primeiro_nome,
				ultimo_nome: this.arbitro.ultimo_nome,
				nome_completo: this.arbitro.nome_completo,
				apelido: this.arbitro.apelido,
				email: this.arbitro.email,
				rg: this.arbitro.rg,
				rg_uf: this.arbitro.rg_uf,
				rg_dt_emissao: this.arbitro.rg_dt_emissao_br,
				rg_emissor: this.arbitro.rg_emissor,
				nacionalidade: this.arbitro.nacionalidade,
				naturalidade: this.arbitro.naturalidade,
				foto: this.arbitro.foto,
				observacao: this.arbitro.observacao,
				dt_nascimento: this.arbitro.dt_nascimento_br,
				status: this.arbitro.status,
				dt_exame: this.arbitro.dt_exame_br,
				ano_inicio_carreira: this.arbitro.ano_inicio_carreira,
				dt_registro: this.arbitro.dt_registro_br,
				dt_graduacao: this.arbitro.dt_graduacao_br,
				graduacao_id: this.arbitro.graduacao_id,
				graduacao: this.arbitro.graduacao,
				federado: this.arbitro.federado == 'S' ? 'S' : 'N',
				federacao_id: this.arbitro.federacao_id,
				federacao: this.arbitro.federacao,
				website: this.arbitro.website,
				estado_civil_id: this.arbitro.estado_civil_id,
				tamanho_camisa: this.arbitro.tamanho_camisa,
				tamanho_agasalho: this.arbitro.tamanho_agasalho,
				tamanho_calca: this.arbitro.tamanho_calca,
				tamanho_calcado: this.arbitro.tamanho_calcado,
				tamanho_chinelo: this.arbitro.tamanho_chinelo,

				pis: this.arbitro.pis,
				grau_instrucao_id: this.arbitro.grau_instrucao_id
			});
			this.checkModalidade();
		},
		(error) => {
			this.message = error.error.message;
		})
	}

	/* Incluir no editForm os ids das modalidades selecionadas */
	checkModalidade() {
		const formArray: FormArray = this.editForm.get('modalidade') as FormArray;
		if(this.arbitro.modalidade != null){	
			this.arbitro.modalidade.forEach((key : any) => {
				formArray.push(new FormControl(key.id));
			})
		}
	}

	ngOnInit(){
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.pessoa_arbitro.includes("EDITAR"))
			this.message = 'Você não possui permissão para executar esta ação'
	}
	
	
}