import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { PreCadastroService } from 'src/app/pre-cadastro/pre-cadastro.service';
import { SYSCONF_SIGLA } from 'src/app.api';
@Component({
	selector: 'app-funcionario-search',
  	templateUrl: './funcionarioSearch.component.html',
  	styleUrls: ['./funcionarioSearch.component.scss']
})

export class FuncionarioSearchComponent implements OnInit{
	
	@ViewChild('Swal') public swal:SwalComponent; 
	public message:string = '';
	searchForm: FormGroup;
	genero;
	estadoCivil = [{}];
	sigla = SYSCONF_SIGLA;
	constructor(private preCadastroService: PreCadastroService, private formBuilder: FormBuilder){
		this.searchForm = this.formBuilder.group({
			estrangeiro: this.formBuilder.control(''),
			passaporte: this.formBuilder.control(''),
			passaporte_dt_validade: this.formBuilder.control(''),
			cpf: this.formBuilder.control(''),
			dt_nascimento: this.formBuilder.control(''),
			primeiro_nome: this.formBuilder.control(''),
			ultimo_nome: this.formBuilder.control(''),
			nome_completo: this.formBuilder.control(''),
			apelido: this.formBuilder.control(''),
			email: this.formBuilder.control(''),
			nacionalidade: this.formBuilder.control(''),
			naturalidade: this.formBuilder.control(''),
			status: this.formBuilder.control(''),
			genero_id: this.formBuilder.control(''),
			rg: this.formBuilder.control(''),
			rg_dt_emissao: this.formBuilder.control(''),
			rg_emissor: this.formBuilder.control(''),
			rg_uf: this.formBuilder.control(''),
			dt_admissao: this.formBuilder.control(null),
			dt_desligamento: this.formBuilder.control(null),
			observacao: this.formBuilder.control(''),
			cargo: this.formBuilder.control(''),
			estado_civil_id: this.formBuilder.control(''),
			tipo_sanguineo: this.formBuilder.control(''),
			tamanho_camisa: this.formBuilder.control(''),
			tamanho_agasalho: this.formBuilder.control(''),
			tamanho_calca: this.formBuilder.control(''),
			tamanho_calcado: this.formBuilder.control(''),
			tamanho_chinelo: this.formBuilder.control(''),

			pis: this.formBuilder.control(''),
			grau_instrucao_id: this.formBuilder.control('')
		});	

		this.preCadastroService.get('pessoa/genero')
        .subscribe( (success) => {
			this.genero = success.dados;
			
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});

		this.preCadastroService.get('pessoa/estado-civil')
        .subscribe( (success) => {
			this.estadoCivil = success.dados;
			
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});
	}

	ngOnInit(){
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.rh_funcionario.includes("CONSULTAR"))
			this.message = 'Você não possui permissão para executar esta ação'
	}
	
	
}