import { PessoaService } from '../../pessoa.service';
import { Arbitro } from './../arbitro';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { SYSCONF_SIGLA } from 'src/app.api';
@Component({
	selector: 'app-arbitro-detail',
  	templateUrl: './arbitroDetail.component.html',
  	styleUrls: ['./arbitroDetail.component.scss']
})

export class ArbitroDetailComponent implements OnInit{
	
	@ViewChild('Swal') public swal:SwalComponent; 
	public message:string = '';
	detailForm: FormGroup;
	arbitro: Arbitro;
	arbitro_id:number;
	thumb:string = '';
	pessoa_id:number;
	enderecos:object = new Array();
	telefones:object = new Array();
	idiomas:object = new Array();
	contasBancarias:object = new Array();
	sigla = SYSCONF_SIGLA;
	anexos: any;
	acesso = new Array();
	constructor(private pessoaService: PessoaService, private activatedRoute: ActivatedRoute,private formBuilder: FormBuilder){
		this.detailForm = this.formBuilder.group({
			id: this.formBuilder.control(0),
			estrangeiro: this.formBuilder.control('N'),
			passaporte: this.formBuilder.control(''),
			passaporte_dt_validade: this.formBuilder.control(''),
			cpf: this.formBuilder.control(''),
			dt_cadastro: this.formBuilder.control(''),
			dt_atualizacao: this.formBuilder.control(''),
			dt_nascimento: this.formBuilder.control(''),
			primeiro_nome: this.formBuilder.control(''),
			ultimo_nome: this.formBuilder.control(''),
			apelido: this.formBuilder.control(''),
			nome_completo: this.formBuilder.control(''),
			email: this.formBuilder.control(''),
			nacionalidade: this.formBuilder.control(''),
			naturalidade: this.formBuilder.control(''),
			status: this.formBuilder.control('A'),
			genero_id: this.formBuilder.control(''),
			genero: this.formBuilder.control(''),
			rg: this.formBuilder.control(''),
			rg_dt_emissao: this.formBuilder.control(''),
			rg_emissor: this.formBuilder.control(''),
			rg_uf: this.formBuilder.control(''),
			dt_exame: this.formBuilder.control(''),
			ano_inicio_carreira: this.formBuilder.control(''),
			dt_registro: this.formBuilder.control(''),
			dt_graduacao: this.formBuilder.control(''),
			graduacao_id: this.formBuilder.control(''),
			graduacao: this.formBuilder.control(''),
			federacao_id: this.formBuilder.control(''),
			federacao: this.formBuilder.control(''),
			federado: this.formBuilder.control(''),
			observacao: this.formBuilder.control(''),
			website: this.formBuilder.control(''),
			estado_civil: this.formBuilder.control(''),
			estado_civil_id: this.formBuilder.control(''),
			tamanho_camisa: this.formBuilder.control(''),
			tamanho_agasalho: this.formBuilder.control(''),
			tamanho_calca: this.formBuilder.control(''),
			tamanho_calcado: this.formBuilder.control(''),
			tamanho_chinelo: this.formBuilder.control(''),
			modalidade: this.formBuilder.control(''),
			pis: this.formBuilder.control(''),
			grau_instrucao_id: this.formBuilder.control(''),
			grau_instrucao: this.formBuilder.control(''),
		});	
		this.arbitro_id = this.activatedRoute.snapshot.params['id'];
		this.pessoaService.getPessoaById('pessoa/arbitro',this.activatedRoute.snapshot.params['id']).subscribe(
		(arbitro) => {
			this.arbitro = arbitro.dados;
			this.pessoa_id = this.arbitro.pessoa_id;
			this.acesso = this.arbitro.acesso;
			if(this.arbitro.foto){
				this.thumb = this.arbitro.foto.thumb;
			}

			this.pessoaService.getPessoaById('pessoa',this.pessoa_id).subscribe(
				(success) =>{
					this.enderecos = success.dados.endereco;
					this.telefones = success.dados.telefone;
					this.idiomas = success.dados.idioma;
					this.contasBancarias = success.dados.conta_bancaria;
					this.anexos = success.dados.anexo;
				},
				(error) => {
					this.message = error.error.message;
				})


				this.detailForm.patchValue({
					id: this.arbitro.id,
					estrangeiro: this.arbitro.estrangeiro,
					passaporte: this.arbitro.passaporte,
					passaporte_dt_validade: this.arbitro.passaporte_dt_validade_br,
					cpf: this.arbitro.cpf,
					dt_cadastro: this.arbitro.dt_cadastro_br,
					dt_atualizacao: this.arbitro.dt_atualizacao_br,
					genero_id: this.arbitro.genero_id,
					genero: this.arbitro.genero,
					primeiro_nome: this.arbitro.primeiro_nome,
					ultimo_nome: this.arbitro.ultimo_nome,
					nome_completo: this.arbitro.nome_completo,
					apelido: this.arbitro.apelido,
					email: this.arbitro.email,
					rg: this.arbitro.rg,
					rg_uf: this.arbitro.rg_uf,
					rg_dt_emissao: this.arbitro.rg_dt_emissao_br,
					rg_emissor: this.arbitro.rg_emissor,
					nacionalidade: this.arbitro.nacionalidade,
					naturalidade: this.arbitro.naturalidade,
					foto: this.thumb,
					observacao: this.arbitro.observacao,
					dt_nascimento: this.arbitro.dt_nascimento_br,
					status: this.arbitro.status,
					dt_exame: this.arbitro.dt_exame_br,
					ano_inicio_carreira: this.arbitro.ano_inicio_carreira,
					dt_registro: this.arbitro.dt_registro_br,
					dt_graduacao: this.arbitro.dt_graduacao_br,
					graduacao_id: this.arbitro.graduacao_id,
					graduacao: this.arbitro.graduacao,
					federado: this.arbitro.federado == 'S' ? 'S' : 'N',
					federacao_id: this.arbitro.federacao_id,
					federacao: this.arbitro.federacao,
					website: this.arbitro.website,
					estado_civil_id: this.arbitro.estado_civil_id,
					estado_civil: this.arbitro.estado_civil,
					tamanho_camisa: this.arbitro.tamanho_camisa,
					tamanho_agasalho: this.arbitro.tamanho_agasalho,
					tamanho_calca: this.arbitro.tamanho_calca,
					tamanho_calcado: this.arbitro.tamanho_calcado,
					tamanho_chinelo: this.arbitro.tamanho_chinelo,
					pis: this.arbitro.pis,
					grau_instrucao_id: this.arbitro.grau_instrucao_id,
					grau_instrucao: this.arbitro.grau_instrucao
				});
				let modalidade = '';
				this.arbitro.modalidade.forEach( (v:any) => {
					modalidade += v.nome+';';
				});
				this.detailForm.patchValue({
					modalidade: modalidade
				})
			})
		}

	ngOnInit(){
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.pessoa_arbitro.includes("CONSULTAR"))
			this.message = 'Você não possui permissão para executar esta ação'
	}
	
	
}