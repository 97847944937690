import { ProjetoService } from 'src/app/projeto/projeto.service';
import { Component, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';
@Component({
	selector: 'app-form-projeto-instituicao-parceira',
  	templateUrl: './form-projeto-instituicao-parceira.component.html',
  	styleUrls: ['./form-projeto-instituicao-parceira.component.scss']
})
export class FormProjetoInstituicaoParceiraComponent{
	@ViewChild('Swal') public swal:SwalComponent;
	@ViewChild('SwalDelete') public swalDelete:SwalComponent; 
	@Input() projetosInstituicoesParceiras = new Array();
	@Input() detalhe: boolean;
	@Input() pessoa_id: number;
	@Input() acao:string;
	create:boolean;
	edit:boolean;
	detail:boolean;
	delete:boolean;
	modalDetalhe:boolean = false;
	form: FormGroup;
	projeto_instituicao_parceira_id:number = null;
	modalTitle:string;
	submitted: boolean = false;
	loading: boolean = false;
	modalReference: NgbModalRef; 
	constructor(private projetoService: ProjetoService, private formBuilder: FormBuilder, private modalService: NgbModal, private activatedRoute: ActivatedRoute){
        this.form = this.formBuilder.group({
			nome: this.formBuilder.control('',[Validators.required]),
			tp_envolvimento: this.formBuilder.control('',[Validators.required]),
			contrapartida_financeira: this.formBuilder.control('',[Validators.required]),
			observacao: this.formBuilder.control(''),
		})
	}

	ngOnInit(){ 
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(t.permissao.projeto_instituicao_parceira.includes("CONSULTAR"))
			this.detail = true;
		if(t.permissao.projeto_instituicao_parceira.includes("CRIAR"))
			this.create = true;
		if(t.permissao.projeto_instituicao_parceira.includes("EDITAR"))
			this.edit = true;
		if(t.permissao.projeto_instituicao_parceira.includes("EXCLUIR"))
			this.delete = true;
	}
	
	onSubmit(){
		this.submitted = true;
        if(!this.form.valid)
		return;
		this.modalReference.close();
		if(!this.projeto_instituicao_parceira_id){
			this.projetoService.addProjetoInstituicaoParceira(this.activatedRoute.snapshot.params['id'], this.form)
			.subscribe( (success) => {
				this.swal.text = "Instituição parceira cadastrada com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
				this.zeraForm();
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
			return;
		}

		this.projetoService.editProjetoInstituicaoParceira(this.projeto_instituicao_parceira_id,this.form)
			.subscribe( (success) => {
				this.swal.text = "Instituição parceira atualizada com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
				this.zeraForm();
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
				this.zeraForm();
			});
	}
	zeraForm():void{
		this.form.patchValue({
			nome: '',
			tp_envolvimento: '',
			contrapartida_financeira: '',
			observacao: '',
		});
	}

	openModal(modal,id:number = null,detalhe:boolean = false) {
		this.modalDetalhe = detalhe;
		//Se id == null então é cadastro.
		if(!id){
			this.projeto_instituicao_parceira_id = null;
			this.modalTitle = "Cadastrar Instituição Parceira";
			this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
			return;
		}
		
		//Senão é edição.
		this.projeto_instituicao_parceira_id = id;
		this.modalTitle = "Editar Instituição Parceira";
		this.projetoService.getProjetoInstituicaoParceiraById(id).subscribe((success) => {
			this.form.patchValue({
				nome: success.dados.nome,
				tp_envolvimento: success.dados.tp_envolvimento,
				contrapartida_financeira: success.dados.contrapartida_financeira,
				observacao: success.dados.observacao,
			});
			this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		})
	}

	askDelete(id:number){
		this.projeto_instituicao_parceira_id = id;
		this.swalDelete.show();
	}

	confirmDelete(){
		this.projetoService.delProjetoInstituicaoParceira(this.projeto_instituicao_parceira_id).subscribe( 
		(success) => {
			this.swal.text = "Instituição parceira deletada com sucesso!";
			this.swal.type = "success";
			this.swal.title = "Pronto!";
			this.swal.show();
			this.submitted = false;
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}

	onCloseModal(){
		if(this.swal.type == "success"){
			this.projetoService.getProjetoById(this.activatedRoute.snapshot.params['id']).subscribe((success)=>{
				this.projetosInstituicoesParceiras = success.dados.instituicao_parceira;
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
		}
	}
}