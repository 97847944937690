import { Component, OnInit} from '@angular/core';
import { SYSCONF_SIGLA } from 'src/app.api';
@Component({
	selector: 'app-movimento-search',
  	templateUrl: './movimentoSearch.component.html',
  	styleUrls: ['./movimentoSearch.component.scss']
})

export class MovimentoSearchComponent implements OnInit{
	public message:string = '';
	sigla = SYSCONF_SIGLA;
	ngOnInit(){
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.financeiro_movimento.includes("CONSULTAR"))
			this.message = 'Você não possui permissão para executar esta ação'
	}
	
	
}