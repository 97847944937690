import { LoginService } from './../../login/login.service';
import { Component, OnInit } from '@angular/core';
import { SYSCONF_SIGLA } from './../../../app.api';
declare var $:any
@Component({
	selector: 'app-aside-left',
	templateUrl: './aside-left.component.html',
	styleUrls: ['./aside-left.component.scss']
})

export class AsideLeftComponent implements OnInit{
	permissao;
	CONFInfo;
	sigla;
	constructor(private loginService: LoginService){
		this.sigla = SYSCONF_SIGLA;
	}
	ngOnInit(){
		if(localStorage.getItem('CONFLogin') == null)
			return false;
		this.permissao = JSON.parse(localStorage.getItem('CONFLogin')).permissao;
		let t = JSON.parse(localStorage.getItem("CONFLogin"));
		if(t){
			this.CONFInfo = t;
		}
	}

	logout(){
		this.loginService.logout();
	}
	getPermissao(modulo,secao){
		return this.loginService.getPermissao(modulo,secao);
	}
}
