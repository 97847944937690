import { Component, OnInit, AfterViewInit, ChangeDetectionStrategy, ViewChild, TemplateRef } from '@angular/core';
import { LANGUAGE } from 'src/app.api';
import { LoginService } from '../login/login.service';
import { SYSCONF_SIGLA } from '../../app.api';
declare var $: any;


@Component({
	templateUrl: './home.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
  	styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit, AfterViewInit{
	@ViewChild('modalContent')
	modalContent: TemplateRef<any>;
	locale: string = LANGUAGE;
	viewDate: Date = new Date();
	dayMonth: string;
	sigla = SYSCONF_SIGLA;
	menus = [];
	permissaoMovimento: boolean;
	permissaoProjeto: boolean;
	permissaoContrato: boolean;
	permissaoEstoque: boolean;
	permissaoPessoa: boolean;
	messageUsuarios:string;
	messageConfederacoes:string;
	messageNiveis:string;
	constructor(private loginService: LoginService){}
	

	getPermissao(modulo:string,secao:string){
		return this.loginService.getPermissao(modulo,secao);
	}
	
	
  	ngOnInit(){ 
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		
		this.permissaoMovimento = this.getPermissao('financeiro_movimento','CONSULTAR') ? true : false;
		this.permissaoProjeto = this.getPermissao('projeto',"CONSULTAR") ? true : false;
		this.permissaoContrato = this.getPermissao('contrato',"CONSULTAR") ? true : false;
		this.permissaoEstoque = this.getPermissao('estoque_suprimento',"CONSULTAR") ? true : false;
		this.permissaoPessoa = this.getPermissao('pessoa',"CONSULTAR") ? true : false;

		if(this.getPermissao('projeto',"consultar")){
			this.menus.push({"titulo":"Projetos", "subtitulo":"Gerenciar","link":"/projeto"});
		}

		if(this.getPermissao('contrato',"criar")){
			this.menus.push({"titulo":"Contratos", "subtitulo":"Cadastrar","link":"/contrato/cadastrar"});
		}

		if(this.getPermissao('financeiro_movimento',"criar")){
			this.menus.push({"titulo":"Movimentos", "subtitulo":"Cadastrar","link":"/movimento/cadastrar"});
		}

		if(this.getPermissao('pessoa_atleta',"consultar")){
			this.menus.push({"titulo":"Atletas", "subtitulo":"Gerenciar","link":"/atleta"});
		}
	  }
		
	ngAfterViewInit() {
		$(".preloader").fadeOut();
	 }
}
