import { LoginService } from 'src/app/login/login.service';
import { PessoaService } from '../../pessoa.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { PrestadorServico } from '../prestadorServico';
import { PreCadastroService } from 'src/app/pre-cadastro/pre-cadastro.service';
import { SYSCONF_SIGLA } from 'src/app.api';
@Component({
	selector: 'app-prestador-servico-edit',
  	templateUrl: './prestadorServicoEdit.component.html',
  	styleUrls: ['./prestadorServicoEdit.component.scss']
})

export class PrestadorServicoEditComponent implements OnInit{
	
	@ViewChild('Swal') public swal:SwalComponent; 
	public message:string = '';
	editForm: FormGroup;
	prestadorServico: PrestadorServico;
	prestador_id:number;
	genero;
	thumb:string = '';
	pessoa_id:number;
	enderecos:object = new Array();
	telefones:object = new Array();
	contasBancarias:object = new Array();
	estadoCivil: [{}];
	sigla = SYSCONF_SIGLA;
	anexos: any;
	acesso = new Array();
	constructor(public loginService:LoginService, private pessoaService: PessoaService, private preCadastroService: PreCadastroService,private activatedRoute: ActivatedRoute ,private formBuilder: FormBuilder){
		this.preCadastroService.get('pessoa/genero')
        .subscribe( (success) => {
			this.genero = success.dados;
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});

		this.preCadastroService.get('pessoa/estado-civil')
        .subscribe( (success) => {
			this.estadoCivil = success.dados;
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});

		this.editForm = this.formBuilder.group({
			id: this.formBuilder.control(0,[Validators.required]),
			estrangeiro: this.formBuilder.control('N',[Validators.required]),
			tipo: this.formBuilder.control('F',[Validators.required]),
			passaporte: this.formBuilder.control(''),
			passaporte_dt_validade: this.formBuilder.control(''),
			cpf: this.formBuilder.control('',[Validators.required]),
			cnpj: this.formBuilder.control(''),
			dt_cadastro: this.formBuilder.control(''),
			dt_atualizacao: this.formBuilder.control(''),
			dt_nascimento: this.formBuilder.control('',[Validators.required]),
			primeiro_nome: this.formBuilder.control('',[Validators.required]),
			ultimo_nome: this.formBuilder.control('',[Validators.required]),
			nome_completo: this.formBuilder.control(''),
			apelido: this.formBuilder.control(''),
			email: this.formBuilder.control('',[Validators.required, Validators.email]),
			status: this.formBuilder.control('',[Validators.required]),
			genero_id: this.formBuilder.control(1,[Validators.required]),
			rg: this.formBuilder.control(''),
			rg_dt_emissao: this.formBuilder.control(''),
			rg_emissor: this.formBuilder.control(''),
			rg_uf: this.formBuilder.control(''),
			observacao: this.formBuilder.control(''),
			website: this.formBuilder.control(''),
			estado_civil_id: this.formBuilder.control('',[Validators.required]),
			pis: this.formBuilder.control(''),
			grau_instrucao_id: this.formBuilder.control(''),
			nome_pai: this.formBuilder.control(''),
			nome_mae: this.formBuilder.control(''),
			razao_social: this.formBuilder.control(''),
		});	
		this.prestador_id = this.activatedRoute.snapshot.params['id'];
		this.pessoaService.getPessoaById('pessoa/prestador-servico',this.prestador_id).subscribe(
			(prestadorServico) => {
			this.prestadorServico = prestadorServico.dados;
			this.pessoa_id = this.prestadorServico.pessoa_id;
			this.acesso = this.prestadorServico.acesso;
			if(this.prestadorServico.foto){
				this.thumb = this.prestadorServico.foto.thumb;
			}
			this.pessoaService.getPessoaById('pessoa',this.pessoa_id).subscribe(
				(success) =>{
					this.enderecos = success.dados.endereco;
					this.telefones = success.dados.telefone;
					this.contasBancarias = success.dados.conta_bancaria;
					this.anexos = success.dados.anexo;
				},
				(error) => {
					this.message = error.error.message;
				})
			
			this.editForm.patchValue({
				id: this.prestadorServico.id,
				estrangeiro: this.prestadorServico.estrangeiro,
				tipo: this.prestadorServico.tipo,
				passaporte: this.prestadorServico.passaporte,
				passaporte_dt_validade: this.prestadorServico.passaporte_dt_validade_br,
				cpf: this.prestadorServico.cpf,
				cnpj: this.prestadorServico.cnpj,
				genero_id: this.prestadorServico.genero_id,
				dt_cadastro: this.prestadorServico.dt_cadastro_br,
				dt_atualizacao: this.prestadorServico.dt_atualizacao_br,
				primeiro_nome: this.prestadorServico.primeiro_nome,
				ultimo_nome: this.prestadorServico.ultimo_nome,
				nome_completo: this.prestadorServico.nome_completo,
				apelido: this.prestadorServico.apelido,
				email: this.prestadorServico.email,
				rg: this.prestadorServico.rg,
				rg_uf: this.prestadorServico.rg_uf,
				rg_dt_emissao: this.prestadorServico.rg_dt_emissao_br,
				rg_emissor: this.prestadorServico.rg_emissor,
				nacionalidade: this.prestadorServico.nacionalidade,
				naturalidade: this.prestadorServico.naturalidade,
				foto: this.prestadorServico.foto,
				observacao: this.prestadorServico.observacao,
				dt_nascimento: this.prestadorServico.dt_nascimento_br,
				status: this.prestadorServico.status,
				website: this.prestadorServico.website,
				estado_civil_id: this.prestadorServico.estado_civil_id,
				pis: this.prestadorServico.pis,
				grau_instrucao_id: this.prestadorServico.grau_instrucao_id,
				nome_pai: this.prestadorServico.nome_pai,
				nome_mae: this.prestadorServico.nome_mae,
				razao_social: this.prestadorServico.razao_social,
			});
		},
		(error) => {
			this.message = error.error.message;
		})
	}

	ngOnInit(){
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.pessoa_prestador.includes("EDITAR"))
			this.message = 'Você não possui permissão para executar esta ação'
	}
	
	
}