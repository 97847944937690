import { Component,ChangeDetectorRef, OnInit,  AfterViewInit} from '@angular/core';
import { Movimento } from '../../financeiro/movimento/movimento';
import { FinanceiroService } from '../../financeiro/financeiro.service';

declare var c3: any;

@Component({
  selector: 'app-home-despesa-centro-custo',
  templateUrl: './home-despesa-centro-custo.component.html',
  styleUrls: ['../home.component.scss']
})
export class HomeDespesaCentroCustoComponent implements OnInit, AfterViewInit {
  
  stats: any[];
 
  message: string;
  constructor(private cd: ChangeDetectorRef, private financeiroService: FinanceiroService) { }

  ngOnInit() {
    this.financeiroService.getStats('despesa-centro-custo')
    .subscribe(
      (despesaCentroCusto) => {
      
        this.stats = despesaCentroCusto.dados;

        let statsDonut = new Array();
        for (let s of this.stats) {
          statsDonut.push([s.nome, s.percentual]);
        }

    
        var chart = c3.generate({
          bindto: '#visitor',
          data: {
              columns: statsDonut,
              
              type : 'donut',
              onclick: function (d, i) { /* console.log("onclick", d, i); */ },
              onmouseover: function (d, i) { /* console.log("onmouseover", d, i); */},
              onmouseout: function (d, i) { /* console.log("onmouseout", d, i); */}
          },
          donut: {
              label: {
                  show: false
                },
                columns: statsDonut,
              title:"",
              width:30,
              
          },
          
          legend: {
            hide: true
            //or hide: 'data1'
            //or hide: ['data1', 'data2']
          },
          color: {
                pattern: ['#0075bd', '#00903c', '#f7b625', '#333333', '#e13648']
          }
      });
     

        if(this.stats.length == 0) {
          this.message = "Nenhuma receita encontrada";
        }

        this.cd.markForCheck();
      },
      (error) => {
        this.cd.markForCheck();
        this.message = error.error.message;
      });

  }

  ngAfterViewInit() {
    //######### grafico 2

      
  }
}
