import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SYSCONF_API, SYSCONF_SIGLA, SYSCONF_APP_ID, SYSCONF_CREDENTIALS, SYSCONF_LIMIT } from '../../app.api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { tap } from 'rxjs/operators';
import { FormHelper } from '../_includes/forms/form-helper';
import { FormGroup } from '@angular/forms';
import { Financeiro } from './financeiro';

@Injectable()
export class FinanceiroService{
	
    financeiro: Financeiro[];
    private headers;
    cob = null;
    constructor(private http: HttpClient, private formHelper: FormHelper){
    this.cob = JSON.parse(localStorage.getItem('CONFLogin'));
        this.headers = {'App-Id': SYSCONF_APP_ID,'Content-Type':'application/json','App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
    }

    get(endpoint:string): Observable<any>{
        return this.http.get(`${SYSCONF_API}/financeiro/${endpoint}?limit=10000&page=1`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response,
                error => error
            )
        );
    }

    getStats(endpoint:string): Observable<any>{
        return this.http.get(`${SYSCONF_API}/stats/${endpoint}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response,
                error => error
            )
        );
    }
   
    getById(endpoint:string, id): Observable<any>{
        return this.http.get(`${SYSCONF_API}/financeiro/${endpoint}/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response,
                error => error
            )
        );
    }

    exportar(tipo: 'excel' | 'pdf',endpoint: string, form: FormGroup, params: any) {
		let criterioUrl:string = '';
        let campos:string;
        campos = form.get('campos').value.join('&campos[]=');
        criterioUrl = this.formHelper.getConditionURL(params);
        return this.http.get(`${SYSCONF_API}/financeiro/${endpoint}/exportar/${tipo}/?campos[]=${campos}&${criterioUrl}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response,
                error => error
            )
        );
	}

    conciliar(dt_conciliacao: string, conciliacao_fitid: string, movimento_id: number):Observable<any> {
		this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario.append('dt_conciliacao',dt_conciliacao)
        formulario.append('conciliacao_fitid',conciliacao_fitid)

        return this.http.post(`${SYSCONF_API}/financeiro/movimento/${movimento_id}`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => this.financeiro = response.dados,
                error => error
            )
        )
	}

    list(endpoint:string, params, limit = SYSCONF_LIMIT): Observable<any>{
        let criterioUrl:string = '';
        criterioUrl = this.formHelper.getConditionURL(params);
        return this.http.get(`${SYSCONF_API}/financeiro/${endpoint}/?limit=${limit}&page=${params.page}&${criterioUrl}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response,
                error => error
            )
        );
    }

    listCustom(endpoint:string, params, limit = SYSCONF_LIMIT): Observable<any>{

        let customParam = '';
        for (let p in params) {
            customParam += "&" + encodeURIComponent(p) + '=' + encodeURIComponent(params[p]);
        }

    
        return this.http.get(`${SYSCONF_API}/financeiro/${endpoint}/?limit=${limit}${customParam}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response,
                error => error
            )
        );
    }

    add(endpoint:string, form: FormGroup, arquivo: File = null):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        if(form)
        formulario = this.formHelper.createFormDataFinanceiro(form,arquivo);
        else{
            formulario.append('arquivo',arquivo);
        }

        return this.http.post(`${SYSCONF_API}/financeiro/${endpoint}`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => this.financeiro = response.dados,
                error => error
            )
        )
    }
    edit(endpoint:string, form: FormGroup, arquivo: File = null):Observable<any>{
       
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataFinanceiro(form,arquivo);
        return this.http.post(`${SYSCONF_API}/financeiro/${endpoint}/${form.value.id}`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => this.financeiro = response.dados,
                error => error
            )
        )
    }
    del(endpoint, id):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.delete(`${SYSCONF_API}/financeiro/${endpoint}/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => this.financeiro = response.dados,
                error => error
            )
        );
    }

    delVarios(endpoint, ids):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        if(!ids)
        return;

        ids.forEach(k => {
            formulario.append('id[]',k);
        });
        return this.http.post(`${SYSCONF_API}/financeiro/${endpoint}`,formulario,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => this.financeiro = response.dados,
                error => error
            )
        );
    }

    concluirVarios(endpoint:string, ids:any, form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        if(!ids)
        return;

        ids.forEach(k => {
            formulario.append('id[]',k);
        });
        formulario.append('dt_conciliacao',`${form.value.dt_conciliacao.day}/${form.value.dt_conciliacao.month}/${form.value.dt_conciliacao.year}`);
        formulario.append('dt_baixa',`${form.value.dt_baixa.day}/${form.value.dt_baixa.month}/${form.value.dt_baixa.year}`);
        return this.http.post(`${SYSCONF_API}/financeiro/${endpoint}`,formulario,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => this.financeiro = response.dados,
                error => error
            )
        );
    }

    remessa(ids:any, form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        if(!ids)
        return;
        ids.forEach(k => {
            formulario.append('boleto_id[]',k);
        });
        formulario.append('id_ocorrencia',form.value.operacao);
        return this.http.post(`${SYSCONF_API}/financeiro/boleto/remessa`,formulario,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => this.financeiro = response.dados,
                error => error
            )
        );
    }
    
}