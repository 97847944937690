import { PoliticaCookiesComponent } from './politica-cookies/politica-cookies.component';
import { RecuperarSenhaComponent } from './recuperar-senha/recuperar-senha.component';
import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.export';
import { AuthGuard } from './_services/auth-guard.service';
import { PessoaComponent } from './pessoa/pessoa.export';
import { ArbitroListComponent, ArbitroCreateComponent, ArbitroEditComponent, ArbitroDetailComponent, ArbitroSearchComponent } from './pessoa/oficial/oficial.export';
import { AtletaListComponent, AtletaCreateComponent, AtletaEditComponent, AtletaDetailComponent, AtletaSearchComponent } from './pessoa/atleta/atleta.export';
import { RHComponent, AfastamentoListComponent, CargosESalariosListComponent, FeriasListComponent, FolhaDePagamentoListComponent, FolhaDePagamentoCreateComponent, FolhaDePagamentoEditComponent, FolhaDePagamentoDetailComponent } from './rh/rh.export';
import { FinanceiroComponent, ConciliacaoListComponent, ChequeListComponent, ChequeCreateComponent, ChequeEditComponent, ChequeDetailComponent, ChequeSearchComponent, MovimentoListComponent, MovimentoCreateComponent, MovimentoEditComponent, MovimentoDetailComponent, MovimentoSearchComponent, FinanceiroPlanoDeContaComponent, ConfiguracaoBoletoComponent, BoletoListComponent, RemessaListComponent, RelatorioFinanceiroComponent, RelatorioConciliacaoComponent, RelatorioBalanceteComponent } from './financeiro/financeiro.export';
import { ClubeListComponent, ClubeCreateComponent, ClubeEditComponent, ClubeDetailComponent, ClubeSearchComponent } from './pessoa/clube/clube.export';
import { ContratoComponent, ContratoListComponent, ContratoCreateComponent, ContratoEditComponent, ContratoDetailComponent, ContratoSearchComponent } from './contrato/contrato.export';
import { DependenteListComponent, DependenteCreateComponent, DependenteEditComponent, DependenteDetailComponent, DependenteSearchComponent } from './pessoa/dependente/dependente.export';
import { EstoqueComponent, MovimentacaoListComponent, MovimentacaoCreateComponent, MovimentacaoEditComponent, MovimentacaoDetailComponent, MovimentacaoSearchComponent, SuprimentoListComponent, SuprimentoCreateComponent, SuprimentoEditComponent, SuprimentoDetailComponent, SuprimentoSearchComponent } from './estoque/estoque.export';
import { EstudantilListComponent, EstudantilCreateComponent, EstudantilEditComponent, EstudantilDetailComponent, EstudantilSearchComponent } from './pessoa/estudantil/estudantil.export';
import { FederacaoListComponent, FederacaoCreateComponent, FederacaoEditComponent, FederacaoDetailComponent, FederacaoSearchComponent } from './pessoa/federacao/federacao.export';
import { FornecedorListComponent, FornecedorCreateComponent, FornecedorEditComponent, FornecedorDetailComponent, FornecedorSearchComponent } from './pessoa/fornecedor/fornecedor.export';
import { FuncionarioListComponent, FuncionarioCreateComponent, FuncionarioEditComponent, FuncionarioDetailComponent, FuncionarioSearchComponent } from './pessoa/funcionario/funcionario.export';
import { GovernamentalListComponent, GovernamentalCreateComponent, GovernamentalEditComponent, GovernamentalDetailComponent, GovernamentalSearchComponent } from './pessoa/governamental/governamental.export';
import { PatrocinadorListComponent, PatrocinadorCreateComponent, PatrocinadorEditComponent, PatrocinadorDetailComponent, PatrocinadorSearchComponent } from './pessoa/patrocinador/patrocinador.export';
import { PrestadorServicoListComponent, PrestadorServicoCreateComponent, PrestadorServicoEditComponent, PrestadorServicoDetailComponent, PrestadorServicoSearchComponent } from './pessoa/prestador-servico/prestador.export';
import { ResponsavelListComponent, ResponsavelCreateComponent, ResponsavelEditComponent, ResponsavelDetailComponent, ResponsavelSearchComponent } from './pessoa/responsavel/responsavel.export';
import { TecnicoListComponent, TecnicoCreateComponent, TecnicoEditComponent, TecnicoDetailComponent, TecnicoSearchComponent } from './pessoa/tecnico/tecnico.export';
import { TutorialComponent, preCadastroVideoComponent, contratoVideoComponent, estoqueVideoComponent, financeiroVideoComponent, projetoVideoComponent, permissaoAcessoVideoComponent, pessoaInstituicaoVideoComponent, rhVideoComponent } from './tutorial/tutorial.export';
import { NivelComponent, NivelListComponent, NivelDetailComponent, NivelEditComponent, NivelCreateComponent, NivelSearchComponent } from './nivel/nivel.export';
import { PreCadastroComponent } from './pre-cadastro/pre-cadastro.component';
import { FormPreCadastroContratoAreaComponent, FormPreCadastroFuncionarioCargoComponent, FormPreCadastroEstoqueCategoriaComponent, FormFinanceiroCentroDeCustoComponent, FormFinanceiroContaBancariaComponent, FormFinanceiroFormaDePagamentoComponent, FormGraduacaoComponent, FormPreCadastroPessoaIdiomaComponent, FormModalidadeComponent, FormPreCadastroContratoNaturezaComponent, FormEstadoCivilComponent, FormGeneroComponent, FormGrauParentescoComponent, FormFinanceiroOrgaoComponent, FormProjetoStatusComponent, FormProjetoEnquadramentoComponent, FormPreCadastroRhVerbaComponent, FormPreCadastroEstoqueAtributoComponent, FormPreCadastroEstoqueMarcaComponent, FormPreCadastroEstoqueTipoComponent, FormPreCadastroEstoqueUnidadeComponent, FormPreCadastroEstoqueLocalComponent, FormFinanceiroFonteDeRecursoComponent, FormPreCadastroEstoqueMotivoRetiradaComponent} from './_includes/forms/form.export';
import { ProjetoComponent, ProjetoListComponent, ProjetoCreateComponent, ProjetoEditComponent, ProjetoDetailComponent, ProjetoSearchComponent } from './projeto/projeto.export';
import { LoginComponent } from './login/login.export';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ImportacaoListComponent, ImportacaoComponent } from './importacao/importacao.export';
import { PerfilEditComponent } from './perfil/edit/perfilEdit.component';

export const routes: Routes = [
    {
         path: ':sigla',
         children:[
             {
                 path: 'home',
                 component: HomeComponent,
                 canLoad: [AuthGuard],
                 canActivate: [AuthGuard]
 
             },{
                path: 'relatorio-financeiro',
                     component: RelatorioFinanceiroComponent,
                     children: [
                             { path: '', redirectTo: 'home', pathMatch: 'full' },
                             { path: 'conciliacao', component: RelatorioConciliacaoComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                             { path: 'balancete', component: RelatorioBalanceteComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     ]
             },{
                 path: 'arbitro',
                 component: PessoaComponent,
                 children: [
                     { path: '', redirectTo: 'page/1', pathMatch: 'full' },
                     { path: 'page/:page', component: ArbitroListComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'cadastrar', component: ArbitroCreateComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'editar/:id', component: ArbitroEditComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'detalhes/:id', component: ArbitroDetailComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'buscar', component: ArbitroSearchComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                 ]
             },{
                 path: 'atleta',
                 component: PessoaComponent,
                 children: [
                     { path: '', redirectTo: 'page/1', pathMatch: 'full' },
                     { path: 'page/:page', component: AtletaListComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'cadastrar', component: AtletaCreateComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'editar/:id', component: AtletaEditComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'detalhes/:id', component: AtletaDetailComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'buscar', component: AtletaSearchComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                 ]
             },{
                 path: 'afastamento',
                 component: RHComponent,
                 children: [
                     { path: '', redirectTo: 'page/1', pathMatch: 'full' },
                     { path: 'page/:page', component: AfastamentoListComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                 ]
             },{
                path: 'boleto',
                component: FinanceiroComponent,
                children: [
                    { path: '', redirectTo: 'page/1', pathMatch: 'full' },
                     { path: 'page/:page', component: BoletoListComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },

                ]
            },{
                path: 'configuracao-boleto',
                component: FinanceiroComponent,
                children: [
                    { path: '', component: ConfiguracaoBoletoComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },

                ]
            },{
                 path: 'cargo-e-salario',
                 component: RHComponent,
                 children: [
                     { path: '', redirectTo: 'page/1', pathMatch: 'full' },
                     { path: 'page/:page', component: CargosESalariosListComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                 ]
             },{
                 path: 'cheque',
                 component: FinanceiroComponent,
                 children: [
                     { path: '', redirectTo: 'page/1', pathMatch: 'full' },
                     { path: 'page/:page', component: ChequeListComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'cadastrar', redirectTo: 'page/1' },
                     { path: 'editar/:id', component: ChequeEditComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'detalhes/:id', component: ChequeDetailComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'buscar', component: ChequeSearchComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },
 
                 ]
             },{
                 path: 'clube',
                 component: PessoaComponent,
                 children: [
                     { path: '', redirectTo: 'page/1', pathMatch: 'full' },
                     { path: 'page/:page', component: ClubeListComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'cadastrar', component: ClubeCreateComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'editar/:id', component: ClubeEditComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'detalhes/:id', component: ClubeDetailComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'buscar', component: ClubeSearchComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
 
                 ]
             },{
                 path: 'contrato',
                 component: ContratoComponent,
                 children: [
                     { path: '', redirectTo: 'page/1', pathMatch: 'full' },
                     { path: 'page/:page', component: ContratoListComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'cadastrar', component: ContratoCreateComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'editar/:id', component: ContratoEditComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'detalhes/:id', component: ContratoDetailComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'buscar', component: ContratoSearchComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
 
                 ]
             },{
                path: 'conciliacao',
                component: FinanceiroComponent,
                children: [
                    { path: '', redirectTo: 'page/1', pathMatch: 'full' },
                    { path: 'page/:page', component: ConciliacaoListComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },

                ]
            },
            {
                 path: 'dependente',
                 component: PessoaComponent,
                 children: [
                     { path: '', redirectTo: 'page/1', pathMatch: 'full' },
                     { path: 'page/:page', component: DependenteListComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'cadastrar', component: DependenteCreateComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'editar/:id', component: DependenteEditComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'detalhes/:id', component: DependenteDetailComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'buscar', component: DependenteSearchComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                 ]
             },{
                 path: 'estudantil',
                 component: PessoaComponent,
                 children: [
                     { path: '', redirectTo: 'page/1', pathMatch: 'full' },
                     { path: 'page/:page', component: EstudantilListComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'cadastrar', component: EstudantilCreateComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'editar/:id', component: EstudantilEditComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'detalhes/:id', component: EstudantilDetailComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'buscar', component: EstudantilSearchComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
 
                 ]
             },{
                 path: 'federacao',
                 component: PessoaComponent,
                 children: [
                     { path: '', redirectTo: 'page/1', pathMatch: 'full' },
                     { path: 'page/:page', component: FederacaoListComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'cadastrar', component: FederacaoCreateComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'editar/:id', component: FederacaoEditComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'detalhes/:id', component: FederacaoDetailComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'buscar', component: FederacaoSearchComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                 ]
             },{
                 path: 'ferias',
                 component: RHComponent,
                 children: [
                     { path: '', redirectTo: 'page/1', pathMatch: 'full' },
                     { path: 'page/:page', component: FeriasListComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                 ]
             },{
                 path: 'folha-de-pagamento',
                 component: RHComponent,
                 children: [
                     { path: '', redirectTo: 'page/1', pathMatch: 'full' },
                     { path: 'page/:page', component: FolhaDePagamentoListComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'cadastrar', component: FolhaDePagamentoCreateComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'editar/:id', component: FolhaDePagamentoEditComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'detalhes/:id', component: FolhaDePagamentoDetailComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     
                 ]
             },{
                 path: 'fornecedor',
                 component: PessoaComponent,
                 children: [
                     { path: '', redirectTo: 'page/1', pathMatch: 'full' },
                     { path: 'page/:page', component: FornecedorListComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'cadastrar', component: FornecedorCreateComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'editar/:id', component: FornecedorEditComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'detalhes/:id', component: FornecedorDetailComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'buscar', component: FornecedorSearchComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                 ]
             },{
                 path: 'funcionario',
                 component: PessoaComponent,
                 children: [
                     { path: '', redirectTo: 'page/1', pathMatch: 'full' },
                     { path: 'page/:page', component: FuncionarioListComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'cadastrar', component: FuncionarioCreateComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'editar/:id', component: FuncionarioEditComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'detalhes/:id', component: FuncionarioDetailComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'buscar', component: FuncionarioSearchComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                 ]
             },{
                 path: 'governamental',
                 component: PessoaComponent,
                 children: [
                     { path: '', redirectTo: 'page/1', pathMatch: 'full' },
                     { path: 'page/:page', component: GovernamentalListComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'cadastrar', component: GovernamentalCreateComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'editar/:id', component: GovernamentalEditComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'detalhes/:id', component: GovernamentalDetailComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'buscar', component: GovernamentalSearchComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
 
                 ]
             },{
                path: 'meus-dados',
                component: PerfilEditComponent,
                canLoad: [AuthGuard],
                canActivate: [AuthGuard]
            },{
                path: 'movimento',
                component: FinanceiroComponent,
                children: [
                    { path: '', redirectTo: 'page/1', pathMatch: 'full' },
                    { path: 'page/:page', component: MovimentoListComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                    { path: 'cadastrar', component: MovimentoCreateComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },
                    { path: 'editar/:id', component: MovimentoEditComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                    { path: 'detalhes/:id', component: MovimentoDetailComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                    { path: 'buscar', component: MovimentoSearchComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },

                ]
            },{
                 path: 'patrocinador',
                 component: PessoaComponent,
                 children: [
                     { path: '', redirectTo: 'page/1', pathMatch: 'full' },
                     { path: 'page/:page', component: PatrocinadorListComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'cadastrar', component: PatrocinadorCreateComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'editar/:id', component: PatrocinadorEditComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'detalhes/:id', component: PatrocinadorDetailComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'buscar', component: PatrocinadorSearchComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                 ]
             },{
                 path: 'prestador-servico',
                 component: PessoaComponent,
                 children: [
                     { path: '', redirectTo: 'page/1', pathMatch: 'full' },
                     { path: 'page/:page', component: PrestadorServicoListComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'cadastrar', component: PrestadorServicoCreateComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'editar/:id', component: PrestadorServicoEditComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'detalhes/:id', component: PrestadorServicoDetailComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'buscar', component: PrestadorServicoSearchComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                 ]
             },{
                 path: 'responsavel',
                 component: PessoaComponent,
                 children: [
                     { path: '', redirectTo: 'page/1', pathMatch: 'full' },
                     { path: 'page/:page', component: ResponsavelListComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'cadastrar', component: ResponsavelCreateComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'editar/:id', component: ResponsavelEditComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'detalhes/:id', component: ResponsavelDetailComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'buscar', component: ResponsavelSearchComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                 ]
             },{
                 path: 'estoque',
                 children: [
                     {
                         path: 'movimentacao',
                         component: EstoqueComponent,
                         children: [
                             { path: '', redirectTo: 'page/1', pathMatch: 'full' },
                             { path: 'page/:page', component: MovimentacaoListComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                             { path: 'cadastrar', component: MovimentacaoCreateComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },
                             { path: 'editar/:id', component: MovimentacaoEditComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                             { path: 'detalhes/:id', component: MovimentacaoDetailComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                             { path: 'buscar', component: MovimentacaoSearchComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },
                         ]
                     },
                     {
                         path: 'suprimento',
                         component: EstoqueComponent,
                         children: [
                             { path: '', redirectTo: 'page/1', pathMatch: 'full' },
                             { path: 'page/:page', component: SuprimentoListComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                             { path: 'cadastrar', component: SuprimentoCreateComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },
                             { path: 'editar/:id', component: SuprimentoEditComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                             { path: 'detalhes/:id', component: SuprimentoDetailComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                             { path: 'buscar', component: SuprimentoSearchComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },
                         ]
                     }
                 ]
             }
             ,{
                path: 'remessa',
                component: FinanceiroComponent,
                children: [
                    { path: '', redirectTo: 'page/1', pathMatch: 'full' },
                     { path: 'page/:page', component: RemessaListComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },

                ]
            },{
                 path: 'tecnico',
                 component: PessoaComponent,
                 children: [
                     { path: '', redirectTo: 'page/1', pathMatch: 'full' },
                     { path: 'page/:page', component: TecnicoListComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'cadastrar', component: TecnicoCreateComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'editar/:id', component: TecnicoEditComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'detalhes/:id', component: TecnicoDetailComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'buscar', component: TecnicoSearchComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                 ]
             },{
                 path: 'tutorial',
                 component: TutorialComponent,
                 children: [
                     { path: 'pre-cadastro', component: preCadastroVideoComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'contrato', component: contratoVideoComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'estoque', component: estoqueVideoComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'financeiro', component: financeiroVideoComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'projeto', component: projetoVideoComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'permissao-acesso', component: permissaoAcessoVideoComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'pessoa-instituicao', component: pessoaInstituicaoVideoComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'rh', component: rhVideoComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },
                 ]
             },{
                 path: 'nivel',
                 component: NivelComponent,
             children: [
                     { path: '', redirectTo: 'page/1', pathMatch: 'full' },
                     { path: 'page/:page', component: NivelListComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'detalhes/:id', component: NivelDetailComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'editar/:id', component: NivelEditComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'cadastrar', component: NivelCreateComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     { path: 'buscar', component: NivelSearchComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] }
                 ]
             },{
                 path: 'plano-de-conta',
                 component: FinanceiroComponent,
                 children: [
                     { path: '', redirectTo: 'page/1', pathMatch: 'full' },
                     { path: 'page/:page', component: FinanceiroPlanoDeContaComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },
                     
                 ]
             },{
                 path: 'pre-cadastro',
                 component: PreCadastroComponent,
                 children: [
                         { path: '', redirectTo: 'home', pathMatch: 'full' },
                         { path: 'area', component: FormPreCadastroContratoAreaComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                         { path: 'cargo', component: FormPreCadastroFuncionarioCargoComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                         { path: 'categoria', component: FormPreCadastroEstoqueCategoriaComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                         { path: 'centro-de-custo', component: FormFinanceiroCentroDeCustoComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                         { path: 'conta-bancaria', component: FormFinanceiroContaBancariaComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                         { path: 'fonte-de-recurso', component: FormFinanceiroFonteDeRecursoComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                         { path: 'forma-de-pagamento', component: FormFinanceiroFormaDePagamentoComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                         { path: 'graduacao', component: FormGraduacaoComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                         { path: 'idioma', component: FormPreCadastroPessoaIdiomaComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                         { path: 'modalidade', component: FormModalidadeComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                         { path: 'natureza', component: FormPreCadastroContratoNaturezaComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                         { path: 'estado-civil', component: FormEstadoCivilComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                         { path: 'genero', component: FormGeneroComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                         { path: 'grau-parentesco', component: FormGrauParentescoComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                         { path: 'orgao', component: FormFinanceiroOrgaoComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                         { path: 'projeto-status', component: FormProjetoStatusComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                         { path: 'projeto-enquadramento', component: FormProjetoEnquadramentoComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                         { path: 'verba', component: FormPreCadastroRhVerbaComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                         { path: 'atributo', component: FormPreCadastroEstoqueAtributoComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                         { path: 'marca', component: FormPreCadastroEstoqueMarcaComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                         { path: 'motivo-retirada', component: FormPreCadastroEstoqueMotivoRetiradaComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                         { path: 'tipo', component: FormPreCadastroEstoqueTipoComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                         { path: 'unidade', component: FormPreCadastroEstoqueUnidadeComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                         { path: 'local', component: FormPreCadastroEstoqueLocalComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                 ]
             },
             {
                path: 'projeto',
                component: ProjetoComponent,
                children: [
                    { path: '', redirectTo: 'page/1', pathMatch: 'full' },
                    { path: 'page/:page', component: ProjetoListComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                    { path: 'cadastrar', component: ProjetoCreateComponent, canLoad: [AuthGuard], canActivate: [AuthGuard] },
                    { path: 'editar/:id', component: ProjetoEditComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                    { path: 'detalhes/:id', component: ProjetoDetailComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                    { path: 'buscar', component: ProjetoSearchComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                ]
            },{
                path: 'importacao',
                component: ImportacaoComponent,
                children: [
                    { path: '', redirectTo: 'page/1', pathMatch: 'full' },
                    { path: 'page/:page', component: ImportacaoListComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                    // { path: 'detalhes/:id', component: ProjetoDetailComponent,canLoad: [AuthGuard], canActivate: [AuthGuard] },
                ]
            },{
                path: 'forgot/:token',
                component: RecuperarSenhaComponent,
            },{
                path: 'politica-cookies',
                component: PoliticaCookiesComponent,
                // canLoad: [AuthGuard], 
                // canActivate: [AuthGuard]
            },{
                 path: '',
                 redirectTo: 'home',
                 pathMatch: 'full'
             },{
                 path: 'login/:to',
                 component: LoginComponent
             },{
                 path: 'login',
                 component: LoginComponent
             },
             {
                 path: '404',
                 component: PageNotFoundComponent
             },
             {path: '**', redirectTo: '404'}
         ]
     },
     {path: '**', component: PageNotFoundComponent}
    
 ];