import { PreCadastroService } from 'src/app/pre-cadastro/pre-cadastro.service';
import { ProjetoService } from 'src/app/projeto/projeto.service';
import { Component, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';
import { PessoaService } from 'src/app/pessoa/pessoa.service';
@Component({
	selector: 'app-form-projeto-orcamento',
  	templateUrl: './form-projeto-orcamento.component.html',
  	styleUrls: ['./form-projeto-orcamento.component.scss']
})
export class FormProjetoOrcamentoComponent{
	@ViewChild('Swal') public swal:SwalComponent;
	@ViewChild('SwalDelete') public swalDelete:SwalComponent; 
	@Input() projetosOrcamentos = new Array();
	@Input() detalhe: boolean;
	@Input() pessoa_id: number;
	@Input() acao:string;
	create:boolean;
	edit:boolean;
	detail:boolean;
	delete:boolean;
	modalDetalhe:boolean = false;
	form: FormGroup;
	projeto_orcamento_id:number = null;
	modalTitle:string;
	submitted: boolean = false;
	loading: boolean = false;
	modalReference: NgbModalRef; 
	tiposPessoasSelect = new Array();
	pessoasSelect = new Array();
	suprimentosUnidadesSelect  = new Array();
	constructor(private preCadastroService: PreCadastroService,  private projetoService: ProjetoService, private pessoaService: PessoaService, private formBuilder: FormBuilder, private modalService: NgbModal, private activatedRoute: ActivatedRoute){
		this.preCadastroService.getOptions('estoque/suprimento/unidade')
        .subscribe( (success) => {
			this.suprimentosUnidadesSelect = success.dados;
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
			this.submitted = false;
		});

	}

	initForm(){
		this.form = this.formBuilder.group({
			tipo_pessoa_id: this.formBuilder.control(''),
			tipo_pessoa: this.formBuilder.control(''),
			pessoa_id: this.formBuilder.control(''),
			pessoa: this.formBuilder.control(''),
			item: this.formBuilder.control('',[Validators.required]),
			quantidade: this.formBuilder.control('',[Validators.required]),
			valor: this.formBuilder.control(''),
			dt_previsao_pagamento: this.formBuilder.control(''),
			dt_pagamento: this.formBuilder.control(''),
			destino: this.formBuilder.control(''),
			suprimento_unidade_id: this.formBuilder.control('',[Validators.required]),
			suprimento_unidade: this.formBuilder.control(''),
			observacao: this.formBuilder.control(''),
		})
	}

	ngOnInit(){ 
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(t.permissao.projeto_orcamento.includes("CONSULTAR"))
			this.detail = true;
		if(t.permissao.projeto_orcamento.includes("CRIAR"))
			this.create = true;
		if(t.permissao.projeto_orcamento.includes("EDITAR"))
			this.edit = true;
		if(t.permissao.projeto_orcamento.includes("EXCLUIR"))
			this.delete = true;
	}

	//Carrega o campo "Executor" de acordo com o "Tipo de Executor"
	loadTiposPessoas(id){
		this.form.patchValue({pessoa_id: ''})
		this.pessoasSelect = new Array();
		let endpoint = this.tiposPessoasSelect.find(c => c.id == id);
		if(!endpoint)
			return;
		this.pessoaService.getOptions('pessoa/'+endpoint.slug).subscribe((success) => {
			this.pessoasSelect = success.dados;	
		},(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		})
		
	}

	
	onSubmit(){
		this.submitted = true;
        if(!this.form.valid)
		return;
		this.modalReference.close();
		if(!this.projeto_orcamento_id){
			this.projetoService.addProjetoOrcamento(this.activatedRoute.snapshot.params['id'], this.form)
			.subscribe( (success) => {
				this.swal.text = "Orçamento cadastrado com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
				this.initForm();
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
			return;
		}

		this.projetoService.editProjetoOrcamento(this.projeto_orcamento_id,this.form)
			.subscribe( (success) => {
				this.swal.text = "Orçamento atualizado com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
				this.initForm();
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
				this.initForm();
			});
	}
	

	openModal(modal,id:number = null,detalhe:boolean = false) {
		this.initForm();
		this.modalDetalhe = detalhe;
		//Se id == null então é cadastro.
		if(!id){
			this.pessoaService.getOptions('pessoa/tipo?id[]=3&id[]=4').subscribe((success) => {
				this.tiposPessoasSelect = success.dados;
				this.projeto_orcamento_id = null;
				this.modalTitle = "Cadastrar Orçamento";
				this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
			},(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
			})
			return;
		}
		
		//Senão é edição.
		this.projeto_orcamento_id = id;
		this.modalTitle = "Editar Orçamento";
		this.pessoaService.getOptions('pessoa/tipo?id[]=3&id[]=4').subscribe((success) => {
			this.tiposPessoasSelect = success.dados;
			this.projetoService.getProjetoOrcamentoById(id).subscribe((success) => {
				this.form.patchValue({
					tipo_pessoa_id: success.dados.tipo_pessoa_id,
					tipo_pessoa: success.dados.tipo_pessoa,
					pessoa_id: success.dados.pessoa_id,
					pessoa: success.dados.pessoa,
					item: success.dados.item,
					quantidade: success.dados.quantidade,
					valor: success.dados.valor,
					dt_previsao_pagamento: success.dados.dt_previsao_pagamento_br,
					dt_pagamento: success.dados.dt_pagamento_br,
					destino: success.dados.destino,
					suprimento_unidade_id: success.dados.suprimento_unidade_id,
					suprimento_unidade: success.dados.suprimento_unidade,
					observacao: success.dados.observacao,
				});
				this.loadTiposPessoas(success.dados.tipo_pessoa_id);	
				this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
			})
		},(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		})
		
	}

	askDelete(id:number){
		this.projeto_orcamento_id = id;
		this.swalDelete.show();
	}

	confirmDelete(){
		this.projetoService.delProjetoOrcamento(this.projeto_orcamento_id).subscribe( 
		(success) => {
			this.swal.text = "Orçamento deletado com sucesso!";
			this.swal.type = "success";
			this.swal.title = "Pronto!";
			this.swal.show();
			this.submitted = false;
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}

	onCloseModal(){
		if(this.swal.type == "success"){
			this.projetoService.getProjetoById(this.activatedRoute.snapshot.params['id']).subscribe((success)=>{
				this.projetosOrcamentos = success.dados.orcamento;
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
		}
	}
}