import { DataService } from './../../../_services/data.service';
import { Movimento } from './../movimento';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SYSCONF_LIMIT, SYSCONF_SIGLA } from '../../../../app.api';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { FinanceiroService } from '../../financeiro.service';
import { PreCadastroService } from 'src/app/pre-cadastro/pre-cadastro.service';
import { PessoaService } from '../../../pessoa/pessoa.service';
import { isArray } from 'util';
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from 'src/app/login/login.export';

@Component({
	selector: 'app-movimento-list',
	templateUrl: './movimentoList.component.html',
	styleUrls: ['./movimentoList.component.scss']
})

export class MovimentoListComponent implements OnInit{
	@ViewChild('Swal') public swal: SwalComponent;
	@ViewChild('SwalDelete') public swalDelete:SwalComponent; 
	movimento: Movimento[];
	movimento_id = null;
	public message:string = '';
	edit:boolean = false;
	detail:boolean = false;
	delete:boolean = false;
	create:boolean = false;
	params = null;
	page = 1;
	totalPages:number = 1;
	recordsPerPage:number = SYSCONF_LIMIT;
	buscaForm: FormGroup;
	formExportar: FormGroup;
	formConcluir: FormGroup;
	sigla = SYSCONF_SIGLA;
	pessoasSelect = new Array();
	contasBancariasSelect = new Array();
	tiposPessoasSelect = new Array();
	checkMovimentos = new FormData();
	total_despesa: any;
	total_receita: any;
	saldo: any;
	submitted: boolean = false;
	loading: boolean = false;
	modalReference: NgbModalRef; 
	saldoFinal: any;
	exibeParcial: boolean = true;
	total_receita_provisionado: number;
	total_despesa_provisionado: number;
	total_provisionado: number;
	constructor(private activatedRoute: ActivatedRoute,  private loginService:LoginService, private router: Router, private financeiroService: FinanceiroService, private modalService: NgbModal, private formBuilder: FormBuilder, private preCadastroService: PreCadastroService, private dt: DataService){
		this.preCadastroService.getOptions('financeiro/conta-bancaria').subscribe((success) => {
			this.contasBancariasSelect = success.dados;
			this.contasBancariasSelect.map((row) => { row.labelSelect = `${row.banco} - Ag.: ${row.num_agencia}-${row.dv_agencia} - ${row.tipo}: ${row.num_conta}-${row.dv_conta}`; return row; });
		},(error) => {
			
		})
		this.exibeParcial = true;
	}
	changePage(event){
		let page = parseInt(event);
		let newParams:Object = new Object();
		this.activatedRoute.params.subscribe(
			param => {
				for(let i in param) {
					if(i != 'page'){ 
						newParams[i] = param[i];
					}				
				}
				
			})	
			
			if(newParams['status'] != '' && newParams['status'] != undefined){
				this.exibeParcial = false;
			}			

		if(!page)
		return;
		this.router.navigate(['/'+SYSCONF_SIGLA+`/movimento/page/${page}`,newParams]);
	}
	ngOnInit(){ 
		this.buscaForm = this.formBuilder.group({
			id: this.formBuilder.control(''),
			tipo: this.formBuilder.control(''),
			de_dt_vencimento: this.formBuilder.control(null),
			ate_dt_vencimento: this.formBuilder.control(null),
			conta_bancaria_id: this.formBuilder.control(null),
		});

		this.formConcluir = this.formBuilder.group({
			dt_baixa: this.formBuilder.control(null,[Validators.required]),
			dt_conciliacao: this.formBuilder.control(null,[Validators.required])
		})

		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(t.permissao.financeiro_movimento.includes("CONSULTAR"))
			this.detail = true;
		if(t.permissao.financeiro_movimento.includes("CRIAR"))
			this.create = true;
		if(t.permissao.financeiro_movimento.includes("EDITAR"))
			this.edit = true;
		if(t.permissao.financeiro_movimento.includes("EXCLUIR"))
			this.delete = true;
		this.activatedRoute.params.subscribe(params => {
			this.paramsChange(params);
		});
	}

	doCheck(event){
		let name = event.target.name;
		let value = event.target.value;
		if(event.target.checked){
			this.checkMovimentos.append(name,value);
		}else{
			let tmp = this.checkMovimentos.getAll(name); 
			
			const index: number = tmp.indexOf(value);
			if (index !== -1) {
				tmp.splice(index, 1);
			} 
			this.checkMovimentos.delete(name);
			tmp.forEach((key : any) => {
				this.checkMovimentos.append(name,key);
			})
		}
	}

	askDelete(id:number, varios = false){
		this.movimento_id = id;
		if(varios){
			if(this.checkMovimentos.getAll('movimentos[]').length <= 0){
				this.swal.text = 'Você deve selecionar no mínimo um movimento.';
				this.swal.type = "warning";
				this.swal.title = "Atenção!";
				this.swal.show();
				return;
			}
			this.movimento_id = this.checkMovimentos.getAll('movimentos[]');
			
		}
		this.swalDelete.show();
	}

	confirmDelete(){
		if(isArray(this.movimento_id) && this.movimento_id.length > 0){
			this.financeiroService.delVarios('movimento/apaga-multiplo',this.movimento_id).subscribe( 
				(success) => {
					this.movimento_id = 0;
					this.swal.text = "Movimentos deletados com sucesso!";
					this.swal.type = "success";
					this.swal.title = "Pronto!";
					this.swal.show();
				},
				(error) => {
					this.swal.text = error.error.message;
					this.swal.type = "error";
					this.swal.title = "Erro!";
					this.swal.show();
				});
				return;
		}
		this.financeiroService.del('movimento',this.movimento_id).subscribe( 
		(success) => {
			this.movimento_id = 0;
			this.swal.text = "Movimento deletado com sucesso!";
			this.swal.type = "success";
			this.swal.title = "Pronto!";
			this.swal.show();
			
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		});
	}

	paramsChange(params) {
		this.params = params;
		this.message = "";
		this.financeiroService.list('movimento',params)
		.subscribe(
			(movimento) => {
				this.total_receita = movimento.dados.total_receita;
				this.total_despesa = movimento.dados.total_despesa;
				this.total_receita_provisionado = movimento.dados.provisionado_receita;
				this.total_despesa_provisionado = movimento.dados.provisionado_despesa;
				this.total_provisionado = movimento.dados.total_provisionado;
				this.saldoFinal = movimento.dados.saldo;
				this.saldo = movimento.dados.saldo_conta;
				this.movimento_id = null;
				this.movimento = movimento.dados.registros;
				this.totalPages = movimento.paging.rows;
				this.page = params['page'];
				if(typeof params['id']){
					this.buscaForm.patchValue({
						id: params['id'] != undefined ? params['id'] : '' ,
						tipo: params['tipo'] != undefined ? params['tipo'] : ''  ,
						de_dt_vencimento: params['de_dt_vencimento'] != undefined ? this.dt.formataDataBr(params['de_dt_vencimento']) : null,				
						ate_dt_vencimento: params['ate_dt_vencimento'] != undefined ? this.dt.formataDataBr(params['ate_dt_vencimento']) : null,
						conta_bancaria_id: params['conta_bancaria_id'] != undefined ? params['conta_bancaria_id'] : null ,
					})
				}
			},
			(error) => {
				this.message = error.error.message;
			})
	}

	onSubmit(){
		let de_dt_vencimento = this.dt.convertDataObject(this.buscaForm.value,'de_dt_vencimento');
		let ate_dt_vencimento = this.dt.convertDataObject(this.buscaForm.value,'ate_dt_vencimento');
		this.buscaForm.patchValue({
			de_dt_vencimento: de_dt_vencimento,				
			ate_dt_vencimento: ate_dt_vencimento,
		})
		if(de_dt_vencimento.indexOf('undefined') !== -1){
			this.buscaForm.patchValue({
				de_dt_vencimento: ''
			})
		}
		if(ate_dt_vencimento.indexOf('undefined') !== -1){
			this.buscaForm.patchValue({
				ate_dt_vencimento: ''
			})
		}
		this.router.navigate(['/'+SYSCONF_SIGLA+'/movimento/page/1',this.buscaForm.value]);
	}
	
	onCloseModal(){
		if(this.swal.type == "success"){
			this.activatedRoute.params.subscribe(params => {
				this.paramsChange(params);
			});
		}
	}

	initFormExportar(){
		this.formExportar = this.formBuilder.group({
			campos: new FormArray([]),
		});
	}

	openExportModal(modal) {
		this.initFormExportar();
		this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
	}

	openModal(modal) {
		if(this.checkMovimentos.getAll('movimentos[]').length <= 0){
			
			this.swal.text = 'Você deve selecionar no mínimo um movimento.';
			this.swal.type = "warning";
			this.swal.title = "Atenção!";
			this.swal.show();
			return;
		}
		this.movimento_id = this.checkMovimentos.getAll('movimentos[]');
		this.formConcluir.patchValue({
			dt_conciliacao: '',
			dt_baixa: ''
		});
		this.modalReference = this.modalService.open(modal, {backdrop: 'static'});
	}

	onSubmitModal(){
        if(!this.formConcluir.valid)
		return;
		this.submitted = true;
		this.modalReference.close();
		this.financeiroService.concluirVarios('movimento/concluir-multiplo', this.movimento_id,this.formConcluir)
			.subscribe( (success) => {
				this.swal.text = "Movimentos concluídos com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
				
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});

        
	}

	getPermissao(modulo,secao){
		return this.loginService.getPermissao(modulo,secao);
	}


	
}
