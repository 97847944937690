import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SYSCONF_API, SYSCONF_SIGLA, SYSCONF_APP_ID, SYSCONF_CREDENTIALS} from '../../app.api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { tap } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { FormHelper } from '../_includes/forms/form-helper';

@Injectable()
export class PerfilService{
    private headers;
    cob = null;
    constructor(private http: HttpClient, private formHelper: FormHelper){
    this.cob = JSON.parse(localStorage.getItem('CONFLogin'));
        this.headers = {'App-Id': SYSCONF_APP_ID,'Content-Type':'application/json','App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
    }
    getPerfilByToken():Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.get(`${SYSCONF_API}/perfil`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }
    edit(form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataAcesso(form)
        return this.http.post(`${SYSCONF_API}/perfil`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        )
    }
}