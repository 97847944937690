import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from './../../login/login.service';

declare var $: any;
@Component({
	selector: 'app-pessoas-tab',
  	templateUrl: './tab.component.html',
  	styleUrls: ['./tab.component.scss']
})
export class PessoaTabComponent{
    @Input() pessoa_id:string;
    @Input() tipoForm:string;
    @Input() tipo_id:number;
    @Input() atleta:boolean = false;
	@Input() arbitro:boolean = false;
	@Input() tecnico:boolean = false;
	@Input() prestador:boolean = false;
    @Input() fornecedor:boolean = false;
    @Input() federacao:boolean = false;
    @Input() governamental:boolean = false;
    @Input() estudantil:boolean = false;
    @Input() patrocinador:boolean = false;
    @Input() funcionario:boolean = false;
    @Input() responsavel:boolean = false;
	@Input() dependente:boolean = false;
    constructor(public loginService: LoginService, private router: Router){
    }

}