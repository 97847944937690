import { Component, OnInit} from '@angular/core';
import { SYSCONF_SIGLA } from '../../../app.api';
import { LoginService } from './../../login/login.service';

declare var $: any;
@Component({
	selector: 'app-contrato-create',
  	templateUrl: './contratoCreate.component.html',
  	styleUrls: ['./contratoCreate.component.scss']
})

export class ContratoCreateComponent implements OnInit{
	public message:string = '';
	sigla = SYSCONF_SIGLA;
	ngOnInit(){
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.contrato.includes("CRIAR"))
			this.message = 'Você não possui permissão para executar esta ação'
	}

	constructor(public loginService: LoginService) {
		
	}
	
	
}