import { Component, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { ProjetoService } from 'src/app/projeto/projeto.service';
import { Projeto } from 'src/app/projeto/projeto';
import { PessoaService } from '../../../pessoa/pessoa.service';
import { PreCadastroService } from 'src/app/pre-cadastro/pre-cadastro.service';
import { ContratoService } from 'src/app/contrato/contrato.service';
import { SYSCONF_SIGLA } from 'src/app.api';
import { DataService } from '../../../_services/data.service';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
declare var $:any;
@Component({
	selector: 'app-form-projeto',
  	templateUrl: './form-projeto.component.html',
  	styleUrls: ['./form-projeto.component.scss']
})
export class FormProjetoComponent{
    @ViewChild('Swal') public swal:SwalComponent; 
	@Input() form: FormGroup;
	@Input() rota:string;
	@Input() acao:string;
	@Input() detalhe:boolean;
	projeto: Projeto;
	submitted: boolean = false;
	loading: boolean = false;
	id:number;    
	tiposPessoasSelect = new Array();
	pessoasSelect = new Array();
	enquadramentosSelect= new Array();
	projetosSelect= new Array();
	contratosSelect= new Array();
	statusSelect = new Array();
	slugs = new Array();
	disabled: boolean;
	fontesDeRecursosSelect = new Array();
	constructor(private pessoaService: PessoaService, private projetoService: ProjetoService, private contratoService: ContratoService, private preCadastroService:PreCadastroService, private router: Router, private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, private dt: DataService){
		this.preCadastroService.getOptions('financeiro/recurso').subscribe((success) => {
			this.fontesDeRecursosSelect = success.dados;
		},(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		})
		this.projetoService.getOptions('projeto').subscribe((success) => {
			this.projetosSelect = success.dados;
		})

		this.contratoService.getOptions('contrato').subscribe((success) => {
			this.contratosSelect = success.dados;
		})

		this.preCadastroService.getOptions('projeto/status').subscribe((success) => {
			this.statusSelect = success.dados;
		},(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		})

		this.preCadastroService.getOptions('projeto/enquadramento').subscribe((success) => {
			this.enquadramentosSelect = success.dados;
		})

		if(this.activatedRoute.snapshot.params['id'])
			this.id = this.activatedRoute.snapshot.params['id'];
	}

	verificaPeriodo(dt_inicio,dt_termino,titulo){
		if(!dt_inicio || !dt_termino)
		return;
		let convert_dt_inicio = new Date(dt_inicio.year, dt_inicio.month-1, dt_inicio.day)
		let convert_dt_termino = new Date(dt_termino.year, dt_termino.month-1, dt_termino.day)
		if(convert_dt_inicio > convert_dt_termino){
			if(titulo == 'Planejamento'){
				this.form.patchValue({
					dt_plan_termino: null
				})
			}
			else if(titulo == 'Execução'){
				this.form.patchValue({
					dt_execucao_termino: null
				})
			}
			else if(titulo == 'Pós Execução'){
				this.form.patchValue({
					dt_posexecucao_termino: null
				})
			}
			this.swal.text = 'O início da Data de '+titulo+' deve ser inferior ao término da Data de '+titulo+'!'; 
			this.swal.type = "warning";
			this.swal.title = "Atenção!";
			this.swal.show();
			return;
		}
	}

	initForm(){
		if(this.acao == 'buscar'){
			this.form = this.formBuilder.group({
				id: this.formBuilder.control(''),
				codigo_cob: this.formBuilder.control(''),
				projeto_status_id: this.formBuilder.control(''),
				projeto_enquadramento_id: this.formBuilder.control(''),
				financeiro_recurso_id:  this.formBuilder.control(''),
				projeto_id: this.formBuilder.control(''),
				tipo_pessoa_id: this.formBuilder.control(''),
				pessoa_id: this.formBuilder.control(''),
				dt_cadastro: this.formBuilder.control(''),
				dt_atualizacao: this.formBuilder.control(''),
				nome: this.formBuilder.control(''),
				contrato_id: this.formBuilder.control(''),
				contrato_numero: this.formBuilder.control(''),
				apresentacao: this.formBuilder.control(''),
				justificativa: this.formBuilder.control(''),
				objetivo_geral: this.formBuilder.control(''),
				objetivo_especifico: this.formBuilder.control(''),
				observacao: this.formBuilder.control(''),
				de_dt_plan_inicio: this.formBuilder.control(null),
				ate_dt_plan_inicio: this.formBuilder.control(null),
				de_dt_plan_termino: this.formBuilder.control(null),
				ate_dt_plan_termino: this.formBuilder.control(null),
				de_dt_execucao_inicio: this.formBuilder.control(null),
				ate_dt_execucao_inicio: this.formBuilder.control(null),
				de_dt_execucao_termino: this.formBuilder.control(null),
				ate_dt_execucao_termino: this.formBuilder.control(null),
				de_dt_posexecucao_inicio: this.formBuilder.control(null),
				ate_dt_posexecucao_inicio: this.formBuilder.control(null),
				de_dt_posexecucao_termino: this.formBuilder.control(null),
				ate_dt_posexecucao_termino: this.formBuilder.control(null),
				local_realizacao: this.formBuilder.control(''),
				valor_previsto: this.formBuilder.control(''),
				de_valor: this.formBuilder.control(''),
				ate_valor: this.formBuilder.control(''),
				saldo: this.formBuilder.control(''),
				status: this.formBuilder.control('')
			});	
			return;
		}
		this.form = this.formBuilder.group({
			id: this.formBuilder.control(''),
			codigo_cob: this.formBuilder.control(''),
			projeto_status_id: this.formBuilder.control('',[Validators.required]),
			projeto_status: this.formBuilder.control(''),
			projeto_enquadramento_id: this.formBuilder.control('',[Validators.required]),
			financeiro_recurso_id:  this.formBuilder.control('',[Validators.required]),
			financeiro_recurso:  this.formBuilder.control(''),
			projeto_enquadramento: this.formBuilder.control(''),
			projeto_id: this.formBuilder.control(''),
			projeto: this.formBuilder.control(''),
			tipo_pessoa_id: this.formBuilder.control('',[Validators.required]),
			tipo_pessoa: this.formBuilder.control(''),
			pessoa_id: this.formBuilder.control('',[Validators.required]),
			pessoa: this.formBuilder.control(''),
			dt_cadastro: this.formBuilder.control(''),
			dt_atualizacao: this.formBuilder.control(''),
			nome: this.formBuilder.control('',[Validators.required]),
			contrato_id: this.formBuilder.control(''),
			contrato_numero: this.formBuilder.control(''),
			apresentacao: this.formBuilder.control('',[Validators.required]),
			justificativa: this.formBuilder.control('',[Validators.required]),
			objetivo_geral: this.formBuilder.control('',[Validators.required]),
			objetivo_especifico: this.formBuilder.control('',[Validators.required]),
			observacao: this.formBuilder.control(''),
			dt_plan_inicio: this.formBuilder.control(null,[Validators.required]),
			dt_plan_termino: this.formBuilder.control(null,[Validators.required]),
			dt_execucao_inicio: this.formBuilder.control(null,[Validators.required]),
			dt_execucao_termino: this.formBuilder.control(null,[Validators.required]),
			dt_posexecucao_inicio: this.formBuilder.control(null),
			dt_posexecucao_termino: this.formBuilder.control(null),
			local_realizacao: this.formBuilder.control(''),
			valor_previsto: this.formBuilder.control('',[Validators.required]),
			saldo: this.formBuilder.control('')
		});	
	}

	//Carrega o campo "Executor" de acordo com o "Tipo de Executor"
	loadTiposPessoas(id, pessoa_id:any = ''){
		this.form.patchValue({pessoa_id: pessoa_id})
		this.pessoasSelect = new Array();
		let endpoint = this.tiposPessoasSelect.find(c => c.id == id);
		if(!endpoint)
			return;
		this.pessoaService.getOptions('pessoa/'+endpoint.slug).subscribe((success) => {
			this.pessoasSelect = success.dados;	
		},(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		})
		
	}

	ngOnInit(){
		
		this.disabled = this.acao == 'edit' ? true : false;
		this.initForm();
		if(this.id){
			this.pessoaService.getOptions('pessoa/tipo').subscribe((success) => {
				this.tiposPessoasSelect = success.dados;
				this.projetoService.getProjetoById(this.id).subscribe((projeto) =>{
					this.projeto = projeto.dados;
					this.form.patchValue({
						id: this.projeto.id,
						financeiro_recurso_id: this.projeto.financeiro_recurso_id,
						financeiro_recurso: this.projeto.financeiro_recurso,
						codigo_cob: this.projeto.codigo_cob,
						projeto_status_id: this.projeto.projeto_status_id,
						projeto_status: this.projeto.projeto_status,
						projeto_enquadramento_id: this.projeto.projeto_enquadramento_id,
						projeto_enquadramento: this.projeto.projeto_enquadramento,
						projeto_id: this.projeto.projeto_id,
						projeto: this.projeto.projeto,
						tipo_pessoa_id: this.projeto.tipo_pessoa_id,
						tipo_pessoa: this.projeto.tipo_pessoa,
						pessoa_id: this.projeto.pessoa_id,
						pessoa: this.projeto.pessoa,
						dt_cadastro: this.projeto.dt_cadastro_br,
						dt_atualizacao: this.projeto.dt_atualizacao_br,
						nome: this.projeto.nome,
						contrato_id: this.projeto.contrato_id,
						contrato_numero: this.projeto.contrato_numero,
						apresentacao: this.projeto.apresentacao,
						justificativa: this.projeto.justificativa,
						objetivo_geral: this.projeto.objetivo_geral,
						objetivo_especifico: this.projeto.objetivo_especifico,
						observacao: this.projeto.observacao,
						dt_plan_inicio: this.dt.formataDataBr(this.projeto.dt_plan_inicio_br, this.detalhe),
						dt_plan_termino: this.dt.formataDataBr(this.projeto.dt_plan_termino_br, this.detalhe),
						dt_execucao_inicio: this.dt.formataDataBr(this.projeto.dt_execucao_inicio_br, this.detalhe),
						dt_execucao_termino: this.dt.formataDataBr(this.projeto.dt_execucao_termino_br, this.detalhe),
						dt_posexecucao_inicio: this.dt.formataDataBr(this.projeto.dt_posexecucao_inicio_br, this.detalhe),
						dt_posexecucao_termino: this.dt.formataDataBr(this.projeto.dt_posexecucao_termino_br, this.detalhe),
						local_realizacao: this.projeto.local_realizacao,
						valor_previsto: this.projeto.valor_previsto,
						saldo: this.projeto.saldo
					});	
					this.loadTiposPessoas(this.projeto.tipo_pessoa_id,this.projeto.pessoa_id);	
					
				},(error) => {
					this.swal.text = error.error.message;
					this.swal.type = "error";
					this.swal.title = "Erro!";
					this.swal.show();
				})
			},(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
			})
		}else{
			this.pessoaService.getOptions('pessoa/tipo').subscribe((success) => {
				this.tiposPessoasSelect = success.dados;
				
			},(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
			})
		}
		
	}
	onSubmit(){
		this.submitted = true;
        if(!this.form.valid)
		return;
		if(this.acao == "buscar"){
			this.convertData();
			this.router.navigate(['/'+SYSCONF_SIGLA+`/projeto/page/1`,this.form.value]);
			return;
		}
        this.projetoService[this.acao](this.form)
        .subscribe( (success) => {
			this.id = parseInt(success.dados.id);

			if(this.acao == 'add'){
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.text = `Projeto cadastrado com sucesso!`;
				this.swal.show();
				this.submitted = false;
			}
			else{
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.text = `Projeto atualizado com sucesso!`;
				this.swal.show();
				this.submitted = false;
			}
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}
	convertData(){
     		
		let de_dt_plan_inicio = this.dt.convertDataObject(this.form.value,'de_dt_plan_inicio');
		let ate_dt_plan_inicio = this.dt.convertDataObject(this.form.value,'ate_dt_plan_inicio');
		let de_dt_plan_termino = this.dt.convertDataObject(this.form.value,'de_dt_plan_termino');
		let ate_dt_plan_termino = this.dt.convertDataObject(this.form.value,'ate_dt_plan_termino');
		let de_dt_execucao_inicio = this.dt.convertDataObject(this.form.value,'de_dt_execucao_inicio');
		let ate_dt_execucao_inicio = this.dt.convertDataObject(this.form.value,'ate_dt_execucao_inicio');
		let de_dt_execucao_termino = this.dt.convertDataObject(this.form.value,'de_dt_execucao_termino');
		let ate_dt_execucao_termino = this.dt.convertDataObject(this.form.value,'ate_dt_execucao_termino');
		let de_dt_posexecucao_inicio = this.dt.convertDataObject(this.form.value,'de_dt_posexecucao_inicio');
		let ate_dt_posexecucao_inicio = this.dt.convertDataObject(this.form.value,'ate_dt_posexecucao_inicio');
		let de_dt_posexecucao_termino = this.dt.convertDataObject(this.form.value,'de_dt_posexecucao_termino');
		let ate_dt_posexecucao_termino = this.dt.convertDataObject(this.form.value,'ate_dt_posexecucao_termino');
			
		this.form.patchValue({
			de_dt_plan_inicio: de_dt_plan_inicio,				
			ate_dt_plan_inicio: ate_dt_plan_inicio,
			de_dt_plan_termino: de_dt_plan_termino,
			ate_dt_plan_termino: ate_dt_plan_termino,
			de_dt_execucao_inicio: de_dt_execucao_inicio,
			ate_dt_execucao_inicio: ate_dt_execucao_inicio,
			de_dt_execucao_termino: de_dt_execucao_termino,
			ate_dt_execucao_termino: ate_dt_execucao_termino,
			de_dt_posexecucao_inicio: de_dt_posexecucao_inicio,
			ate_dt_posexecucao_inicio: ate_dt_posexecucao_inicio,
			de_dt_posexecucao_termino: de_dt_posexecucao_termino,
			ate_dt_posexecucao_termino: ate_dt_posexecucao_termino,
		})
			
	}
	onCloseModal(){
		if(this.swal.type == "success"){
			this.router.navigate([this.rota, this.id]);
		}
	}
}