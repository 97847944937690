import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjetoService } from '../projeto.service';
import { SYSCONF_SIGLA } from 'src/app.api';
@Component({
	selector: 'app-projeto-detail',
  	templateUrl: './projetoDetail.component.html',
  	styleUrls: ['./projetoDetail.component.scss']
})

export class ProjetoDetailComponent implements OnInit{
	public message:string = '';
	documentos = new Array();
	atividades = new Array();
	etapas = new Array();
	instituicoesParceiras = new Array();
	metas = new Array();
	orcamentos = new Array();
	riscos = new Array();
	situacoes = new Array();
	stakeholders = new Array();
	projeto_id:number = null;
	sigla = SYSCONF_SIGLA;
	constructor(private projetoService: ProjetoService, private activatedRoute: ActivatedRoute){
		this.projetoService.getProjetoById(this.activatedRoute.snapshot.params['id']).subscribe(
			(success) =>{
				this.projeto_id = success.dados.id;
				this.atividades = success.dados.atividade;
				this.documentos = success.dados.documento;
				this.etapas = success.dados.etapa;
				this.instituicoesParceiras = success.dados.instituicao_parceira;
				this.metas = success.dados.meta;
				this.orcamentos = success.dados.orcamento;
				this.riscos = success.dados.risco;
				this.situacoes = success.dados.situacao;
				this.stakeholders = success.dados.stakeholder;
			},
			(error) => {
				this.message = error.error.message;
			})
	}
	ngOnInit(){
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.projeto.includes("CONSULTAR"))
			this.message = 'Você não possui permissão para executar esta ação'
	}
}