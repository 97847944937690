import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SYSCONF_API, SYSCONF_SIGLA, SYSCONF_APP_ID, SYSCONF_CREDENTIALS, SYSCONF_LIMIT } from '../../app.api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { tap } from 'rxjs/operators';
import { FormHelper } from '../_includes/forms/form-helper';
import { FormGroup, FormArray } from '@angular/forms';
import { Estoque } from './estoque';

@Injectable()
export class EstoqueService{
    estoque: Estoque[];
    private headers;
    cob = null;
    constructor(private http: HttpClient, private formHelper: FormHelper){
    this.cob = JSON.parse(localStorage.getItem('CONFLogin'));
        this.headers = {'App-Id': SYSCONF_APP_ID,'Content-Type':'application/json','App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
    }
    get(endpoint:string): Observable<any>{
        return this.http.get(`${SYSCONF_API}/estoque/${endpoint}?limit=10000&page=1`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response,
                error => error
            )
        );
    }

    export(tipo: 'excel' | 'pdf',endpoint: string, form: FormGroup, params: any) {
		let criterioUrl:string = '';
        let campos:string;
        campos = form.get('campos').value.join('&campos[]=');
        criterioUrl = this.formHelper.getConditionURL(params);
        return this.http.get(`${SYSCONF_API}/estoque/${endpoint}/exportar/${tipo}/?campos[]=${campos}&${criterioUrl}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response,
                error => error
            )
        );
	}

    getById(endpoint:string, id): Observable<any>{
        return this.http.get(`${SYSCONF_API}/estoque/${endpoint}/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response,
                error => error
            )
        );
    }
    getLoteBySuprimentoId(local_id: number, suprimento_id:number): Observable<any>{
        return this.http.get(`${SYSCONF_API}/estoque/suprimento/total-lote?suprimento=${suprimento_id}&local=${local_id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response,
                error => error
            )
        );
    }
    list(endpoint:string, params, limit = SYSCONF_LIMIT): Observable<any>{
        let criterioUrl:string = '';
        criterioUrl = this.formHelper.getConditionURL(params);
        return this.http.get(`${SYSCONF_API}/estoque/${endpoint}/?limit=${limit}&page=${params.page}&${criterioUrl}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response,
                error => error
            )
        );
    }
    add(endpoint:string, form: FormGroup, atributos:FormArray = null, arquivo: File = null, suprimentos:FormArray = null):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataEstoque(form, atributos, arquivo, suprimentos);
        return this.http.post(`${SYSCONF_API}/estoque/${endpoint}`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => this.estoque = response.dados,
                error => error
            )
        )
    }
    edit(endpoint:string, form: FormGroup, atributos:FormArray = null, arquivo: File = null, suprimentos:FormArray = null):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataEstoque(form, atributos, arquivo, suprimentos);
        return this.http.post(`${SYSCONF_API}/estoque/${endpoint}/${form.value.id}`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => this.estoque = response.dados,
                error => error
            )
        )
    }
    del(endpoint, id):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.delete(`${SYSCONF_API}/estoque/${endpoint}/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => this.estoque = response.dados,
                error => error
            )
        );
    }   
}