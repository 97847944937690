import { NivelService } from './../../../nivel/nivel.service';
import { Component, ViewChild, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { PessoaService } from '../../../pessoa/pessoa.service';
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from './../../../login/login.service';
import { Pessoa } from 'src/app/pessoa/pessoa';

declare var $: any;
@Component({
	selector: 'app-form-acesso',
  	templateUrl: './form-acesso.component.html',
  	styleUrls: ['./form-acesso.component.scss']
})
export class FormAcessoComponent implements OnInit{
	@ViewChild('Swal') public swal:SwalComponent;
	@ViewChild('SwalDelete') public swalDelete:SwalComponent; 
	@Input() acessos = new Array();
	@Input() endpoint: string;
	@Input() pessoa_id: number;
	// vamos chamar de funcionario porém pode ser atleta, 
	@Input() pessoa: Pessoa = new Pessoa();
	@Input() id:number; // id do funcionario, atleta, prestador de servico e etc..
	@Input() acao:string;
	@Input() detalhe:boolean;
	@Input() login:boolean;
	@Input() pessoa_status: string = 'A';
	form: FormGroup;
	acesso_id:number = null;
	modalTitle:string;
	submitted: boolean = false;
	loading: boolean = false;
	modalReference: NgbModalRef; 
	niveis;  
    checkpassword:string = '';
	exibe_nivel: boolean = false;
	constructor(public loginService: LoginService, private pessoaService: PessoaService, private nivelService: NivelService, private formBuilder: FormBuilder, private modalService: NgbModal){
	    this.nivelService.getNivel()
        .subscribe( (success) => {
			this.niveis = success.dados;
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
		
		this.form = this.formBuilder.group({
			login: this.formBuilder.control('',[Validators.required]),
			nivel_id: this.formBuilder.control('',[Validators.required]),
			status: this.formBuilder.control('A',[Validators.required]),
			senha: this.formBuilder.control('',[Validators.required]),
			confirmarSenha: this.formBuilder.control('',[Validators.required]),
		})
	}
	
	ngOnInit(){
		this.pessoaService.getPessoaById('pessoa/'+this.endpoint,this.id).subscribe((success)=>{
			this.pessoa.email = success.dados.email;
			this.form.patchValue({
				login: success.dados.email,
			});
			this.submitted = false;
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});

		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(typeof t.permissao.nivel != undefined){
			if(t.permissao.nivel.includes("EDITAR"))
				this.exibe_nivel = true;
		}
	}

	changeRadioValue(form,name,value){
		this[form].patchValue({[name]: value});
	}
	checkPassword(){
		if(this.form.value.senha == this.form.value.confirmarSenha){
			this.checkpassword = "";
			return true;
		}
		this.checkpassword = "As senhas não conferem!";
		return false;
	}
	onSubmit(){
		this.submitted = true;
        if(!this.form.valid || !this.checkPassword())
		return;
		this.modalReference.close();
		if(!this.acesso_id){
			this.pessoaService.addUsuario(this.pessoa_id,this.form)
			.subscribe( (success) => {
				this.swal.text = "Dados de acesso cadastrados com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
			return;
		}

		this.pessoaService.editUsuario(this.acesso_id,this.form)
			.subscribe( (success) => {
				this.swal.text = "Dados de acesso atualizados com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});

        
	}

	setValidators(id = null):void{
		this.form.get('senha').setValidators([]);
			this.form.get('confirmarSenha').setValidators([]);
		if(!id){
			this.form.get('senha').setValidators([Validators.required]);
			this.form.get('confirmarSenha').setValidators([Validators.required]);
		}
		this.form.get('senha').updateValueAndValidity();
		this.form.get('confirmarSenha').updateValueAndValidity();
	}

	openModal(modal,id:number = null) {
		this.setValidators(id);
		//Se id == null então é cadastro.
		if(!id){
			this.acesso_id = null;
			this.modalTitle = "Cadastrar Dados de Acesso";
			this.form.patchValue({
				login: this.pessoa.email,
				nivel_id: '',
				status: 'A',
				senha: '',
				confirmarSenha: '',
			});
			this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
			return;
		}
		
		//Senão é edição.
		this.acesso_id = id;
		this.modalTitle = "Editar Dados de Acesso";
		this.pessoaService.getUsuarioById(id).subscribe((success) => {
			this.form.patchValue({
				login: success.dados.login,
				nivel_id: success.dados.nivel,
				status: success.dados.status,
			});
			this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		})
	}

	askDelete(id:number){
		this.acesso_id = id;
		this.swalDelete.show();
	}

	confirmDelete(){
		this.pessoaService.delUsuario(this.acesso_id).subscribe( 
		(success) => {
			this.swal.text = "Dados de acesso deletados com sucesso!";
			this.swal.type = "success";
			this.swal.title = "Pronto!";
			this.swal.show();
			this.submitted = false;
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}

	onCloseModal(){
		if(this.swal.type == "success"){
			this.pessoaService.getPessoaById('pessoa/'+this.endpoint,this.id).subscribe((success)=>{
				this.acessos = success.dados.acesso;
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
		}
	}
}