import { LoginService } from 'src/app/login/login.service';
import { Injectable } from '@angular/core';
import {
  HttpEvent, 
  HttpInterceptor, 
  HttpHandler, 
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http'
import { Observable } from 'rxjs/Observable';
import {BehaviorSubject, throwError } from 'rxjs';
import 'rxjs/add/observable/throw'
import { tap, filter, take, switchMap, map, retry } from 'rxjs/operators';

@Injectable()

export class InterceptService  implements HttpInterceptor {
    private preloader = document.getElementsByClassName('preloader')[0];
    private refreshTokenInProgress = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    date = new Date();
    zero:string = '';
    zeroSeconds:string = '';
    zeroHours:string = '';
    zeroDate:string = '';
    zeroMilliseconds:string = '';
    SYSCONF_DATE:any;
    Milliseconds;
    constructor(private loginService: LoginService) { 
      if((this.date.getMonth()+1) < 10)
            this.zero = '0';
        if(this.date.getSeconds() < 10)
            this.zeroSeconds = '0';
        if(this.date.getHours() < 10)
            this.zeroHours = '0';
        if(this.date.getDate() < 10)
            this.zeroDate = '0';
        if(this.date.getMilliseconds() < 10)
            this.zeroMilliseconds = '0';
        
        this.Milliseconds = this.date.getMilliseconds();
        if(this.Milliseconds > 99){
            this.Milliseconds = 59;
        }
        
        this.SYSCONF_DATE = this.date.getFullYear()+'-'+this.zero+(this.date.getMonth()+1)+'-'+this.zeroDate+this.date.getDate()+' '+this.zeroHours+this.date.getHours()+':'+this.zeroSeconds+this.date.getSeconds()+':'+this.zeroMilliseconds+this.Milliseconds;
    }

	  //Interceptando todos as requisições HTTP e adicionando a Token de acesso.
  	intercept(request: HttpRequest<any>, next: HttpHandler):Observable<HttpEvent<any>> {
      return next.handle(this.addTokenHeader(request))
      .pipe(
        tap(event => {
          this.preloader.setAttribute("style","display:visible");
          if(event instanceof HttpResponse)
            this.preloader.setAttribute("style","display:none");

          let CONFLogin = JSON.parse(localStorage.getItem('CONFLogin'));
          let diferenca:any;
          if(CONFLogin){
            let t:any = new Date(CONFLogin.token.dt_expiracao);
            let m:any = new Date(); 
            diferenca = ( (t - m) / (1000*60));
          }
          if(!request.url.includes('auth') && diferenca <= 30){
            if (this.refreshTokenInProgress) {
              // Se refreshTokenInProgress for verdadeiro, vamos esperar até o refreshTokenSubject ter um valor não nulo...
              //.. O que significa que a nova token está pronta e podemos tentar a requisição novamente.
              return this.refreshTokenSubject.pipe(
                  filter(result => result !== null)
                  ,take(1)
                  ,map(() => next.handle(this.addTokenHeader(request))))
            } else {
                this.refreshTokenInProgress = true;
                // Setando o refreshTokenSubject para nulo para que as chamadas subsquentes da API aguardem até que a nova token seja capturada
                this.refreshTokenSubject.next(null);
                // Chamando o update-token, uma observable que será retornada com a chave.
                return this.loginService
                    .updateToken()
                    .subscribe(
                      (success) => {
                        this.loginService.setToken(success.dados)
                        //Quando a chamada ao updateToken acabar, nós setamos o refreshTokenInProgress = false
                        // Para a próxima vez que a token precisar ser renovada
                        this.refreshTokenInProgress = false;
                        this.refreshTokenSubject.next(success);
                        return next.handle(this.addTokenHeader(request));
                      },
                      (error) => {
                        this.refreshTokenInProgress = false;
                        return throwError(error);
                      })
                    }
            }
          })
      )
      .catch(error => {
        this.preloader.setAttribute("style","display:none");
        switch(error.status){
          case 404:
            return throwError(error);
          
          case 400:
            return throwError(error);

          case 403:
              return next.handle(this.addTokenHeader(request)).catch(err => {
                this.loginService.logout();
                return throwError(err);
              });
        }
        return throwError(error);
      })
    }

    addTokenHeader(request: HttpRequest<any>) {
      if(request.url.includes('auth') || !request.url.includes('sysconf'))
        return request;
      // Se nao houver token de acesso, retornamos o request atual.
      let token = this.loginService.getToken();
      // Se houver token de acesso, adicionarmos ao cabecalho clonando o request, pois a original nao pode ser mudada.
      
      return request.clone({
          setHeaders: {
            'App-Token': token
          }
      });
  }
  
 
}
