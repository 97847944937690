import { FormGroup, FormArray } from '@angular/forms';
import { SYSCONF_PUBKEY } from 'src/app.api';
import { isArray } from 'util';
declare var JSEncrypt: any;
export class FormHelper {
    getConditionURL(params):string{
        let condicao = '';
        let criterios = new Array();
        if(params.id && params.id != '')
            criterios.push('id='+params.id);
        if(params.cpf && params.cpf != '')
            criterios.push('cpf='+params.cpf);
        if(params.primeiro_nome && params.primeiro_nome != '')
            criterios.push('primeiro_nome='+params.primeiro_nome);
        if(params.ultimo_nome && params.ultimo_nome != '')
            criterios.push('ultimo_nome='+params.ultimo_nome);
        if(params.apelido && params.apelido != '')
            criterios.push('apelido='+params.apelido);
        if(params.razao_social && params.razao_social != '')
            criterios.push('razao_social='+params.razao_social);
        if(params.nome_completo && params.nome_completo != '')
            criterios.push('nome_completo='+params.nome_completo);
        if(params.cnpj && params.cnpj != '')
            criterios.push('cnpj='+params.cnpj);
        if(params.inscricao_estadual && params.inscricao_estadual != '')
            criterios.push('inscricao_estadual='+params.inscricao_estadual);
        if(params.nome_representante && params.nome_representante != '')
            criterios.push('nome_representante='+params.nome_representante);
        if(params.website && params.website != '')
            criterios.push('website='+params.website);
        if(params.presidente && params.presidente != '')
            criterios.push('presidente='+params.presidente);
        if(params.federacao_id && params.federacao_id != '')
            criterios.push('federacao_id='+params.federacao_id);
        if(params.federado && params.federado != '')
            criterios.push('federado='+params.federado);
        if(params.clube_id && params.clube_id != '')
            criterios.push('clube_id='+params.clube_id);
        if(params.sigla && params.sigla != '')
            criterios.push('sigla='+params.sigla);
        if(params.email && params.email != '')
            criterios.push('email='+params.email);
        if(params.genero_id && params.genero_id != '')
            criterios.push('genero_id='+params.genero_id);
        if(params.nacionalidade && params.nacionalidade != '')
            criterios.push('nacionalidade='+params.nacionalidade);
        if(params.naturalidade && params.naturalidade != '')
            criterios.push('naturalidade='+params.naturalidade);
        if(params.pis && params.pis != '')
            criterios.push('pis='+params.pis);
        if(params.grau_instrucao_id && params.grau_instrucao_id != '')
            criterios.push('grau_instrucao_id='+params.grau_instrucao_id);
        if(params.nome_pai && params.nome_pai != '')
            criterios.push('nome_pai='+params.nome_pai);
        if(params.nome_mae && params.nome_mae != '')
            criterios.push('nome_mae='+params.nome_mae);
        if(params.peso && params.peso != '')
            criterios.push('peso='+params.peso);
        if(params.altura && params.altura != '')
            criterios.push('altura='+params.altura);
        if(params.tipo_sanguineo && params.tipo_sanguineo != '')
            criterios.push('tipo_sanguineo='+params.tipo_sanguineo);
        if(params.tamanho_camisa && params.tamanho_camisa != '')
            criterios.push('tamanho_camisa='+params.tamanho_camisa);
        if(params.tamanho_agasalho && params.tamanho_agasalho != '')
            criterios.push('tamanho_agasalho='+params.tamanho_agasalho);
        if(params.tamanho_calca && params.tamanho_calca != '')
            criterios.push('tamanho_calca='+params.tamanho_calca);
        if(params.tamanho_calcado && params.tamanho_calcado != '')
            criterios.push('tamanho_calcado='+params.tamanho_calcado);
        if(params.tamanho_chinelo && params.tamanho_chinelo != '')
            criterios.push('tamanho_chinelo='+params.tamanho_chinelo);
        if(params.dt_nascimento && params.dt_nascimento != '')
            criterios.push('dt_nascimento='+params.dt_nascimento);
        if(isArray(params.status)){
            params.status.forEach((value : any) => {
                criterios.push('status[]='+value);
            })
        }
        else if(params.status && params.status != '')
            criterios.push('status='+params.status);
        if(params.rg && params.rg != '')
            criterios.push('rg='+params.rg);
        if(params.rg_dt_emissao && params.rg_dt_emissao != '')
            criterios.push('rg_dt_emissao='+params.rg_dt_emissao);
        if(params.rg_uf && params.rg_uf != '')
            criterios.push('rg_uf='+params.rg_uf);
        if(params.rg_emissor && params.rg_emissor != '')
            criterios.push('rg_emissor='+params.rg_emissor);
        if(params.situacao_federacao && params.situacao_federacao != '')
            criterios.push('situacao_federacao='+params.situacao_federacao);
        if(params.dt_registro_federacao && params.dt_registro_federacao != '')
            criterios.push('dt_registro_federacao='+params.dt_registro_federacao);
        if(params.situacao_confederacao && params.situacao_confederacao != '')
            criterios.push('situacao_confederacao='+params.situacao_confederacao);
        if(params.graduacao && params.graduacao != '')
            criterios.push('graduacao='+params.graduacao);
        if(params.dt_graduacao && params.dt_graduacao != '')
            criterios.push('dt_graduacao='+params.dt_graduacao);
        if(params.estado && params.estado != '')
            criterios.push('estado='+params.estado);
        if(params.dt_exame && params.dt_exame != '')
            criterios.push('dt_exame='+params.dt_exame);
        if(params.ano_inicio_carreira && params.ano_inicio_carreira != '')
            criterios.push('ano_inicio_carreira='+params.ano_inicio_carreira);
        if(params.dt_registro && params.dt_registro != '')
            criterios.push('dt_registro='+params.dt_registro);
        if(params.dt_registro)
            criterios.push('dt_registro='+params.dt_registro);
        if(params.cref_numero)
            criterios.push('cref_numero='+params.cref_numero);
        if(params.cref_dt_expedicao)
            criterios.push('cref_dt_expedicao='+params.cref_dt_expedicao);
        if(params.cref_dt_validade)
            criterios.push('cref_dt_validade='+params.cref_dt_validade);
        if(params.modalidade){
            let mod = params.modalidade.split(',');
            mod.forEach((value : any) => {
				criterios.push('modalidade_id[]='+value);
			})
        }
        if(params.tipo)
            criterios.push('tipo='+params.tipo); 
                
        if(params.dt_admissao)
            criterios.push('dt_admissao='+params.dt_admissao);
        if(params.dt_desligamento)
            criterios.push('dt_desligamento='+params.dt_desligamento);
        if(params.estado_civil_id)
            criterios.push('estado_civil_id='+params.estado_civil_id);
        if(params.observacao)
            criterios.push('observacao='+params.observacao);
        if(params.contrato_status_id)
            criterios.push('contrato_status_id='+params.contrato_status_id); 
        if(params.gestor_id)
            criterios.push('gestor_id='+params.gestor_id);    
        if(params.tipo_pessoa_id)
            criterios.push('tipo_pessoa_id='+params.tipo_pessoa_id); 
        if(params.pessoa_id)
            criterios.push('pessoa_id='+params.pessoa_id); 
        if(params.dt_atualizacao)
            criterios.push('dt_atualizacao='+params.dt_atualizacao); 
        if(params.dt_inicio)
            criterios.push('dt_inicio='+params.dt_inicio); 
        if(params.dt_termino)
            criterios.push('dt_termino='+params.dt_termino); 
        if(params.objeto)
            criterios.push('objeto='+params.objeto); 
        if(params.numero)
            criterios.push('numero='+params.numero); 
        if(params.valor)
            criterios.push('valor='+params.valor);
        if(params.de_valor)
            criterios.push('de_valor='+params.de_valor);
        if(params.ate_valor)
            criterios.push('ate_valor='+params.ate_valor);
        if(params.contrato_natureza_id)
            criterios.push('contrato_natureza_id='+params.contrato_natureza_id);
        if(params.contrato_area_id)
            criterios.push('contrato_area_id='+params.contrato_area_id);
        if(params.irrf)
            criterios.push('irrf='+params.irrf);
        if(params.dnv)
            criterios.push('dnv='+params.dnv);
        if(params.cartorio_registro)
            criterios.push('cartorio_registro='+params.cartorio_registro);
        if(params.plano_saude)
            criterios.push('plano_saude='+params.plano_saude);
        if(params.cartorio_nome)
            criterios.push('cartorio_nome='+params.cartorio_nome);
        if(params.cartorio_livro)
            criterios.push('cartorio_livro='+params.cartorio_livro);
        if(params.cartorio_folha)
            criterios.push('cartorio_folha='+params.cartorio_folha);
        if(params.codigo_cob)
            criterios.push('codigo_cob='+params.codigo_cob); 
        if(params.projeto_status_id)
            criterios.push('projeto_status_id='+params.projeto_status_id); 
        if(params.projeto_enquadramento_id)
            criterios.push('projeto_enquadramento_id='+params.projeto_enquadramento_id); 
        if(params.projeto_id)
            criterios.push('projeto_id='+params.projeto_id); 
        if(params.contrato_id)
            criterios.push('contrato_id='+params.contrato_id); 
        if(params.estoque_movimento_id)
            criterios.push('estoque_movimento_id='+params.estoque_movimento_id)
        if(params.nome)
            criterios.push('nome='+params.nome); 
        if(params.apresentacao)
            criterios.push('apresentacao='+params.apresentacao); 
        if(params.justificativa)
            criterios.push('justificativa='+params.justificativa);
        if(params.objetivo_geral)
            criterios.push('objetivo_geral='+params.objetivo_geral);
        if(params.objetivo_especifico)
            criterios.push('objetivo_especifico='+params.objetivo_especifico);
        if(params.de_dt_plan_inicio)
            criterios.push('de_dt_plan_inicio='+params.de_dt_plan_inicio);
        if(params.ate_dt_plan_inicio)
            criterios.push('ate_dt_plan_inicio='+params.ate_dt_plan_inicio);
        if(params.de_dt_plan_termino)
            criterios.push('de_dt_plan_termino='+params.de_dt_plan_termino);
        if(params.ate_dt_plan_termino)
            criterios.push('ate_dt_plan_termino='+params.ate_dt_plan_termino);
        if(params.de_dt_execucao_inicio)
            criterios.push('de_dt_execucao_inicio='+params.de_dt_execucao_inicio);
        if(params.ate_dt_execucao_inicio)
            criterios.push('ate_dt_execucao_inicio='+params.ate_dt_execucao_inicio);
        if(params.de_dt_execucao_termino)
            criterios.push('de_dt_execucao_termino='+params.de_dt_execucao_termino);
        if(params.ate_dt_execucao_termino)
            criterios.push('ate_dt_execucao_termino='+params.ate_dt_execucao_termino);
        if(params.de_dt_posexecucao_inicio)
            criterios.push('de_dt_posexecucao_inicio='+params.de_dt_posexecucao_inicio);
        if(params.ate_dt_posexecucao_inicio)
            criterios.push('ate_dt_posexecucao_inicio='+params.ate_dt_posexecucao_inicio);
        if(params.de_dt_posexecucao_termino)
            criterios.push('de_dt_posexecucao_termino='+params.de_dt_posexecucao_termino);
        if(params.ate_dt_posexecucao_termino)
            criterios.push('ate_dt_posexecucao_termino='+params.ate_dt_posexecucao_termino);
        if(params.local_realizacao)
            criterios.push('local_realizacao='+params.local_realizacao);
        if(params.valor_previsto)
            criterios.push('valor_previsto='+parseFloat(params.valor_previsto).toFixed(2));
        if(params.de_valor)
            criterios.push('de_valor='+parseFloat(params.de_valor).toFixed(2));
        if(params.ate_valor)
            criterios.push('ate_valor='+parseFloat(params.ate_valor).toFixed(2));
        if(params.saldo)
            criterios.push('saldo='+parseFloat(params.saldo).toFixed(2));
        if(params.de_valor_bruto)
            criterios.push('de_valor_bruto='+parseFloat(params.de_valor_bruto).toFixed(2));
        if(params.ate_valor_bruto)
            criterios.push('ate_valor_bruto='+parseFloat(params.ate_valor_bruto).toFixed(2));
        if(params.de_valor_liquido)
            criterios.push('de_valor_liquido='+parseFloat(params.de_valor_liquido).toFixed(2));
        if(params.ate_valor_liquido)
            criterios.push('ate_valor_liquido='+parseFloat(params.ate_valor_liquido).toFixed(2));
        if(params.de_juros)
            criterios.push('de_juros='+parseFloat(params.de_juros).toFixed(2));
        if(params.ate_juros)
            criterios.push('ate_juros='+parseFloat(params.ate_juros).toFixed(2));
        if(params.de_desconto)
            criterios.push('de_desconto='+parseFloat(params.de_desconto).toFixed(2));
        if(params.ate_desconto)
            criterios.push('ate_desconto='+parseFloat(params.ate_desconto).toFixed(2));
        if(params.de_dt_emissao)
            criterios.push('de_dt_emissao='+params.de_dt_emissao);
        if(params.ate_dt_emissao)
            criterios.push('ate_dt_emissao='+params.ate_dt_emissao);
        if(params.de_dt_vencimento)
            criterios.push('de_dt_vencimento='+params.de_dt_vencimento);
        if(params.ate_dt_vencimento)
            criterios.push('ate_dt_vencimento='+params.ate_dt_vencimento);
        if(params.de_dt_previsao)
            criterios.push('de_dt_previsao='+params.de_dt_previsao);
        if(params.ate_dt_previsao)
            criterios.push('ate_dt_previsao='+params.ate_dt_previsao);
        if(params.de_dt_baixa)
            criterios.push('de_dt_baixa='+params.de_dt_baixa);
        if(params.ate_dt_baixa)
            criterios.push('ate_dt_baixa='+params.ate_dt_baixa);
        if(params.de_dt_conciliacao)
            criterios.push('de_dt_conciliacao='+params.de_dt_conciliacao);
        if(params.ate_dt_conciliacao)
            criterios.push('ate_dt_conciliacao='+params.ate_dt_conciliacao);
        if(params.de_dt_competencia)
            criterios.push('de_dt_competencia='+params.de_dt_competencia);
        if(params.ate_dt_competencia)
            criterios.push('ate_dt_competencia='+params.ate_dt_competencia);
        if(params.conta_bancaria_id && params.conta_bancaria_id != '')
            criterios.push('conta_bancaria_id='+params.conta_bancaria_id);
        if(params.origem_conta_bancaria_id && params.origem_conta_bancaria_id != '')
            criterios.push('origem_conta_bancaria_id='+params.origem_conta_bancaria_id);
        if(params.destino_conta_bancaria_id && params.destino_conta_bancaria_id != '')
            criterios.push('destino_conta_bancaria_id='+params.destino_conta_bancaria_id);
        if(params.plano_contas_id && params.plano_contas_id != '')
            criterios.push('plano_contas_id='+params.plano_contas_id);
        if(params.origem_plano_contas_id && params.origem_plano_contas_id != '')
            criterios.push('origem_plano_contas_id='+params.origem_plano_contas_id);
        if(params.destino_plano_contas_id && params.destino_plano_contas_id != '')
            criterios.push('destino_plano_contas_id='+params.destino_plano_contas_id);
        if(params.centro_custo_id && params.centro_custo_id != '')
            criterios.push('centro_custo_id='+params.centro_custo_id);
        if(params.origem_centro_custo_id && params.origem_centro_custo_id != '')
            criterios.push('origem_centro_custo_id='+params.origem_centro_custo_id);
        if(params.destino_centro_custo_id && params.destino_centro_custo_id != '')
            criterios.push('destino_centro_custo_id='+params.destino_centro_custo_id);
        if(params.forma_pagamento_id && params.forma_pagamento_id != '')
            criterios.push('forma_pagamento_id='+params.forma_pagamento_id);
        if(params.descricao && params.descricao != '')
            criterios.push('descricao='+params.descricao);
        if(params.codigo_barras && params.codigo_barras != '')
            criterios.push('codigo_barras='+params.codigo_barras);
        if(params.numero_doc && params.numero_doc != '')
            criterios.push('numero_doc='+params.numero_doc);
        if(params.nosso_numero && params.nosso_numero != '')
            criterios.push('nosso_numero='+params.nosso_numero);
        if(params.conta_transferencia_id && params.conta_transferencia_id != '')
            criterios.push('conta_transferencia_id='+params.conta_transferencia_id);
        if(params.funcionario_id)
            criterios.push('funcionario_id='+params.funcionario_id);
        if(params.grau_parentesco_id)
            criterios.push('grau_parentesco_id='+params.grau_parentesco_id);
        if(params.estrangeiro)
            criterios.push('estrangeiro='+params.estrangeiro);
        if(params.passaporte)
            criterios.push('passaporte='+params.passaporte);
        if(params.passaporte_dt_validade)
            criterios.push('passaporte_dt_validade='+params.passaporte_dt_validade);
        if(params.atleta_id)
            criterios.push('atleta_id='+params.atleta_id);
        if(params.sku)
            criterios.push('sku='+params.sku);
        if(params.upc)
            criterios.push('upc='+params.upc);
        if(params.suprimento_categoria_id)
            criterios.push('suprimento_categoria_id='+params.suprimento_categoria_id);
        if(params.suprimento_tipo_id)
            criterios.push('suprimento_tipo_id='+params.suprimento_tipo_id);
        if(params.suprimento_marca_id)
            criterios.push('suprimento_marca_id='+params.suprimento_marca_id);
        if(params.suprimento_unidade_id)
            criterios.push('suprimento_unidade_id='+params.suprimento_unidade_id);
        if(params.suprimento_id)
            criterios.push('suprimento_id='+params.suprimento_id);
        if(params.origem_local_id)
            criterios.push('origem_local_id='+params.origem_local_id);
        if(params.destino_local_id)
            criterios.push('destino_local_id='+params.destino_local_id);
        if(params.nota_fiscal)
            criterios.push('nota_fiscal='+params.nota_fiscal);
        if(params.lote)
            criterios.push('lote='+params.lote);
        if(params.de_dt_movimento)
            criterios.push('de_dt_movimento='+params.de_dt_movimento);
        if(params.ate_dt_movimento)
            criterios.push('ate_dt_movimento='+params.ate_dt_movimento);
        if(params.de_estoque_minimo)
            criterios.push('de_estoque_minimo='+params.de_estoque_minimo);
        if(params.ate_estoque_minimo)
            criterios.push('ate_estoque_minimo='+params.ate_estoque_minimo);
        if(params.financeiro_recurso_id)
            criterios.push('financeiro_recurso_id='+params.financeiro_recurso_id)
        if(params.selecao && params.selecao != '')
            criterios.push('selecao='+params.selecao);   
        if(params.ordem && params.ordem != '')
            criterios.push('ordem='+params.ordem);
        if(params.ordem_direcao && params.ordem_direcao != '')
            criterios.push('ordem_direcao='+params.ordem_direcao);
        
        if(params.de_dt_inicio)
            criterios.push('de_dt_inicio='+params.de_dt_inicio);
        if(params.ate_dt_termino)
            criterios.push('ate_dt_termino='+params.ate_dt_termino);
        if(params.area_funcional)
            criterios.push('area_funcional='+params.area_funcional);

            
        if(params){
            condicao = criterios.join('&');
        }
        return condicao;
    }

    /* Pessoa Física e Jurídica */
    createFormDataPessoa(form: FormGroup, foto: File):FormData{
        let formulario = new FormData();
        if(foto)
            formulario.append('foto',foto); 

        /* Utilizado para duplicar cadastro para outro tipo. Ex: Atleta já existente, será inserido como funcionário no sysconf. */
        if(form.value.pessoa_id)
        formulario.append('pessoa_id',form.value.pessoa_id); 
        /* ------ */

        if(typeof form.value.nome_completo !== 'undefined' && form.value.nome_completo !== null )
            formulario.append('nome_completo',form.value.nome_completo);
            if(typeof form.value.primeiro_nome !== 'undefined' && form.value.primeiro_nome !== null )
            formulario.append('primeiro_nome',form.value.primeiro_nome);
        if(typeof form.value.ultimo_nome !== 'undefined' && form.value.ultimo_nome !== null )
            formulario.append('ultimo_nome',form.value.ultimo_nome);
        if(typeof form.value.apelido !== 'undefined' && form.value.apelido !== null )
            formulario.append('apelido',form.value.apelido);
        if(typeof form.value.razao_social !== 'undefined' && form.value.razao_social !== null )
            formulario.append('razao_social',form.value.razao_social);
        if(typeof form.value.cnpj !== 'undefined' && form.value.cnpj !== null )
            formulario.append('cnpj',form.value.cnpj);
        if(typeof form.value.inscricao_estadual !== 'undefined' && form.value.inscricao_estadual !== null )
            formulario.append('inscricao_estadual',form.value.inscricao_estadual);
        if(typeof form.value.nome_representante !== 'undefined' && form.value.nome_representante !== null )
            formulario.append('nome_representante',form.value.nome_representante);
        if(typeof form.value.website !== 'undefined' && form.value.website !== null )
            formulario.append('website',form.value.website);
        if(typeof form.value.presidente !== 'undefined' && form.value.presidente !== null )
            formulario.append('presidente',form.value.presidente);
        if(typeof form.value.federacao_id !== 'undefined' && form.value.federacao_id !== null )
            formulario.append('federacao_id',form.value.federacao_id);
        if(typeof form.value.federado !== 'undefined' && form.value.federado !== null )
            formulario.append('federado',form.value.federado);
        if(typeof form.value.clube_id !== 'undefined' && form.value.clube_id !== null )
            formulario.append('clube_id',form.value.clube_id);
        if(typeof form.value.selecao !== 'undefined' && form.value.selecao !== null )
            formulario.append('selecao',form.value.selecao);
        if(typeof form.value.sigla !== 'undefined' && form.value.sigla !== null )
            formulario.append('sigla',form.value.sigla);
        if(typeof form.value.email !== 'undefined' && form.value.email !== null )
            formulario.append('email',form.value.email);
        if(typeof form.value.genero_id !== 'undefined' && form.value.genero_id !== null )
            formulario.append('genero_id',form.value.genero_id);
        if(typeof form.value.nacionalidade !== 'undefined' && form.value.nacionalidade !== null )
            formulario.append('nacionalidade',form.value.nacionalidade);
        if(typeof form.value.naturalidade !== 'undefined' && form.value.naturalidade !== null )
            formulario.append('naturalidade',form.value.naturalidade);
        if(typeof form.value.pis !== 'undefined' && form.value.pis !== null )
            formulario.append('pis',form.value.pis);
        if(typeof form.value.grau_instrucao_id !== 'undefined' && form.value.grau_instrucao_id !== null )
            formulario.append('grau_instrucao_id',form.value.grau_instrucao_id);
        if(typeof form.value.nome_pai !== 'undefined' && form.value.nome_pai !== null )
            formulario.append('nome_pai',form.value.nome_pai);
        if(typeof form.value.nome_mae !== 'undefined' && form.value.nome_mae !== null )
            formulario.append('nome_mae',form.value.nome_mae);
        if(typeof form.value.peso !== 'undefined' && form.value.peso !== null )
            formulario.append('peso',form.value.peso);
        if(typeof form.value.altura !== 'undefined' && form.value.altura !== null )
            formulario.append('altura',form.value.altura);
        if(typeof form.value.tipo_sanguineo !== 'undefined' && form.value.tipo_sanguineo !== null )
            formulario.append('tipo_sanguineo',form.value.tipo_sanguineo);
        if(typeof form.value.tamanho_camisa !== 'undefined' && form.value.tamanho_camisa !== null )
            formulario.append('tamanho_camisa',form.value.tamanho_camisa);
        if(typeof form.value.tamanho_agasalho !== 'undefined' && form.value.tamanho_agasalho !== null )
            formulario.append('tamanho_agasalho',form.value.tamanho_agasalho);
        if(typeof form.value.tamanho_calca !== 'undefined' && form.value.tamanho_calca !== null )
            formulario.append('tamanho_calca',form.value.tamanho_calca);
        if(typeof form.value.tamanho_calcado !== 'undefined' && form.value.tamanho_calcado !== null )
            formulario.append('tamanho_calcado',form.value.tamanho_calcado);
        if(typeof form.value.tamanho_chinelo !== 'undefined' && form.value.tamanho_chinelo !== null )
            formulario.append('tamanho_chinelo',form.value.tamanho_chinelo);
        if(typeof form.value.cpf !== 'undefined' && form.value.cpf !== null )
            formulario.append('cpf',form.value.cpf);
        if(typeof form.value.dt_nascimento !== 'undefined' && form.value.dt_nascimento !== null )
            formulario.append('dt_nascimento',form.value.dt_nascimento);
        if(typeof form.value.status !== 'undefined' && form.value.status !== null )
            formulario.append('status',form.value.status);
        if(typeof form.value.rg !== 'undefined' && form.value.rg !== null )
            formulario.append('rg',form.value.rg);
        if(typeof form.value.rg_dt_emissao !== 'undefined' && form.value.rg_dt_emissao !== null )
            formulario.append('rg_dt_emissao',form.value.rg_dt_emissao);
        if(typeof form.value.rg_uf !== 'undefined' && form.value.rg_uf !== null )
            formulario.append('rg_uf',form.value.rg_uf);
        if(typeof form.value.rg_emissor !== 'undefined' && form.value.rg_emissor !== null )
            formulario.append('rg_emissor',form.value.rg_emissor);
        if(typeof form.value.situacao_federacao !== 'undefined' && form.value.situacao_federacao !== null )
            formulario.append('situacao_federacao',form.value.situacao_federacao);
        if(typeof form.value.dt_registro_federacao !== 'undefined' && form.value.dt_registro_federacao !== null )
            formulario.append('dt_registro_federacao',form.value.dt_registro_federacao);
        if(typeof form.value.situacao_confederacao !== 'undefined' && form.value.situacao_confederacao !== null )
            formulario.append('situacao_confederacao',form.value.situacao_confederacao);
        if(typeof form.value.graduacao_id !== 'undefined' && form.value.graduacao_id !== null )
            formulario.append('graduacao_id',form.value.graduacao_id);
        if(typeof form.value.dt_graduacao !== 'undefined' && form.value.dt_graduacao !== null )
            formulario.append('dt_graduacao',form.value.dt_graduacao);
        if(typeof form.value.estado !== 'undefined' && form.value.estado !== null )
            formulario.append('estado',form.value.estado);
        if(typeof form.value.dt_exame !== 'undefined' && form.value.dt_exame !== null )
            formulario.append('dt_exame',form.value.dt_exame);
        if(typeof form.value.ano_inicio_carreira !== 'undefined' && form.value.ano_inicio_carreira !== null)
            formulario.append('ano_inicio_carreira',form.value.ano_inicio_carreira);
        if(typeof form.value.dt_registro !== 'undefined' && form.value.dt_registro !== null)
            formulario.append('dt_registro',form.value.dt_registro);
        if(typeof form.value.cref_numero !== 'undefined' && form.value.cref_numero !== null )
            formulario.append('cref_numero',form.value.cref_numero);
        if(typeof form.value.cref_dt_expedicao !== 'undefined' && form.value.cref_dt_expedicao !== null )
            formulario.append('cref_dt_expedicao',form.value.cref_dt_expedicao);
        if(typeof form.value.cref_dt_validade !== 'undefined' && form.value.cref_dt_validade !== null )
            formulario.append('cref_dt_validade',form.value.cref_dt_validade);
        
        let dt_admissao = '';
        if(typeof form.value.dt_admissao == 'object' && form.value.dt_admissao != null)
            dt_admissao = `${form.value.dt_admissao.day}/${form.value.dt_admissao.month}/${form.value.dt_admissao.year}`;
        formulario.append('dt_admissao',dt_admissao); 
        
        let dt_desligamento = '';
        if(typeof form.value.dt_desligamento == 'object' && form.value.dt_desligamento != null)
            dt_desligamento = `${form.value.dt_desligamento.day}/${form.value.dt_desligamento.month}/${form.value.dt_desligamento.year}`;
        formulario.append('dt_desligamento',dt_desligamento); 
        
        if(typeof form.value.observacao !== 'undefined' && form.value.observacao !== null )
            formulario.append('observacao',form.value.observacao);
        if(typeof form.value.tipo !== 'undefined' && form.value.tipo !== null )
            formulario.append('tipo',form.value.tipo);
        if(typeof form.value.estado_civil_id !== 'undefined' && form.value.estado_civil_id !== null )
            formulario.append('estado_civil_id',form.value.estado_civil_id);
        if(typeof form.value.irrf !== 'undefined' && form.value.irrf !== null )
            formulario.append('irrf',form.value.irrf);
        if(typeof form.value.dnv !== 'undefined' && form.value.dnv !== null )
            formulario.append('dnv',form.value.dnv);
        if(typeof form.value.cartorio_registro !== 'undefined' && form.value.cartorio_registro !== null )
            formulario.append('cartorio_registro',form.value.cartorio_registro);
        if(typeof form.value.plano_saude !== 'undefined' && form.value.plano_saude !== null )
            formulario.append('plano_saude',form.value.plano_saude);
        if(typeof form.value.cartorio_nome !== 'undefined' && form.value.cartorio_nome !== null )
            formulario.append('cartorio_nome',form.value.cartorio_nome);
        if(typeof form.value.cartorio_livro !== 'undefined' && form.value.cartorio_livro !== null )
            formulario.append('cartorio_livro',form.value.cartorio_livro);
        if(typeof form.value.cartorio_folha !== 'undefined' && form.value.cartorio_folha !== null )
            formulario.append('cartorio_folha',form.value.cartorio_folha);
        if(typeof form.value.modalidade !== 'undefined' && form.value.modalidade !== null ){
            form.value.modalidade.forEach((key : any) => {
                formulario.append('modalidade[]',key);
            })
        }
        if(typeof form.value.estrangeiro !== 'undefined' && form.value.estrangeiro !== null )
            formulario.append('estrangeiro',form.value.estrangeiro);
        if(typeof form.value.passaporte !== 'undefined' && form.value.passaporte !== null )
            formulario.append('passaporte',form.value.passaporte);
        if(typeof form.value.passaporte_dt_validade !== 'undefined' && form.value.passaporte_dt_validade !== null )
            formulario.append('passaporte_dt_validade',form.value.passaporte_dt_validade);
        return formulario;
    }
    /* ------------------------ */

    /* Endereço */
    createFormDataEndereco(form: FormGroup, pessoa_id = null):FormData{
        let formulario = new FormData();
        if(pessoa_id)
            formulario.append('pessoa_id',pessoa_id);
        if(form.value.descricao)
            formulario.append('descricao',form.value.descricao);
        if(form.value.estrangeiro)
            formulario.append('estrangeiro',form.value.estrangeiro);

        if(form.value.estrangeiro == 'S'){
            if(form.value.codigo_postal)
                formulario.append('cep',form.value.codigo_postal);
            if(form.value.endereco1)
                formulario.append('endereco1',form.value.endereco1);
            if(form.value.endereco2)
                formulario.append('endereco2',form.value.endereco2);
            if(form.value.complemento)
                formulario.append('complemento',form.value.complemento);
        } else{
            if(form.value.cep)
                formulario.append('cep',form.value.cep);
            if(form.value.logradouro)
                formulario.append('logradouro',form.value.logradouro);
            if(form.value.numero)
                formulario.append('numero',form.value.numero);
            if(form.value.complemento)
                formulario.append('complemento',form.value.complemento);
            if(form.value.bairro)
                formulario.append('bairro',form.value.bairro);
            if(form.value.cidade)
                formulario.append('cidade',form.value.cidade);
            if(form.value.estado)
                formulario.append('estado',form.value.estado);
        }
        return formulario;
    }
    /* -------- */

    /* Telefone */
    createFormDataTelefone(form: FormGroup, pessoa_id = null):FormData{
        let formulario = new FormData();
        if(pessoa_id)
            formulario.append('pessoa_id',pessoa_id);
        if(form.value.referencia)
            formulario.append('referencia',form.value.referencia);
        if(form.value.numero)
            formulario.append('numero',form.value.numero);
        
        return formulario;
    }
    /* -------- */

    /* Idioma */
    createFormDataIdioma(form: FormGroup, pessoa_id = null):FormData{
        let formulario = new FormData();
        if(pessoa_id)
            formulario.append('pessoa_id',pessoa_id);
        if(form.value.idioma_id)
            formulario.append('idioma_id',form.value.idioma_id);
        if(form.value.nivel)
            formulario.append('nivel',form.value.nivel);
        
        return formulario;
    }
    /* -------- */

    /* Pessoas - Conta Bancária */
    createFormDataContaBancaria(form: FormGroup, pessoa_id = null):FormData{
        let formulario = new FormData();
        if(pessoa_id)
            formulario.append('pessoa_id',pessoa_id);
        if(form.value.banco_id)
            formulario.append('banco_id',form.value.banco_id);
        if(form.value.descricao)
            formulario.append('descricao',form.value.descricao);
        if(form.value.status)
            formulario.append('status',form.value.status);
        if(form.value.tipo)
            formulario.append('tipo',form.value.tipo);
        if(form.value.num_agencia)
            formulario.append('num_agencia',form.value.num_agencia);
        if(form.value.dv_agencia)
            formulario.append('dv_agencia',form.value.dv_agencia);
        if(form.value.num_conta)
            formulario.append('num_conta',form.value.num_conta);
        if(form.value.dv_conta)
            formulario.append('dv_conta',form.value.dv_conta);
        if(form.value.variacao)
            formulario.append('variacao',form.value.variacao);
        return formulario;
    }
    /* -------- */

    /* Dados de Anexos das Pessoas */
    createFormDataPessoaAnexo(form: FormGroup, arquivo: File, pessoa_id = null):FormData{
        let formulario = new FormData();
        if(pessoa_id)
            formulario.append('pessoa_id',pessoa_id); 
        if(form.value.descricao)
            formulario.append('descricao',form.value.descricao);  
        if(arquivo)
            formulario.append('arquivo',arquivo);  
        return formulario;
    }
    /* -------- */

    /* Válido para todos os submenus de Pré Cadastro */
    createFormDataPreCadastro(form: FormGroup, formArray: FormArray = null):FormData{
        let formulario = new FormData();
        if(form.value.nome)
            formulario.append('nome',form.value.nome);
        if(form.value.descricao)
            formulario.append('descricao',form.value.descricao); 
        
        /* endereço em estoque locais */

        if(form.value.cep)
            formulario.append('cep',form.value.cep);
        if(form.value.logradouro)
            formulario.append('logradouro',form.value.logradouro);
        if(form.value.numero)
            formulario.append('numero',form.value.numero);
        if(form.value.complemento)
            formulario.append('complemento',form.value.complemento);
        if(form.value.bairro)
            formulario.append('bairro',form.value.bairro);
        if(form.value.cidade)
            formulario.append('cidade',form.value.cidade);
        if(form.value.estado)
            formulario.append('estado',form.value.estado);

        /* Cargos e Salários*/
        if(form.value.cbo)
            formulario.append('cbo',form.value.cbo);
        if(form.value.piso_salarial)
            formulario.append('piso_salarial',parseFloat(form.value.piso_salarial).toFixed(2));
        if(form.value.teto_salarial)
            formulario.append('teto_salarial',parseFloat(form.value.teto_salarial).toFixed(2));
        /* --------- */

        /* Estoque - Atributo e Categoria*/
        if(formArray && formArray.value.length > 0){
            for(let i = 0; i <= (formArray.value.length-1); i++){
                if(formArray.at(i).value.id)
                    formulario.append(`item[${i}][id]`,formArray.at(i).value.id);
                if(formArray.at(i).value.nome)    
                    formulario.append(`item[${i}][nome]`,formArray.at(i).value.nome);
                if(formArray.at(i).value > 0)    
                    formulario.append(`atributo[${i}]`,formArray.at(i).value);
            }
        }
        /* --------- */

        /* Estoque - Motivo de Retirada*/
        if(form.value.motivo)
            formulario.append('motivo',form.value.motivo);
        /* --------- */
        
        /* Financeiro - Conta bancária */
        if(form.value.banco_id)
            formulario.append('banco_id',form.value.banco_id);
        if(form.value.status)
            formulario.append('status',form.value.status);
        if(form.value.tipo)
            formulario.append('tipo',form.value.tipo);
        if(form.value.num_agencia)
            formulario.append('num_agencia',form.value.num_agencia);
        if(form.value.dv_agencia)
            formulario.append('dv_agencia',form.value.dv_agencia);
        if(form.value.num_conta)
            formulario.append('num_conta',form.value.num_conta);
        if(form.value.dv_conta)
            formulario.append('dv_conta',form.value.dv_conta);
        if(form.value.ano_vigencia)
            formulario.append('ano_vigencia',form.value.ano_vigencia);
        if(form.value.saldo)
            formulario.append('saldo',parseFloat(form.value.saldo).toFixed(2));

        if(form.value.variacao)
            formulario.append('variacao',form.value.variacao);
        return formulario;
    }
    /* -------- */

    /* Dados de Acesso */
    createFormDataAcesso(form: FormGroup, pessoa_id = null):FormData{
        let formulario = new FormData();
        if(pessoa_id)
            formulario.append('pessoa_id',pessoa_id);
        if(form.value.login)
            formulario.append('login',form.value.login); 
        if(form.value.senha){
            let crypt = new JSEncrypt;
            crypt.setPublicKey(SYSCONF_PUBKEY);
            let cryptedPassword = crypt.encrypt(form.value.senha);
            formulario.append('senha',cryptedPassword);  
        }
        if(form.value.nivel_id)
            formulario.append('nivel[]',form.value.nivel_id);
        if(form.value.status)
            formulario.append('status',form.value.status);       
        return formulario;
    }
    /* -------- */

    /* Dados de Dependente (Serve tanto para Atleta x Responsável, quanto Dependente x Funcionário) */
    createFormDataDependente(form: FormGroup):FormData{
        let formulario = new FormData();
        if(form.value.dependente_id)
            formulario.append('dependente_id',form.value.dependente_id);
        if(form.value.funcionario_id)
            formulario.append('funcionario_id',form.value.funcionario_id);
        if(form.value.responsavel_id)
            formulario.append('responsavel_id',form.value.responsavel_id);
        if(form.value.atleta_id)
            formulario.append('atleta_id',form.value.atleta_id); 
        if(form.value.grau_parentesco_id)
            formulario.append('grau_parentesco_id',form.value.grau_parentesco_id);       
        return formulario;
    }
    /* -------- */

    /* Dados de Contrato */
    createFormDataContrato(form: FormGroup):FormData{
        let formulario = new FormData();
        
        if(form.value.contrato_status_id)
            formulario.append('contrato_status_id',form.value.contrato_status_id); 
        if(form.value.gestor_id)
            formulario.append('gestor_id',form.value.gestor_id);    
        if(form.value.tipo_pessoa_id)
            formulario.append('tipo_pessoa_id',form.value.tipo_pessoa_id); 
        if(form.value.pessoa_id)
            formulario.append('pessoa_id',form.value.pessoa_id); 
        if(form.value.dt_atualizacao)
            formulario.append('dt_atualizacao',form.value.dt_atualizacao); 
        if(form.value.dt_inicio)
            formulario.append('dt_inicio',`${form.value.dt_inicio.day}/${form.value.dt_inicio.month}/${form.value.dt_inicio.year}`); 
        if(form.value.dt_termino)
            formulario.append('dt_termino',`${form.value.dt_termino.day}/${form.value.dt_termino.month}/${form.value.dt_termino.year}`); 
        if(form.value.objeto)
            formulario.append('objeto',form.value.objeto); 
        if(form.value.numero)
            formulario.append('numero',form.value.numero); 
        if(form.value.valor)
            formulario.append('valor',parseFloat(form.value.valor).toFixed(2));
        if(form.value.observacao)
            formulario.append('observacao',form.value.observacao);
        if(form.value.contrato_natureza_id)
            formulario.append('contrato_natureza_id',form.value.contrato_natureza_id);
        if(form.value.contrato_area_id)
            formulario.append('contrato_area_id',form.value.contrato_area_id);
        if(form.value.conta_bancaria_id)
            formulario.append('conta_bancaria_id',form.value.conta_bancaria_id);
        if(form.value.centro_custo_id)
            formulario.append('centro_custo_id',form.value.centro_custo_id);
        if(form.value.forma_pagamento_id)
            formulario.append('forma_pagamento_id',form.value.forma_pagamento_id);
        if(form.value.plano_contas_id)
            formulario.append('plano_contas_id',form.value.plano_contas_id);
        if(form.value.financeiro_recurso_id)
            formulario.append('financeiro_recurso_id',form.value.financeiro_recurso_id);

        return formulario;
    }
    /* -------- */

    /* Dados de Aditivo */
    createFormDataAditivo(form: FormGroup, contrato_id = null):FormData{
        let formulario = new FormData();
        if(contrato_id)
            formulario.append('contrato_id',contrato_id); 
        if(form.value.contrato_status_id)
            formulario.append('contrato_status_id',form.value.contrato_status_id); 
        if(form.value.dt_atualizacao)
            formulario.append('dt_atualizacao',form.value.dt_atualizacao); 
        if(form.value.dt_inicio)
            formulario.append('dt_inicio',`${form.value.dt_inicio.day}/${form.value.dt_inicio.month}/${form.value.dt_inicio.year}`); 
        if(form.value.dt_termino)
            formulario.append('dt_termino',`${form.value.dt_termino.day}/${form.value.dt_termino.month}/${form.value.dt_termino.year}`); 
        if(form.value.numero)
            formulario.append('numero',form.value.numero); 
        if(form.value.valor)
            formulario.append('valor',parseFloat(form.value.valor).toFixed(2));
        if(form.value.observacao)
            formulario.append('observacao',form.value.observacao);

        return formulario;
    }
    /* -------- */

    /* Dados das parcelas do contrato/aditivo */
    createFormDataContratoParcela(form: FormArray, contrato_id, contrato_aditivo_id = null):FormData{
        let formulario = new FormData();
        for(let i=0;i<=(form.length-1);i++){
            formulario.append(`parcelas[${i}][id]`,form.at(i).value.id);
            formulario.append(`parcelas[${i}][contrato_id]`,contrato_id);
            formulario.append(`parcelas[${i}][valor]`,parseFloat(form.at(i).value.valor).toFixed(2));
            formulario.append(`parcelas[${i}][dt_previsao]`,`${form.at(i).value.dt_previsao.day}/${form.at(i).value.dt_previsao.month}/${form.at(i).value.dt_previsao.year}`);
            formulario.append(`parcelas[${i}][status_pagamento]`,form.at(i).value.status_pagamento);
            if(form.at(i).value.dt_pagamento)
            formulario.append(`parcelas[${i}][dt_pagamento]`,`${form.at(i).value.dt_pagamento.day}/${form.at(i).value.dt_pagamento.month}/${form.at(i).value.dt_pagamento.year}`);
            if(contrato_aditivo_id){
                formulario.append(`parcelas[${i}][contrato_aditivo_id]`,contrato_aditivo_id);
            }
           // console.log(`${form.at(i).value.dt_previsao.day}/${form.at(i).value.dt_previsao.month}/${form.at(i).value.dt_previsao.year}`)
        }
        return formulario;
    }
    /* -------- */

    /* Dados de Anexos dos contratos */
    createFormDataAnexo(form: FormGroup, arquivo: File, contrato_id = null):FormData{
        let formulario = new FormData();
        if(contrato_id)
            formulario.append('contrato_id',contrato_id); 
        if(form.value.descricao)
            formulario.append('descricao',form.value.descricao);  
        if(arquivo)
            formulario.append('arquivo',arquivo);  
        return formulario;
    }
    /* -------- */

     /* Dados de Cargo dos funcionários */
     createFormDataCargo(form: FormGroup):FormData{
        let formulario = new FormData();
        if(form.value.funcionario_id)
            formulario.append('funcionario_id',form.value.funcionario_id);
        if(form.value.cargo_id)
            formulario.append('cargo_id',form.value.cargo_id);
        if(form.value.area_funcional)
            formulario.append('area_funcional',form.value.area_funcional);
        if(form.value.dt_inicio)
            formulario.append('dt_inicio',form.value.dt_inicio);

        let dt_termino = '';
        if(form.value.dt_termino)
            dt_termino = form.value.dt_termino;
        formulario.append('dt_termino',dt_termino); 
        

        if(form.value.salario)
            formulario.append('salario',parseFloat(form.value.salario).toFixed(2));   
        if(form.value.observacao)
            formulario.append('observacao',form.value.observacao);     
        return formulario;
    }
    /* -------- */

    /* Dados de Afastamento dos funcionários */
    createFormDataAfastamento(form: FormGroup):FormData{
        let formulario = new FormData();
        if(form.value.funcionario_id)
            formulario.append('funcionario_id',form.value.funcionario_id);
        if(form.value.dt_inicio)
            formulario.append('dt_inicio',`${form.value.dt_inicio.day}/${form.value.dt_inicio.month}/${form.value.dt_inicio.year}`); 
        let dt_termino = '';
        if(form.value.dt_termino)
            dt_termino = `${form.value.dt_termino.day}/${form.value.dt_termino.month}/${form.value.dt_termino.year}`;  
        formulario.append('dt_termino',dt_termino);
        if(form.value.motivo)
            formulario.append('motivo',form.value.motivo);     
        return formulario;
    }
    /* -------- */

    /* Dados de Férias dos funcionários */
    createFormDataFerias(form: FormGroup):FormData{
        let formulario = new FormData();
        if(form.value.funcionario_id)
            formulario.append('funcionario_id',form.value.funcionario_id);
        if(form.value.dt_inicio)
            formulario.append('dt_inicio',`${form.value.dt_inicio.day}/${form.value.dt_inicio.month}/${form.value.dt_inicio.year}`); 
        if(form.value.dt_termino)
            formulario.append('dt_termino',`${form.value.dt_termino.day}/${form.value.dt_termino.month}/${form.value.dt_termino.year}`);  
        if(form.value.observacao)
            formulario.append('observacao',form.value.observacao);     
        if(form.value.status)
            formulario.append('status',form.value.status);     
        return formulario;
    }
    /* -------- */

    /* Dados da Folha de Pagamento - RH */
    createFormDataFolhaDePagamento(form: FormGroup):FormData{
        let formulario = new FormData();
        if(form.value.dt_previsao)
            formulario.append('dt_previsao',form.value.dt_previsao);
        if(form.value.descricao)
            formulario.append('descricao',form.value.descricao);
        if(form.value.competencia){
            let data = form.value.competencia.split('/');
            formulario.append('competencia_mes',data[0]); 
            formulario.append('competencia_ano',data[1]); 
        }
        return formulario;
    }
    /* -------- */

    /* Dados de cada funcionário dentro da folha de pagamento */
    createFormDataFolhaDePagamentoFuncionario(form:FormGroup):FormData{
        let formulario = new FormData();
        if(form.value.funcionario_id)
            formulario.append('funcionario_id',form.value.funcionario_id);
        if(form.value.folhadepagamento_id)
            formulario.append('folhapagamento_id',form.value.folhadepagamento_id);
        return formulario;
    }
    /* -------- */

    /* Dados das verbas de cada funcionário que já estará vinculado à folha de pagamento */
    createFormDataFolhaDePagamentoFuncionarioVerba(folhapagamento_funcionario_id, verbas:FormArray):FormData{
        let formulario = new FormData();
        if(verbas.length > 0){    
            for(let i=0;i<=(verbas.length-1);i++){
                formulario.append(`verbas[${i}][folhapagamento_funcionario_id]`,folhapagamento_funcionario_id);
                formulario.append(`verbas[${i}][folhapagamento_verba_id]`,verbas.at(i).value.id);
                formulario.append(`verbas[${i}][referencia]`,parseFloat(verbas.at(i).value.referencia).toFixed(2));
                formulario.append(`verbas[${i}][valor]`,parseFloat(verbas.at(i).value.valor).toFixed(2));
            }
        }
        return formulario;
    }
    /* -------- */

    /* Dados de Projeto */
    createFormDataProjeto(form: FormGroup):FormData{
        let formulario = new FormData();
        if(form.value.codigo_cob)
            formulario.append('codigo_cob',form.value.codigo_cob);
        if(form.value.projeto_status_id)
            formulario.append('projeto_status_id',form.value.projeto_status_id); 
        if(form.value.projeto_enquadramento_id)
            formulario.append('projeto_enquadramento_id',form.value.projeto_enquadramento_id); 
        if(form.value.financeiro_recurso_id)
            formulario.append('financeiro_recurso_id',form.value.financeiro_recurso_id)

        formulario.append('contrato_id',form.value.contrato_id); 
        formulario.append('projeto_id',form.value.projeto_id); 
        
        
        if(form.value.tipo_pessoa_id)
            formulario.append('tipo_pessoa_id',form.value.tipo_pessoa_id); 
        if(form.value.pessoa_id)
            formulario.append('pessoa_id',form.value.pessoa_id); 
        if(form.value.nome)
            formulario.append('nome',form.value.nome); 
        if(form.value.apresentacao)
            formulario.append('apresentacao',form.value.apresentacao); 
        if(form.value.justificativa)
            formulario.append('justificativa',form.value.justificativa);
        if(form.value.objetivo_geral)
            formulario.append('objetivo_geral',form.value.objetivo_geral);
        if(form.value.objetivo_especifico)
            formulario.append('objetivo_especifico',form.value.objetivo_especifico);
        if(form.value.dt_plan_inicio)
            formulario.append('dt_plan_inicio',`${form.value.dt_plan_inicio.day}/${form.value.dt_plan_inicio.month}/${form.value.dt_plan_inicio.year}`);
        if(form.value.dt_plan_termino)
            formulario.append('dt_plan_termino',`${form.value.dt_plan_termino.day}/${form.value.dt_plan_termino.month}/${form.value.dt_plan_termino.year}`);
        if(form.value.dt_execucao_inicio)
            formulario.append('dt_execucao_inicio',`${form.value.dt_execucao_inicio.day}/${form.value.dt_execucao_inicio.month}/${form.value.dt_execucao_inicio.year}`);
        if(form.value.dt_execucao_termino)
            formulario.append('dt_execucao_termino',`${form.value.dt_execucao_termino.day}/${form.value.dt_execucao_termino.month}/${form.value.dt_execucao_termino.year}`);
        if(form.value.dt_posexecucao_inicio)
            formulario.append('dt_posexecucao_inicio',`${form.value.dt_posexecucao_inicio.day}/${form.value.dt_posexecucao_inicio.month}/${form.value.dt_posexecucao_inicio.year}`);
        if(form.value.dt_posexecucao_termino)
            formulario.append('dt_posexecucao_termino',`${form.value.dt_posexecucao_termino.day}/${form.value.dt_posexecucao_termino.month}/${form.value.dt_posexecucao_termino.year}`);
        if(form.value.observacao)
            formulario.append('observacao',form.value.observacao);
        if(form.value.local_realizacao)
            formulario.append('local_realizacao',form.value.local_realizacao);
        if(form.value.valor_previsto)
            formulario.append('valor_previsto',parseFloat(form.value.valor_previsto).toFixed(2));

        return formulario;
    }
    /* -------- */
    
    /* Dados de atividades dos projetos */
    createFormDataAtividade(form: FormGroup, projeto_id = null):FormData{
        let formulario = new FormData();
        if(projeto_id)
            formulario.append('projeto_id',projeto_id); 
        if(form.value.nome)
            formulario.append('nome',form.value.nome); 
        if(form.value.dt_inicio)
            formulario.append('dt_inicio',`${form.value.dt_inicio.day}/${form.value.dt_inicio.month}/${form.value.dt_inicio.year}`); 
        if(form.value.dt_termino)
            formulario.append('dt_termino',`${form.value.dt_termino.day}/${form.value.dt_termino.month}/${form.value.dt_termino.year}`); 
        if(form.value.observacao)
            formulario.append('observacao',form.value.observacao); 
        return formulario;
    }
    /* -------- */

    /* Dados de documentos dos projetos */
    createFormDataProjetoDocumento(form: FormGroup, arquivo: File, projeto_id = null):FormData{
        let formulario = new FormData();
        if(projeto_id)
            formulario.append('projeto_id',projeto_id); 
        if(form.value.descricao)
            formulario.append('descricao',form.value.descricao);  
        if(arquivo)
            formulario.append('arquivo',arquivo);  
        if(form.value.dt_previsao_recebimento)
            formulario.append('dt_previsao_recebimento',`${form.value.dt_previsao_recebimento.day}/${form.value.dt_previsao_recebimento.month}/${form.value.dt_previsao_recebimento.year}`);
        if(form.value.dt_recebimento)
            formulario.append('dt_recebimento',`${form.value.dt_recebimento.day}/${form.value.dt_recebimento.month}/${form.value.dt_recebimento.year}`);
        if(form.value.observacao)
            formulario.append('observacao',form.value.observacao); 
        return formulario;
    }
    /* -------- */

    /* Dados de stakeholders dos projetos */
    createFormDataStakeholder(form: FormGroup, projeto_id = null):FormData{
        let formulario = new FormData();
        if(projeto_id)
            formulario.append('projeto_id',projeto_id); 
        if(form.value.nome)
            formulario.append('nome',form.value.nome);  
        if(form.value.instituicao)
            formulario.append('instituicao',form.value.instituicao); 
        if(form.value.funcao)
            formulario.append('funcao',form.value.funcao);
        if(form.value.telefone)
            formulario.append('telefone',form.value.telefone);
        if(form.value.email)
            formulario.append('email',form.value.email); 
        if(form.value.observacao)
            formulario.append('observacao',form.value.observacao); 
        return formulario;
    }
    /* -------- */

    /* Dados de instiuições parceiras dos projetos */
    createFormDataInstituicaoParceira(form: FormGroup, projeto_id = null):FormData{
        let formulario = new FormData();
        if(projeto_id)
            formulario.append('projeto_id',projeto_id); 
        if(form.value.nome)
            formulario.append('nome',form.value.nome);  
        if(form.value.tp_envolvimento)
            formulario.append('tp_envolvimento',form.value.tp_envolvimento); 
        if(form.value.contrapartida_financeira)
            formulario.append('contrapartida_financeira',parseFloat(form.value.contrapartida_financeira).toFixed(2));
        if(form.value.observacao)
            formulario.append('observacao',form.value.observacao); 
        return formulario;
    }
    /* -------- */

    /* Dados das etapas dos projetos */
    createFormDataEtapa(form: FormGroup, projeto_id = null):FormData{
        let formulario = new FormData();
        if(projeto_id)
            formulario.append('projeto_id',projeto_id); 
        if(form.value.nome)
            formulario.append('nome',form.value.nome);  
        if(form.value.projeto_stakeholder_id)
            formulario.append('projeto_stakeholder_id',form.value.projeto_stakeholder_id); 
        if(form.value.dt_previsao_inicio)
            formulario.append('dt_previsao_inicio',form.value.dt_previsao_inicio);
        if(form.value.dt_previsao_termino)
            formulario.append('dt_previsao_termino',form.value.dt_previsao_termino);
        if(form.value.dt_inicio)
            formulario.append('dt_inicio',form.value.dt_inicio);
        if(form.value.dt_termino)
            formulario.append('dt_termino',form.value.dt_termino);
        if(form.value.observacao)
            formulario.append('observacao',form.value.observacao); 
        if(form.value.projeto_etapa_status_id)
            formulario.append('projeto_etapa_status_id',form.value.projeto_etapa_status_id); 
        return formulario;
    }
    /* -------- */

    /* Dados das metas dos projetos */
    createFormDataMeta(form: FormGroup, projeto_id = null):FormData{
        let formulario = new FormData();
        if(projeto_id)
            formulario.append('projeto_id',projeto_id); 
        if(form.value.tipo)
            formulario.append('tipo',form.value.tipo);  
        if(form.value.descricao)
            formulario.append('descricao',form.value.descricao); 
        if(form.value.quantidade)
            formulario.append('quantidade',parseFloat(form.value.quantidade).toFixed(2));
        if(form.value.alcancada)
            formulario.append('alcancada',form.value.alcancada);
        return formulario;
    }
    /* -------- */

    /* Dados dos orçamentos dos projetos */
    createFormDataOrcamento(form: FormGroup, projeto_id = null):FormData{
        let formulario = new FormData();
        if(projeto_id)
            formulario.append('projeto_id',projeto_id); 
        if(form.value.tipo_pessoa_id)
            formulario.append('tipo_pessoa_id',form.value.tipo_pessoa_id); 
        if(form.value.pessoa_id)
            formulario.append('pessoa_id',form.value.pessoa_id);  
        if(form.value.item)
            formulario.append('item',form.value.item); 
        if(form.value.quantidade)
            formulario.append('quantidade',form.value.quantidade);
        if(form.value.valor)
            formulario.append('valor',parseFloat(form.value.valor).toFixed(2));
        if(form.value.dt_previsao_pagamento)
            formulario.append('dt_previsao_pagamento',form.value.dt_previsao_pagamento);
        if(form.value.dt_pagamento)
            formulario.append('dt_pagamento',form.value.dt_pagamento);
        if(form.value.suprimento_unidade_id)
            formulario.append('suprimento_unidade_id',form.value.suprimento_unidade_id);
        if(form.value.destino)
            formulario.append('destino',form.value.destino);
        if(form.value.observacao)
            formulario.append('observacao',form.value.observacao);
        return formulario;
    }
    /* -------- */

    /* Dados dos riscos dos projetos */
    createFormDataRisco(form: FormGroup, projeto_id = null):FormData{
        let formulario = new FormData();
        if(projeto_id)
            formulario.append('projeto_id',projeto_id); 
        if(form.value.nome)
            formulario.append('nome',form.value.nome);  
        if(form.value.projeto_stakeholder_id)
            formulario.append('projeto_stakeholder_id',form.value.projeto_stakeholder_id); 
        if(form.value.exposicao)
            formulario.append('exposicao',form.value.exposicao);
        if(form.value.plano_resposta)
            formulario.append('plano_resposta',form.value.plano_resposta);
        if(form.value.observacao)
            formulario.append('observacao',form.value.observacao); 
        return formulario;
    }
    /* -------- */

    /* Dados das situações dos projetos */
    createFormDataSituacao(form: FormGroup, projeto_id = null):FormData{
        let formulario = new FormData();
        if(projeto_id)
            formulario.append('projeto_id',projeto_id); 
        if(form.value.projeto_stakeholder_id)
            formulario.append('projeto_stakeholder_id',form.value.projeto_stakeholder_id); 
        if(form.value.descricao)
            formulario.append('descricao',form.value.descricao); 
        if(form.value.acoes)
            formulario.append('acoes',form.value.acoes);
        if(form.value.observacao)
            formulario.append('observacao',form.value.observacao); 
        return formulario;
    }
    /* -------- */

    /* Dados para Plano de Contas, Cheques e Movimentos */
    createFormDataFinanceiro(form: FormGroup, arquivo: File = null):FormData{
        let formulario = new FormData();
        if(form.value.financeiro_recurso_id)
            formulario.append('financeiro_recurso_id',form.value.financeiro_recurso_id)
        if(form.value.tipo_pessoa_id)
            formulario.append('tipo_pessoa_id',form.value.tipo_pessoa_id); 
        if(form.value.pessoa_id)
            formulario.append('pessoa_id',form.value.pessoa_id); 
        if(form.value.cheque_dt_emissao)
            formulario.append('cheque_dt_emissao', `${form.value.cheque_dt_emissao.day}/${form.value.cheque_dt_emissao.month}/${form.value.cheque_dt_emissao.year}`)
        if(form.value.dt_compensacao)
            formulario.append('dt_compensacao', `${form.value.dt_compensacao.day}/${form.value.dt_compensacao.month}/${form.value.dt_compensacao.year}`)
        if(form.value.numero)
            formulario.append('numero',form.value.numero); 
        if(form.value.valor)
            formulario.append('valor',parseFloat(form.value.valor).toFixed(2)); 
        if(form.value.finalidade)
            formulario.append('finalidade',form.value.finalidade); 
        if(form.value.observacao)
            formulario.append('observacao',form.value.observacao); 
        if(form.value.conta_bancaria_id)
            formulario.append('conta_bancaria_id',form.value.conta_bancaria_id);
        if(form.value.nome)
            formulario.append('nome',form.value.nome); 
        if(form.value.plano_contas_id)
            formulario.append('plano_contas_id',form.value.plano_contas_id);
        if(form.value.centro_custo_id)
            formulario.append('centro_custo_id',form.value.centro_custo_id);
        if(form.value.forma_pagamento_id)
            formulario.append('forma_pagamento_id',form.value.forma_pagamento_id);
        if(form.value.descricao)
            formulario.append('descricao',form.value.descricao);
        if(form.value.dt_emissao)
            formulario.append('dt_emissao', `${form.value.dt_emissao.day}/${form.value.dt_emissao.month}/${form.value.dt_emissao.year}`)
        if(form.value.dt_vencimento)
            formulario.append('dt_vencimento', `${form.value.dt_vencimento.day}/${form.value.dt_vencimento.month}/${form.value.dt_vencimento.year}`)
        if(form.value.dt_baixa)
            formulario.append('dt_baixa', `${form.value.dt_baixa.day}/${form.value.dt_baixa.month}/${form.value.dt_baixa.year}`)
        if(form.value.dt_previsao)
            formulario.append('dt_previsao', `${form.value.dt_previsao.day}/${form.value.dt_previsao.month}/${form.value.dt_previsao.year}`)
        if(form.value.codigo_barras)
            formulario.append('codigo_barras',form.value.codigo_barras);
        if(form.value.numero_doc)
            formulario.append('numero_doc',form.value.numero_doc);
        if(form.value.nosso_numero)
            formulario.append('nosso_numero',form.value.nosso_numero);
        if(form.value.dt_competencia)
            formulario.append('dt_competencia',form.value.dt_competencia);
        if(form.value.dt_conciliacao)
            formulario.append('dt_conciliacao', `${form.value.dt_conciliacao.day}/${form.value.dt_conciliacao.month}/${form.value.dt_conciliacao.year}`)
        if(form.value.valor_bruto)
            formulario.append('valor_bruto',parseFloat(form.value.valor_bruto).toFixed(2));
        if(form.value.juros)
            formulario.append('juros',parseFloat(form.value.juros).toFixed(2));
        if(form.value.desconto)
            formulario.append('desconto',parseFloat(form.value.desconto).toFixed(2));
        if(form.value.valor_liquido)
            formulario.append('valor_liquido',parseFloat(form.value.valor_liquido).toFixed(2));
        if(form.value.tipo)
            formulario.append('tipo',form.value.tipo);
        if(!form.value.status)
            formulario.append('status','0');
        else
            formulario.append('status',form.value.status);

        if(form.value.conta_transferencia_id)
            formulario.append('conta_transferencia_id',form.value.conta_transferencia_id);

        if(form.value.contrato_id)
            formulario.append('contrato_id',form.value.contrato_id);
        if(form.value.projeto_id)
            formulario.append('projeto_id',form.value.projeto_id);
        if(form.value.estoque_movimento_id)
            formulario.append('estoque_movimento_id',form.value.estoque_movimento_id); 

        if(form.value.nota_fiscal)
            formulario.append('nota_fiscal',form.value.nota_fiscal);

        /* Campos utilizados no tipo  Transferencia */
        if(form.value.origem_conta_bancaria_id)
            formulario.append('origem_conta_bancaria_id',form.value.origem_conta_bancaria_id);
        if(form.value.origem_plano_contas_id)
            formulario.append('origem_plano_contas_id',form.value.origem_plano_contas_id);
        if(form.value.origem_centro_custo_id)
            formulario.append('origem_centro_custo_id',form.value.origem_centro_custo_id);
        if(form.value.destino_conta_bancaria_id)
            formulario.append('destino_conta_bancaria_id',form.value.destino_conta_bancaria_id);
        if(form.value.destino_plano_contas_id)
            formulario.append('destino_plano_contas_id',form.value.destino_plano_contas_id);
        if(form.value.destino_centro_custo_id)
            formulario.append('destino_centro_custo_id',form.value.destino_centro_custo_id);       
        /* */


        /* Utilizado em Anexos de Movimentos */
        if(form.value.movimento_id)
            formulario.append('financeiro_movimento_id',form.value.movimento_id);
        if(arquivo)
            formulario.append('arquivo',arquivo);
        /* ----- */

        /* Utilizado na Configuracao de boleto */
		if(form.value.ambiente)
            formulario.append('ambiente',form.value.ambiente);
        if(form.value.nome_beneficiario)
            formulario.append('nome_beneficiario',form.value.nome_beneficiario);
        if(form.value.codigo_beneficiario)
            formulario.append('codigo_beneficiario',form.value.codigo_beneficiario);
        if(form.value.sequencial_nosso_numero)
            formulario.append('sequencial_nosso_numero',form.value.sequencial_nosso_numero);
        if(form.value.sequencial_remessa)
            formulario.append('sequencial_remessa',form.value.sequencial_remessa);
        if(form.value.carteira)
            formulario.append('carteira',form.value.carteira);
        if(form.value.cnpj)
            formulario.append('cnpj',form.value.cnpj);
        if(form.value.financeiro_conta_bancaria_id)
            formulario.append('financeiro_conta_bancaria_id',form.value.financeiro_conta_bancaria_id);
        if(form.value.valor_mora)
            formulario.append('valor_mora',parseFloat(form.value.valor_mora).toFixed(2));
        else
            formulario.append('valor_mora','0');

        if(form.value.taxa_mora)
            formulario.append('taxa_mora',parseFloat(form.value.taxa_mora).toFixed(2));
        else
            formulario.append('taxa_mora','0');

        if(form.value.instrucao1)
            formulario.append('instrucao1',form.value.instrucao1);
        if(form.value.instrucao2)
            formulario.append('instrucao2',form.value.instrucao2);
        if(form.value.instrucao3)
            formulario.append('instrucao3',form.value.instrucao3);
        if(form.value.instrucao4)
            formulario.append('instrucao4',form.value.instrucao4);
    /* ----- */

        return formulario;
    }
    /* -------- */

    /* Dados para Estoque */
    createFormDataEstoque(form: FormGroup, atributos: FormArray, arquivo: File = null, suprimentos: FormArray):FormData{
        let formulario = new FormData();
        if(form.value.nome)
            formulario.append('nome',form.value.nome); 
        if(form.value.sku)
            formulario.append('sku',form.value.sku); 
        if(form.value.upc)
            formulario.append('upc',form.value.upc); 
        if(form.value.suprimento_unidade_id)
            formulario.append('suprimento_unidade_id',form.value.suprimento_unidade_id); 
        if(form.value.suprimento_tipo_id)
            formulario.append('suprimento_tipo_id',form.value.suprimento_tipo_id); 
        if(form.value.suprimento_marca_id)
            formulario.append('suprimento_marca_id',form.value.suprimento_marca_id); 
        if(form.value.suprimento_categoria_id)
            formulario.append('suprimento_categoria_id',form.value.suprimento_categoria_id); 
        if(atributos){
            if(atributos.length > 0){    
                for(let i=0;i<=(atributos.length-1);i++){
                    formulario.append(`atributo[${atributos.at(i).value.estoque_atributo_id}]`, atributos.at(i).value.id);
                }
            }
        }
        if(form.value.estoque_minimo)
            formulario.append('estoque_minimo',form.value.estoque_minimo);

        // Usado no cadastro de anexos da movimentação de estoque
        if(form.value.descricao){
            formulario.append('estoque_movimento_id', form.value.estoque_movimento_id);
            formulario.append('descricao', form.value.descricao);
            formulario.append('arquivo',arquivo);
        } else if(arquivo)
        formulario.append('imagem',arquivo);

        if(form.value.status)
            formulario.append('status',form.value.status);
        if(form.value.tipo)
            formulario.append('tipo',form.value.tipo); 
        if(form.value.funcionario_id)
            formulario.append('funcionario_id', form.value.funcionario_id)
        if(form.value.fornecedor_id)
            formulario.append('fornecedor_id', form.value.fornecedor_id)

        if(form.value.centro_custo_id != undefined)
            formulario.append('centro_custo_id', form.value.centro_custo_id)
        if(form.value.origem_local_id)
            formulario.append('origem_local_id', form.value.origem_local_id)
        if(form.value.destino_local_id)
            formulario.append('destino_local_id', form.value.destino_local_id)
        if(form.value.nota_fiscal)
            formulario.append('nota_fiscal', form.value.nota_fiscal)
        if(form.value.dt_movimento){
            formulario.append('dt_movimento', `${form.value.dt_movimento.day}/${form.value.dt_movimento.month}/${form.value.dt_movimento.year}`)
        }
        if(form.value.observacao)
            formulario.append('observacao',form.value.observacao); 
        if(form.value.motivo_retirada_id)
            formulario.append('motivo_retirada_id',form.value.motivo_retirada_id); 
        if(form.value.valor_previsto)
            formulario.append('valor_previsto',form.value.valor_previsto); 
            
        if(suprimentos){
            if(suprimentos.length > 0){    
                for(let i=0;i<=(suprimentos.length-1);i++){
                    formulario.append(`suprimento[${i}][estoque_suprimento_id]`, suprimentos.at(i).value.id);
                    formulario.append(`suprimento[${i}][lote]`, suprimentos.at(i).value.lote);
                    formulario.append(`suprimento[${i}][quantidade]`, suprimentos.at(i).value.quantidade);
                    if(suprimentos.at(i).value.dt_validade)
                    formulario.append(`suprimento[${i}][dt_validade]`, `${suprimentos.at(i).value.dt_validade.day}/${suprimentos.at(i).value.dt_validade.month}/${suprimentos.at(i).value.dt_validade.year}` );
                }
            }
        }
        return formulario;
    }
    /* -------- */

    
}