import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SYSCONF_API, SYSCONF_SIGLA, SYSCONF_APP_ID, SYSCONF_CREDENTIALS, SYSCONF_PUBKEY, SYSCONF_LIMIT } from '../../app.api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { tap } from 'rxjs/operators';
import { Pessoa } from './pessoa';
import { FormGroup, FormArray } from '@angular/forms';
import { FormHelper } from '../_includes/forms/form-helper';

@Injectable()
export class PessoaService{
	
    pessoa: Pessoa[];
    private headers;
    cob = null;
    constructor(private http: HttpClient, private formHelper: FormHelper){
    this.cob = JSON.parse(localStorage.getItem('CONFLogin'));
        this.headers = {'App-Id': SYSCONF_APP_ID,'Content-Type':'application/json','App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
    }

    getOptions(tipo,params = []): Observable<any>{
        let criterioUrl:string = '';
        criterioUrl = this.formHelper.getConditionURL(params);
        if(criterioUrl == ''){
            criterioUrl += 'status=A';
        }
        return this.http.get(`${SYSCONF_API}/${tipo}?limit=10000&page=1&${criterioUrl}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response,
                error => error
            )
        );
    }
    
    exportar(tipo: 'excel' | 'pdf',endpoint: string, form: FormGroup, params: any) {
		let criterioUrl:string = '';
        let campos:string;
        campos = form.get('campos').value.join('&campos[]=');
        criterioUrl = this.formHelper.getConditionURL(params);
        return this.http.get(`${SYSCONF_API}/pessoa/${endpoint}/exportar/${tipo}/?campos[]=${campos}&${criterioUrl}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response,
                error => error
            )
        );
	}

    list(tipoPessoa,params, limit = SYSCONF_LIMIT): Observable<any>{
        let criterioUrl:string = '';
        criterioUrl = this.formHelper.getConditionURL(params);
        return this.http.get(`${SYSCONF_API}/${tipoPessoa}?limit=${limit}&page=${params.page}&${criterioUrl}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response,
                error => error
            )
        );
    }

  
    listLinguas(): Observable<any>{
        return this.http.get(`${SYSCONF_API}/idioma`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    listBancos(): Observable<any>{
        return this.http.get(`${SYSCONF_API}/banco?limit=500`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }
    
    pessoaNivelById(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key}
        return this.http.get(`${SYSCONF_API}/nivel/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    add(tipo:string, form:FormGroup, foto: File): Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}

        let formulario = this.formHelper.createFormDataPessoa(form,foto);
        return this.http.post(`${SYSCONF_API}/${tipo}`,formulario,
        {headers: new HttpHeaders(this.headers)})
        .pipe(
            tap(
                response => this.pessoa = response.dados,
                error => error
            )
        )
    }

    edit(tipo:string, form: FormGroup, foto: File): Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        
        let formulario = this.formHelper.createFormDataPessoa(form,foto);
        return this.http.post(`${SYSCONF_API}/${tipo}/${form.value.id}`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => this.pessoa = response.dados,
                error => error
            )
        )
    }

    removerFoto(pessoa_id:number){
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.delete(`${SYSCONF_API}/pessoa/remover-foto/${pessoa_id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response,
                error => error
            )
        );
    }

    getPessoaById(tipo:string ,id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.get(`${SYSCONF_API}/${tipo}/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    
    getPessoaByCpf(cpf:string):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario.append('cpf',cpf);
        return this.http.post(`${SYSCONF_API}/pessoa/consulta-cpf`,formulario, {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    getPessoaByCnpj(cnpj:string):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario.append('cnpj',cnpj);
        return this.http.post(`${SYSCONF_API}/pessoa/consulta-cnpj`,formulario, {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    getPessoaByPassaporte(passaporte:string):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario.append('passaporte',passaporte);
        return this.http.post(`${SYSCONF_API}/pessoa/consulta-passaporte`,formulario, {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    getPessoaByTipo(tipo:string, pessoa_id):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();

        if (tipo === 'prestador-servico') {
            tipo = 'prestador_servico';
        }

        formulario.append('tipo',tipo);
        formulario.append('pessoa_id',pessoa_id);
        return this.http.post(`${SYSCONF_API}/pessoa/verifica-tipo`,formulario, {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }
    
    getEnderecoById(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.get(`${SYSCONF_API}/pessoa/endereco/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    addEndereco(pessoa_id, form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataEndereco(form,pessoa_id);
        return this.http.post(`${SYSCONF_API}/pessoa/endereco`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => this.pessoa = response.dados,
                error => error
            )
        )
    }

    editEndereco(id:number, form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataEndereco(form);
        return this.http.post(`${SYSCONF_API}/pessoa/endereco/${id}`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => this.pessoa = response.dados,
                error => error
            )
        )
    }

    delEndereco(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.delete(`${SYSCONF_API}/pessoa/endereco/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    getTelefoneById(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.get(`${SYSCONF_API}/pessoa/telefone/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    addTelefone(pessoa_id, form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataTelefone(form,pessoa_id);
        return this.http.post(`${SYSCONF_API}/pessoa/telefone`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => this.pessoa = response.dados,
                error => error
            )
        )
    }

    editTelefone(id:number, form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let t = new FormData();
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataTelefone(form);
        return this.http.post(`${SYSCONF_API}/pessoa/telefone/${id}`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => this.pessoa = response.dados,
                error => error
            )
        )
    }

    delTelefone(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.delete(`${SYSCONF_API}/pessoa/telefone/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    getIdiomaById(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.get(`${SYSCONF_API}/pessoa/idioma/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    addIdioma(pessoa_id, form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataIdioma(form,pessoa_id);
        return this.http.post(`${SYSCONF_API}/pessoa/idioma`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => this.pessoa = response.dados,
                error => error
            )
        )
    }

    editIdioma(id:number, form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataIdioma(form)
        return this.http.post(`${SYSCONF_API}/pessoa/idioma/${id}`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => this.pessoa = response.dados,
                error => error
            )
        )
    }

    delIdioma(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.delete(`${SYSCONF_API}/pessoa/idioma/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    getContaBancariaById(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.get(`${SYSCONF_API}/pessoa/conta_bancaria/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    addContaBancaria(pessoa_id, form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataContaBancaria(form,pessoa_id);
        return this.http.post(`${SYSCONF_API}/pessoa/conta_bancaria`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => this.pessoa = response.dados,
                error => error
            )
        )
    }

    editContaBancaria(id:number, form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataContaBancaria(form);
        return this.http.post(`${SYSCONF_API}/pessoa/conta_bancaria/${id}`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => this.pessoa = response.dados,
                error => error
            )
        )
    }

    delContaBancaria(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.delete(`${SYSCONF_API}/pessoa/conta_bancaria/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    getUsuarioById(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.get(`${SYSCONF_API}/usuario/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    addUsuario(pessoa_id, form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataAcesso(form,pessoa_id);
        return this.http.post(`${SYSCONF_API}/usuario`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => this.pessoa = response.dados,
                error => error
            )
        )
    }
    
    editUsuario(id:number, form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataAcesso(form)
        return this.http.post(`${SYSCONF_API}/usuario/${id}`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => this.pessoa = response.dados,
                error => error
            )
        )
    }

    delUsuario(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.delete(`${SYSCONF_API}/usuario/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    /* Esses dependentes são os atletas menores de idade */
    getDependenteById(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.get(`${SYSCONF_API}/pessoa/atleta/responsavel/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    addDependente(form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataDependente(form);
        return this.http.post(`${SYSCONF_API}/pessoa/atleta/responsavel`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => this.pessoa = response.dados,
                error => error
            )
        )
    }

    editDependente(id:number, form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataDependente(form)
        return this.http.post(`${SYSCONF_API}/pessoa/atleta/responsavel/${id}`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => this.pessoa = response.dados,
                error => error
            )
        )
    }

    delDependente(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.delete(`${SYSCONF_API}/pessoa/atleta/responsavel/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }
    /* --------------------- */


    /* Dependentes dos Funcionários, para RH. */
    getFuncionarioDependenteById(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.get(`${SYSCONF_API}/pessoa/funcionario/dependente/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    addFuncionarioDependente(form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataDependente(form);
        return this.http.post(`${SYSCONF_API}/pessoa/funcionario/dependente`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => this.pessoa = response.dados,
                error => error
            )
        )
    }

    editFuncionarioDependente(id:number, form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataDependente(form)
        return this.http.post(`${SYSCONF_API}/pessoa/funcionario/dependente/${id}`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => this.pessoa = response.dados,
                error => error
            )
        )
    }

    delFuncionarioDependente(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.delete(`${SYSCONF_API}/pessoa/funcionario/dependente/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }
    /* --------------------- */

     /* Cargos dos Funcionários, para RH. */
     getFuncionarioCargoById(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.get(`${SYSCONF_API}/pessoa/funcionario/cargo/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    addFuncionarioCargo(form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataCargo(form);
        return this.http.post(`${SYSCONF_API}/pessoa/funcionario/cargo`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => this.pessoa = response.dados,
                error => error
            )
        )
    }

    editFuncionarioCargo(id:number, form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataCargo(form)
        return this.http.post(`${SYSCONF_API}/pessoa/funcionario/cargo/${id}`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => this.pessoa = response.dados,
                error => error
            )
        )
    }

    delFuncionarioCargo(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.delete(`${SYSCONF_API}/pessoa/funcionario/cargo/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }
    /* --------------------- */

    /* Aniversariantes */
    getFuncionarioAniversarianteByMes(mes: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.get(`${SYSCONF_API}/pessoa/funcionario/aniversariantes/${mes}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    /* Afastamento dos Funcionários, para RH. */
    getFuncionarioAfastamentoById(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.get(`${SYSCONF_API}/pessoa/funcionario/afastamento/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    addFuncionarioAfastamento(form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataAfastamento(form);
        return this.http.post(`${SYSCONF_API}/pessoa/funcionario/afastamento`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => this.pessoa = response.dados,
                error => error
            )
        )
    }

    editFuncionarioAfastamento(id:number, form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataAfastamento(form)
        return this.http.post(`${SYSCONF_API}/pessoa/funcionario/afastamento/${id}`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => this.pessoa = response.dados,
                error => error
            )
        )
    }

    delFuncionarioAfastamento(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.delete(`${SYSCONF_API}/pessoa/funcionario/afastamento/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }
    /* --------------------- */

    /* Férias dos Funcionários, para RH. */
    getFuncionarioFeriasById(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.get(`${SYSCONF_API}/pessoa/funcionario/ferias/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }
    addFuncionarioFerias(form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataFerias(form);
        return this.http.post(`${SYSCONF_API}/pessoa/funcionario/ferias`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => this.pessoa = response.dados,
                error => error
            )
        )
    }
    editFuncionarioFerias(id:number, form: FormGroup):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataFerias(form)
        return this.http.post(`${SYSCONF_API}/pessoa/funcionario/ferias/${id}`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => this.pessoa = response.dados,
                error => error
            )
        )
    }
    delFuncionarioFerias(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.delete(`${SYSCONF_API}/pessoa/funcionario/ferias/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }
    /* --------------------- */

    getAnexoById(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.get(`${SYSCONF_API}/pessoa/anexo/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    addAnexo(pessoa_id, form: FormGroup, arquivo: File):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataPessoaAnexo(form, arquivo, pessoa_id);
        return this.http.post(`${SYSCONF_API}/pessoa/anexo`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        )
    }

    editAnexo(id:number, form: FormGroup, arquivo: File):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        let formulario = new FormData();
        formulario = this.formHelper.createFormDataPessoaAnexo(form, arquivo);
        return this.http.post(`${SYSCONF_API}/pessoa/anexo/${id}`,formulario,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        )
    }

    delAnexo(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.delete(`${SYSCONF_API}/pessoa/anexo/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }
   
}