export * from '../estoque/estoque.component';
export * from '../estoque/suprimento/list/suprimentoList.component';
export * from '../estoque/suprimento/create/suprimentoCreate.component';
export * from '../estoque/suprimento/edit/suprimentoEdit.component';
export * from '../estoque/suprimento/detail/suprimentoDetail.component';
export * from '../estoque/suprimento/search/suprimentoSearch.component';
export * from '../estoque/suprimento/tab/tab.component';
export * from '../estoque/estoque.service';
export * from '../estoque/movimentacao/search/movimentacaoSearch.component';
export * from '../estoque/movimentacao/list/movimentacaoList.component';
export * from '../estoque/movimentacao/create/movimentacaoCreate.component';
export * from '../estoque/movimentacao/edit/movimentacaoEdit.component';
export * from '../estoque/movimentacao/detail/movimentacaoDetail.component';
export * from '../estoque/movimentacao/tab/tab.component';