import { ProjetoService } from 'src/app/projeto/projeto.service';
import { Component, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';
@Component({
	selector: 'app-form-projeto-situacao',
  	templateUrl: './form-projeto-situacao.component.html',
  	styleUrls: ['./form-projeto-situacao.component.scss']
})
export class FormProjetoSituacaoComponent{
	@ViewChild('Swal') public swal:SwalComponent;
	@ViewChild('SwalDelete') public swalDelete:SwalComponent; 
	@Input() projetosSituacoes = new Array();
	@Input() stakeholdersSelect = new Array();
	@Input() detalhe: boolean;
	@Input() acao:string;
	create:boolean;
	edit:boolean;
	detail:boolean;
	delete:boolean;
	modalDetalhe:boolean = false;
	form: FormGroup;
	projeto_situacao_id:number = null;
	modalTitle:string;
	submitted: boolean = false;
	loading: boolean = false;
	modalReference: NgbModalRef; 
	
	constructor(private projetoService: ProjetoService,private formBuilder: FormBuilder, private modalService: NgbModal, private activatedRoute: ActivatedRoute){
		
		this.form = this.formBuilder.group({
			projeto_stakeholder_id: this.formBuilder.control('',[Validators.required]),
			projeto_stakeholder: this.formBuilder.control(''),
			descricao: this.formBuilder.control('',[Validators.required]),
			acoes: this.formBuilder.control('',[Validators.required]),
			observacao: this.formBuilder.control(''),
		})
	}

	ngOnInit(){ 
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(t.permissao.projeto_situacao.includes("CONSULTAR"))
			this.detail = true;
		if(t.permissao.projeto_situacao.includes("CRIAR"))
			this.create = true;
		if(t.permissao.projeto_situacao.includes("EDITAR"))
			this.edit = true;
		if(t.permissao.projeto_situacao.includes("EXCLUIR"))
			this.delete = true;
	}
	
	onSubmit(){
		this.submitted = true;
        if(!this.form.valid)
		return;
		this.modalReference.close();
		if(!this.projeto_situacao_id){
			this.projetoService.addProjetoSituacao(this.activatedRoute.snapshot.params['id'], this.form)
			.subscribe( (success) => {
				this.swal.text = "Situação cadastrada com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
				this.zeraForm();
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
			return;
		}

		this.projetoService.editProjetoSituacao(this.projeto_situacao_id,this.form)
			.subscribe( (success) => {
				this.swal.text = "Situação atualizada com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
				this.zeraForm();
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
				this.zeraForm();
			});

        
	}
	zeraForm():void{
		this.form.patchValue({
			projeto_stakeholder_id: '',
			projeto_stakeholder: '',
			descricao: '',
			acoes: '',
			observacao: '',
		});
	}

	openModal(modal,id:number = null,detalhe:boolean = false) {
		this.projetoService.getProjetoById(this.activatedRoute.snapshot.params['id']).subscribe((success)=>{
			this.stakeholdersSelect = success.dados.stakeholder;
			this.modalDetalhe = detalhe;
			//Se id == null então é cadastro.
			if(!id){
				this.projeto_situacao_id = null;
				this.modalTitle = "Cadastrar Situação";
				this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
				return;
			}
			
			//Senão é edição.
			this.projeto_situacao_id = id;
			this.modalTitle = "Editar Situação";
			this.projetoService.getProjetoSituacaoById(id).subscribe((success) => {
				this.form.patchValue({
					projeto_stakeholder_id: success.dados.projeto_stakeholder_id,
					projeto_stakeholder: success.dados.projeto_stakeholder,
					descricao: success.dados.descricao,
					acoes: success.dados.acoes,
					observacao: success.dados.observacao,
				});
				this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
			})
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}

	askDelete(id:number){
		this.projeto_situacao_id = id;
		this.swalDelete.show();
	}

	confirmDelete(){
		this.projetoService.delProjetoSituacao(this.projeto_situacao_id).subscribe( 
		(success) => {
			this.swal.text = "Situação deletada com sucesso!";
			this.swal.type = "success";
			this.swal.title = "Pronto!";
			this.swal.show();
			this.submitted = false;
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}

	onCloseModal(){
		if(this.swal.type == "success"){
			this.projetoService.getProjetoById(this.activatedRoute.snapshot.params['id']).subscribe((success)=>{
				this.projetosSituacoes = success.dados.situacao;
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
		}
	}
}