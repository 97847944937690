import { Component, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { PessoaService } from '../../../pessoa/pessoa.service';
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';
import { PreCadastroService } from 'src/app/pre-cadastro/pre-cadastro.service';

declare var $: any;
@Component({
	selector: 'app-form-funcionario-cargo',
  	templateUrl: './form-funcionario-cargo.component.html',
  	styleUrls: ['./form-funcionario-cargo.component.scss']
})
export class FormFuncionarioCargoComponent{
	@ViewChild('Swal') public swal:SwalComponent;
	@ViewChild('SwalDelete') public swalDelete:SwalComponent; 
	@Input() cargos = new Array();
	@Input() pessoa_id: number;
	@Input() acao:string;
	@Input() detalhe:boolean;
	form: FormGroup;
	funcionario_cargo_id:number = null;
	modalTitle:string;
	submitted: boolean = false;
	loading: boolean = false;
	modalReference: NgbModalRef; 
	cargosSelect = new Array();
	piso_salarial: number = 1;
	teto_salarial: number = 999999999;
	@Input() pessoa_status: string = 'A';
	constructor(private pessoaService: PessoaService, private preCadastroService: PreCadastroService, private formBuilder: FormBuilder, private modalService: NgbModal, private activatedRoute: ActivatedRoute){
        this.preCadastroService.getOptions('cargo')
        .subscribe( (success) => {
			this.cargosSelect = success.dados;
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
			// this.submitted = false;
		});

	}
	
	formInit(){
		this.piso_salarial = 1;
		this.teto_salarial = 999999999;
		this.form = this.formBuilder.group({
			cargo_id: this.formBuilder.control('',[Validators.required]),
			area_funcional: this.formBuilder.control(''),
			dt_inicio: this.formBuilder.control('',[Validators.required]),
			dt_termino: this.formBuilder.control(''),
			salario: this.formBuilder.control('',[Validators.required,Validators.min(this.piso_salarial),Validators.max(this.teto_salarial)]),
			observacao: this.formBuilder.control(''),
			funcionario_id: this.activatedRoute.snapshot.params['id']
		})
		let control = this.form.get('salario');
		control.enable();
	}

	carregaFaixaSalarial(cargo_id){
		this.piso_salarial = 1;
		this.teto_salarial = 999999999;
		this.form.get('salario').setValidators([Validators.required,Validators.min(this.piso_salarial),Validators.max(this.teto_salarial)]);
		this.form.get('salario').updateValueAndValidity();
		if(!cargo_id){
			return;
		}
		

		let cargo = this.cargosSelect.find(x => x.id == cargo_id);
		
		if(cargo.piso_salarial){
			this.piso_salarial = cargo.piso_salarial;
			this.teto_salarial = cargo.teto_salarial;
			this.form.get('salario').setValidators([Validators.required,Validators.min(cargo.piso_salarial),Validators.max(cargo.teto_salarial)]);
			this.form.get('salario').updateValueAndValidity();
		}
	}

	onSubmit(){
		this.submitted = true;
        if(!this.form.valid)
		return;
		this.modalReference.close();
		if(!this.funcionario_cargo_id){
			this.pessoaService.addFuncionarioCargo(this.form)
			.subscribe( (success) => {
				this.swal.text = "Cargo cadastrado com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
				this.formInit();
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
				
			});
			return;
		}

		this.pessoaService.editFuncionarioCargo(this.funcionario_cargo_id,this.form)
			.subscribe( (success) => {
				this.swal.text = "Cargo atualizado com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
				this.formInit();
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
				this.formInit();
			});

        
	}
	
	openModal(modal,id:number = null) {
		this.formInit();
		//Se id == null então é cadastro.
		if(!id){
			this.funcionario_cargo_id = null;
			this.modalTitle = "Cadastrar Cargo";
			this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
			return;
		}
		
		
		//Senão é edição.
		this.funcionario_cargo_id = id;
		this.modalTitle = "Editar Cargo";
		this.pessoaService.getFuncionarioCargoById(id).subscribe((success) => {
			this.form.patchValue({
				cargo_id: success.dados.cargo_id,
				area_funcional: success.dados.area_funcional,
				dt_inicio: success.dados.dt_inicio_br,
				dt_termino: success.dados.dt_termino_br,
				salario: success.dados.salario,
				observacao: success.dados.observacao,
			});
			let control = this.form.get('salario');
			control.disable();
			this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		})
	}

	askDelete(id:number){
		this.funcionario_cargo_id = id;
		this.swalDelete.show();
	}

	confirmDelete(){
		this.pessoaService.delFuncionarioCargo(this.funcionario_cargo_id).subscribe( 
		(success) => {
			this.swal.text = "Cargo deletado com sucesso!";
			this.swal.type = "success";
			this.swal.title = "Pronto!";
			this.swal.show();
			this.submitted = false;
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}

	onCloseModal(){
		if(this.swal.type == "success"){
			this.pessoaService.getPessoaById('pessoa/funcionario',this.activatedRoute.snapshot.params['id']).subscribe((success)=>{
				this.cargos = success.dados.cargo;
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
		}
	}
}