import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from './../../../login/login.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PessoaService } from '../../pessoa.service';
import { SYSCONF_LIMIT, SYSCONF_SIGLA } from '../../../../app.api';
import { Patrocinador } from '../patrocinador';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
@Component({
	selector: 'app-patrocinador-list',
	templateUrl: './patrocinadorList.component.html',
	styleUrls: ['./patrocinadorList.component.scss']
})
export class PatrocinadorListComponent implements OnInit{
	patrocinadores: Patrocinador[];
	private permissao: boolean;
	public message:string = '';
	edit:boolean = false;
	detail:boolean = false;
	delete:boolean = false;
	params = null;
	page = 1;
	totalPages:number = 1;
	recordsPerPage:number = SYSCONF_LIMIT;
	buscaForm: FormGroup;
	formExportar: FormGroup;
	sigla = SYSCONF_SIGLA;
	constructor(private activatedRoute: ActivatedRoute,  private router: Router, private pessoaService:PessoaService, private loginService: LoginService, private formBuilder: FormBuilder, private modalService: NgbModal){
		this.buscaForm = this.formBuilder.group({
			id: this.formBuilder.control(''),
			nome_completo: this.formBuilder.control(''),
			cnpj: this.formBuilder.control(''),
			status: this.formBuilder.control(''),
		});
	}
	changePage(event){
		let page = parseInt(event);
		let newParams:Object = new Object();
		this.activatedRoute.params.subscribe(
			param => {
				for(let i in param) {
					if(i != 'page') 
						newParams[i] = param[i];
				}
			})
		if(!page)
		return;
		this.router.navigate(['/'+SYSCONF_SIGLA+`/patrocinador/page/${page}`,newParams]);
	}
	ngOnInit(){ 
		
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		
		if(t.permissao.pessoa_patrocinador.includes("EDITAR"))
			this.edit = true;
		if(t.permissao.pessoa_patrocinador.includes("CONSULTAR"))
			this.detail = true;
		
		this.activatedRoute.params.subscribe(params => {
			this.paramsChange(params);
		});
	}
	paramsChange(params) {
		this.params = params;
		this.message = "";
		this.pessoaService.list('pessoa/patrocinador',params)
		.subscribe(
			(patrocinador) => {
				this.patrocinadores = patrocinador.dados;
				this.totalPages = patrocinador.paging.rows;
				this.page = params['page'];
			},
			(error) => {
				this.message = error.error.message;
			})
	}
	onSubmit(){
		this.router.navigate(['/'+SYSCONF_SIGLA+'/patrocinador/page/1',this.buscaForm.value]);
	}
	getPermissao(modulo,secao){
		return this.loginService.getPermissao(modulo,secao);
	}
	initFormExportar(){
		this.formExportar = this.formBuilder.group({
			campos: new FormArray([]),
		});
	}

	openModal(modal) {
		this.initFormExportar();
		this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
	}
}
