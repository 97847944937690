import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Nivel } from '../nivel';
import { SYSCONF_SIGLA } from 'src/app.api';

@Component({
  selector: 'app-nivel-search',
  templateUrl: './nivelSearch.component.html',
  styleUrls: ['./nivelSearch.component.css']
})
export class NivelSearchComponent implements OnInit {
  public message:string = '';
	buscaForm: FormGroup;
	public conf: Nivel;
	submitted: boolean = false;
  loading: boolean = false;
  sigla = SYSCONF_SIGLA;
  constructor(private formBuilder: FormBuilder, private router: Router) {

  }

  ngOnInit() {
    let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.entidade.includes("CONSULTAR"))
			this.message = 'Você não possui permissão para executar esta ação';
		this.buscaForm = this.formBuilder.group({
			nome: this.formBuilder.control(''),
			sigla: this.formBuilder.control(''),
			olimpica:"",
			status: "",
    });

  }

  changeRadioValue(form,name,value){
    this[form].patchValue({[name]: [value]});
  }



  onSubmit(){
    this.router.navigate(['/'+SYSCONF_SIGLA+'/nivel',{sigla: this.buscaForm.value.sigla,nome: this.buscaForm.value.nome,olimpica: this.buscaForm.value.olimpica,status: this.buscaForm.value.status}]);
  }

}
