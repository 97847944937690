import { Component, ViewChild, Input, AfterViewInit, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { EstoqueService } from 'src/app/estoque/estoque.service';
import { PreCadastroService } from 'src/app/pre-cadastro/pre-cadastro.service';
import { SYSCONF_SIGLA } from 'src/app.api';
import { Suprimento } from '../../../estoque/suprimento/suprimento';
declare var $:any;
@Component({
	selector: 'app-form-estoque-suprimento',
  	templateUrl: './form-estoque-suprimento.component.html',
  	styleUrls: ['./form-estoque-suprimento.component.scss']
})
export class FormEstoqueSuprimentoComponent implements AfterViewInit, OnInit{
    @ViewChild('Swal') public swal:SwalComponent; 
	@Input() form: FormGroup;
	@Input() rota:string;
	@Input() acao:string;
	@Input() detalhe:boolean;
	suprimento: Suprimento;
	submitted: boolean = false;
	loading: boolean = false;
	id:number;    
	slugs = new Array();
	suprimentosUnidadesSelect  = new Array();
	suprimentosTiposSelect = new Array();
	suprimentosCategoriasSelect = new Array();
	suprimentosMarcasSelect = new Array();
	public imagem: File;
	thumb:string;
	atributo: FormArray;
	atributosSelect: any;
	constructor(private preCadastroService:PreCadastroService, private estoqueService: EstoqueService, private router: Router, private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute){
		this.preCadastroService.getOptions('estoque/suprimento/unidade')
        .subscribe( (success) => {
			this.suprimentosUnidadesSelect = success.dados;
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
			this.submitted = false;
		});

		this.preCadastroService.getOptions('estoque/suprimento/tipo')
        .subscribe( (success) => {
			this.suprimentosTiposSelect = success.dados;
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
			this.submitted = false;
		});

		this.preCadastroService.getOptions('estoque/suprimento/marca')
        .subscribe( (success) => {
			this.suprimentosMarcasSelect = success.dados;
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
			this.submitted = false;
		});

		if(this.activatedRoute.snapshot.params['id'])
			this.id = this.activatedRoute.snapshot.params['id'];
	}
	ngAfterViewInit(){
		setTimeout(() => {
			$('.dropify').dropify({
				defaultFile: this.thumb,
			});
		}, 3000);
	}
	onFileChange(event){
		this.imagem = event.target.files[0];		
	}
	get formAtributos(){
		return this.form.get('atributos');
	}
	//Carrega os atributos da categoria para serem selecionados
	loadAtributos(id){
		const formArray: FormArray = this.form.get('atributos') as FormArray;
		while (formArray.length) {
			formArray.removeAt(0);
		 }
		this.atributosSelect = this.suprimentosCategoriasSelect.find(x => x.id == id).atributo;
	}
	initForm(){
		if(this.acao == 'buscar'){
			this.form = this.formBuilder.group({
				id: this.formBuilder.control(''),
				dt_cadastro: this.formBuilder.control(''),
				dt_atualizacao: this.formBuilder.control(''),
				nome: this.formBuilder.control(''),
				sku: this.formBuilder.control(''),
				upc: this.formBuilder.control(''),
				suprimento_unidade_id: this.formBuilder.control(''),
				suprimento_tipo_id: this.formBuilder.control(''),
				suprimento_marca_id: this.formBuilder.control(''),
				suprimento_categoria_id: this.formBuilder.control(''),
				quantidade: this.formBuilder.control(''),
				de_estoque_minimo: this.formBuilder.control('',[Validators.min(0),Validators.max(9999)]),
				ate_estoque_minimo: this.formBuilder.control('',[Validators.min(0),Validators.max(9999)]),
				atributos: this.formBuilder.array([]),
				lote: this.formBuilder.control(''),
			});	
			return;
		}
		this.form = this.formBuilder.group({
			id: this.formBuilder.control(''),
			dt_cadastro: this.formBuilder.control(''),
			dt_atualizacao: this.formBuilder.control(''),
			nome: this.formBuilder.control('',[Validators.required]),
			sku: this.formBuilder.control('',[Validators.required]),
			upc: this.formBuilder.control(''),
			suprimento_unidade_id: this.formBuilder.control('',[Validators.required]),
			suprimento_unidade: this.formBuilder.control(''),
			suprimento_tipo_id: this.formBuilder.control('',[Validators.required]),
			suprimento_tipo: this.formBuilder.control(''),
			suprimento_marca_id: this.formBuilder.control('',[Validators.required]),
			suprimento_marca: this.formBuilder.control(''),
			suprimento_categoria_id: this.formBuilder.control('',[Validators.required]),
			suprimento_categoria: this.formBuilder.control(''),
			quantidade: this.formBuilder.control(''),
			estoque_minimo: this.formBuilder.control('',[Validators.required,Validators.min(0),Validators.max(9999)]),
			atributos: this.formBuilder.array([]),
		});	
	}
	ngOnInit(){
		this.initForm();
		this.preCadastroService.getOptions('estoque/suprimento/categoria')
        .subscribe( (success) => {
			this.suprimentosCategoriasSelect = success.dados;
			if(this.id){
				this.estoqueService.getById('suprimento',this.id).subscribe((suprimento) =>{
					this.suprimento = suprimento.dados;
					this.suprimento.bloqueia_edicao = (this.suprimento.total_movimento > 0) ? true : false;
					this.form.patchValue({
						id: this.suprimento.id,
						dt_cadastro: this.suprimento.dt_cadastro_br,
						dt_atualizacao: this.suprimento.dt_atualizacao_br,
						nome: this.suprimento.nome,
						sku: this.suprimento.sku,
						upc: this.suprimento.upc,
						suprimento_unidade_id: this.suprimento.suprimento_unidade_id,
						suprimento_unidade: this.suprimento.suprimento_unidade,
						suprimento_tipo_id: this.suprimento.suprimento_tipo_id,
						suprimento_tipo: this.suprimento.suprimento_tipo,
						suprimento_marca_id: this.suprimento.suprimento_marca_id,
						suprimento_marca: this.suprimento.suprimento_marca,
						suprimento_categoria_id: this.suprimento.suprimento_categoria_id,
						suprimento_categoria: this.suprimento.suprimento_categoria,
						estoque_minimo: this.suprimento.estoque_minimo,
						quantidade: this.suprimento.quantidade
					});	
					this.thumb = '';
					if(this.suprimento.imagem)
					this.thumb = this.suprimento.imagem.thumb;
					this.loadAtributos(this.suprimento.suprimento_categoria_id);
					this.suprimento.atributo.forEach((v) => {
						this.addAtributo(v.estoque_atributo_item_id, v.estoque_atributo_id)
					})
					
				},(error) => {
					this.swal.text = error.error.message;
					this.swal.type = "error";
					this.swal.title = "Erro!";
					this.swal.show();
				})
			}
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
			this.submitted = false;
		});
	}

	initAtributos(id:number, estoque_atributo_id:number): FormGroup {
		return this.formBuilder.group({
			id: this.formBuilder.control(id),
			estoque_atributo_id: this.formBuilder.control(estoque_atributo_id),
		});
	}

	addAtributo(id, estoque_atributo_id:number){
		const formArray: FormArray = this.form.get('atributos') as FormArray;
		formArray.value.forEach( (v,k) =>  {
			if(v.estoque_atributo_id == estoque_atributo_id){
				formArray.removeAt(k);
			}
		})
		if(id)
		formArray.push(this.initAtributos(id, estoque_atributo_id));
	}

	verifySelected(id){
		const formArray: FormArray = this.form.get('atributos') as FormArray;
		if(formArray.value.find(x => x.id == id))
			return true;

		return false;
	}

	verifyAtributo(id){
		if(typeof this.suprimento != 'undefined') {
			let obj = this.suprimento.atributo.find(x => x.estoque_atributo_id === id);
			if(!(obj)){
				return false;
			}
		}
		
		return true;
	}

	onSubmit(){
		this.submitted = true;
        if(!this.form.valid)
		return;
		if(this.acao == "buscar"){
			this.router.navigate(['/'+SYSCONF_SIGLA+'/estoque/suprimento/page/1',this.form.value]);
			return;
		}
		const formArray: FormArray = this.form.get('atributos') as FormArray;
        this.estoqueService[this.acao]('suprimento',this.form, formArray, this.imagem)
        .subscribe( (success) => {
			this.id = parseInt(success.dados.id);
			if(this.acao == 'add'){
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.text = `Suprimento cadastrado com sucesso!`;
				this.swal.show();
				this.submitted = false;
			}
			else{
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.text = `Suprimento atualizado com sucesso!`;
				this.swal.show();
				this.submitted = false;
			}
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}
	onCloseModal(){
		if(this.swal.type == "success"){
			this.router.navigate([this.rota, this.id]);
		}
	}
}