import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { SYSCONF_SIGLA } from 'src/app.api';

declare var $: any;
@Component({
	selector: 'app-clube-search',
  	templateUrl: './clubeSearch.component.html',
  	styleUrls: ['./clubeSearch.component.scss']
})

export class ClubeSearchComponent implements OnInit{
	
	@ViewChild('Swal') public swal:SwalComponent; 
	public message:string = '';
	searchForm: FormGroup;
	sigla = SYSCONF_SIGLA;
	constructor(private formBuilder: FormBuilder, ){
		this.searchForm = this.formBuilder.group({
			cnpj: this.formBuilder.control(''),
			nome_completo: this.formBuilder.control(''),
			razao_social: this.formBuilder.control(''),
			sigla: this.formBuilder.control(''),
			federado: this.formBuilder.control(''),
			federacao_id: this.formBuilder.control(''),
			email: this.formBuilder.control(''),
			status: this.formBuilder.control(''),
			observacao: this.formBuilder.control(''),
			website: this.formBuilder.control(''),
		});	

	}

	ngOnInit(){
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.pessoa_clube.includes("CONSULTAR"))
			this.message = 'Você não possui permissão para executar esta ação'
	}
	
	
}