import { Component, ViewChild, Input, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { EstoqueService } from 'src/app/estoque/estoque.service';
import { PreCadastroService } from 'src/app/pre-cadastro/pre-cadastro.service';
import { SYSCONF_SIGLA } from 'src/app.api';
import { Movimentacao } from '../../../estoque/movimentacao/movimentacao';
import { PessoaService } from 'src/app/pessoa/pessoa.service';
import { Suprimento } from '../../../estoque/suprimento/suprimento';
import { Fornecedor } from '../../../pessoa/fornecedor/fornecedor';
import { DataService } from '../../../_services/data.service';
import { nextTick } from 'q';
declare var $:any;
@Component({
	selector: 'app-form-estoque-movimentacao',
  	templateUrl: './form-estoque-movimentacao.component.html',
  	styleUrls: ['./form-estoque-movimentacao.component.scss']
})
export class FormEstoqueMovimentacaoComponent implements OnInit{
    @ViewChild('Swal') public swal:SwalComponent; 
	@Input() form: FormGroup;
	@Input() rota:string;
	@Input() acao:string;
	@Input() detalhe:boolean;
	movimentacao: Movimentacao;
	submitted: boolean = false;
	loading: boolean = false;
	id:number;    
	slugs = new Array();
	funcionariosSelect = new Array();
	centroCustoSelect = new Array();
	localOrigemSelect = new Array();
	localDestinoSelect = new Array();
	lotesSelect = new Array();
	atributo: FormArray;
	atributosSelect: any;
	public model: any;
	search;
	formatter;
	suprimentosSelect: Suprimento[];
	suprimentos: FormArray;
	fornecedoresSelect: Fornecedor[];
	private todayDate = new Date();
	motivoRetiradaSelect = new Array();
	constructor(private pessoaService: PessoaService, private preCadastroService:PreCadastroService, private estoqueService: EstoqueService, private router: Router, private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, private dt: DataService){
		
		this.pessoaService.getOptions('pessoa/funcionario').subscribe((success) => {
			this.funcionariosSelect = success.dados;
		},(error) => {
		})

		this.preCadastroService.getOptions('financeiro/centro-custo').subscribe((success) => {
			this.centroCustoSelect = success.dados;
		},(error) => {
		})

		this.preCadastroService.getOptions('estoque/local').subscribe((success) => {
			this.localOrigemSelect = success.dados;
			this.localDestinoSelect = this.localOrigemSelect;
		},(error) => {
		})

		this.pessoaService.getOptions('pessoa/fornecedor').subscribe((success) => {
			this.fornecedoresSelect = success.dados;	
		},(error) => {
		})

		this.preCadastroService.getOptions('estoque/motivo-retirada').subscribe((success) => {
			this.motivoRetiradaSelect = success.dados;
		},(error) => {
		})

		let params = new Array();
		params['page'] = 1;
		this.estoqueService.list('suprimento',params, 3000)
		.subscribe(
		(suprimento) => {
			this.suprimentosSelect = suprimento.dados;
			this.suprimentosSelect.forEach( (v, k) => {
				this.suprimentosSelect[k].atributo_desc = '';
				v.atributo.forEach( (v2, k2) => {
					this.suprimentosSelect[k].atributo_desc += `${v2.estoque_atributo}: ${v2.estoque_atributo_item}; `;
				})
			})
		});
		this.search = (text$: Observable<string>) =>
			text$.pipe(debounceTime(200), map(term => term === '' ? []
			: this.suprimentosSelect.filter(v => (`${v.nome} - ${v.suprimento_categoria} - ${v.suprimento_tipo} - ${v.suprimento_marca} | ${v.atributo_desc}`).toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)))

		this.formatter = (x: Suprimento) => ``;

		if(this.activatedRoute.snapshot.params['id'])
			this.id = this.activatedRoute.snapshot.params['id'];
	}



	initSuprimentos(suprimento: Suprimento, lote: string = ''): FormGroup {
		let img = '';
		if(suprimento.imagem)
			img = suprimento.imagem.thumb;
		let form = this.formBuilder.group({
			id: this.formBuilder.control(suprimento.id),
			imagem: this.formBuilder.control(img),
			nome: this.formBuilder.control(suprimento.nome),
			sku: this.formBuilder.control(suprimento.sku),
			suprimento_tipo: this.formBuilder.control(suprimento.suprimento_tipo),
			suprimento_marca: this.formBuilder.control(suprimento.suprimento_marca),
			suprimento_categoria: this.formBuilder.control(suprimento.suprimento_categoria),
			suprimento_atributo_desc: this.formBuilder.control(suprimento.atributo_desc),
			quantidade: this.formBuilder.control(suprimento.quantidade,[Validators.required]),
			quantidade_maxima: this.formBuilder.control(''),
			quantidade_minima: this.formBuilder.control(''),
			lote: this.formBuilder.control(lote,[Validators.required]),
			dt_validade: this.formBuilder.control(suprimento.quantidade),
		});
		if(this.acao == 'buscar'){
			form.get('quantidade').setValidators([]);
			form.get('lote').setValidators([]);
			form.get('quantidade').updateValueAndValidity();
			form.get('lote').updateValueAndValidity();
		}
		return form;
	}

	

	async addSuprimento(suprimento, id:number = null, key:number = null){
		this.suprimentos = this.form.get('suprimentos') as FormArray;
		if(this.suprimentos.value.find(x => x.id == suprimento.id)){
			this.swal.text = 'Este suprimento já foi adicionado à lista.';
			this.swal.type = "warning";
			this.swal.title = "Oops!";
			this.swal.show();
			return;
		}

		if(this.acao == 'buscar'){
			this.suprimentos.push(this.initSuprimentos(suprimento));
			return;
		}
	
		// Quando clicar no suprimento sugerido, se for transferencia ou retirada, verifica a qtd maxima no local de origem.
		if(this.form.value.tipo !== 'A' && this.form.value.origem_local_id && !this.detalhe && !id){
			let quantidade = 0;
			let buscaEstoque = suprimento.estoque.find(x => x.estoque_local_id == this.form.value.origem_local_id)
			if(buscaEstoque){
				quantidade = parseInt(buscaEstoque.quantidade);
				this.estoqueService.getLoteBySuprimentoId(this.form.value.origem_local_id,suprimento.id).subscribe((success) =>{
					this.lotesSelect[suprimento.id] = success.dados;
					if(parseInt(this.lotesSelect[suprimento.id][0].quantidade) <= 0){
						this.swal.text = 'O lote deste suprimento está esgotado no local de origem selecionado! Mude o lote para verificar a quantidade.';
						this.swal.type = "warning";
						this.swal.title = "Oops!";
						this.swal.show();
					}
					this.suprimentos.push(this.initSuprimentos(suprimento, this.lotesSelect[suprimento.id][0].lote));
					this.atualizaQtdMaxima(this.form.value.origem_local_id);
				},(err) => {
					this.lotesSelect[suprimento.id] = [];
					this.swal.text = 'Este suprimento está esgotado no local de origem selecionado!';
					this.swal.type = "warning";
					this.swal.title = "Oops!";
					this.swal.show();
					return;
				})
			}else{
				this.lotesSelect[suprimento.id] = [];
				this.swal.text = 'Este suprimento está esgotado no local de origem selecionado!';
				this.swal.type = "warning";
				this.swal.title = "Oops!";
				this.swal.show();
				return;
			}
		}else if(this.form.value.tipo !== 'A' && this.form.value.origem_local_id && !this.detalhe && id){
			this.estoqueService.getLoteBySuprimentoId(this.form.value.origem_local_id,suprimento.id).subscribe((success) =>{
				this.lotesSelect[suprimento.id] = success.dados;
				if(parseInt(this.lotesSelect[suprimento.id][0].quantidade) <= 0){
					this.swal.text = 'O lote deste suprimento está esgotado no local de origem selecionado! Mude o lote para verificar a quantidade.';
					this.swal.type = "warning";
					this.swal.title = "Oops!";
					this.swal.show();
				}
				this.suprimentos.push(this.initSuprimentos(suprimento, this.lotesSelect[suprimento.id][0].lote));
				this.atualizaQtdMaxima(this.form.value.origem_local_id);
				this.suprimentos = this.form.get('suprimentos') as FormArray;
				this.suprimentos.at(key).patchValue({
					quantidade: suprimento.quantidade,
					lote: suprimento.lote,
					dt_validade: this.dt.formataDataBr(suprimento.dt_validade_br, this.detalhe)
				})
			},(err) => {
				this.lotesSelect[suprimento.id] = [];
				this.swal.text = 'Este suprimento está esgotado no local de origem selecionado!';
				this.swal.type = "warning";
				this.swal.title = "Oops!";
				this.swal.show();
				return;
			})
		}else{
			this.suprimentos.push(this.initSuprimentos(suprimento));
		}
		
	}
	
	get formSuprimentos(){ 
		return <FormArray>this.form.get('suprimentos'); 
	}

	setValidators(tipoMovimentacao):void{
		if(this.acao == 'buscar'){
			return;
		}
		this.zeraSuprimentos();
		this.form.get('origem_local_id').setValidators([]);
		this.form.get('destino_local_id').setValidators([]);
		this.form.get('fornecedor_id').setValidators([]);
		this.form.get('nota_fiscal').setValidators([]);
		this.form.get('motivo_retirada_id').setValidators([]);
		if(this.acao != 'buscar'){
			if(tipoMovimentacao === 'A'){
				this.form.get('destino_local_id').setValidators([Validators.required]);
				this.form.get('fornecedor_id').setValidators([Validators.required]);
				this.form.get('nota_fiscal').setValidators([Validators.required]);
			}else if(tipoMovimentacao === 'R'){
				this.form.get('origem_local_id').setValidators([Validators.required]);
				this.form.get('motivo_retirada_id').setValidators([Validators.required]);
			}
			else{
				this.form.get('origem_local_id').setValidators([Validators.required]);
				this.form.get('destino_local_id').setValidators([Validators.required]);
			}
			this.form.get('origem_local_id').updateValueAndValidity();
			this.form.get('destino_local_id').updateValueAndValidity();
			this.form.get('fornecedor_id').updateValueAndValidity();
			this.form.get('nota_fiscal').updateValueAndValidity();
			this.form.get('motivo_retirada_id').updateValueAndValidity();
			this.form.patchValue({
				fornecedor_id: '',
				origem_local_id: '',
				destino_local_id: '',
				nota_fiscal: '',
				motivo_retirada_id: '',
			})
		}
	}

	zeraSuprimentos(){
		this.suprimentos = this.form.get('suprimentos') as FormArray;
		while (this.suprimentos.controls.length) {
			this.suprimentos.removeAt(0);
		}
		// if(this.suprimentos.length > 0)
		// this.suprimentos.value.forEach( (v,k) => {
		// 	this.suprimentos.at(k).get('quantidade').setValidators([Validators.required,Validators.min(0)]);
		// 	this.suprimentos.at(k).get('quantidade').updateValueAndValidity();
		// 	Atualizando total de cada produto
		// 	this.suprimentos.at(k).patchValue({
		// 		quantidade_maxima: 0,
		// 		quantidade_minima: 0,
		// 		quantidade: 0
		// 	})
		// })
	}

	atualizaQtdMaximaByLote(suprimento_id:any,lote:string){
		this.suprimentos = this.form.get('suprimentos') as FormArray;
		let k = this.suprimentos.value.findIndex(x => x.id == suprimento_id);
		
		let rowId = this.suprimentosSelect.find(x => x.id == suprimento_id);
		//le.log(rowId);
		let rowEstoque = this.lotesSelect[rowId.id].find(x => x.lote == lote);
		//console.log(rowEstoque);
		let qtdMaxima = 0;
		if(rowEstoque){
			qtdMaxima = parseInt(rowEstoque.quantidade);
		}
		let qtdMin = 0;
		//Se o estoque nao estiver esgotado, valida o campo para minimo = 1;
		if(qtdMaxima > 0){
			qtdMin = 1;
			this.suprimentos.at(k).patchValue({
				lote: lote
			})
		}
		else{
			// Zera o lote em caso dos produtos sem estoque para travar o form.
			this.suprimentos.at(k).patchValue({
				lote: ''
			})
			//console.log('zerou aqui')
		} 
		// Atualizando validacoes
		this.suprimentos.at(k).get('quantidade').setValidators([Validators.required,Validators.min(qtdMin),Validators.max(qtdMaxima)]);
		this.suprimentos.at(k).get('quantidade').updateValueAndValidity();

		//Se o valor digitado pelo usuário for maior do que o limite, zera para ele visualizar.
		let qtdDesejada = parseInt(this.suprimentos.at(k).value.quantidade);
		if(qtdDesejada > qtdMaxima){
			qtdDesejada = 0;
		}
		//Atualizando total de cada produto
		this.suprimentos.at(k).patchValue({
			quantidade_maxima: qtdMaxima,
			quantidade_minima: qtdMin,
			quantidade: qtdDesejada
		})
	}

	atualizaQtdMaxima(origem_local_id:number){
		this.suprimentos = this.form.get('suprimentos') as FormArray;
		if(origem_local_id && this.acao !== 'buscar'){
			//Percorrendo cada suprimento que foi adicionado na movimentacao
			this.suprimentos.value.forEach( (v,k) => {
				//console.log(v);
				//Buscando a linha do select de suprimentos que contem a qtd maxima em cada local
				let rowId = this.suprimentosSelect.find(x => x.id == v.id);
				let estoqueSelecionado = this.suprimentos.at(k).value.lote;
				//Pegando a quantidade maxima do local selecionado na movimentacao
				let qtdMaxima = 0;
				let rowEstoque = this.lotesSelect[rowId.id].find(x => x.lote == estoqueSelecionado);
				if(rowEstoque){
					qtdMaxima = parseInt(rowEstoque.quantidade);
				}
				let qtdMin = 0;
				//Se o estoque nao estiver esgotado, valida o campo para minimo = 1;
				if(qtdMaxima > 0){
					qtdMin = 1;
					
					this.suprimentos.at(k).patchValue({
						lote: estoqueSelecionado
					})
				}
				else{
					// Zera o lote em caso dos produtos sem estoque para travar o form.
					this.suprimentos.at(k).patchValue({
						lote: ''
					})
					//console.log('zerou aqui 2')
				} 
				// Atualizando validacoes
				this.suprimentos.at(k).get('quantidade').setValidators([Validators.required,Validators.min(qtdMin),Validators.max(qtdMaxima)]);
				this.suprimentos.at(k).get('quantidade').updateValueAndValidity();

				//Se o valor digitado pelo usuário for maior do que o limite, zera para ele visualizar.
				let qtdDesejada = parseInt(this.suprimentos.at(k).value.quantidade);
				if(qtdDesejada > qtdMaxima || isNaN(qtdDesejada) || qtdDesejada == null){
					qtdDesejada = 0;
				}
				//Atualizando total de cada produto
				this.suprimentos.at(k).patchValue({
					quantidade_maxima: qtdMaxima,
					quantidade_minima: qtdMin,
					quantidade: qtdDesejada
				})
				//console.log(this.suprimentos.at(k).value)
			})
		}
	}

	initForm(){
		if(this.acao == 'buscar'){
			this.form = this.formBuilder.group({
				id: this.formBuilder.control(''),
				dt_cadastro: this.formBuilder.control(''),
				dt_atualizacao: this.formBuilder.control(''),
				centro_custo_id: this.formBuilder.control(''),
				funcionario_id: this.formBuilder.control(''),
				fornecedor_id: this.formBuilder.control(''),
				origem_local_id: this.formBuilder.control(''),
				destino_local_id: this.formBuilder.control(''),
				tipo: this.formBuilder.control(''),
				status: this.formBuilder.control(''),
				nota_fiscal: this.formBuilder.control(''),
				de_dt_movimento: this.formBuilder.control(null),
				ate_dt_movimento: this.formBuilder.control(null),
				suprimentos: this.formBuilder.array([]),
				suprimento_id: this.formBuilder.control(''),
				motivo_retirada_id: this.formBuilder.control(''),
				observacao: this.formBuilder.control(''),
				de_valor_previsto: this.formBuilder.control(''),
				ate_valor_previsto: this.formBuilder.control(''),
			});	
			return;
		}
		this.form = this.formBuilder.group({
			id: this.formBuilder.control(''),
			dt_cadastro: this.formBuilder.control(''),
			dt_atualizacao: this.formBuilder.control(''),
			centro_custo_id: this.formBuilder.control(''),
			centro_custo: this.formBuilder.control(''),
			funcionario_id: this.formBuilder.control('',[Validators.required]),
			funcionario: this.formBuilder.control(''),
			fornecedor_id: this.formBuilder.control(''),
			fornecedor: this.formBuilder.control(''),
			origem_local_id: this.formBuilder.control(''),
			origem_local: this.formBuilder.control(''),
			destino_local_id: this.formBuilder.control(''),
			destino_local: this.formBuilder.control(''),
			tipo: this.formBuilder.control('',[Validators.required]),
			descricao_tipo: this.formBuilder.control(''),   
			status: this.formBuilder.control('1',[Validators.required]),
			descricao_status: this.formBuilder.control(''),
			nota_fiscal: this.formBuilder.control(''),
			dt_movimento: this.formBuilder.control(null,[Validators.required]),
			suprimentos: this.formBuilder.array([]),
			motivo_retirada_id: this.formBuilder.control(''),
			motivo_retirada: this.formBuilder.control(''),
			observacao: this.formBuilder.control(''),
			valor_previsto: this.formBuilder.control(''),
			valor_pago: this.formBuilder.control(''),
		});	
		
	}
	ngOnInit(){
		this.initForm();
		if(this.id){
			let params = new Array();
			params['page'] = 1;
			this.estoqueService.list('suprimento',params, 3000).subscribe(
			(suprimento) => {
				this.suprimentosSelect = suprimento.dados;
				this.suprimentosSelect.forEach( (v, k) => {
					this.suprimentosSelect[k].atributo_desc = '';
					v.atributo.forEach( (v2, k2) => {
						this.suprimentosSelect[k].atributo_desc += `${v2.estoque_atributo}: ${v2.estoque_atributo_item}; `;
					})
				})
				this.estoqueService.getById('movimento',this.id).subscribe((movimentacao) =>{
					this.movimentacao = movimentacao.dados;
	
					this.form.patchValue({
						id: this.movimentacao.id,
						dt_cadastro: this.movimentacao.dt_cadastro_br,
						dt_atualizacao: this.movimentacao.dt_atualizacao_br,
						centro_custo_id: this.movimentacao.centro_custo_id,
						centro_custo: this.movimentacao.centro_custo,
						funcionario_id: this.movimentacao.funcionario_id,
						funcionario: this.movimentacao.funcionario,
						fornecedor_id: this.movimentacao.fornecedor_id,
						fornecedor: this.movimentacao.fornecedor,
						origem_local_id: this.movimentacao.origem_local_id,
						origem_local: this.movimentacao.origem_local,
						destino_local_id: this.movimentacao.destino_local_id,
						destino_local: this.movimentacao.destino_local,
						tipo: this.movimentacao.tipo,
						descricao_tipo: this.movimentacao.descricao_tipo,
						status: this.movimentacao.status,
						descricao_status: this.movimentacao.descricao_status,
						nota_fiscal: this.movimentacao.nota_fiscal,
						dt_movimento: this.dt.formataDataBr(this.movimentacao.dt_movimento_br, this.detalhe),
						motivo_retirada: this.movimentacao.motivo_retirada,
						motivo_retirada_id: this.movimentacao.motivo_retirada_id,
						observacao: this.movimentacao.observacao,
						valor_previsto: this.movimentacao.valor_previsto,
						valor_pago: this.movimentacao.valor_pago,
					});	
					this.movimentacao.suprimentos.forEach((v,k) => {
						let sup = this.suprimentosSelect.find(x => x.id == v.estoque_suprimento_id);
						this.movimentacao.suprimentos[k].imagem = sup.imagem;
						this.movimentacao.suprimentos[k].id = v.estoque_suprimento_id;
						this.movimentacao.suprimentos[k].suprimento_marca = sup.suprimento_marca;
						this.movimentacao.suprimentos[k].atributo_desc = sup.atributo_desc;
						 this.addSuprimento(this.movimentacao.suprimentos[k],this.id, k);
						 if(this.movimentacao.tipo == 'A'){
							this.suprimentos = this.form.get('suprimentos') as FormArray;
							this.suprimentos.at(k).patchValue({
								quantidade: v.quantidade,
								lote: v.lote,
								dt_validade: this.dt.formataDataBr(v.dt_validade_br, this.detalhe)
							})
						 }
						
					})
				},(error) => {
					this.swal.text = error.error.message;
					this.swal.type = "error";
					this.swal.title = "Erro!";
					this.swal.show();
				})
			});
		}
		
	}

	onSubmit(){
		this.submitted = true;
        if(!this.form.valid)
		return;
		this.suprimentos = this.form.get('suprimentos') as FormArray;
		if(this.acao == "buscar"){
			let de_dt_movimento = '';
			if(this.form.value.de_dt_movimento != null)
				de_dt_movimento = `${this.form.value.de_dt_movimento.day}/${this.form.value.de_dt_movimento.month}/${this.form.value.de_dt_movimento.year}`
			
			let ate_dt_movimento = '';
			if(this.form.value.ate_dt_movimento != null)
				ate_dt_movimento = `${this.form.value.ate_dt_movimento.day}/${this.form.value.ate_dt_movimento.month}/${this.form.value.ate_dt_movimento.year}`

			let suprimento_id = 0;
			this.formSuprimentos.value.forEach(v => {
				suprimento_id = v.id;
			});
			this.form.patchValue({
				de_dt_movimento: de_dt_movimento,				
				ate_dt_movimento: ate_dt_movimento,
				suprimento_id: suprimento_id
			});

			this.form.removeControl('suprimentos');
			this.router.navigate(['/'+SYSCONF_SIGLA+'/estoque/movimentacao/page/1',this.form.value]);
			return;
		}
		
        this.estoqueService[this.acao]('movimento',this.form,null,null,this.suprimentos)
        .subscribe( (success) => {
			this.id = parseInt(success.dados.id);
			if(this.acao == 'add'){
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.text = `Movimentação cadastrada com sucesso!`;
				this.swal.show();
				this.submitted = false;
			}
			else{
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.text = `Movimentação atualizada com sucesso!`;
				this.swal.show();
				this.submitted = false;
			}
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}
	onCloseModal(){
		if(this.swal.type == "success"){
			this.router.navigate([this.rota, this.id]);
		}
	}
}