import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { LoginService } from './../../../login/login.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SYSCONF_LIMIT, SYSCONF_SIGLA } from '../../../../app.api';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { RHService } from '../../rh.service';
import { DataService } from 'src/app/_services/data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-ferias-list',
	templateUrl: './feriasList.component.html',
	styleUrls: ['./feriasList.component.scss']
})

export class FeriasListComponent implements OnInit{
	@ViewChild('Swal') public swal: SwalComponent;
	ferias = new Array();
	public message:string = '';
	edit:boolean = false;
	detail:boolean = false;
	delete:boolean = false;
	params = null;
	page = 1;
	totalPages:number = 1;
	recordsPerPage:number = SYSCONF_LIMIT;
	buscaForm: FormGroup;
	formExportar: FormGroup;
	
	sigla = SYSCONF_SIGLA;
	exibeProgresso = false;
	constructor(private activatedRoute: ActivatedRoute, private modalService: NgbModal, private router: Router, private rhService: RHService, private loginService: LoginService, private formBuilder: FormBuilder, private dt: DataService){
		this.buscaForm = this.formBuilder.group({
			ano: this.formBuilder.control(''),
			mes: this.formBuilder.control(''),
			funcionario_id: this.formBuilder.control('')
		});

	}

	diasFerias(dtInicio:any, dtTermino:any) {

		dtInicio = Date.parse(dtInicio);

		if(!dtTermino) {
			//dtTermino = new Date();
			return 'Indeterminado';
		} else {
			dtTermino = Date.parse(dtTermino);
		}
		return  Math.floor(( dtTermino - dtInicio ) / 86400000)+1; 
	}

	changePage(event){
		let page = parseInt(event);
		let newParams:Object = new Object();
		this.activatedRoute.params.subscribe(
			param => {
				for(let i in param) {
					if(i != 'page') 
						newParams[i] = param[i];
				}
			})
		if(!page)
		return;
		this.router.navigate(['/'+SYSCONF_SIGLA+`/ferias/page/${page}`,newParams]);
	}
	ngOnInit(){ 
		this.buscaForm = this.formBuilder.group({
			nome: this.formBuilder.control(''),
			area_funcional: this.formBuilder.control(''),
			de_dt_inicio: this.formBuilder.control(null),
			ate_dt_termino: this.formBuilder.control(null)
		});

		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		
		if(t.permissao.rh_ferias.includes("CONSULTAR"))
			this.detail = true;
		
		this.activatedRoute.params.subscribe(params => {
			this.paramsChange(params);
		});
	}

	initFormExportar(){
		this.formExportar = this.formBuilder.group({
			campos: new FormArray([]),
		});
	}
	openExportModal(modal) {
		this.initFormExportar();
		this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
	}

	paramsChange(params) {
		this.message = "";
		this.params = params;
		this.rhService.get('ferias',params)
		.subscribe(
			(ferias) => {
				this.ferias = ferias.dados;
				this.ferias.map(
				(row) => {
					let dataTermino = new Date(row.dt_termino);
					let dataInicio = new Date(row.dt_inicio);
					let duracao = Math.ceil((dataTermino.valueOf() - dataInicio.valueOf() ) / (1000 * 3600 * 24));
					
					let dias_ferias_porcentagem:any = 100;
					let type = 'info';
					let hoje = new Date();
					let dias_ferias:any = Math.ceil((hoje.valueOf() - dataInicio.valueOf() ) / (1000 * 3600 * 24));
					let retornou = false;
					let dias_ferias_inicio = 0;

					if(hoje.valueOf() > dataTermino.valueOf()){
						retornou = true;
						dias_ferias = duracao;
					}
					dias_ferias_porcentagem = (100 * parseInt(dias_ferias)) / duracao;

					if(dias_ferias_porcentagem <= 25){
						type = 'danger';
					}else if(dias_ferias_porcentagem > 25 && dias_ferias_porcentagem <= 50 ){
						type = 'warning';
					} else if(dias_ferias_porcentagem >50 && dias_ferias_porcentagem <= 99){
						type = 'info';
					} else if(dias_ferias_porcentagem == 100){
						type = 'success';
					}

					if(hoje.valueOf() < dataInicio.valueOf()){
						dias_ferias_inicio = Math.ceil((dataInicio.valueOf() - hoje.valueOf() ) / (1000 * 3600 * 24));
					}
					row.dias_ferias = dias_ferias; 
					row.dias_ferias_inicio = dias_ferias_inicio; 
					row.dias_afastamento = duracao; 
					row.retornou = retornou;
					row.type = type;

					if(typeof params['nome']){
						this.buscaForm.patchValue({
							nome: params['nome'] != undefined ? params['nome'] : '' ,
							area_funcional: params['area_funcional'] != undefined ? params['area_funcional'] : ''  ,
							de_dt_inicio: params['de_dt_inicio'] != undefined ? this.dt.formataDataBr(params['de_dt_inicio']) : null,				
							ate_dt_termino: params['ate_dt_termino'] != undefined ? this.dt.formataDataBr(params['ate_dt_termino']) : null,
						})
					}

					return row;
				});
				this.totalPages = ferias.paging.rows;
				this.page = params['page'];
			},
			(error) => {
				this.message = error.error.message;
			})
	}

	onSubmit(){
		let de_dt_inicio = this.dt.convertDataObject(this.buscaForm.value,'de_dt_inicio');
		let ate_dt_termino = this.dt.convertDataObject(this.buscaForm.value,'ate_dt_termino');
		this.buscaForm.patchValue({
			de_dt_inicio: de_dt_inicio,				
			ate_dt_termino: ate_dt_termino,
		})
		if(de_dt_inicio.indexOf('undefined') !== -1){
			this.buscaForm.patchValue({
				de_dt_inicio: ''
			})
		}
		if(ate_dt_termino.indexOf('undefined') !== -1){
			this.buscaForm.patchValue({
				ate_dt_termino: ''
			})
		}
		this.router.navigate(['/'+SYSCONF_SIGLA+'/ferias/page/1',this.buscaForm.value]);
	}
	
	getPermissao(modulo,secao){
		return this.loginService.getPermissao(modulo,secao);
	}
}
