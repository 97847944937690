import { Component, ViewChild, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { PerfilService } from 'src/app/perfil/perfil.service';

declare var $: any;
@Component({
	selector: 'app-form-perfil',
  	templateUrl: './form-perfil.component.html',
  	styleUrls: ['./form-perfil.component.scss']
})
export class FormPerfilComponent implements OnInit{
	@ViewChild('Swal') public swal:SwalComponent;
	form: FormGroup;
    checkpassword:string = '';
	constructor(private perfilService: PerfilService, private formBuilder: FormBuilder){
		this.form = this.formBuilder.group({
			login: this.formBuilder.control('',[Validators.required]),
			senha: this.formBuilder.control(''),
			confirmarSenha: this.formBuilder.control(''),
		})
	}
	
	ngOnInit(){
		this.perfilService.getPerfilByToken().subscribe((success)=>{
			this.form.patchValue({
				login: success.dados.email,
			});
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		});
	}

	checkPassword(){
		if(this.form.value.senha == this.form.value.confirmarSenha){
			this.checkpassword = "";
			return true;
		}
		this.checkpassword = "As senhas não conferem!";
		return false;
	}
	
	onSubmit(){
        if(!this.form.valid || !this.checkPassword())
		return;

		this.perfilService.edit(this.form)
			.subscribe( (success) => {
				this.swal.text = "Perfil atualizado com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
			});

        
	}


	onCloseModal(){
		
	}
}