import { Router, NavigationStart} from '@angular/router';
import { Component } from '@angular/core';
import { LoginService } from '../../login/login.service';
import { SYSCONF_SIGLA } from 'src/app.api';

@Component({
	templateUrl: './relatorioFinanceiro.component.html',
	styleUrls: ['./relatorioFinanceiro.component.scss']
})

export class RelatorioFinanceiroComponent {
	title:string;
	sigla = SYSCONF_SIGLA;
	constructor(private router: Router, private loginService: LoginService){
		this.changeRoute(this.router.url);
		router.events.forEach((event) => {
			if(event instanceof NavigationStart) {
				this.changeRoute(event.url);
			}
		  });
	}

    

	changeRoute(url){
		if(url.indexOf('conciliacao') !== -1)
			this.title = 'Conciliação Bancária';
		
		if(url.indexOf('balancete') !== -1)
			this.title = 'Balancete';
		
	}
	
	getPermissao(modulo,secao){
		return this.loginService.getPermissao(modulo,secao);
	}

}