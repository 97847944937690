import { PreCadastroService } from './../../../pre-cadastro/pre-cadastro.service';
import { Component, ViewChild, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';


@Component({
	selector: 'app-form-grau-parentesco',
  	templateUrl: './form-grau-parentesco.component.html',
  	styleUrls: ['./form-grau-parentesco.component.scss']
})
export class FormGrauParentescoComponent{
	@ViewChild('Swal') public swal:SwalComponent;
	@ViewChild('SwalDelete') public swalDelete:SwalComponent; 
	@Input() acao:string;
	@Input() detalhe:boolean;
	form: FormGroup;
	grau_parentesco_id:number = null;
	modalTitle:string;
	submitted: boolean = false;
	loading: boolean = false;
	modalReference: NgbModalRef; 
	cob = null;
	grausParentesco;
	constructor(private preCadastro: PreCadastroService, private formBuilder: FormBuilder,private modalService: NgbModal){
    	this.form = this.formBuilder.group({
			nome: this.formBuilder.control('',[Validators.required])
		})
		this.preCadastro.get('pessoa/grau-parentesco')
        .subscribe( (success) => {
			this.grausParentesco = success.dados;
		},
		(error) => {});
	}
	
	onSubmit(){
		this.submitted = true;
        if(!this.form.valid)
		return;
		this.modalReference.close();
		if(!this.grau_parentesco_id){
			this.preCadastro.add('pessoa/grau-parentesco', this.form)
			.subscribe( (success) => {
				this.swal.text = "Registro cadastrado com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
			return;
		}

		this.preCadastro.edit('pessoa/grau-parentesco', this.grau_parentesco_id,this.form)
			.subscribe( (success) => {
				this.swal.text = "Registro atualizado com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});

        
	}

	openModal(modal,id:number = null) {

		//Se id == null então é cadastro.
		if(!id){
			this.grau_parentesco_id = null;
			this.modalTitle = "Cadastrar Grau de Parentesco";
			this.form.patchValue({
				nome: ''
			});
			this.modalReference = this.modalService.open(modal, {backdrop: 'static'});
			return;
		}
		
		//Senão é edição.
		this.grau_parentesco_id = id;
		this.modalTitle = "Editar Grau de Parentesco";
		this.preCadastro.getById('pessoa/grau-parentesco', id).subscribe((success) => {
			this.form.patchValue({
				nome: success.dados.nome
			});
			this.modalReference = this.modalService.open(modal, {backdrop: 'static'});
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		})
	}

	askDelete(id:number){
		this.grau_parentesco_id = id;
		this.swalDelete.show();
	}

	confirmDelete(){
		this.preCadastro.del('pessoa/grau-parentesco', this.grau_parentesco_id).subscribe( 
		(success) => {
			this.swal.text = "Registro deletado com sucesso!";
			this.swal.type = "success";
			this.swal.title = "Pronto!";
			this.swal.show();
			this.submitted = false;
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}

	onCloseModal(){
		if(this.swal.type == "success"){
			this.preCadastro.get('pessoa/grau-parentesco').subscribe((success)=>{
				this.grausParentesco = success.dados;
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
		}
	}
}