import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SYSCONF_API, SYSCONF_APP_ID, SYSCONF_CREDENTIALS, SYSCONF_SIGLA } from './../../app.api';
import { Injectable } from '@angular/core';
import { Nivel } from './nivel';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { tap } from 'rxjs/operators';
@Injectable()
export class NivelService{
    nivel: Nivel[];
    private headers;
    cob = null;
    constructor(private http: HttpClient){
    this.cob = JSON.parse(localStorage.getItem('CONFLogin'));
        this.headers = {'App-Id': SYSCONF_APP_ID,'Content-Type':'application/json','App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
    }

    getNivel(): Observable<any>{
        return this.http.get(`${SYSCONF_API}/nivel`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    list(busca): Observable<any>{
        let buscaUrl:string = '';
        if(busca.sigla != undefined){
            buscaUrl = '?sigla='+busca.sigla+'&nome='+busca.nome+'&olimpica='+busca.olimpica+'&status='+busca.status;
        }

        return this.http.get(`${SYSCONF_API}/nivel${buscaUrl}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }


    listModulo(): Observable<any>{
        return this.http.get(`${SYSCONF_API}/modulo/categoria?limit=1000`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    add(form: FormData): Observable<any>{

        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.post(`${SYSCONF_API}/nivel`,form,
        {headers: new HttpHeaders(this.headers)})
        .pipe(
            tap(
                response => this.nivel = response.dados,
                error => error
            )
        )
    }

    nivelById(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.get(`${SYSCONF_API}/nivel/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }

    edit(form: FormData): Observable<any>{
        let id = form.get("id");
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.post(`${SYSCONF_API}/nivel/${id}`,form,
        {headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => this.nivel = response.dados,
                error => error
            )
        )
    }
}
