import { Federacao } from '../federacao';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SYSCONF_SIGLA } from 'src/app.api';
@Component({
	selector: 'app-federacao-create',
  	templateUrl: './federacaoCreate.component.html',
  	styleUrls: ['./federacaoCreate.component.scss']
})
export class FederacaoCreateComponent implements OnInit{
	public message:string = '';
	cadastroForm: FormGroup;
	Federacao: Federacao;
	sigla = SYSCONF_SIGLA;
	private preloader = document.getElementsByClassName('preloader')[0];
	constructor(private formBuilder: FormBuilder){
		this.cadastroForm = this.formBuilder.group({
			pessoa_id: this.formBuilder.control(''),
			cnpj: this.formBuilder.control('',[Validators.required]),
			razao_social: this.formBuilder.control('',[Validators.required]),
			nome_completo: this.formBuilder.control('',[Validators.required]),
			email: this.formBuilder.control('',[Validators.required, Validators.email]),
			status: this.formBuilder.control('A',[Validators.required]),
			presidente: this.formBuilder.control('',[Validators.required]),
			sigla: this.formBuilder.control('',[Validators.required]),
			estado: this.formBuilder.control('',[Validators.required]),
			observacao: this.formBuilder.control(''),
			website: this.formBuilder.control(''),
		});	
	}
	ngOnInit(){
		this.preloader.setAttribute("style","display:none");
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.pessoa_federacao.includes("CRIAR"))
			this.message = 'Você não possui permissão para executar esta ação'
	}
}