import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { LoginService } from './../../../login/login.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SYSCONF_LIMIT, SYSCONF_SIGLA } from '../../../../app.api';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { RHService } from '../../rh.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-folha-de-pagamento-list',
	templateUrl: './folhaDePagamentoList.component.html',
	styleUrls: ['./folhaDePagamentoList.component.scss']
})

export class FolhaDePagamentoListComponent implements OnInit{
	@ViewChild('Swal') public swal: SwalComponent;
	@ViewChild('SwalDelete') public swalDelete:SwalComponent; 
	folhadepagamento = new Array();
	folhadepagamento_id:number = null;
	public message:string = '';
	edit:boolean = false;
	detail:boolean = false;
	delete:boolean = false;
	create:boolean = false;
	params = null;
	page = 1;
	totalPages:number = 1;
	recordsPerPage:number = SYSCONF_LIMIT;
	buscaForm: FormGroup;
	formExportar: FormGroup;
	sigla = SYSCONF_SIGLA;
	constructor(private activatedRoute: ActivatedRoute, private modalService: NgbModal, private router: Router, private rhService: RHService, private loginService: LoginService, private formBuilder: FormBuilder){
		this.buscaForm = this.formBuilder.group({
			ano: this.formBuilder.control(''),
			mes: this.formBuilder.control(''),
		});

	}
	changePage(event){
		let page = parseInt(event);
		let newParams:Object = new Object();
		this.activatedRoute.params.subscribe(
			param => {
				for(let i in param) {
					if(i != 'page') 
						newParams[i] = param[i];
				}
			})
		if(!page)
		return;
		this.router.navigate(['/'+SYSCONF_SIGLA+`/folha-de-pagamento/page/${page}`,newParams]);
	}
	ngOnInit(){ 
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(t.permissao.rh_folha_pagamento.includes("CONSULTAR"))
			this.detail = true;
		if(t.permissao.rh_folha_pagamento.includes("CRIAR"))
			this.create = true;
		if(t.permissao.rh_folha_pagamento.includes("EDITAR"))
			this.edit = true;
		if(t.permissao.rh_folha_pagamento.includes("EXCLUIR"))
			this.delete = true;
		this.activatedRoute.params.subscribe(params => {
			this.paramsChange(params);
		});
	}

	initFormExportar(){
		this.formExportar = this.formBuilder.group({
			campos: new FormArray([]),
		});
	}
	openExportModal(modal) {
		this.initFormExportar();
		this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
	}


	askDelete(id:number){
		this.folhadepagamento_id = id;
		this.swalDelete.show();
	}

	confirmDelete(){
		this.rhService.delete('folha-pagamento',this.folhadepagamento_id).subscribe( 
		(success) => {
			this.swal.text = "Folha de pagamento deletada com sucesso!";
			this.swal.type = "success";
			this.swal.title = "Pronto!";
			this.swal.show();
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		});
	}

	paramsChange(params) {
		this.message = "";
		this.params = params;
		this.rhService.get('folha-pagamento',params)
		.subscribe(
			(folhadepagamento) => {
				this.folhadepagamento = folhadepagamento.dados;
				this.totalPages = folhadepagamento.paging.rows;
				this.page = params['page'];
			},
			(error) => {
				this.message = error.error.message;
			})
	}

	onSubmit(){
		this.router.navigate(['/'+SYSCONF_SIGLA+'/folha-de-pagamento/page/1',this.buscaForm.value]);
	}
	
	getPermissao(modulo,secao){
		return this.loginService.getPermissao(modulo,secao);
	}

	onCloseModal(){
		let params = new Array();
		params['page'] = 1;
		if(this.swal.type == "success"){
			this.rhService.get('folha-pagamento',params)
			.subscribe(
				(folhadepagamento) => {
					this.folhadepagamento = folhadepagamento.dados;
					this.totalPages = folhadepagamento.paging.rows;
					this.page = params['page'];
				},
				(error) => {
					this.message = error.error.message;
				})
		}
	}
}
