import { LoginService } from './../login/login.service';
import { Injectable }       from '@angular/core';
import {
  CanActivate, CanLoad, Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Route
} from '@angular/router';

@Injectable()
export class AuthGuard implements CanLoad, CanActivate {

  constructor(private loginService: LoginService) {}
  
  checkAuthentication(path: string):any{
    if(!this.loginService.isLoggedIn()){
      localStorage.removeItem('CONFLogin');
      this.loginService.handleLogin();
      return;
    }
    return true;
  }

  canLoad(route: Route): boolean {
    return this.checkAuthentication(route.path);
  }

  canActivate(activatedRoute: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): boolean {
    return this.checkAuthentication(activatedRoute.routeConfig.path)
  }

  
}