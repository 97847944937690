import { Component, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { PessoaService } from '../../../pessoa/pessoa.service';
import { NgxViacepService } from '@brunoc/ngx-viacep';
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;
@Component({
	selector: 'app-form-pessoa-conta-bancaria',
  	templateUrl: './form-pessoa-conta-bancaria.component.html',
  	styleUrls: ['./form-pessoa-conta-bancaria.component.scss']
})
export class FormPessoaContaBancariaComponent{
	@ViewChild('Swal') public swal:SwalComponent;
	@ViewChild('SwalDelete') public swalDelete:SwalComponent; 
	@Input() contasBancarias;
	@Input() pessoa_id: number;
	@Input() acao:string;
	@Input() detalhe:boolean;
	@Input() pessoa_status: string = 'A';
	form: FormGroup;
	conta_bancaria_id:number = null;
	modalTitle:string;
	submitted: boolean = false;
	loading: boolean = false;
	modalReference: NgbModalRef;   
	bancos: object = new Array();
    
	constructor(private pessoaService: PessoaService, private formBuilder: FormBuilder, private router: Router, private viaCep: NgxViacepService,private modalService: NgbModal){
		this.pessoaService.listBancos()
        .subscribe( (success) => {
			this.bancos = success.dados;
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
		
		this.formInit();
	}
	
	formInit():void{
		this.form = this.formBuilder.group({
			banco_id: this.formBuilder.control('',[Validators.required]),
			descricao: this.formBuilder.control('',[Validators.required]),
			num_conta: this.formBuilder.control('',[Validators.required]),
			dv_conta: this.formBuilder.control('',[Validators.required, Validators.minLength(1), Validators.maxLength(1)]),
			num_agencia: this.formBuilder.control('',[Validators.required]),
			dv_agencia: this.formBuilder.control('',[Validators.minLength(1), Validators.maxLength(1)]),
			tipo: this.formBuilder.control('CC',[Validators.required]),
			status: this.formBuilder.control('A',[Validators.required]),
			variacao: this.formBuilder.control('')
		})
	}
	changeRadioValue(form,name,value){
		this[form].patchValue({[name]: value});
	}

	onSubmit(){
		this.submitted = true;
        if(!this.form.valid)
		return;
		this.modalReference.close();
		if(!this.conta_bancaria_id){
			this.pessoaService.addContaBancaria(this.pessoa_id,this.form)
			.subscribe( (success) => {
				this.swal.text = "Conta bancária cadastrada com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
			return;
		}
		
		this.pessoaService.editContaBancaria(this.conta_bancaria_id,this.form)
			.subscribe( (success) => {
				this.swal.text = "Conta bancária atualizada com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
	}

	openModal(modal,id:number = null) {
		this.formInit();
		//Se id == null então é cadastro.
		if(!id){
			this.conta_bancaria_id = null;
			this.modalTitle = "Cadastrar Conta Bancária";
			this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
			return;
		}
		
		
		//Senão é edição.
		this.conta_bancaria_id = id;
		this.modalTitle = "Editar Conta Bancária";
		this.pessoaService.getContaBancariaById(id).subscribe((success) => {
			this.form.patchValue({
				banco_id: success.dados.banco_id,
				descricao: success.dados.descricao,
				num_conta: success.dados.num_conta,
				dv_conta: success.dados.dv_conta,
				num_agencia: success.dados.num_agencia,
				dv_agencia: success.dados.dv_agencia,
				tipo: success.dados.tipo,
				status: success.dados.status,
				variacao: success.dados.variacao
			});
			this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		})
	}

	askDelete(id:number){
		this.conta_bancaria_id = id;
		this.swalDelete.show();
	}

	confirmDelete(){
		this.pessoaService.delContaBancaria(this.conta_bancaria_id).subscribe( 
		(success) => {
			this.swal.text = "Conta bancária deletada com sucesso!";
			this.swal.type = "success";
			this.swal.title = "Pronto!";
			this.swal.show();
			this.submitted = false;
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}

	onCloseModal(){
		if(this.swal.type == "success"){
			this.pessoaService.getPessoaById('pessoa',this.pessoa_id).subscribe((success)=>{
				this.contasBancarias = success.dados.conta_bancaria;
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
		}
	}
}