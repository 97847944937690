import { Component, OnInit} from '@angular/core';
import { SYSCONF_SIGLA } from 'src/app.api';
@Component({
	selector: 'app-movimento-create',
  	templateUrl: './movimentoCreate.component.html',
  	styleUrls: ['./movimentoCreate.component.scss']
})

export class MovimentoCreateComponent implements OnInit{
	public message:string = '';
	sigla = SYSCONF_SIGLA;
	ngOnInit(){
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.financeiro_movimento.includes("CRIAR"))
			this.message = 'Você não possui permissão para executar esta ação'
	}
}