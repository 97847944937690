import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SYSCONF_LIMIT, SYSCONF_SIGLA } from '../../../../app.api';
import { FinanceiroService } from '../../financeiro.service';
import { NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-remessa-list',
	templateUrl: './remessaList.component.html',
	styleUrls: ['./remessaList.component.scss']
})

export class RemessaListComponent implements OnInit{
	remessa = new Array();
	public message:string = '';
	delete:boolean = false;
	params = null;
	page = 1;
	totalPages:number = 1;
	recordsPerPage:number = SYSCONF_LIMIT;
	sigla = SYSCONF_SIGLA;
	modalReference: NgbModalRef; 
	constructor(private activatedRoute: ActivatedRoute,  private router: Router, private financeiroService: FinanceiroService){
	}
	changePage(event){
		let page = parseInt(event);
		let newParams:Object = new Object();
		this.activatedRoute.params.subscribe(
			param => {
				for(let i in param) {
					if(i != 'page') 
						newParams[i] = param[i];
				}
			})
		if(!page)
		return;
		this.router.navigate(['/'+SYSCONF_SIGLA+`/remessa/page/${page}`,newParams]);
	}
	ngOnInit(){ 
		this.activatedRoute.params.subscribe(params => {
			this.paramsChange(params);
		});
	}
	paramsChange(params) {
		this.message = "";
		this.financeiroService.list('boleto/remessa',params)
		.subscribe(
			(remessa) => {
				this.remessa = remessa.dados;
				this.totalPages = remessa.paging.rows;
				this.page = params['page'];
			},
			(error) => {
				this.message = error.error.message;
			})
	}
}
