import { FinanceiroService } from '../../../financeiro/financeiro.service';
import { PreCadastroService } from '../../../pre-cadastro/pre-cadastro.service';
import { ConfiguracaoBoleto } from '../../../financeiro/configuracao-boleto/configuracao-boleto';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
	selector: 'app-form-financeiro-configuracao-boleto',
  	templateUrl: './form-financeiro-configuracao-boleto.component.html',
  	styleUrls: ['./form-financeiro-configuracao-boleto.component.scss']
})
export class FormFinanceiroConfiguracaoBoletoComponent implements OnInit{
	@ViewChild('Swal') public swal:SwalComponent;
	@Input() acao:string;
	@Input() detalhe:boolean;
	form: FormGroup;
	contaBancariaSelect = new Array();
	boleto: ConfiguracaoBoleto;
	constructor(private financeiroService: FinanceiroService, private preCadastroService: PreCadastroService, private formBuilder: FormBuilder){
		this.formInit();
		this.preCadastroService.getOptions('financeiro/conta-bancaria').subscribe((success) => {
			this.contaBancariaSelect = success.dados;
			this.contaBancariaSelect.map((row) => { row.labelSelect = `${row.banco} - Ag.: ${row.num_agencia}-${row.dv_agencia} - ${row.tipo}: ${row.num_conta}-${row.dv_conta}`; return row; });
		})
	}
	changeRadioValue(form,name,value){
		this[form].patchValue({[name]: value});
		if(name == 'tipo_mora'){
			this.form.get('valor_mora').setValidators([]);
			this.form.get('taxa_mora').setValidators([]);
			this.form.patchValue({
				valor_mora: '',
				taxa_mora: ''
			})
			switch(value){
				case 'valor':
					this.form.get('valor_mora').setValidators([Validators.required]);
				break
				case 'taxa':
					this.form.get('taxa_mora').setValidators([Validators.required]);
				break;
			}
			this.form.get('valor_mora').updateValueAndValidity();
			this.form.get('taxa_mora').updateValueAndValidity();
		}

	}
	formInit():void{
		this.form = this.formBuilder.group({
			ambiente: this.formBuilder.control('homologacao',[Validators.required]),
			nome_beneficiario: this.formBuilder.control('',[Validators.required]),
			codigo_beneficiario: this.formBuilder.control('',[Validators.required]),
			sequencial_nosso_numero: this.formBuilder.control('001',[Validators.required]),
			sequencial_remessa: this.formBuilder.control('001',[Validators.required]),
			carteira: this.formBuilder.control('',[Validators.required]),
			cnpj: this.formBuilder.control('',[Validators.required]),
			financeiro_conta_bancaria_id: this.formBuilder.control('',[Validators.required]),
			tipo_mora: this.formBuilder.control('valor',[Validators.required]),
			valor_mora: this.formBuilder.control('',[Validators.required]),
			taxa_mora: this.formBuilder.control(''),
			instrucao1: this.formBuilder.control('',[Validators.maxLength(60)]),
			instrucao2: this.formBuilder.control('',[Validators.maxLength(60)]),
			instrucao3: this.formBuilder.control('',[Validators.maxLength(60)]),
			instrucao4: this.formBuilder.control('',[Validators.maxLength(60)])
		})
	}

	ngOnInit(){
		this.financeiroService.get('boleto/config').subscribe((success) => {
			this.boleto = success.dados;
			let tipo_mora = 'isento'
			if(parseInt(this.boleto.valor_mora) > 0)
				tipo_mora = 'valor'
			if(parseInt(this.boleto.taxa_mora) > 0)
				tipo_mora = 'taxa'
			
			if(this.boleto.ambiente)
			this.form.patchValue({
				ambiente: this.boleto.ambiente,
				nome_beneficiario: this.boleto.nome_beneficiario,
				codigo_beneficiario: this.boleto.codigo_beneficiario,
				sequencial_nosso_numero: this.boleto.sequencial_nosso_numero,
				sequencial_remessa: this.boleto.sequencial_remessa,
				carteira: this.boleto.carteira,
				cnpj: this.boleto.cnpj,
				financeiro_conta_bancaria_id: this.boleto.financeiro_conta_bancaria_id,
				tipo_mora: tipo_mora,
				valor_mora: this.boleto.valor_mora,
				taxa_mora: this.boleto.taxa_mora,
				instrucao1: this.boleto.instrucao1,
				instrucao2: this.boleto.instrucao2,
				instrucao3: this.boleto.instrucao3,
				instrucao4: this.boleto.instrucao4
			})
		},
		(error) => {
			if(error.status != '404'){
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
			}
		})
	}

	onSubmit(){
        if(!this.form.valid)
		return;
		this.financeiroService.add('boleto/config',this.form)
		.subscribe( (success) => {
			this.swal.text = "Configuração de boleto atualizada com sucesso!";
			this.swal.type = "success";
			this.swal.title = "Pronto!";
			this.swal.show();
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		});
	}
}