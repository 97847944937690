import { NivelService } from './../nivel.service';
import { ViewEncapsulation, Component, OnInit, ViewChild } from '@angular/core';
import { Nivel } from '../nivel';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { Router } from '@angular/router';
import { SYSCONF_SIGLA } from 'src/app.api';

@Component({
  	templateUrl: './nivelCreate.component.html',
    encapsulation: ViewEncapsulation.None,
  	styleUrls: [
  		'./nivelCreate.component.scss'
	]
})

export class NivelCreateComponent implements OnInit{
	@ViewChild('Swal') public swal:SwalComponent;
	public message:string = '';
	cadastroForm: FormGroup;
	cadastroFormData = new FormData();
	isChecked:boolean = false;
	public nivel: Nivel;
	niveis:any;
	submitted: boolean = false;
	modulosAgrupados = new Array()
	acoesAgrupadas = new Array();
	private id: number = 0;
	sigla = SYSCONF_SIGLA;
	newModulo: any;
	constructor(private router: Router, private nivelService: NivelService, private formBuilder: FormBuilder){

	}

	doCheck(event){
	
		let name = event.target.name;
		let value = event.target.value;
		
		if(event.target.checked){
			this.cadastroFormData.append(name,value);
			
		}else{
			let tmp = this.cadastroFormData.getAll(name); 
			const index: number = tmp.indexOf(value);
			if (index !== -1) {
				tmp.splice(index, 1);
			} 
			this.cadastroFormData.delete(name);
			tmp.forEach((key : any) => {
				this.cadastroFormData.append(name,key);
			})
		}
	}

	ngOnInit(){

		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.nivel.includes("CRIAR"))
			this.message = 'Você não possui permissão para executar esta ação';

		
		this.cadastroForm = this.formBuilder.group({
			nome: this.formBuilder.control('',[Validators.required]),
		});

		this.nivelService.listModulo()
		.subscribe((modulos) => {
			this.newModulo = modulos.dados
		
		}, (error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		})
		
	}

	onSubmit(){
		this.submitted = true;
        if(!this.cadastroForm.valid)
		return;
		this.cadastroFormData.append('nome',this.cadastroForm.value.nome);
        this.nivelService.add(this.cadastroFormData)
        .subscribe( (success) => {
				this.id = success.dados.id;
				this.swal.text = "Nível de acesso cadastrado com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}
	
	onCloseModal(){
		if(this.swal.type == "success"){
			this.router.navigate(['/'+SYSCONF_SIGLA+'/nivel/editar', this.id]);
		}
	}
}
