import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { LoginService } from './../../../login/login.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SYSCONF_LIMIT, SYSCONF_SIGLA } from '../../../../app.api';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { RHService } from '../../rh.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-afastamento-list',
	templateUrl: './afastamentoList.component.html',
	styleUrls: ['./afastamentoList.component.scss']
})

export class AfastamentoListComponent implements OnInit{
	@ViewChild('Swal') public swal: SwalComponent;
	afastamentos = new Array();
	public message:string = '';
	edit:boolean = false;
	detail:boolean = false;
	delete:boolean = false;
	params = null;
	page = 1;
	totalPages:number = 1;
	recordsPerPage:number = SYSCONF_LIMIT;
	buscaForm: FormGroup;
	formExportar: FormGroup;
	sigla = SYSCONF_SIGLA;
	constructor(private activatedRoute: ActivatedRoute, private modalService: NgbModal, private router: Router, private rhService: RHService, private loginService: LoginService, private formBuilder: FormBuilder){
		this.buscaForm = this.formBuilder.group({
			ano: this.formBuilder.control(''),
			mes: this.formBuilder.control(''),
			funcionario_id: this.formBuilder.control('')
		});

	}

	// diasAfastado(dtInicio:any, dtTermino:any) {

	// 	dtInicio = Date.parse(dtInicio);

	// 	if(!dtTermino) {
	// 		//dtTermino = new Date();
	// 		return 'Indeterminado';
	// 	} else {
	// 		dtTermino = Date.parse(dtTermino);
	// 	}
	// 	return  Math.floor(( dtTermino - dtInicio ) / 86400000)+1; 
	// }

	changePage(event){
		let page = parseInt(event);
		let newParams:Object = new Object();
		this.activatedRoute.params.subscribe(
			param => {
				for(let i in param) {
					if(i != 'page') 
						newParams[i] = param[i];
				}
			})
		if(!page)
		return;
		this.router.navigate(['/'+SYSCONF_SIGLA+`/afastamento/page/${page}`,newParams]);
	}
	ngOnInit(){ 
		
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		
		if(t.permissao.rh_afastamento.includes("CONSULTAR"))
			this.detail = true;
		
		this.activatedRoute.params.subscribe(params => {
			this.paramsChange(params);
		});
	}

	initFormExportar(){
		this.formExportar = this.formBuilder.group({
			campos: new FormArray([]),
		});
	}
	openExportModal(modal) {
		this.initFormExportar();
		this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
	}

	paramsChange(params) {
		this.message = "";
		this.params = params;
		this.rhService.get('afastamento',params)
		.subscribe(
			(afastamentos) => {
				this.afastamentos = afastamentos.dados;
				this.afastamentos.map(
					(row) => {
						let dataTermino;
						let dataInicio = new Date(row.dt_inicio);
						let duracao:any = 'indeterminado(s)';
						let dias_afastado_porcentagem:any = 100;
						let type = 'info';
						let hoje = new Date();
						let dias_afastado:any = Math.ceil((hoje.valueOf() - dataInicio.valueOf() ) / (1000 * 3600 * 24));
						let retornou = false;
						let dias_afastado_inicio = 0;
						if(row.dt_termino){
							dataTermino = new Date(row.dt_termino);
							duracao = Math.ceil((dataTermino.valueOf() - dataInicio.valueOf() ) / (1000 * 3600 * 24));
							if(hoje.valueOf() > dataTermino.valueOf()){
								retornou = true;
								dias_afastado = duracao;
							}
							dias_afastado_porcentagem = (100 * parseInt(dias_afastado)) / parseInt(duracao);
						}

						if(dias_afastado_porcentagem <= 25){
							type = 'danger';
						}else if(dias_afastado_porcentagem > 25 && dias_afastado_porcentagem <= 50 ){
							type = 'warning';
						} else if(dias_afastado_porcentagem >50 && dias_afastado_porcentagem <= 99){
							type = 'info';
						} else if(dias_afastado_porcentagem == 100){
							type = 'success';
						}

						if(hoje.valueOf() < dataInicio.valueOf()){
							dias_afastado_inicio = Math.ceil((dataInicio.valueOf() - hoje.valueOf() ) / (1000 * 3600 * 24));
						}
						row.dias_afastado = dias_afastado; 
						row.dias_afastado_inicio = dias_afastado_inicio; 
						row.dias_afastamento = duracao; 
						row.retornou = retornou;
						row.type = type;
						return row;
					});
				this.totalPages = afastamentos.paging.rows;
				this.page = params['page'];
			},
			(error) => {
				this.message = error.error.message;
			})
	}

	onSubmit(){
		this.router.navigate(['/'+SYSCONF_SIGLA+'/afastamento/page/1',this.buscaForm.value]);
	}
	
	getPermissao(modulo,secao){
		return this.loginService.getPermissao(modulo,secao);
	}
}
