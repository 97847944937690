import { SYSCONF_SIGLA } from './../app.api';
import { RecuperarSenhaComponent } from './recuperar-senha/recuperar-senha.component';
import { FormPerfilComponent } from './_includes/forms/form-perfil/form-perfil.component';
import { PerfilEditComponent } from './perfil/edit/perfilEdit.component';
import { PerfilService } from './perfil/perfil.service';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { CURRENCY_MASK_CONFIG } from "ng2-currency-mask/src/currency-mask.config";
import { NgbModule, NgbDatepickerI18n, NgbDateParserFormatter, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxResponsiveStackTableModule } from 'ngx-responsive-stack-table';
// import { NgxPhoneMaskBrModule } from 'ngx-phone-mask-br';
import { I18n, CustomDatepickerI18n } from './_custom-config-providers/custom-config-ngbdatepicker-language';
import { NgbDateCustomParserFormatter } from './_custom-config-providers/custom-config-ngbdatepicker-format';
import { NgbDatepickerCustomConfig } from './_custom-config-providers/custom-config-ngbdatepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AuthGuard } from './_services/auth-guard.service';
import { SweetAlert2Module, SwalComponent } from '@toverux/ngx-sweetalert2';
import { ErrorHandle } from './app.error-handler';
import { NgxMaskModule } from 'ngx-mask';
import { NgxViacepModule } from '@brunoc/ngx-viacep';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { LoaderComponent } from './_loader/loader.component';
import { InterceptService } from './_services/intercept.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SlimLoadingBarModule } from 'ng2-slim-loading-bar';
import { TreeviewModule } from 'ngx-treeview';
import { AppComponent } from './app.component';
import { HeaderComponent, FooterComponent, AsideLeftComponent } from './_includes/includes.export';
import { LoginComponent, LoginService } from './login/login.export';
import { PreCadastroComponent } from './pre-cadastro/pre-cadastro.component';
import { FormFinanceiroConfiguracaoBoletoComponent, FormPreCadastroPessoaIdiomaComponent, FormPessoaAnexoComponent, FormEstoqueMovimentacaoComponent, FormEstoqueSuprimentoComponent, FormPreCadastroEstoqueCategoriaComponent, FormFinanceiroAnexoComponent, FormFinanceiroMovimentoComponent, FormFinanceiroChequeComponent, FormPreCadastroEstoqueAtributoComponent, FormPreCadastroEstoqueLocalComponent, FormPreCadastroEstoqueUnidadeComponent, FormPreCadastroEstoqueTipoComponent, FormPreCadastroEstoqueMarcaComponent, FormPreCadastroEstoqueMotivoRetiradaComponent, FormFinanceiroContaBancariaComponent, FormFinanceiroFormaDePagamentoComponent, FormFinanceiroOrgaoComponent, FormFinanceiroCentroDeCustoComponent, FormProjetoEnquadramentoComponent, FormProjetoStatusComponent, FormProjetoComponent, FormPreCadastroRhVerbaComponent, FormFolhaDePagamentoComponent, FormFuncionarioFeriasComponent, FormFuncionarioAfastamentoComponent, FormFuncionarioCargoComponent, FormFuncionarioDependenteComponent, FormPreCadastroFuncionarioCargoComponent, FormContratoAditivoComponent, FormContratoAnexoComponent, FormContratoComponent, FormPreCadastroContratoNaturezaComponent, FormPreCadastroContratoAreaComponent, FormGeneroComponent, FormModalidadeComponent, FormGrauParentescoComponent, FormGraduacaoComponent, FormEstadoCivilComponent, FormHelper, FormDependenteComponent, FormIdiomaComponent, FormEnderecoComponent, FormPessoaComponent, FormAcessoComponent, FormFolhaDePagamentoFuncionarioComponent, FormPessoaTelefoneComponent, FormProjetoStakeholderComponent, FormProjetoSituacaoComponent, FormProjetoRiscoComponent, FormProjetoOrcamentoComponent, FormProjetoMetaComponent, FormProjetoInstituicaoParceiraComponent, FormProjetoEtapaComponent, FormProjetoDocumentoComponent, FormProjetoAtividadeComponent, FormPessoaContaBancariaComponent, FormFinanceiroFonteDeRecursoComponent, FormEstoqueMovimentacaoAnexoComponent, FormPessoaExportarComponent, FormResponsavelComponent } from './_includes/forms/form.export';
import { ArbitroListComponent , ArbitroCreateComponent, ArbitroEditComponent, ArbitroDetailComponent, ArbitroSearchComponent } from './pessoa/oficial/oficial.export';
import { AtletaListComponent, AtletaCreateComponent, AtletaEditComponent, AtletaDetailComponent, AtletaSearchComponent } from './pessoa/atleta/atleta.export';
import { ClubeListComponent, ClubeCreateComponent, ClubeEditComponent, ClubeDetailComponent, ClubeSearchComponent } from './pessoa/clube/clube.export';
import { DependenteListComponent, DependenteCreateComponent, DependenteEditComponent, DependenteDetailComponent, DependenteSearchComponent } from './pessoa/dependente/dependente.export';
import { EstudantilListComponent, EstudantilCreateComponent, EstudantilEditComponent, EstudantilDetailComponent, EstudantilSearchComponent } from './pessoa/estudantil/estudantil.export';
import { FederacaoListComponent, FederacaoCreateComponent, FederacaoEditComponent, FederacaoDetailComponent, FederacaoSearchComponent } from './pessoa/federacao/federacao.export';
import { CargosESalariosListComponent, RHComponent, RHService, AfastamentoListComponent, FeriasListComponent, FolhaDePagamentoTabComponent, FolhaDePagamentoDetailComponent, FolhaDePagamentoEditComponent, FolhaDePagamentoListComponent, FolhaDePagamentoCreateComponent } from './rh/rh.export';
import { FornecedorListComponent, FornecedorCreateComponent, FornecedorEditComponent, FornecedorDetailComponent, FornecedorSearchComponent } from './pessoa/fornecedor/fornecedor.export';
import { FuncionarioListComponent, FuncionarioCreateComponent, FuncionarioEditComponent, FuncionarioDetailComponent, FuncionarioSearchComponent } from './pessoa/funcionario/funcionario.export';
import { GovernamentalListComponent, GovernamentalCreateComponent, GovernamentalEditComponent, GovernamentalDetailComponent, GovernamentalSearchComponent } from './pessoa/governamental/governamental.export';
import { PatrocinadorListComponent, PatrocinadorCreateComponent, PatrocinadorEditComponent, PatrocinadorDetailComponent, PatrocinadorSearchComponent } from './pessoa/patrocinador/patrocinador.export';
import { PrestadorServicoListComponent, PrestadorServicoCreateComponent, PrestadorServicoEditComponent, PrestadorServicoDetailComponent, PrestadorServicoSearchComponent } from './pessoa/prestador-servico/prestador.export';
import { ResponsavelListComponent, ResponsavelCreateComponent, ResponsavelEditComponent, ResponsavelDetailComponent, ResponsavelSearchComponent } from './pessoa/responsavel/responsavel.export';
import { TecnicoListComponent, TecnicoCreateComponent, TecnicoEditComponent, TecnicoDetailComponent, TecnicoSearchComponent } from './pessoa/tecnico/tecnico.export';
import { NivelService, NivelComponent, NivelListComponent, NivelDetailComponent, NivelCreateComponent, NivelEditComponent, NivelSearchComponent } from './nivel/nivel.export';
import { PessoaService, PessoaComponent, PessoaTabComponent } from './pessoa/pessoa.export';
import { ContratoListComponent, ContratoCreateComponent, ContratoEditComponent, ContratoDetailComponent, ContratoSearchComponent, ContratoService, ContratoTabComponent, ContratoComponent } from './contrato/contrato.export';
import { ProjetoComponent, ProjetoListComponent, ProjetoCreateComponent, ProjetoEditComponent, ProjetoDetailComponent, ProjetoSearchComponent , ProjetoTabComponent, ProjetoService } from './projeto/projeto.export';
import { DropdownTreeviewSelectComponent, FinanceiroService, FinanceiroComponent, FinanceiroPlanoDeContaComponent, MovimentoListComponent, MovimentoCreateComponent, MovimentoEditComponent, MovimentoDetailComponent , MovimentoSearchComponent, MovimentoTabComponent, ChequeListComponent, ChequeCreateComponent, ChequeEditComponent, ChequeDetailComponent, ChequeSearchComponent, ChequeTabComponent, ConfiguracaoBoletoComponent, ConciliacaoListComponent, BoletoListComponent, RemessaListComponent } from './financeiro/financeiro.export';
import { EstoqueService, EstoqueComponent, SuprimentoListComponent, SuprimentoCreateComponent, SuprimentoEditComponent, SuprimentoDetailComponent, SuprimentoSearchComponent, SuprimentoTabComponent, MovimentacaoSearchComponent, MovimentacaoListComponent, MovimentacaoCreateComponent, MovimentacaoEditComponent, MovimentacaoDetailComponent, MovimentacaoTabComponent } from './estoque/estoque.export';
import { HomeComponent, HomeMovimentoComponent, HomeDespesaCentroCustoComponent, HomeAniversarianteComponent, HomeUltimoProjetoComponent, HomeAgendamentoComponent, HomeContratoExpirandoComponent, HomeAlertaEstoqueComponent, HomeReceitaDespesaComponent } from './home/home.export';
import { TutorialComponent, preCadastroVideoComponent, contratoVideoComponent, estoqueVideoComponent, financeiroVideoComponent, projetoVideoComponent, permissaoAcessoVideoComponent, pessoaInstituicaoVideoComponent, rhVideoComponent } from './tutorial/tutorial.export';

import { RelatorioFinanceiroService, RelatorioFinanceiroComponent, RelatorioConciliacaoComponent, RelatorioBalanceteComponent }from './financeiro/financeiro.export';

import { PreCadastroService } from './pre-cadastro/pre-cadastro.service';
import { routes } from "./app.routing";
import { CustomCurrencyMaskConfig } from "./_custom-config-providers/custom-config-currencymask";
import { ImportacaoComponent, ImportacaoListComponent, ImportacaoService } from "./importacao/importacao.export";
registerLocaleData(localePt);
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';
import { environment } from 'src/environments/environment';
import { PoliticaCookiesComponent } from './politica-cookies/politica-cookies.component';
import { getExtensionPipe } from './_pipes/extension.pipe';
import { FormFinanceiroExportarComponent } from './_includes/forms/form-financeiro-exportar/form-financeiro-exportar.component';
import { FormPreCadastroExportarComponent } from './_includes/forms/form-precadastro-exportar/form-precadastro-exportar.component';
import { FormProjetoExportarComponent } from './_includes/forms/form-projeto-exportar/form-projeto-exportar.component';
import { FormContratoExportarComponent } from './_includes/forms/form-contrato-exportar/form-contrato-exportar.component';
import { FormEstoqueExportarComponent } from './_includes/forms/form-estoque-exportar/form-estoque-exportar.component';
import { FormRHExportarComponent } from './_includes/forms/form-rh-exportar/form-rh-exportar.component';
 
const cookieConfig:NgcCookieConsentConfig = {} as NgcCookieConsentConfig;

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forRoot(routes),
        SlimLoadingBarModule,
        HttpClientModule,
        SweetAlert2Module.forRoot({
            buttonsStyling: false,
            customClass: 'modal-content',
            confirmButtonClass: 'btn btn-primary',
            cancelButtonClass: 'btn'
        }),
        NgxMaskModule.forRoot({
            dropSpecialCharacters: false,
            clearIfNotMatch: true,
        }),
        NgxViacepModule,
        NgbModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
          }),
        CurrencyMaskModule,
        TreeviewModule.forRoot(),
        NgxResponsiveStackTableModule,
        // NgxPhoneMaskBrModule,
        NgcCookieConsentModule.forRoot(cookieConfig)
    ],

    declarations: [
        getExtensionPipe,
        DropdownTreeviewSelectComponent,
        HeaderComponent,
        FooterComponent,
        AsideLeftComponent,
        AppComponent,
        LoginComponent,
        NivelComponent,
        NivelListComponent,
        NivelEditComponent,
        NivelSearchComponent,
        NivelDetailComponent,
        NivelCreateComponent,
        PageNotFoundComponent,
        HomeComponent,
        LoaderComponent,
        PessoaComponent,
        EstoqueComponent,
        ContratoComponent,
        AfastamentoListComponent,
        ArbitroListComponent,
        ArbitroCreateComponent,
        ArbitroEditComponent,
        ArbitroDetailComponent,
        ArbitroSearchComponent,
        AtletaListComponent,
        AtletaCreateComponent,
        AtletaEditComponent,
        AtletaDetailComponent,
        AtletaSearchComponent,
        CargosESalariosListComponent,
        BoletoListComponent,
        RemessaListComponent,
        ConfiguracaoBoletoComponent,
        ConciliacaoListComponent,
        ContratoListComponent,
        ContratoCreateComponent,
        ContratoEditComponent,
        ContratoDetailComponent,
        ContratoSearchComponent,
        ChequeListComponent,
        ChequeCreateComponent,
        ChequeEditComponent,
        ChequeDetailComponent,
        ChequeSearchComponent,
        ChequeTabComponent,
        ClubeListComponent,
        ClubeCreateComponent,
        ClubeEditComponent,
        ClubeDetailComponent,
        ClubeSearchComponent,
        DependenteListComponent,
        DependenteCreateComponent,
        DependenteEditComponent,
        DependenteDetailComponent,
        DependenteSearchComponent,
        EstudantilListComponent,
        EstudantilCreateComponent,
        EstudantilEditComponent,
        EstudantilDetailComponent,
        EstudantilSearchComponent,
        FederacaoListComponent,
        FederacaoCreateComponent,
        FederacaoEditComponent,
        FederacaoDetailComponent,
        FederacaoSearchComponent,
        FeriasListComponent,
        FinanceiroPlanoDeContaComponent,
        FinanceiroComponent,
        FolhaDePagamentoListComponent,
        FolhaDePagamentoCreateComponent,
        FolhaDePagamentoEditComponent,
        FolhaDePagamentoDetailComponent,
        FornecedorListComponent,
        FornecedorCreateComponent,
        FornecedorEditComponent,
        FornecedorDetailComponent,
        FornecedorSearchComponent,
        FuncionarioListComponent,
        FuncionarioCreateComponent,
        FuncionarioEditComponent,
        FuncionarioDetailComponent,
        FuncionarioSearchComponent,
        GovernamentalListComponent,
        GovernamentalCreateComponent,
        GovernamentalEditComponent,
        GovernamentalDetailComponent,
        GovernamentalSearchComponent,
        MovimentacaoListComponent,
        MovimentacaoCreateComponent,
        MovimentacaoEditComponent,
        MovimentacaoDetailComponent,
        MovimentacaoSearchComponent,
        MovimentacaoTabComponent,
        MovimentoListComponent,
        MovimentoCreateComponent,
        MovimentoEditComponent,
        MovimentoDetailComponent,
        MovimentoSearchComponent,
        MovimentoTabComponent,
        PatrocinadorListComponent,
        PatrocinadorCreateComponent,
        PatrocinadorEditComponent,
        PatrocinadorDetailComponent,
        PatrocinadorSearchComponent,
        PerfilEditComponent,
        PrestadorServicoListComponent,
        PrestadorServicoCreateComponent,
        PrestadorServicoEditComponent,
        PrestadorServicoDetailComponent,
        PrestadorServicoSearchComponent,
        RelatorioFinanceiroComponent,
        RelatorioConciliacaoComponent,
        RelatorioBalanceteComponent,
        ResponsavelListComponent,
        ResponsavelCreateComponent,
        ResponsavelEditComponent,
        ResponsavelDetailComponent,
        ResponsavelSearchComponent,
        SuprimentoListComponent,
        SuprimentoCreateComponent,
        SuprimentoEditComponent,
        SuprimentoDetailComponent,
        SuprimentoSearchComponent,
        SuprimentoTabComponent,
        TecnicoListComponent,
        TecnicoCreateComponent,
        TecnicoEditComponent,
        TecnicoDetailComponent,
        TecnicoSearchComponent,
        FolhaDePagamentoTabComponent,
        FormFolhaDePagamentoComponent,
        FormFolhaDePagamentoFuncionarioComponent,
        FormAcessoComponent,
        FormContratoComponent,
        FormContratoExportarComponent,
        ContratoTabComponent,
        FormPessoaComponent,
        FormPessoaExportarComponent,
        FormPerfilComponent,
        PessoaTabComponent,
        FormPessoaAnexoComponent,
        FormEnderecoComponent,
        FormPessoaTelefoneComponent,
        FormIdiomaComponent,
        FormPessoaContaBancariaComponent,
        FormDependenteComponent,
        FormResponsavelComponent,
        PreCadastroComponent,
        FormPreCadastroContratoAreaComponent,
        FormGraduacaoComponent,
        FormModalidadeComponent,
        FormPreCadastroContratoNaturezaComponent,
        FormGrauParentescoComponent,
        FormGeneroComponent,
        FormEstadoCivilComponent,
        FormContratoAnexoComponent,
        FormContratoAditivoComponent,
        FormPreCadastroExportarComponent,
        FormPreCadastroPessoaIdiomaComponent,
        FormPreCadastroFuncionarioCargoComponent,
        FormPreCadastroRhVerbaComponent,
        FormPreCadastroEstoqueAtributoComponent,
        FormPreCadastroEstoqueMarcaComponent,
        FormPreCadastroEstoqueMotivoRetiradaComponent,
        FormPreCadastroEstoqueTipoComponent,
        FormPreCadastroEstoqueUnidadeComponent,
        FormPreCadastroEstoqueLocalComponent,
        FormPreCadastroEstoqueCategoriaComponent,
        FormEstoqueSuprimentoComponent,
        FormEstoqueMovimentacaoComponent,
        FormEstoqueMovimentacaoAnexoComponent,
        FormEstoqueExportarComponent,
        FormContratoExportarComponent,
        FormFuncionarioDependenteComponent,
        FormFuncionarioCargoComponent,
        FormFuncionarioAfastamentoComponent,
        FormFuncionarioFeriasComponent,
        FormFinanceiroConfiguracaoBoletoComponent,
        FormFinanceiroExportarComponent,
        FormFinanceiroCentroDeCustoComponent,
        FormFinanceiroFonteDeRecursoComponent,
        FormFinanceiroChequeComponent,
        FormFinanceiroContaBancariaComponent,
        FormFinanceiroFormaDePagamentoComponent,
        FormFinanceiroOrgaoComponent,
        FormFinanceiroMovimentoComponent,
        FormFinanceiroAnexoComponent,
        RHComponent,
        ProjetoComponent,
        ProjetoListComponent,
        ProjetoCreateComponent,
        ProjetoEditComponent,
        ProjetoDetailComponent,
        ProjetoSearchComponent,
        FormProjetoComponent,
        FormProjetoExportarComponent,
        FormProjetoEnquadramentoComponent,
        FormProjetoStatusComponent,
        FormProjetoAtividadeComponent,
        FormProjetoDocumentoComponent,
        FormProjetoEtapaComponent,
        FormProjetoInstituicaoParceiraComponent,
        FormProjetoMetaComponent,
        FormProjetoOrcamentoComponent,
        FormProjetoRiscoComponent,
        FormProjetoSituacaoComponent,
        FormProjetoStakeholderComponent,
        ProjetoTabComponent,
        HomeMovimentoComponent,
        HomeDespesaCentroCustoComponent,
        HomeAniversarianteComponent,
        HomeUltimoProjetoComponent,
        HomeAgendamentoComponent,
        HomeContratoExpirandoComponent,
        HomeAlertaEstoqueComponent,
        HomeReceitaDespesaComponent,
        TutorialComponent,
        preCadastroVideoComponent,
        contratoVideoComponent,
        estoqueVideoComponent,
        financeiroVideoComponent,
        projetoVideoComponent,
        permissaoAcessoVideoComponent,
        pessoaInstituicaoVideoComponent,
        rhVideoComponent,
        ImportacaoComponent,
        ImportacaoListComponent,
        RecuperarSenhaComponent,
        PoliticaCookiesComponent,
        FormRHExportarComponent

    ],
    exports: [
        FormPessoaComponent,
        FormPessoaExportarComponent,
        FormFinanceiroExportarComponent,
        FormPreCadastroExportarComponent,
        FormContratoComponent,
        FormContratoExportarComponent,
        FormEstoqueExportarComponent,
        FormProjetoComponent,
        FormProjetoExportarComponent,
        FormRHExportarComponent
    ],
    providers: [
        LoginService,
        NivelService,
        ContratoService, 
        PessoaService,
        PerfilService,
        RHService,
        PreCadastroService,
        ProjetoService,
        
        RelatorioFinanceiroService,
        FinanceiroService,
        ContratoTabComponent,
        PessoaTabComponent, 
        ImportacaoService,
        FolhaDePagamentoTabComponent,
        ChequeTabComponent,
        MovimentoTabComponent,
        FormAcessoComponent,
        FormPessoaComponent, 
        FormPessoaExportarComponent, 
        FormPessoaAnexoComponent, 
        FormEnderecoComponent, 
        FormPessoaTelefoneComponent, 
        FormIdiomaComponent, 
        FormFolhaDePagamentoFuncionarioComponent,
        FormFuncionarioAfastamentoComponent,
        FormFuncionarioFeriasComponent,
        FormPessoaContaBancariaComponent, 
        FormDependenteComponent,
        FormFuncionarioDependenteComponent,
        FormFuncionarioCargoComponent,
        FormContratoAnexoComponent,
        FormPreCadastroExportarComponent,
        FormFinanceiroAnexoComponent,
        FormFinanceiroExportarComponent,
        FormContratoAditivoComponent,
        FormContratoExportarComponent,
        FormEstoqueMovimentacaoAnexoComponent,
        FormProjetoExportarComponent,
        FormEstoqueExportarComponent,
        FormHelper,
        AuthGuard,
        ErrorHandle, 
        SwalComponent,
        InterceptService,
        EstoqueService,
        ProjetoTabComponent,
        
        {
          provide: HTTP_INTERCEPTORS,
          useClass: InterceptService,
          multi: true
        },
        { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
        [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}],
        {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter},
        {provide: NgbDatepickerConfig, useClass: NgbDatepickerCustomConfig},
        HeaderComponent
        ],


    bootstrap: [AppComponent]
})

export class AppModule{
}
