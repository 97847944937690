import { Response } from '@angular/http';
import { throwError } from 'rxjs';
export class ErrorHandle{
    static handleError(error: Response | any, mensagem = null){
        let errorMessage: string;
        if(error instanceof Response && !mensagem){
            errorMessage = `Erro ${error.status} ao obter a URL ${error.url} - ${error.statusText}`
        }else{
            errorMessage = mensagem.toString();
        }

        return throwError(errorMessage)
    }
}