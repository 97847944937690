import { LoginService } from './../../../login/login.service';
import { DataService } from './../../../_services/data.service';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SYSCONF_LIMIT, SYSCONF_SIGLA } from '../../../../app.api';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { FinanceiroService } from '../../financeiro.service';
import { PreCadastroService } from 'src/app/pre-cadastro/pre-cadastro.service';
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';
import { Boleto } from '../boleto';

@Component({
	selector: 'app-boleto-list',
	templateUrl: './boletoList.component.html',
	styleUrls: ['./boletoList.component.scss']
})

export class BoletoListComponent implements OnInit{
	@ViewChild('Swal') public swal: SwalComponent;
	boleto: Boleto[] = null;
	boleto_id = null;
	public message:string = '';
	edit:boolean = false;
	detail:boolean = false;
	delete:boolean = false;
	create:boolean = false;
	params = null;
	formExportar: FormGroup;
	page = 1;
	totalPages:number = 1;
	recordsPerPage:number = SYSCONF_LIMIT;
	buscaForm: FormGroup;
	formRemessa: FormGroup;
	sigla = SYSCONF_SIGLA;
	contasBancariasSelect = new Array();
	checkBoletos = new FormData();
	modalReference: NgbModalRef; 
	allChecked:boolean = false;
	constructor(private loginService: LoginService,private activatedRoute: ActivatedRoute,  private router: Router, private financeiroService: FinanceiroService, private modalService: NgbModal, private formBuilder: FormBuilder, private preCadastroService: PreCadastroService, private dt: DataService){
		this.preCadastroService.getOptions('financeiro/conta-bancaria').subscribe((success) => {
			this.contasBancariasSelect = success.dados;
			this.contasBancariasSelect.map((row) => { row.labelSelect = `${row.banco} - Ag.: ${row.num_agencia}-${row.dv_agencia} - ${row.tipo}: ${row.num_conta}-${row.dv_conta}`; return row; });
		},(error) => {
			
		})
		
		this.buscaForm = this.formBuilder.group({
			id: this.formBuilder.control(''),
			tipo: this.formBuilder.control(''),
			de_dt_vencimento: this.formBuilder.control(null),
			ate_dt_vencimento: this.formBuilder.control(null),
			conta_bancaria_id: this.formBuilder.control(''),
		});

		this.formRemessa = this.formBuilder.group({
			operacao: this.formBuilder.control(null,[Validators.required]),
		})

	}
	changePage(event){
		let page = parseInt(event);
		let newParams:Object = new Object();
		this.activatedRoute.params.subscribe(
			param => {
				for(let i in param) {
					if(i != 'page') 
						newParams[i] = param[i];
				}
			})
		if(!page)
		return;
		this.router.navigate(['/'+SYSCONF_SIGLA+`/boleto/page/${page}`,newParams]);
	}
	ngOnInit(){ 
		if(this.getPermissao('financeiro_boleto','consultar'))
			this.detail = true;
		if(this.getPermissao('financeiro_boleto','criar'))
			this.create = true;
		if(this.getPermissao('financeiro_boleto','editar'))
			this.edit = true;
		if(this.getPermissao('financeiro_boleto','excluir'))
			this.delete = true;
		this.activatedRoute.params.subscribe(params => {
			this.paramsChange(params);
		});
	}

	initFormExportar(){
		this.formExportar = this.formBuilder.group({
			campos: new FormArray([]),
		});
	}

	openExportModal(modal) {
		this.initFormExportar();
		this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
	}

	getPermissao(modulo,secao){
		return this.loginService.getPermissao(modulo,secao)
	}

	selecionaTodos(event){
		if(this.allChecked){
			this.allChecked = false;
			this.checkBoletos.delete('boletos[]');
		}else{
			this.checkBoletos.delete('boletos[]');
			this.allChecked = true;
			this.boleto.forEach(v => {
				this.checkBoletos.append('boletos[]',`${v.id}`);
			})
		}
		//console.log(this.allChecked);
		//console.log(this.checkBoletos.getAll('boletos[]'));
	}

	doCheck(event){
		let name = event.target.name;
		let value = event.target.value;
		if(event.target.checked){
			this.checkBoletos.append(name,value);
		}else{
			let tmp = this.checkBoletos.getAll(name); 
			
			const index: number = tmp.indexOf(value);
			if (index !== -1) {
				tmp.splice(index, 1);
			} 
			this.checkBoletos.delete(name);
			tmp.forEach((key : any) => {
				this.checkBoletos.append(name,key);
			})
		}
		if(this.checkBoletos.getAll('boletos[]').length == this.boleto.length)
		this.allChecked = true;
		else 
		this.allChecked = false
	}


	paramsChange(params) {
		this.params = params;
		this.message = "";
		this.financeiroService.list('boleto',params)
		.subscribe(
			(boleto) => { 
				this.boleto_id = null;
				this.boleto = boleto.dados;
				this.totalPages = boleto.paging.rows;
				this.page = params['page'];
				if(typeof params['id']){
					this.buscaForm.patchValue({
						id: params['id'] != undefined ? params['id'] : '' ,
						tipo: params['tipo'] != undefined ? params['tipo'] : ''  ,
						de_dt_vencimento: params['de_dt_vencimento'] != undefined ? this.dt.formataDataBr(params['de_dt_vencimento']) : null,				
						ate_dt_vencimento: params['ate_dt_vencimento'] != undefined ? this.dt.formataDataBr(params['ate_dt_vencimento']) : null,
						conta_bancaria_id: params['conta_bancaria_id'] != undefined ? params['conta_bancaria_id'] : null ,
					})
				}
			},
			(error) => {
				this.message = error.error.message;
			})
	}

	onSubmit(){
		let de_dt_vencimento = this.dt.convertDataObject(this.buscaForm.value,'de_dt_vencimento');
		let ate_dt_vencimento = this.dt.convertDataObject(this.buscaForm.value,'ate_dt_vencimento');
		this.buscaForm.patchValue({
			de_dt_vencimento: de_dt_vencimento,				
			ate_dt_vencimento: ate_dt_vencimento,
		})
		this.router.navigate(['/'+SYSCONF_SIGLA+'/boleto/page/1',this.buscaForm.value]);
	}
	
	onCloseModal(){
		let params = new Array();
		params['page'] = 1;
		if(this.swal.type == "success"){
			this.financeiroService.list('boleto',params)
			.subscribe(
				(boleto) => {
					this.boleto = boleto.dados;
					this.totalPages = boleto.paging.rows;
					this.page = params['page'];
					this.boleto_id = null;
				},
				(error) => {
					this.message = error.error.message;
				})
		}
	}

	openModal(modal) {
		if(this.checkBoletos.getAll('boletos[]').length <= 0){
			this.swal.text = 'Você deve selecionar no mínimo um boleto.';
			this.swal.type = "warning";
			this.swal.title = "Atenção!";
			this.swal.show();
			return;
		}
		this.boleto_id = this.checkBoletos.getAll('boletos[]');
		this.formRemessa.patchValue({
			operacao: '',
		});
		this.modalReference = this.modalService.open(modal, {backdrop: 'static'});
	}

	onSubmitModal(){
        if(!this.formRemessa.valid)
		return;
		this.modalReference.close();
		this.financeiroService.remessa(this.boleto_id,this.formRemessa)
			.subscribe( (success) => {
				this.swal.text = "Remessa gerada com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
			});

        
	}

	
}
