import { RHService } from './../../rh.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SYSCONF_SIGLA } from 'src/app.api';
@Component({
	selector: 'app-folha-de-pagamento-detail',
  	templateUrl: './folhaDePagamentoDetail.component.html',
  	styleUrls: ['./folhaDePagamentoDetail.component.scss']
})

export class FolhaDePagamentoDetailComponent implements OnInit{
	public message:string = '';
	funcionarios = new Array();
	folhadepagamento_id:number;
	sigla = SYSCONF_SIGLA;
	constructor(private rhService: RHService, private activatedRoute: ActivatedRoute ,){
		this.rhService.getById('folha-pagamento',this.activatedRoute.snapshot.params['id']).subscribe(
			(success) =>{
				this.folhadepagamento_id = success.dados.id;
				this.funcionarios = success.dados.funcionario;
			},
			(error) => {
				this.message = error.error.message;
			})
	}
	ngOnInit(){
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.rh_folha_pagamento.includes("CONSULTAR"))
			this.message = 'Você não possui permissão para executar esta ação'
	}
}