import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { LoginService } from '../login/login.export';

@Component({
    templateUrl: './recuperar-senha.component.html',
    styleUrls: [
        './recuperar-senha.component.scss'
    ]
})

export class RecuperarSenhaComponent implements OnInit, AfterViewInit{
    @ViewChild('errorSwal') private errorSwal:SwalComponent; 
    @ViewChild('successSwal') private successSwal:SwalComponent; 
    recuperarSenhaForm: FormGroup;
    submitted = false;
    loading = false;
    constructor(
                private loginService: LoginService,
                private formBuilder: FormBuilder,
                private ActivatedRoute: ActivatedRoute){}

    ngOnInit(){
        this.recuperarSenhaForm = this.formBuilder.group({
            hash: this.formBuilder.control(this.ActivatedRoute.snapshot.params['token'],[Validators.required]),
            senha: this.formBuilder.control('',[Validators.required]),
            confirmaSenha: this.formBuilder.control('',[Validators.required]),
        });
    }
    get senha(){
        return this.recuperarSenhaForm.get('senha');
    }
    get confirmaSenha(){
        return this.recuperarSenhaForm.get('confirmaSenha');
    }
    ngAfterViewInit(){
		$(function(){
			$(".preloader").fadeOut();
		})
    }
    
    onCloseSwal(){
        this.loginService.logout();
    }

    onSubmit() {
        this.submitted = true;
        if(this.recuperarSenhaForm.value.senha != this.recuperarSenhaForm.value.confirmaSenha){
            this.errorSwal.text = 'A senha e a confirmação de senha não conferem.';
            this.errorSwal.type = "error";
            this.errorSwal.title = "Atenção!";
            this.errorSwal.show();
            return;
        }

        this.loading = true;
        this.loginService.recuperarSenha(this.recuperarSenhaForm.value)
        .subscribe( (success) => {
                    this.successSwal.text = 'Senha atualizada com sucesso!';
                    this.successSwal.type = "success";
                    this.successSwal.title = "Pronto!";
                    this.successSwal.show();
                    return;
                },
                (error) => {
                    let msg = 'O servidor não está respondendo no momento. Tente novamente ou entre em contato com o COB.';
                    if(error.status !== 0)
                    msg = error.error.message;
                    this.errorSwal.text = msg;
                    this.errorSwal.type = "error";
                    this.errorSwal.title = "Oops...";
                    this.errorSwal.show();
                    this.loading = false;
                },
                () => {
                    
                    //this.router.navigate([this.navigateTo])
                })
       }
}