import { Component, OnInit} from '@angular/core';
import { SYSCONF_SIGLA } from 'src/app.api';

declare var $: any;
@Component({
	selector: 'app-projeto-create',
  	templateUrl: './projetoCreate.component.html',
  	styleUrls: ['./projetoCreate.component.scss']
})

export class ProjetoCreateComponent implements OnInit{
	public message:string = '';
	sigla = SYSCONF_SIGLA;
	ngOnInit(){
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.projeto.includes("CRIAR"))
			this.message = 'Você não possui permissão para executar esta ação'
	}
	
	
}