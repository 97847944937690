import { Fornecedor } from '../fornecedor';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { SYSCONF_SIGLA } from 'src/app.api';
@Component({
	selector: 'app-fornecedor-create',
  	templateUrl: './fornecedorCreate.component.html',
  	styleUrls: ['./fornecedorCreate.component.scss']
})
export class FornecedorCreateComponent implements OnInit{
	@ViewChild('Swal') public swal:SwalComponent; 
	public message:string = '';
	cadastroForm: FormGroup;
	fornecedor: Fornecedor;
	foto: File;
	id:number;
	sigla = SYSCONF_SIGLA;
	private preloader = document.getElementsByClassName('preloader')[0];
	constructor(private formBuilder: FormBuilder){
		this.cadastroForm = this.formBuilder.group({
			pessoa_id: this.formBuilder.control(''),
			cnpj: this.formBuilder.control('',[Validators.required]),
			razao_social: this.formBuilder.control('',[Validators.required]),
			nome_completo: this.formBuilder.control('',[Validators.required]),
			email: this.formBuilder.control('',[Validators.required, Validators.email]),
			status: this.formBuilder.control('A',[Validators.required]),
			inscricao_estadual: this.formBuilder.control(''),
			nome_representante: this.formBuilder.control('',[Validators.required]),
			website: this.formBuilder.control(''),
			observacao: this.formBuilder.control(''),
		});	
	}
	ngOnInit(){
		this.preloader.setAttribute("style","display:none");
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.pessoa_fornecedor.includes("CRIAR"))
			this.message = 'Você não possui permissão para executar esta ação'
	}
}