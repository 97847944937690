import { Component, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { PessoaService } from '../../../pessoa/pessoa.service';
import { NgxViacepService } from '@brunoc/ngx-viacep';
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;
@Component({
	selector: 'app-form-pessoa-telefone',
  	templateUrl: './form-pessoa-telefone.component.html',
  	styleUrls: ['./form-pessoa-telefone.component.scss']
})
export class FormPessoaTelefoneComponent{
	@ViewChild('Swal') public swal:SwalComponent;
	@ViewChild('SwalDelete') public swalDelete:SwalComponent; 
	@Input() telefones;
	@Input() pessoa_id: number;
	@Input() acao:string;
	@Input() detalhe:boolean;
	@Input() pessoa_status: string = 'A';
	form: FormGroup;
	telefone_id:number = null;
	modalTitle:string;
	submitted: boolean = false;
	loading: boolean = false;
	modalReference: NgbModalRef;   
	public customPatterns = {'0': { pattern: new RegExp('/^(\([0-9]{2}\))\s([9]{1})?([0-9]{4})-([0-9]{4})$/')}};
	constructor(private pessoaService: PessoaService, private formBuilder: FormBuilder, private router: Router, private viaCep: NgxViacepService,private modalService: NgbModal){
        this.formInit();
    }
	formInit():void{
		this.form = this.formBuilder.group({
			referencia: this.formBuilder.control('',[Validators.required]),
			numero: this.formBuilder.control('',[Validators.required]),
		})
	}
	onSubmit(){
		this.submitted = true;
        if(!this.form.valid)
		return;
		this.modalReference.close();
		if(!this.telefone_id){
			this.pessoaService.addTelefone(this.pessoa_id,this.form)
			.subscribe( (success) => {
				this.swal.text = "Telefone cadastrado com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
			return;
		}

		this.pessoaService.editTelefone(this.telefone_id,this.form)
			.subscribe( (success) => {
				this.swal.text = "Telefone atualizado com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
	}

	openModal(modal,id:number = null) {
		this.formInit();
		//Se id == null então é cadastro.
		if(!id){
			this.telefone_id = null;
			this.modalTitle = "Cadastrar Telefone";
			this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
			return;
		}
		
		//Senão é edição.
		this.telefone_id = id;
		this.modalTitle = "Editar Telefone";
		this.pessoaService.getTelefoneById(id).subscribe((success) => {
			this.form.patchValue({
				referencia: success.dados.referencia,
				numero: success.dados.numero
			});
			this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		})
	}

	askDelete(id:number){
		this.telefone_id = id;
		this.swalDelete.show();
	}

	confirmDelete(){
		this.pessoaService.delTelefone(this.telefone_id).subscribe( 
		(success) => {
			this.swal.text = "Telefone deletado com sucesso!";
			this.swal.type = "success";
			this.swal.title = "Pronto!";
			this.swal.show();
			this.submitted = false;
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}

	onCloseModal(){
		if(this.swal.type == "success"){
			this.pessoaService.getPessoaById('pessoa',this.pessoa_id).subscribe((success)=>{
				this.telefones = success.dados.telefone;
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
		}
	}
}