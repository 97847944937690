import { LoginService } from 'src/app/login/login.service';
import { PessoaService } from '../../pessoa.service';
import { Tecnico } from './../tecnico';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { Federacao } from '../../federacao/federacao';
import { Clube } from '../../clube/clube';
import { PreCadastroService } from 'src/app/pre-cadastro/pre-cadastro.service';
import { SYSCONF_SIGLA } from 'src/app.api';
@Component({
	selector: 'app-tecnico-edit',
  	templateUrl: './tecnicoEdit.component.html',
  	styleUrls: ['./tecnicoEdit.component.scss']
})

export class TecnicoEditComponent implements OnInit{
	
	@ViewChild('Swal') public swal:SwalComponent; 
	public message:string = '';
	editForm: FormGroup;
	tecnico: Tecnico;
	tecnico_id:number;
	genero;
	thumb:string = '';
	pessoa_id:number;
	enderecos:object = new Array();
	telefones:object = new Array();
	idiomas:object = new Array();
	contasBancarias:object = new Array();
	federacoes: Federacao[];
	clubes: Clube[];
	graduacoes: [{}];
	modalidades: [{}];
	estadoCivil:[{}];
	sigla = SYSCONF_SIGLA;
	anexos: any;
	acesso:object = new Array();
	constructor(public loginService:LoginService, private pessoaService: PessoaService, private preCadastroService: PreCadastroService, private activatedRoute: ActivatedRoute ,private formBuilder: FormBuilder){
		this.preCadastroService.get('pessoa/genero')
        .subscribe( (success) => {
			this.genero = success.dados;
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});

		this.preCadastroService.get('pessoa/estado-civil')
        .subscribe( (success) => {
			this.estadoCivil = success.dados;
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});

		this.pessoaService.getOptions('pessoa/federacao')
        .subscribe( (success) => {
			this.federacoes = success.dados;
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});

		this.preCadastroService.get('graduacao')
        .subscribe( (success) => {
			this.graduacoes = success.dados;
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});

		this.preCadastroService.get('modalidade')
        .subscribe( (success) => {
			this.modalidades = success.dados;
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});
		

		this.editForm = this.formBuilder.group({
			id: this.formBuilder.control(0,[Validators.required]),
			estrangeiro: this.formBuilder.control('N',[Validators.required]),
			passaporte: this.formBuilder.control(''),
			passaporte_dt_validade: this.formBuilder.control(''),
			cpf: this.formBuilder.control('',[Validators.required]),
			dt_nascimento: this.formBuilder.control('',[Validators.required]),
			primeiro_nome: this.formBuilder.control('',[Validators.required]),
			ultimo_nome: this.formBuilder.control('',[Validators.required]),
			nome_completo: this.formBuilder.control(''),
			apelido: this.formBuilder.control(''),
			email: this.formBuilder.control('',[Validators.required, Validators.email]),
			nacionalidade: this.formBuilder.control('',[Validators.required]),
			naturalidade: this.formBuilder.control('',[Validators.required]),
			status: this.formBuilder.control('A',[Validators.required]),
			website: this.formBuilder.control(''),
			genero_id: this.formBuilder.control('',[Validators.required]),
			rg: this.formBuilder.control(''),
			rg_dt_emissao: this.formBuilder.control(''),
			rg_emissor: this.formBuilder.control(''),
			rg_uf: this.formBuilder.control(''),
			federado: this.formBuilder.control('N',[Validators.required]),
			federacao_id: this.formBuilder.control(''),
			graduacao_id: this.formBuilder.control(''),
			modalidade: new FormArray([]),
			cref_numero: this.formBuilder.control(''),
			cref_dt_expedicao: this.formBuilder.control(''),
			cref_dt_validade: this.formBuilder.control(''),
			observacao: this.formBuilder.control(''),
			estado_civil_id: this.formBuilder.control('',[Validators.required]),
			tamanho_camisa: this.formBuilder.control(''),
			tamanho_agasalho: this.formBuilder.control(''),
			tamanho_calca: this.formBuilder.control(''),
			tamanho_calcado: this.formBuilder.control(''),
			tamanho_chinelo: this.formBuilder.control(''),

			pis: this.formBuilder.control(''),
			grau_instrucao_id: this.formBuilder.control('')
		});	
		this.tecnico_id = this.activatedRoute.snapshot.params['id'];
		this.pessoaService.getPessoaById('pessoa/tecnico',this.tecnico_id).subscribe(
			(tecnico) => {
			this.tecnico = tecnico.dados;
			this.pessoa_id = this.tecnico.pessoa_id;
			this.acesso = this.tecnico.acesso;
			if(this.tecnico.foto){
				this.thumb = this.tecnico.foto.thumb;
			}
			this.pessoaService.getPessoaById('pessoa',this.pessoa_id).subscribe(
				(success) =>{
					this.enderecos = success.dados.endereco;
					this.telefones = success.dados.telefone;
					this.idiomas = success.dados.idioma;
					this.contasBancarias = success.dados.conta_bancaria;
					this.anexos = success.dados.anexo;
				},
				(error) => {
					this.message = error.error.message;
				})
			
			this.editForm.patchValue({
				id: this.tecnico.id,
				estrangeiro: this.tecnico.estrangeiro,
				passaporte: this.tecnico.passaporte,
				passaporte_dt_validade: this.tecnico.passaporte_dt_validade_br,
				cpf: this.tecnico.cpf,
				dt_cadastro: this.tecnico.dt_cadastro_br,
				dt_atualizacao: this.tecnico.dt_atualizacao_br,
				genero_id: this.tecnico.genero_id,
				genero: this.tecnico.genero,
				primeiro_nome: this.tecnico.primeiro_nome,
				ultimo_nome: this.tecnico.ultimo_nome,
				nome_completo: this.tecnico.nome_completo,
				apelido: this.tecnico.apelido,
				email: this.tecnico.email,
				rg: this.tecnico.rg,
				rg_uf: this.tecnico.rg_uf,
				rg_dt_emissao: this.tecnico.rg_dt_emissao_br,
				rg_emissor: this.tecnico.rg_emissor,
				nacionalidade: this.tecnico.nacionalidade,
				naturalidade: this.tecnico.naturalidade,
				foto: this.thumb,
				dt_nascimento: this.tecnico.dt_nascimento_br,
				observacao: this.tecnico.observacao,
				federado: this.tecnico.federado == 'S' ? 'S' : 'N',
				graduacao_id: this.tecnico.graduacao_id,
				federacao_id: this.tecnico.federacao_id,
				status: this.tecnico.status,
				website: this.tecnico.website,
				cref_numero: this.tecnico.cref_numero,
				cref_dt_expedicao: this.tecnico.cref_dt_expedicao_br,
				cref_dt_validade: this.tecnico.cref_dt_validade_br,
				estado_civil_id: this.tecnico.estado_civil_id,
				tamanho_camisa: this.tecnico.tamanho_camisa,
				tamanho_agasalho: this.tecnico.tamanho_agasalho,
				tamanho_calca: this.tecnico.tamanho_calca,
				tamanho_calcado: this.tecnico.tamanho_calcado,
				tamanho_chinelo: this.tecnico.tamanho_chinelo,

				pis: this.tecnico.pis,
				grau_instrucao_id: this.tecnico.grau_instrucao_id
			});
			this.checkModalidade();
		},
		(error) => {
			this.message = error.error.message;
		})
	}

	/* Incluir no editForm os ids das modalidades selecionadas */
	checkModalidade() {
		const formArray: FormArray = this.editForm.get('modalidade') as FormArray;
		if(this.tecnico.modalidade != null){	
			this.tecnico.modalidade.forEach((key : any) => {
				formArray.push(new FormControl(key.id));
			})
		}
	}

	ngOnInit(){
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.pessoa_tecnico.includes("EDITAR"))
			this.message = 'Você não possui permissão para executar esta ação'
	}
	
	
}