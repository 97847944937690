import { PessoaService } from './../../pessoa.service';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { Federacao } from './../../federacao/federacao';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SYSCONF_SIGLA } from 'src/app.api';
@Component({
	selector: 'app-governamental-create',
  	templateUrl: './governamentalCreate.component.html',
  	styleUrls: ['./governamentalCreate.component.scss']
})
export class GovernamentalCreateComponent implements OnInit{
	@ViewChild('Swal') public swal:SwalComponent; 
	public message:string = '';
	cadastroForm: FormGroup;
	federacoes: Federacao[];
	sigla = SYSCONF_SIGLA;
	private preloader = document.getElementsByClassName('preloader')[0];
	constructor(private pessoaService: PessoaService, private formBuilder: FormBuilder){
		
		this.cadastroForm = this.formBuilder.group({
			pessoa_id: this.formBuilder.control(''),
			cnpj: this.formBuilder.control('',[Validators.required]),
			razao_social: this.formBuilder.control('',[Validators.required]),
			nome_completo: this.formBuilder.control('',[Validators.required]),
			email: this.formBuilder.control('',[Validators.required, Validators.email]),
			status: this.formBuilder.control('A',[Validators.required]),
			sigla: this.formBuilder.control('',[Validators.required]),
			tipo: this.formBuilder.control('',[Validators.required]),
			website: this.formBuilder.control(''),
			observacao: this.formBuilder.control('')
		});	
		
	}
	ngOnInit(){
		this.preloader.setAttribute("style","display:none");
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.pessoa_governamental.includes("CRIAR"))
			this.message = 'Você não possui permissão para executar esta ação'
	}
}