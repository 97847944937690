import { PreCadastroService } from '../../../pre-cadastro/pre-cadastro.service';
import { Component, ViewChild, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';


@Component({
	selector: 'app-form-precadastro-estoque-categoria',
  	templateUrl: './form-precadastro-estoque-categoria.component.html',
  	styleUrls: ['./form-precadastro-estoque-categoria.component.scss']
})
export class FormPreCadastroEstoqueCategoriaComponent{
	@ViewChild('Swal') public swal:SwalComponent;
	@ViewChild('SwalDelete') public swalDelete:SwalComponent; 
	@Input() acao:string;
	@Input() detalhe:boolean;
	form: FormGroup;
	categoria_id:number = null;
	modalTitle:string;
	submitted: boolean = false;
	loading: boolean = false;
	modalReference: NgbModalRef; 
	cob = null;
	atributosCheckbox;
	atributos: FormArray;
	categorias;
	constructor(private preCadastro: PreCadastroService, private formBuilder: FormBuilder,private modalService: NgbModal){
    	this.preCadastro.get('estoque/atributo')
        .subscribe( (success) => {
			this.atributosCheckbox = success.dados;
		});
		this.preCadastro.get('estoque/suprimento/categoria')
        .subscribe( (success) => {
			this.categorias = success.dados;
		});
	}

	/* Método utilizado ao clicar no checbox de atributo */
	onCheckChange(event) {
		const formArray: FormArray = this.form.get('atributos') as FormArray;
		
		/* Marcado */
		if(event.target.checked){
			// Adiciona um novo control para o Form Array
			formArray.push(new FormControl(event.target.value));
		}
		/* Desmarcar */
		else{
			// Encontra o elemento para remover
			let i: number = 0;
		
			formArray.controls.forEach((ctrl: FormControl) => {
			if(ctrl.value == event.target.value) {
				// Remove os elementos desmarcados
				formArray.removeAt(i);
				return;
			}
			i++;
			});
		}
	}

	/* Método utilizado ao clicar no checbox de atributo */
	verifyChecked(atributo) {
		const formArray: FormArray = this.form.get('atributos') as FormArray;
		if(formArray && formArray.value.includes(atributo))
			return true;

		return false;
		
	}
	
	onSubmit(){
		this.submitted = true;
        if(!this.form.valid)
		return;
		this.modalReference.close();
		const formArray: FormArray = this.form.get('atributos') as FormArray;
		if(!this.categoria_id){
			this.preCadastro.add('estoque/suprimento/categoria', this.form, formArray)
			.subscribe( (success) => {
				this.swal.text = "Registro cadastrado com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
			return;
		}

		this.preCadastro.edit('estoque/suprimento/categoria', this.categoria_id,this.form, formArray)
			.subscribe( (success) => {
				this.swal.text = "Registro atualizado com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});

        
	}

	openModal(modal,id:number = null) {
		this.form = this.formBuilder.group({
			nome: this.formBuilder.control('',[Validators.required]),
			atributos: new FormArray([]),
		})

		//Se id == null então é cadastro.
		if(!id){
			this.categoria_id = null;
			this.modalTitle = "Cadastrar Categoria";
			this.modalReference = this.modalService.open(modal, {backdrop: 'static'});
			return;
		}
		
		//Senão é edição.
		this.categoria_id = id;
		this.modalTitle = "Editar Categoria";
		this.preCadastro.getById('estoque/suprimento/categoria', id).subscribe((success) => {
			this.form.patchValue({
				nome: success.dados.nome,
				atributos: []
			});
			if(success.dados.atributo && success.dados.atributo.length > 0){
				
				this.atributos = this.form.get('atributos') as FormArray;
				this.atributos.removeAt(0);
				for(let i=0;i <= (success.dados.atributo.length-1);i++){
					this.atributos.push(new FormControl(success.dados.atributo[i].estoque_atributo_id));
				}
			}
			this.modalReference = this.modalService.open(modal, {backdrop: 'static'});
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		})
	}

	askDelete(id:number){
		this.categoria_id = id;
		this.swalDelete.show();
	}

	confirmDelete(){
		this.preCadastro.del('estoque/suprimento/categoria', this.categoria_id).subscribe( 
		(success) => {
			this.swal.text = "Registro deletado com sucesso!";
			this.swal.type = "success";
			this.swal.title = "Pronto!";
			this.swal.show();
			this.submitted = false;
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}

	onCloseModal(){
		if(this.swal.type == "success"){
			this.preCadastro.get('estoque/suprimento/categoria').subscribe((success)=>{
				this.categorias = success.dados;
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
		}
	}
}