import { PessoaService } from '../../pessoa.service';
import { Fornecedor } from './../fornecedor';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { SYSCONF_SIGLA } from 'src/app.api';
@Component({
	selector: 'app-fornecedor-detail',
  	templateUrl: './fornecedorDetail.component.html',
  	styleUrls: ['./fornecedorDetail.component.scss']
})

export class FornecedorDetailComponent implements OnInit{
	@ViewChild('Swal') public swal:SwalComponent; 
	public message:string = '';
	detailForm: FormGroup;
	fornecedor: Fornecedor;
	fornecedor_id:number;
	thumb:string = '';
	pessoa_id:number;
	enderecos:object = new Array();
	telefones:object = new Array();
	contasBancarias:object = new Array();
	sigla = SYSCONF_SIGLA;
	anexos: any;
	acesso = new Array();
	constructor(private pessoaService: PessoaService, private activatedRoute: ActivatedRoute,private formBuilder: FormBuilder){
		this.detailForm = this.formBuilder.group({
			id: this.formBuilder.control(''),
			dt_cadastro: this.formBuilder.control(''),
			dt_atualizacao: this.formBuilder.control(''),
			cnpj: this.formBuilder.control(''),
			razao_social: this.formBuilder.control(''),
			nome_completo: this.formBuilder.control(''),
			email: this.formBuilder.control(''),
			status: this.formBuilder.control(''),
			inscricao_estadual: this.formBuilder.control(''),
			nome_representante: this.formBuilder.control(''),
			website: this.formBuilder.control(''),
			observacao: this.formBuilder.control(''),
			foto: this.formBuilder.control('')
		});	
		this.fornecedor_id = this.activatedRoute.snapshot.params['id'];
		this.pessoaService.getPessoaById('pessoa/fornecedor',this.fornecedor_id).subscribe(
		(fornecedor) => {
			this.fornecedor = fornecedor.dados;
			this.pessoa_id = this.fornecedor.pessoa_id;
			this.acesso = this.fornecedor.acesso;
			if(this.fornecedor.foto){
				this.thumb = this.fornecedor.foto.thumb;
			}
			

			this.pessoaService.getPessoaById('pessoa',this.pessoa_id).subscribe(
				(success) =>{
					this.enderecos = success.dados.endereco;
					this.telefones = success.dados.telefone;
					this.contasBancarias = success.dados.conta_bancaria;
					this.anexos = success.dados.anexo;
				},
				(error) => {
					this.message = error.error.message;
				})
				this.detailForm.patchValue({
					id: this.fornecedor.id,
					dt_cadastro: this.fornecedor.dt_cadastro_br,
					dt_atualizacao: this.fornecedor.dt_atualizacao_br,
					cnpj: this.fornecedor.cnpj,
					razao_social: this.fornecedor.razao_social,
					nome_completo: this.fornecedor.nome_completo,
					email: this.fornecedor.email,
					status: this.fornecedor.status,
					inscricao_estadual: this.fornecedor.inscricao_estadual,
					nome_representante: this.fornecedor.nome_representante,
					website: this.fornecedor.website,
					observacao: this.fornecedor.observacao,
					foto: this.thumb
				});
			})
		}

	ngOnInit(){
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.pessoa_fornecedor.includes("CONSULTAR"))
			this.message = 'Você não possui permissão para executar esta ação'
	}
	
	
}