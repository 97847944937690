import { FinanceiroService } from './../../financeiro.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SYSCONF_SIGLA } from 'src/app.api';
import { LoginService } from '../../../login/login.service';
@Component({
	selector: 'app-cheque-detail',
  	templateUrl: './chequeDetail.component.html',
  	styleUrls: ['./chequeDetail.component.scss']
})

export class ChequeDetailComponent implements OnInit{
	public message:string = '';
	cheque_id:number;
	sigla = SYSCONF_SIGLA;
	constructor(private financeiroService: FinanceiroService, private activatedRoute: ActivatedRoute, private loginService: LoginService){
		this.financeiroService.getById('cheque',this.activatedRoute.snapshot.params['id']).subscribe(
			(success) =>{
				this.cheque_id = success.dados.id;
			},
			(error) => {
				this.message = error.error.message;
			})
	}
	ngOnInit(){
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.financeiro_cheque.includes("CONSULTAR"))
			this.message = 'Você não possui permissão para executar esta ação'
	}

	getPermissao(modulo,secao){
		return this.loginService.getPermissao(modulo,secao);
	}
}