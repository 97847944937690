import { LoginService } from 'src/app/login/login.service';
import { PessoaService } from '../../pessoa.service';
import { Federacao } from '../federacao';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { SYSCONF_SIGLA } from 'src/app.api';
@Component({
	selector: 'app-federacao-edit',
  	templateUrl: './federacaoEdit.component.html',
  	styleUrls: ['./federacaoEdit.component.scss']
})
export class FederacaoEditComponent implements OnInit{
	@ViewChild('Swal') public swal:SwalComponent; 
	public message:string = '';
	editForm: FormGroup;
	federacao: Federacao;
	federacao_id:number;
	thumb:string = '';
	pessoa_id:number;
	enderecos:object = new Array();
	telefones:object = new Array();
	contasBancarias:object = new Array();
	sigla = SYSCONF_SIGLA;
	anexos: any;
	acesso = new Array();
	constructor(public loginService:LoginService,private pessoaService: PessoaService, private activatedRoute: ActivatedRoute ,private formBuilder: FormBuilder){
		this.editForm = this.formBuilder.group({
			id: this.formBuilder.control(0,[Validators.required]),
			dt_cadastro: this.formBuilder.control(''),
			dt_atualizacao: this.formBuilder.control(''),
			cnpj: this.formBuilder.control('',[Validators.required]),
			razao_social: this.formBuilder.control('',[Validators.required]),
			nome_completo: this.formBuilder.control('',[Validators.required]),
			email: this.formBuilder.control('',[Validators.required, Validators.email]),
			status: this.formBuilder.control('A',[Validators.required]),
			presidente: this.formBuilder.control('',[Validators.required]),
			sigla: this.formBuilder.control('',[Validators.required]),
			observacao: this.formBuilder.control(''),
			estado: this.formBuilder.control('',[Validators.required]),
			website: this.formBuilder.control(''),
		});	
		this.federacao_id = this.activatedRoute.snapshot.params['id'];
		this.pessoaService.getPessoaById('pessoa/federacao',this.federacao_id).subscribe(
			(federacao) => {
			this.federacao = federacao.dados;
			this.pessoa_id = this.federacao.pessoa_id;
			this.acesso = this.federacao.acesso;
			if(this.federacao.foto){
				this.thumb = this.federacao.foto.thumb;
			}
			this.pessoaService.getPessoaById('pessoa',this.pessoa_id).subscribe(
				(success) =>{
					this.enderecos = success.dados.endereco;
					this.telefones = success.dados.telefone;
					this.contasBancarias = success.dados.conta_bancaria;
					this.anexos = success.dados.anexo;
				},
				(error) => {
					this.message = error.error.message;
				})
			
			this.editForm.patchValue({
				id: this.federacao.id,
				dt_cadastro: this.federacao.dt_cadastro_br,
				dt_atualizacao: this.federacao.dt_atualizacao_br,
				cnpj: this.federacao.cnpj,
				razao_social: this.federacao.razao_social,
				nome_completo: this.federacao.nome_completo,
				email: this.federacao.email,
				status: this.federacao.status,
				presidente: this.federacao.presidente,
				sigla: this.federacao.sigla,
				observacao: this.federacao.observacao,
				estado: this.federacao.estado,
				website: this.federacao.website,
			});
			
		},
		(error) => {
			this.message = error.error.message;
		})
	}
	ngOnInit(){
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.pessoa_federacao.includes("EDITAR"))
			this.message = 'Você não possui permissão para executar esta ação'
	}	
}