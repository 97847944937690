import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { LoginService } from './../../../login/login.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PessoaService } from '../../pessoa.service';
import { SYSCONF_LIMIT, SYSCONF_SIGLA } from '../../../../app.api';
import { Arbitro } from '../arbitro';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { Federacao } from '../../federacao/federacao';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-arbitro-list',
	templateUrl: './arbitroList.component.html',
	styleUrls: ['./arbitroList.component.scss']
})

export class ArbitroListComponent implements OnInit{
	@ViewChild('Swal') public swal: SwalComponent;
	arbitros: Arbitro[];
	public message:string = '';
	edit:boolean = false;
	detail:boolean = false;
	delete:boolean = false;
	params = null;
	page = 1;
	totalPages:number = 1;
	recordsPerPage:number = SYSCONF_LIMIT;
	buscaForm: FormGroup;
	formExportar: FormGroup;
	federacoes: Federacao[];
	sigla = SYSCONF_SIGLA;
	constructor(private activatedRoute: ActivatedRoute,  private router: Router, private pessoaService:PessoaService, private loginService: LoginService, private formBuilder: FormBuilder, private modalService: NgbModal){
		this.buscaForm = this.formBuilder.group({
			id: this.formBuilder.control(''),
			nome_completo: this.formBuilder.control(''),
			federacao_id: this.formBuilder.control(null),
			cpf: this.formBuilder.control(''),
		});

		this.pessoaService.getOptions('pessoa/federacao')
        .subscribe( (success) => {
			this.federacoes = success.dados;
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});
	}
	changePage(event){
		let page = parseInt(event);
		let newParams:Object = new Object();
		this.activatedRoute.params.subscribe(
			param => {
				for(let i in param) {
					if(i != 'page') 
						newParams[i] = param[i];
				}
			})
		if(!page)
		return;
		this.router.navigate(['/'+SYSCONF_SIGLA+`/arbitro/page/${page}`,newParams]);
	}
	ngOnInit(){ 
		
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		
		if(t.permissao.pessoa_arbitro.includes("EDITAR"))
			this.edit = true;
		if(t.permissao.pessoa_arbitro.includes("CONSULTAR"))
			this.detail = true;
		
		this.activatedRoute.params.subscribe(params => {
			this.paramsChange(params);
		});
	}
	paramsChange(params) {
		this.params = params;
		this.message = "";
		this.pessoaService.list('pessoa/arbitro',params)
		.subscribe(
			(arbitro) => {
				this.arbitros = arbitro.dados;
				this.totalPages = arbitro.paging.rows;
				this.page = params['page'];
			},
			(error) => {
				this.message = error.error.message;
			})
	}

	onSubmit(){
		this.router.navigate(['/'+SYSCONF_SIGLA+'/arbitro/page/1',this.buscaForm.value]);
	}
	
	getPermissao(modulo,secao){
		return this.loginService.getPermissao(modulo,secao);
	}
	
	initFormExportar(){
		this.formExportar = this.formBuilder.group({
			campos: new FormArray([]),
		});
	}

	openModal(modal) {
		this.initFormExportar();
		this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
	}
}
