import { PreCadastroService } from '../../../pre-cadastro/pre-cadastro.service';
import { Component, ViewChild, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';
import { NgxViacepService } from '@brunoc/ngx-viacep';


@Component({
	selector: 'app-form-precadastro-estoque-local',
  	templateUrl: './form-precadastro-estoque-local.component.html',
  	styleUrls: ['./form-precadastro-estoque-local.component.scss']
})
export class FormPreCadastroEstoqueLocalComponent{
	@ViewChild('Swal') public swal:SwalComponent;
	@ViewChild('SwalDelete') public swalDelete:SwalComponent; 
	@Input() acao:string;
	@Input() detalhe:boolean;
	form: FormGroup;
	local_id:number = null;
	modalTitle:string;
	submitted: boolean = false;
	loading: boolean = false;
	modalReference: NgbModalRef; 
	cob = null;
	locais;
	leitura:boolean = false;
	constructor(private preCadastro: PreCadastroService, private formBuilder: FormBuilder,private viaCep: NgxViacepService,private modalService: NgbModal){
    	this.form = this.formBuilder.group({
			nome: this.formBuilder.control('',[Validators.required]),
			cep: this.formBuilder.control('',[Validators.required]),
			logradouro: this.formBuilder.control('',[Validators.required]),
			numero: this.formBuilder.control('',[Validators.required]),
			complemento: this.formBuilder.control(''),
			bairro: this.formBuilder.control('',[Validators.required]),
			cidade: this.formBuilder.control('',[Validators.required]),
			estado: this.formBuilder.control('',[Validators.required]),
			estado_leitura: this.formBuilder.control(''),
		})
		this.preCadastro.get('estoque/local')
        .subscribe( (success) => {
			this.locais = success.dados;
		},
		(error) => {
			
		});
	}
	
	onSubmit(){
		this.submitted = true;
        if(!this.form.valid)
		return;
		this.modalReference.close();
		if(!this.local_id){
			this.preCadastro.add('estoque/local', this.form)
			.subscribe( (success) => {
				this.swal.text = "Registro cadastrado com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
			return;
		}

		this.preCadastro.edit('estoque/local', this.local_id,this.form)
			.subscribe( (success) => {
				this.swal.text = "Registro atualizado com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});

        
	}

	getEndereco():void{
		this.leitura = false;
		let cep = this.form.value.cep;
		this.viaCep.buscarPorCep(cep).then((endereco) =>{
			this.form.patchValue({
				logradouro: endereco.logradouro,
				bairro: endereco.bairro,
				cidade: endereco.localidade,
				estado: endereco.uf,
				estado_leitura: endereco.uf
			});
			this.leitura = true;
		}).catch(
			(error) => {
				this.leitura = false;
				this.swal.text = error.toString().replace('Error: ','');
				this.swal.type = "warning";
				this.swal.title = "Oops...";
				this.swal.show();
			}
		)
	}

	openModal(modal,id:number = null) {

		//Se id == null então é cadastro.
		if(!id){
			this.local_id = null;
			this.modalTitle = "Cadastrar Local";
			this.form.patchValue({
				nome: '',
				cep: '',
				logradouro: '',
				numero: '',
				complemento: '',
				bairro: '',
				cidade: '',
				estado: '',
				estado_leitura: '',
			});
			this.modalReference = this.modalService.open(modal, {backdrop: 'static'});
			return;
		}
		
		//Senão é edição.
		this.local_id = id;
		this.modalTitle = "Editar Local";
		this.preCadastro.getById('estoque/local', id).subscribe((success) => {
			this.form.patchValue({
				nome: success.dados.nome,
				cep: success.dados.cep,
				logradouro: success.dados.logradouro,
				numero: success.dados.numero,
				complemento: success.dados.complemento,
				bairro: success.dados.bairro,
				cidade: success.dados.cidade,
				estado: success.dados.estado,
				estado_leitura: success.dados.estado,
			});
			this.modalReference = this.modalService.open(modal, {backdrop: 'static'});
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		})
	}

	askDelete(id:number){
		this.local_id = id;
		this.swalDelete.show();
	}

	confirmDelete(){
		this.preCadastro.del('estoque/local', this.local_id).subscribe( 
		(success) => {
			this.swal.text = "Registro deletado com sucesso!";
			this.swal.type = "success";
			this.swal.title = "Pronto!";
			this.swal.show();
			this.submitted = false;
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}

	onCloseModal(){
		if(this.swal.type == "success"){
			this.preCadastro.get('estoque/local').subscribe((success)=>{
				this.locais = success.dados;
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
		}
	}
}