import { ProjetoService } from 'src/app/projeto/projeto.service';
import { Component, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '../../../_services/data.service';
@Component({
	selector: 'app-form-projeto-atividade',
  	templateUrl: './form-projeto-atividade.component.html',
  	styleUrls: ['./form-projeto-atividade.component.scss']
})
export class FormProjetoAtividadeComponent{
	@ViewChild('Swal') public swal:SwalComponent;
	@ViewChild('SwalDelete') public swalDelete:SwalComponent; 
	@Input() projetosAtividades = new Array();
	@Input() detalhe: boolean;
	@Input() pessoa_id: number;
	@Input() acao:string;
	create:boolean;
	edit:boolean;
	detail:boolean;
	delete:boolean;
	modalDetalhe:boolean = false;
	form: FormGroup;
	projeto_atividade_id:number = null;
	modalTitle:string;
	submitted: boolean = false;
	loading: boolean = false;
	modalReference: NgbModalRef; 
	constructor(private projetoService: ProjetoService, private formBuilder: FormBuilder, private modalService: NgbModal, private activatedRoute: ActivatedRoute, private dt: DataService){
        this.form = this.formBuilder.group({
			nome: this.formBuilder.control('',[Validators.required]),
			dt_inicio: this.formBuilder.control(null,[Validators.required]),
			dt_termino: this.formBuilder.control(null,[Validators.required]),
			observacao: this.formBuilder.control(''),
		})
	}

	ngOnInit(){ 
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(t.permissao.projeto_atividade.includes("CONSULTAR"))
			this.detail = true;
		if(t.permissao.projeto_atividade.includes("CRIAR"))
			this.create = true;
		if(t.permissao.projeto_atividade.includes("EDITAR"))
			this.edit = true;
		if(t.permissao.projeto_atividade.includes("EXCLUIR"))
			this.delete = true;
	}

	verificaPeriodo(dt_inicio,dt_termino){
		if(!dt_inicio || !dt_termino)
		return;
		let convert_dt_inicio = new Date(dt_inicio.year, dt_inicio.month-1, dt_inicio.day)
		let convert_dt_termino = new Date(dt_termino.year, dt_termino.month-1, dt_termino.day)
		if(convert_dt_inicio > convert_dt_termino){
			this.swal.text = "A data de término deve ser maior que a data de início!";
			this.swal.type = "warning";
			this.swal.title = "Atenção!";
			this.swal.show();
			this.form.patchValue({
				dt_termino: null
			})
			return;
		}
	}


	zeraForm():void{
		this.form.patchValue({
			nome: '',
			dt_inicio: null,
			dt_termino: null,
			observacao: '',
		});
	}
	onSubmit(){
		this.submitted = true;
        if(!this.form.valid)
		return;
		this.modalReference.close();
		if(!this.projeto_atividade_id){
			this.projetoService.addProjetoAtividade(this.activatedRoute.snapshot.params['id'], this.form)
			.subscribe( (success) => {
				this.swal.text = "Atividade cadastrada com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
				this.zeraForm();
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
			return;
		}

		this.projetoService.editProjetoAtividade(this.projeto_atividade_id,this.form)
			.subscribe( (success) => {
				this.swal.text = "Atividade atualizada com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
				this.zeraForm();
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
				this.zeraForm();
			});
		
        
	}

	openModal(modal,id:number = null,detalhe:boolean = false) {
		this.modalDetalhe = detalhe;
		//Se id == null então é cadastro.
		if(!id){
			this.projeto_atividade_id = null;
			this.modalTitle = "Cadastrar Atividade";
			this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
			return;
		}
		
		//Senão é edição.
		this.projeto_atividade_id = id;
		this.modalTitle = "Editar Atividade";
		this.projetoService.getProjetoAtividadeById(id).subscribe((success) => {
			this.form.patchValue({
				nome: success.dados.nome,
				dt_inicio: this.dt.formataDataBr(success.dados.dt_inicio_br, this.modalDetalhe),
				dt_termino: this.dt.formataDataBr(success.dados.dt_termino_br, this.modalDetalhe),
				observacao: success.dados.observacao,
			});
			this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		})
	}

	askDelete(id:number){
		this.projeto_atividade_id = id;
		this.swalDelete.show();
	}

	confirmDelete(){
		this.projetoService.delProjetoAtividade(this.projeto_atividade_id).subscribe( 
		(success) => {
			this.swal.text = "Atividade deletada com sucesso!";
			this.swal.type = "success";
			this.swal.title = "Pronto!";
			this.swal.show();
			this.submitted = false;
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}

	onCloseModal(){
		if(this.swal.type == "success"){
			this.projetoService.getProjetoById(this.activatedRoute.snapshot.params['id']).subscribe((success)=>{
				this.projetosAtividades = success.dados.atividade;
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
		}
	}
}