import { PreCadastroService } from './../../../pre-cadastro/pre-cadastro.service';
import { PessoaService } from '../../pessoa.service';
import { Atleta } from './../atleta';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { FormPessoaComponent } from '../../../_includes/forms/form-pessoa/form-pessoa.component';
import { Federacao } from '../../federacao/federacao';
import { Clube } from '../../clube/clube';
import { SYSCONF_SIGLA } from 'src/app.api';

declare var $: any;
@Component({
	selector: 'app-atleta-create',
  	templateUrl: './atletaCreate.component.html',
  	styleUrls: ['./atletaCreate.component.scss']
})

export class AtletaCreateComponent implements OnInit{
	
	@ViewChild('Swal') public swal:SwalComponent; 
	public message:string = '';
	cadastroForm: FormGroup;
	atleta: Atleta;
	genero;
	federacoes: Federacao[];
	clubes: Clube[];
	graduacoes: [{}];
	modalidades: [{}];
	estadoCivil: [{}];
	sigla = SYSCONF_SIGLA;
	constructor(private pessoaService: PessoaService, private preCadastroService: PreCadastroService, private formBuilder: FormBuilder, private router: Router, private form: FormPessoaComponent){
		this.cadastroForm = this.formBuilder.group({
			pessoa_id: this.formBuilder.control(''),
			estrangeiro: this.formBuilder.control('N',[Validators.required]),
			passaporte: this.formBuilder.control(''),
			passaporte_dt_validade: this.formBuilder.control(''),
			cpf: this.formBuilder.control('',[Validators.required]),
			dt_nascimento: this.formBuilder.control('',[Validators.required]),
			primeiro_nome: this.formBuilder.control('',[Validators.required]),
			ultimo_nome: this.formBuilder.control('',[Validators.required]),
			nome_completo: this.formBuilder.control(''),
			apelido: this.formBuilder.control(''),
			email: this.formBuilder.control('',[Validators.required, Validators.email]),
			nacionalidade: this.formBuilder.control('Brasileiro',[Validators.required]),
			naturalidade: this.formBuilder.control('',[Validators.required]),
			status: this.formBuilder.control('A',[Validators.required]),
			genero_id: this.formBuilder.control('',[Validators.required]),
			rg: this.formBuilder.control(''),
			rg_dt_emissao: this.formBuilder.control(''),
			rg_emissor: this.formBuilder.control(''),
			rg_uf: this.formBuilder.control(''),
			altura: this.formBuilder.control(''),
			peso: this.formBuilder.control(''),
			tipo_sanguineo: this.formBuilder.control(''),
			tamanho_camisa: this.formBuilder.control(''),
			tamanho_agasalho: this.formBuilder.control(''),
			tamanho_calca: this.formBuilder.control(''),
			tamanho_calcado: this.formBuilder.control(''),
			tamanho_chinelo: this.formBuilder.control(''),
			federado: this.formBuilder.control('N',[Validators.required]),
			federacao_id: this.formBuilder.control(''),
			clube_id: this.formBuilder.control(''),
			situacao_federacao: this.formBuilder.control(''),
			dt_registro_federacao: this.formBuilder.control(''),
			situacao_confederacao: this.formBuilder.control(''),
			graduacao_id: this.formBuilder.control(''),
			modalidade: new FormArray([]),
			dt_graduacao: this.formBuilder.control(''),
			website: this.formBuilder.control(''),
			observacao: this.formBuilder.control(''),
			estado_civil_id: this.formBuilder.control('',[Validators.required]),
			pis: this.formBuilder.control(''),
			grau_instrucao_id: this.formBuilder.control('', [Validators.required]),
			selecao: this.formBuilder.control('N',[Validators.required]),
		});	

		this.pessoaService.getOptions('pessoa/federacao')
        .subscribe( (success) => {
			this.federacoes = success.dados;
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});

		this.pessoaService.getOptions('pessoa/clube')
        .subscribe( (success) => {
			this.clubes = success.dados;
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});

		this.preCadastroService.get('pessoa/genero')
        .subscribe( (success) => {
			this.genero = success.dados;
			
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});

		this.preCadastroService.get('graduacao')
        .subscribe( (success) => {
			this.graduacoes = success.dados;
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});

		this.preCadastroService.get('modalidade')
        .subscribe( (success) => {
			this.modalidades = success.dados;
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});

		this.preCadastroService.get('pessoa/estado-civil')
        .subscribe( (success) => {
			this.estadoCivil = success.dados;
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});

	}

	ngOnInit(){
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.pessoa_atleta.includes("CRIAR"))
			this.message = 'Você não possui permissão para executar esta ação'
	}
	
	
}