export class Pessoa{
    id:number;
    genero_id:number;
    genero:string;
    dt_cadastro:string;
    dt_cadastro_br:string;
    dt_atualizacao:string;
    dt_atualizacao_br:string;
    primeiro_nome:string;
    ultimo_nome:string;
    nome_completo:string;
    razao_social:string;
    apelido:string;
    estrangeiro: string;
    passaporte:string;
    passaporte_dt_validade:string;
    passaporte_dt_validade_br:string;
    cpf:string;
    cnpj:string;
    email:string;
    rg:string;
    rg_uf:string;
    rg_dt_emissao:string;
    rg_dt_emissao_br:string;
    rg_emissor:string;
    nacionalidade:string;
    naturalidade:string;
    pis:string;
    grau_instrucao_id:number;
    grau_instrucao:string;
    foto:any;
    observacao:string;
    peso:string;
    altura:string;
	tamanho_camisa: string;
	tamanho_agasalho: string;
	tamanho_calca: string;
	tamanho_calcado: string;
	tamanho_chinelo: string;
    dt_nascimento:string;
    dt_nascimento_br:string;
    endereco:object = new Array();
    website:string;
    cargo:string;
    estado_civil_id:number;
    estado_civil:string;
    tipo_sanguineo: string;
    acesso: [];
}