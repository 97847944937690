import { Component, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { PessoaService } from '../../../pessoa/pessoa.service';
import { NgxViacepService } from '@brunoc/ngx-viacep';
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;
@Component({
	selector: 'app-form-endereco',
  	templateUrl: './form-endereco.component.html',
  	styleUrls: ['./form-endereco.component.scss']
})
export class FormEnderecoComponent{
	@ViewChild('Swal') public swal:SwalComponent;
	@ViewChild('SwalDelete') public swalDelete:SwalComponent; 
	@Input() enderecos;
	@Input() pessoa_id: number;
	@Input() detalhe:boolean;
	@Input() pessoa_status: string = 'A';
	form: FormGroup;
	endereco_id:number = null;
	modalTitle:string;
	submitted: boolean = false;
	loading: boolean = false;
	modalReference: NgbModalRef;   
    leitura:boolean = false;
	constructor(private pessoaService: PessoaService, private formBuilder: FormBuilder, private router: Router, private viaCep: NgxViacepService,private modalService: NgbModal){
        this.formInit();
    }
	formInit():void{
		this.form = this.formBuilder.group({
			estrangeiro: this.formBuilder.control('N',[Validators.required]),
			descricao: this.formBuilder.control('',[Validators.required]),
			cep: this.formBuilder.control('',[Validators.required]),
			logradouro: this.formBuilder.control('',[Validators.required]),
			numero: this.formBuilder.control('',[Validators.required]),
			complemento: this.formBuilder.control(''),
			bairro: this.formBuilder.control('',[Validators.required]),
			cidade: this.formBuilder.control('',[Validators.required]),
			estado: this.formBuilder.control('',[Validators.required]),
			estado_leitura: this.formBuilder.control(''),
			codigo_postal: this.formBuilder.control(''),
			endereco1: this.formBuilder.control(''),
			endereco2: this.formBuilder.control('')
		})
		this.leitura = false;
	}
	changeRadioValue(form,name,value){
		this[form].patchValue({[name]: value});
	}
	setValidators(estrangeiro:string):void{
		this.form.get('cep').setValidators([]);
		this.form.get('logradouro').setValidators([]);
		this.form.get('numero').setValidators([]);
		this.form.get('bairro').setValidators([]);
		this.form.get('cidade').setValidators([]);
		this.form.get('estado').setValidators([]);
		this.form.get('codigo_postal').setValidators([]);
		this.form.get('endereco1').setValidators([]);
		if(estrangeiro === 'N'){
			this.form.get('cep').setValidators([Validators.required]);
			this.form.get('logradouro').setValidators([Validators.required]);
			this.form.get('numero').setValidators([Validators.required]);
			this.form.get('bairro').setValidators([Validators.required]);
			this.form.get('cidade').setValidators([Validators.required]);
			this.form.get('estado').setValidators([Validators.required]);
		}else{
			this.form.get('codigo_postal').setValidators([Validators.required]);
			this.form.get('endereco1').setValidators([Validators.required]);
		}
		this.form.get('cep').updateValueAndValidity();
		this.form.get('logradouro').updateValueAndValidity();
		this.form.get('numero').updateValueAndValidity();
		this.form.get('bairro').updateValueAndValidity();
		this.form.get('cidade').updateValueAndValidity();
		this.form.get('estado').updateValueAndValidity();
		this.form.get('codigo_postal').updateValueAndValidity();
		this.form.get('endereco1').updateValueAndValidity();
	}

	getEndereco():void{
		this.leitura = false;
		if(this.form.value.estrangeiro == 'S')
			return;
		let cep = this.form.value.cep;
		this.viaCep.buscarPorCep(cep).then((endereco) =>{
			this.form.patchValue({
				logradouro: endereco.logradouro,
				bairro: endereco.bairro,
				cidade: endereco.localidade,
				estado: endereco.uf,
				estado_leitura: endereco.uf
			});
			this.leitura = true;
		}).catch(
			(error) => {
				this.leitura = false;
				this.swal.text = error.toString().replace('Error: ','');
				this.swal.type = "warning";
				this.swal.title = "Oops...";
				this.swal.show();
			}
		)
	}

	onSubmit(){
		this.submitted = true;
        if(!this.form.valid)
		return;
		this.modalReference.close();
		if(!this.endereco_id){
			this.pessoaService.addEndereco(this.pessoa_id,this.form)
			.subscribe( (success) => {
				this.swal.text = "Endereço cadastrado com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
			return;
		}

		this.pessoaService.editEndereco(this.endereco_id,this.form)
			.subscribe( (success) => {
				this.swal.text = "Endereço atualizado com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
	}

	openModal(modal,id:number = null) {
		this.formInit();
		//Se id == null então é cadastro.
		if(!id){
			this.endereco_id = null;
			this.modalTitle = "Cadastrar Endereço";
			this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
			return;
		}
		//Senão é edição.
		this.endereco_id = id;
		this.modalTitle = "Editar Endereço";
		this.pessoaService.getEnderecoById(id).subscribe((success) => {
			this.form.patchValue({
				descricao: success.dados.descricao,
				estrangeiro: success.dados.estrangeiro,
				cep: success.dados.cep,
				logradouro: success.dados.logradouro,
				numero: success.dados.numero,
				complemento: success.dados.complemento,
				bairro: success.dados.bairro,
				cidade: success.dados.cidade,
				estado: success.dados.estado,
				estado_leitura: success.dados.estado,
				codigo_postal: success.dados.cep,
				endereco1: success.dados.endereco1,
				endereco2: success.dados.endereco2,
			});
			this.leitura = true;
			this.setValidators(success.dados.estrangeiro)
			this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
		},
		(error) => {
			this.leitura = false;
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		})
	}

	askDelete(id:number){
		this.endereco_id = id;
		this.swalDelete.show();
	}

	confirmDelete(){
		this.pessoaService.delEndereco(this.endereco_id).subscribe( 
		(success) => {
			this.swal.text = "Endereço deletado com sucesso!";
			this.swal.type = "success";
			this.swal.title = "Pronto!";
			this.swal.show();
			this.submitted = false;
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}

	onCloseModal(){
		if(this.swal.type == "success"){
			this.pessoaService.getPessoaById('pessoa',this.pessoa_id).subscribe((success)=>{
				this.enderecos = success.dados.endereco;
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
		}
	}
}