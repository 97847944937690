import { Component, AfterViewInit } from '@angular/core';
import { SYSCONF_SIGLA } from 'src/app.api';
@Component({
	templateUrl: './politica-cookies.component.html',
	styleUrls: [
        './politica-cookies.component.scss'
    ]
})

export class PoliticaCookiesComponent implements AfterViewInit{
	private preloader = document.getElementsByClassName('preloader')[0];
	sigla;
	constructor(){
		this.sigla = SYSCONF_SIGLA;
	}
	ngAfterViewInit(){
		this.preloader.setAttribute("style","display:none");
	}
}