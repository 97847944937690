import { ProjetoService } from 'src/app/projeto/projeto.service';
import { Component, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';
import { PreCadastroService } from 'src/app/pre-cadastro/pre-cadastro.service';
@Component({
	selector: 'app-form-projeto-etapa',
  	templateUrl: './form-projeto-etapa.component.html',
  	styleUrls: ['./form-projeto-etapa.component.scss']
})
export class FormProjetoEtapaComponent{
	@ViewChild('Swal') public swal:SwalComponent;
	@ViewChild('SwalDelete') public swalDelete:SwalComponent; 
	@Input() projetosEtapas = new Array();
	@Input() acao:string;
	@Input() stakeholdersSelect = new Array();
	@Input() detalhe: boolean;
	create:boolean;
	edit:boolean;
	detail:boolean;
	delete:boolean;
	modalDetalhe:boolean = false;
	form: FormGroup;
	projeto_etapa_id:number = null;
	modalTitle:string;
	submitted: boolean = false;
	loading: boolean = false;
	modalReference: NgbModalRef;
	statusSelect = new Array();
	 
	constructor(private projetoService: ProjetoService, private preCadastroService:PreCadastroService, private formBuilder: FormBuilder, private modalService: NgbModal, private activatedRoute: ActivatedRoute){
		
		this.preCadastroService.getOptions('projeto/status').subscribe((success) => {
			this.statusSelect = success.dados;
		},(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		})

        this.form = this.formBuilder.group({
			nome: this.formBuilder.control('',[Validators.required]),
			projeto_stakeholder_id: this.formBuilder.control('',[Validators.required]),
			projeto_stakeholder: this.formBuilder.control(''),
			dt_previsao_inicio: this.formBuilder.control('',[Validators.required]),
			dt_previsao_termino: this.formBuilder.control('',[Validators.required]),
			dt_inicio: this.formBuilder.control(''),
			dt_termino: this.formBuilder.control(''),
			observacao: this.formBuilder.control(''),
			projeto_etapa_status_id: this.formBuilder.control('',[Validators.required])
		})
	}

	ngOnInit(){ 
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(t.permissao.projeto_etapa.includes("CONSULTAR"))
			this.detail = true;
		if(t.permissao.projeto_etapa.includes("CRIAR"))
			this.create = true;
		if(t.permissao.projeto_etapa.includes("EDITAR"))
			this.edit = true;
		if(t.permissao.projeto_etapa.includes("EXCLUIR"))
			this.delete = true;
	}
	
	onSubmit(){
		this.submitted = true;
        if(!this.form.valid)
		return;
		this.modalReference.close();
		if(!this.projeto_etapa_id){
			this.projetoService.addProjetoEtapa(this.activatedRoute.snapshot.params['id'], this.form)
			.subscribe( (success) => {
				this.swal.text = "Etapa cadastrada com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
				this.zeraForm();
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
			return;
		}

		this.projetoService.editProjetoEtapa(this.projeto_etapa_id,this.form)
			.subscribe( (success) => {
				this.swal.text = "Etapa atualizada com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
				this.zeraForm();
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
				this.zeraForm();
			});

		
	}
	zeraForm():void{
		this.form.patchValue({
			nome: '',
			projeto_stakeholder_id: '',
			projeto_stakeholder: '',
			dt_previsao_inicio: '',
			dt_previsao_termino: '',
			dt_inicio: '',
			dt_termino: '',
			observacao: '',
			projeto_etapa_status_id: ''
		});
	}

	openModal(modal,id:number = null,detalhe:boolean = false) {
		this.projetoService.getProjetoById(this.activatedRoute.snapshot.params['id']).subscribe((success)=>{
			this.stakeholdersSelect = success.dados.stakeholder;
		
			this.modalDetalhe = detalhe;
			//Se id == null então é cadastro.
			if(!id){
				this.projeto_etapa_id = null;
				this.modalTitle = "Cadastrar Etapa";
				this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
				return;
			}
			
			//Senão é edição.
			this.projeto_etapa_id = id;
			this.modalTitle = "Editar Etapa";
			this.projetoService.getProjetoEtapaById(id).subscribe((success) => {
				this.form.patchValue({
					nome: success.dados.nome,
					projeto_stakeholder_id: success.dados.projeto_stakeholder_id,
					projeto_stakeholder: success.dados.projeto_stakeholder,
					dt_previsao_inicio: success.dados.dt_previsao_inicio_br,
					dt_previsao_termino: success.dados.dt_previsao_termino_br,
					dt_inicio: success.dados.dt_inicio_br,
					dt_termino: success.dados.dt_termino_br,
					observacao: success.dados.observacao,
					projeto_etapa_status_id: success.dados.projeto_etapa_status_id
				});
				this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
			})
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}

	askDelete(id:number){
		this.projeto_etapa_id = id;
		this.swalDelete.show();
	}

	confirmDelete(){
		this.projetoService.delProjetoEtapa(this.projeto_etapa_id).subscribe( 
		(success) => {
			this.swal.text = "Etapa deletada com sucesso!";
			this.swal.type = "success";
			this.swal.title = "Pronto!";
			this.swal.show();
			this.submitted = false;
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}

	onCloseModal(){
		if(this.swal.type == "success"){
			this.projetoService.getProjetoById(this.activatedRoute.snapshot.params['id']).subscribe((success)=>{
				this.projetosEtapas = success.dados.etapa;
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
		}
	}
}