import { PessoaService } from './../../pessoa.service';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { Federacao } from './../../federacao/federacao';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SYSCONF_SIGLA } from 'src/app.api';
@Component({
	selector: 'app-clube-create',
  	templateUrl: './clubeCreate.component.html',
  	styleUrls: ['./clubeCreate.component.scss']
})
export class ClubeCreateComponent implements OnInit{
	@ViewChild('Swal') public swal:SwalComponent; 
	public message:string = '';
	cadastroForm: FormGroup;
	federacoes: Federacao[];
	sigla = SYSCONF_SIGLA;
	constructor(private pessoaService: PessoaService, private formBuilder: FormBuilder){
		this.pessoaService.getOptions('pessoa/federacao')
        .subscribe( (success) => {
			this.federacoes = success.dados;
		},
		(error) => {
			// this.swal.text = error.error.message;
			// this.swal.type = "error";
			// this.swal.title = "Erro!";
			// this.swal.show();
		});
		this.cadastroForm = this.formBuilder.group({
			pessoa_id: this.formBuilder.control(''),
			cnpj: this.formBuilder.control('',[Validators.required]),
			razao_social: this.formBuilder.control('',[Validators.required]),
			nome_completo: this.formBuilder.control('',[Validators.required]),
			email: this.formBuilder.control('',[Validators.required, Validators.email]),
			status: this.formBuilder.control('A',[Validators.required]),
			federacao_id: this.formBuilder.control(''),
			sigla: this.formBuilder.control('',[Validators.required]),
			federado: this.formBuilder.control('N',[Validators.required]),
			website: this.formBuilder.control(''),
			observacao: this.formBuilder.control('')
		});	
		
	}
	ngOnInit(){
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.pessoa_clube.includes("CRIAR"))
			this.message = 'Você não possui permissão para executar esta ação'
	}
}