import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SYSCONF_API, SYSCONF_SIGLA, SYSCONF_APP_ID, SYSCONF_CREDENTIALS, SYSCONF_LIMIT } from '../../app.api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { tap } from 'rxjs/operators';
import { FormHelper } from '../_includes/forms/form-helper';

@Injectable()
export class ImportacaoService{
    importacao = new Array();
    private headers;
    cob = null;
    constructor(private http: HttpClient, private formHelper: FormHelper){
    this.cob = JSON.parse(localStorage.getItem('CONFLogin'));
        this.headers = {'App-Id': SYSCONF_APP_ID,'Content-Type':'application/json','App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
    }

    getImportacaoById(id: number):Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}
        return this.http.get(`${SYSCONF_API}/importacao/${id}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response.dados,
                error => error
            )
        );
    }
    
    list(params, limit = SYSCONF_LIMIT): Observable<any>{
        let criterioUrl:string = '';
        criterioUrl = this.formHelper.getConditionURL(params);
        return this.http.get(`${SYSCONF_API}/importacao?limit=${limit}&page=${params.page}&${criterioUrl}`,{headers: new HttpHeaders(this.headers),withCredentials: SYSCONF_CREDENTIALS})
        .pipe(
            tap(
                response => response,
                error => error
            )
        );
    }

    add(arquivo:File): Observable<any>{
        this.headers = {'App-Id': SYSCONF_APP_ID,'App-Token': this.cob.token.key,'App-Sigla': SYSCONF_SIGLA}

        let formulario = new FormData();
        formulario.append('arquivo',arquivo);
        
        return this.http.post(`${SYSCONF_API}/importacao`,formulario,
        {headers: new HttpHeaders(this.headers)})
        .pipe(
            tap(
                response => this.importacao = response.dados,
                error => error
            )
        )
    }
}