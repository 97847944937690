import { PessoaService } from '../../pessoa.service';
import { Patrocinador } from './../patrocinador';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { SYSCONF_SIGLA } from 'src/app.api';
@Component({
	selector: 'app-patrocinador-detail',
  	templateUrl: './patrocinadorDetail.component.html',
  	styleUrls: ['./patrocinadorDetail.component.scss']
})

export class PatrocinadorDetailComponent implements OnInit{
	@ViewChild('Swal') public swal:SwalComponent; 
	public message:string = '';
	detailForm: FormGroup;
	patrocinador: Patrocinador;
	patrocinador_id:number;
	thumb:string = '';
	pessoa_id:number;
	enderecos:object = new Array();
	telefones:object = new Array();
	contasBancarias:object = new Array();
	sigla = SYSCONF_SIGLA;
	anexos: any;
	acesso = new Array();
	constructor(private pessoaService: PessoaService, private activatedRoute: ActivatedRoute,private formBuilder: FormBuilder){
		this.detailForm = this.formBuilder.group({
			id: this.formBuilder.control(''),
			dt_cadastro: this.formBuilder.control(''),
			dt_atualizacao: this.formBuilder.control(''),
			cnpj: this.formBuilder.control(''),
			razao_social: this.formBuilder.control(''),
			nome_completo: this.formBuilder.control(''),
			email: this.formBuilder.control(''),
			status: this.formBuilder.control(''),
			nome_representante: this.formBuilder.control(''),
			website: this.formBuilder.control(''),
			observacao: this.formBuilder.control(''),
			foto: this.formBuilder.control('')
		});	
		this.patrocinador_id = this.activatedRoute.snapshot.params['id'];
		this.pessoaService.getPessoaById('pessoa/patrocinador',this.patrocinador_id).subscribe(
		(patrocinador) => {
			this.patrocinador = patrocinador.dados;
			this.pessoa_id = this.patrocinador.pessoa_id;
			this.acesso = this.patrocinador.acesso;
			if(this.patrocinador.foto){
				this.thumb = this.patrocinador.foto.thumb;
			}
			

			this.pessoaService.getPessoaById('pessoa',this.pessoa_id).subscribe(
				(success) =>{
					this.enderecos = success.dados.endereco;
					this.telefones = success.dados.telefone;
					this.contasBancarias = success.dados.conta_bancaria;
					this.anexos = success.dados.anexo;
				},
				(error) => {
					this.message = error.error.message;
				})
				this.detailForm.patchValue({
					id: this.patrocinador.id,
					dt_cadastro: this.patrocinador.dt_cadastro_br,
					dt_atualizacao: this.patrocinador.dt_atualizacao_br,
					cnpj: this.patrocinador.cnpj,
					razao_social: this.patrocinador.razao_social,
					nome_completo: this.patrocinador.nome_completo,
					email: this.patrocinador.email,
					status: this.patrocinador.status,
					nome_representante: this.patrocinador.nome_representante,
					website: this.patrocinador.website,
					observacao: this.patrocinador.observacao,
					foto: this.thumb
				});
			})
		}

	ngOnInit(){
		let t = JSON.parse(localStorage.getItem('CONFLogin'));
		if(!t.permissao.pessoa_patrocinador.includes("CONSULTAR"))
			this.message = 'Você não possui permissão para executar esta ação'
	}
	
	
}