import { Component, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { PessoaService } from '../../../pessoa/pessoa.service';
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';
import { PreCadastroService } from '../../../pre-cadastro/pre-cadastro.service';
import { LoginService } from './../../../login/login.service';

declare var $: any;
@Component({
	selector: 'app-form-financeiro-conta-bancaria',
  	templateUrl: './form-financeiro-conta-bancaria.component.html',
  	styleUrls: ['./form-financeiro-conta-bancaria.component.scss']
})
export class FormFinanceiroContaBancariaComponent{
	@ViewChild('Swal') public swal:SwalComponent;
	@ViewChild('SwalDelete') public swalDelete:SwalComponent; 
	@Input() acao:string;
	@Input() detalhe:boolean;
	form: FormGroup;
	conta_bancaria_id:number = null;
	modalTitle:string;
	submitted: boolean = false;
	loading: boolean = false;
	modalReference: NgbModalRef;   
	bancos: object = new Array();
	contasBancarias = new Array();
	constructor(public loginService: LoginService, private pessoaService: PessoaService, private preCadastroService: PreCadastroService, private formBuilder: FormBuilder, private modalService: NgbModal){
		this.pessoaService.listBancos()
        .subscribe( (success) => {
			this.bancos = success.dados;
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
		
		this.formInit();

		this.preCadastroService.get('financeiro/conta-bancaria')
        .subscribe( (success) => {
			this.contasBancarias = success.dados;
		},
		(error) => {});
	}
	
	changeRadioValue(form,name,value){
		this[form].patchValue({[name]: value});
	}

	formInit():void{
		this.form = this.formBuilder.group({
			banco_id: this.formBuilder.control('',[Validators.required]),
			descricao: this.formBuilder.control('',[Validators.required]),
			num_conta: this.formBuilder.control('',[Validators.required]),
			dv_conta: this.formBuilder.control('',[Validators.required, Validators.minLength(1), Validators.maxLength(1)]),
			num_agencia: this.formBuilder.control('',[Validators.required]),
			dv_agencia: this.formBuilder.control('',[Validators.required, Validators.minLength(1), Validators.maxLength(1)]),
			tipo: this.formBuilder.control('CC',[Validators.required]),
			status: this.formBuilder.control('A',[Validators.required]),
			ano_vigencia: this.formBuilder.control('',[Validators.required, Validators.minLength(4), Validators.maxLength(4)]),
			saldo: this.formBuilder.control(''),
			variacao: this.formBuilder.control('')
		})
	}

	onSubmit(){
		this.submitted = true;
        if(!this.form.valid)
		return;
		this.modalReference.close();
		if(!this.conta_bancaria_id){
			this.preCadastroService.add('financeiro/conta-bancaria',this.form)
			.subscribe( (success) => {
				this.swal.text = "Conta bancária cadastrada com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
				this.formInit();
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
			return;
		}

		this.preCadastroService.edit('financeiro/conta-bancaria',this.conta_bancaria_id,this.form)
			.subscribe( (success) => {
				this.swal.text = "Conta bancária atualizada com sucesso!";
				this.swal.type = "success";
				this.swal.title = "Pronto!";
				this.swal.show();
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});

        
	}
	
	openModal(modal,id:number = null) {
		this.formInit();
		//Se id == null então é cadastro.
		if(!id){
			this.conta_bancaria_id = null;
			this.modalTitle = "Cadastrar Conta Bancária";
			this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
			return;
		}
		
		//Senão é edição.
		this.conta_bancaria_id = id;
		this.modalTitle = "Editar Conta Bancária";
		this.preCadastroService.getById('financeiro/conta-bancaria',id).subscribe((success) => {
			this.form.patchValue({
				banco_id: success.dados.banco_id,
				descricao: success.dados.descricao,
				num_conta: success.dados.num_conta,
				dv_conta: success.dados.dv_conta,
				num_agencia: success.dados.num_agencia,
				dv_agencia: success.dados.dv_agencia,
				tipo: success.dados.tipo,
				status: success.dados.status,
				ano_vigencia: success.dados.ano_vigencia,
				saldo: success.dados.saldo,
				variacao: success.dados.variacao
			});
			this.modalReference = this.modalService.open(modal,{size: 'lg', backdrop: 'static' });
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
		})
	}

	askDelete(id:number){
		this.conta_bancaria_id = id;
		this.swalDelete.show();
	}

	confirmDelete(){
		this.preCadastroService.del('financeiro/conta-bancaria',this.conta_bancaria_id).subscribe( 
		(success) => {
			this.swal.text = "Conta bancária deletada com sucesso!";
			this.swal.type = "success";
			this.swal.title = "Pronto!";
			this.swal.show();
			this.submitted = false;
		},
		(error) => {
			this.swal.text = error.error.message;
			this.swal.type = "error";
			this.swal.title = "Erro!";
			this.swal.show();
			this.submitted = false;
		});
	}

	onCloseModal(){
		if(this.swal.type == "success"){
			this.preCadastroService.get('financeiro/conta-bancaria').subscribe((success)=>{
				this.contasBancarias = success.dados;
				this.submitted = false;
			},
			(error) => {
				this.swal.text = error.error.message;
				this.swal.type = "error";
				this.swal.title = "Erro!";
				this.swal.show();
				this.submitted = false;
			});
		}
	}
}